import { normalize, schema } from 'normalizr';
import { API_HOST } from 'config';
import { get, put, post, del } from 'lib/request';
import { FETCH_BRANDS_REQUEST, FETCH_BRANDS_SUCCESS } from 'types/actions';
import { addNotification } from '../notifications/actions';

export const brandsEntity = new schema.Entity('brands');

export const receiveBrands = brands => ({
  type: FETCH_BRANDS_SUCCESS,
  brands,
});

export const requestBrands = value => ({
  type: FETCH_BRANDS_REQUEST,
  value,
});

export const getBrands = () => dispatch => {
  dispatch(requestBrands(true));
  get(`${API_HOST}/brands?include=aliases,manufacturer`)
    .then(
      (res) => {
        const brands = normalize(res.data, {
          brands: [brandsEntity],
        });
        dispatch(receiveBrands(brands.entities.brands));
      },
      () => { dispatch(addNotification('Неудачная попытка загрузки брендов')); },
    );
};

export const editBrand = (idBrand, name, slug, adapted_name, idManufacturer) => dispatch =>
  put(`${API_HOST}/brands/${idBrand}`, {
    name,
    slug,
    adapted_name,
    manufacturer: idManufacturer,
  })
    .then(
      () => { dispatch(getBrands()); },
      () => { dispatch(addNotification('Неудачная попытка сохранения данных бренда')); },
    );
export const deleteAlias = aliasId => dispatch =>
  del(`${API_HOST}/aliases/${aliasId}`)
    .then(
      () => dispatch(getBrands()),
      () => { dispatch(addNotification('Неудачная попытка удаления синонима бренда')); },
    );

export const addBrand = (name, manufacturer, action) => dispatch =>
  post(`${API_HOST}/brands`, { manufacturer, name })
    .then(
      () => {
        dispatch(action());
        dispatch(getBrands());
      },
      () => { dispatch(addNotification('Неудачная попытка создать новый бренд')); },
    );

export const addBrandWithUpdate = (name, manufacturer) => dispatch =>
  dispatch(addBrand(name, manufacturer, getBrands));
