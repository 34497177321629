// @flow
import { type ThunkAction, type Dispatch } from 'types/actions';
import { type Promotion } from 'types/entities/Promotion';
import { addNotification } from 'components/notifications/actions';
import {
  FETCH_PROMOTION_REQUEST,
  FETCH_PROMOTION_SUCCESS,
  type RequestPromotion,
  type ReceivePromotion,
} from 'types/actions/Promotion';
import {
  fetchPromotion as fetchPromotionFromApi,
  putPromotion as putPromotionByApi,
  uploadPromotionBanner as uploadPromotionBannerByApi,
  uploadPromotionVobler as uploadPromotionVoblerByApi,
  deletePromotionVobler as deletePromotionVoblerByApi,
  deletePromotionBanner as deletePromotionBannerByApi,
} from 'services/promotionApi';

export const requestPromotion = (): RequestPromotion => ({
  type: FETCH_PROMOTION_REQUEST,
});

export const receivePromotion = (promotion: Promotion | null): ReceivePromotion => ({
  type: FETCH_PROMOTION_SUCCESS,
  promotion,
});

export const fetchPromotion =
  (promotionId: number): ThunkAction =>
  (dispatch: Dispatch) => {
    dispatch(requestPromotion());
    return fetchPromotionFromApi(promotionId).then(promotion =>
      dispatch(receivePromotion(promotion)),
    );
  };

export const putPromotion =
  (promotionId: number, promotion: Promotion): ThunkAction =>
  (dispatch: Dispatch) =>
    putPromotionByApi(promotionId, promotion).then(() => dispatch(fetchPromotion(promotionId)));

export const uploadPromotionBanner =
  (promotionId: number, file: any): ThunkAction =>
  (dispatch: Dispatch) => {
    const banner = new FormData();
    banner.append('banner', file);
    return uploadPromotionBannerByApi(promotionId, banner).then(
      () => dispatch(fetchPromotion(promotionId)),
      errors => dispatch(addNotification(errors)),
    );
  };

export const deletePromotionVobler =
  (promotionId: number, file: any): ThunkAction =>
  (dispatch: Dispatch) =>
    deletePromotionVoblerByApi(promotionId).then(
      () => dispatch(fetchPromotion(promotionId)),
      errors => dispatch(addNotification(errors)),
    );

export const deletePromotionBanner =
  (promotionId: number, file: any): ThunkAction =>
  (dispatch: Dispatch) =>
  deletePromotionBannerByApi(promotionId).then(
      () => dispatch(fetchPromotion(promotionId)),
      errors => dispatch(addNotification(errors)),
    );

export const uploadPromotionVobler =
  (promotionId: number, file: any): ThunkAction =>
  (dispatch: Dispatch) => {
    const banner = new FormData();
    banner.append('image', file);
    return uploadPromotionVoblerByApi(promotionId, banner).then(
      () => dispatch(fetchPromotion(promotionId)),
      errors => dispatch(addNotification(errors)),
    );
  };
