// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';
import { type ReactComponent } from 'types/components/ReactComponent';
import { orderStates, orderStatesNames, type OrderState } from 'types/entities/OrderState';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import { ButtonBase } from '@material-ui/core';

const stateColors = {
  [orderStates.processing]: blue[500],
  [orderStates.canceled]: red[700],
  [orderStates.confirmed]: orange[500],
  [orderStates.assembled]: green[500],
  [orderStates.completed]: grey[500],
  [orderStates.all]: grey[700],
};

const styles = (theme: Theme) => ({
  root: {
    display: 'inline-block',
    width: '90px',
  },
  label: {
    color: '#fff',
    padding: '2px 6px',
    borderRadius: '16px',
    fontWeight: 900,
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.4,
    fontFamily: theme.typography.fontFamily,
  },
});

type ProvidedProps = {|
  classes: Object,
|};

type Props = {|
  classes: Object,
  state: OrderState,
  onClick?: () => void,
|};

const OrderStateLabelComponent = ({
  classes,
  state,
  onClick,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const getStyles = () => ({
    backgroundColor: stateColors[state],
  });

  const handleOnClick = () => {
    if (onClick) onClick();
  };
  const renderLabel = () => (
    <span className={classes.label} style={getStyles()}>
      {orderStatesNames[state]}
    </span>
  );

  if (onClick) {
    return (
      <ButtonBase className={classes.root} onClick={handleOnClick}>
        {renderLabel()}
      </ButtonBase>
    );
  }
  return <span className={classes.root}>{renderLabel()}</span>;
};

export const OrderStateLabel: ReactComponent<Props> = withStyles(styles)(OrderStateLabelComponent);
