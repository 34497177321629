// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { type Theme } from 'types/components/Theme';
import { type OrderLine as OrderLineType } from 'types/entities/OrderLine';
import ProductPreview from 'components/product-preview';
import cn from 'classnames';
import Plan from './Plan';

const styles = (theme: Theme) => ({
  line: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    display: 'flex',
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  priceBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '12px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  warning: {
    backgroundColor: theme.palette.secondary[50],
  },
});
type Props = {|
  classes: Object,
  index: number,
  line: OrderLineType | null,
  priceBlock: any,
  actions: any,
|};

const OrderLine = ({ classes, index, line, priceBlock, actions }: Props) => {
  if (!line) return null;
  return (
    <Grid
      container
      spacing={16}
      className={cn(
        classes.line,
        line.provided_quantity !== null &&
          line.provided_quantity !== line.quantity &&
          classes.warning,
      )}
    >
      <Grid item xs={12} md={7}>
        <ProductPreview product={line.product} index={index + 1} />
      </Grid>
      <Grid item xs={12} md={4}>
        <div className={classes.priceBlock}>{priceBlock}</div>
      </Grid>
      <Grid item xs={12} md={1}>
        <div className={classes.actions}>{actions}</div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(OrderLine);
