import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import Collapse from '@material-ui/core/Collapse';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Divider from '@material-ui/core/Divider';
import blue from '@material-ui/core/colors/blue';
import Dustbin from './Dustbin';
import Box from './Box';

/* eslint-disable no-underscore-dangle */

const styles = theme => ({
  root: {
    maxWidth: '600px',
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  categoryName: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    width: '25px',
    height: '25px',
    marginLeft: theme.spacing.unit,
  },
  title: {
    marginTop: '6px',
  },
  draggingOver: {
    backgroundColor: blue[200],
  },
  list: {
    padding: 0,
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '6px',
    paddingBottom: '6px',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  nameWithArrow: {
    display: 'flex',
    alignItems: 'center',
  }
});

class Categories extends React.Component {
  state = {
    tree: this.props.tree,
    expandedCategories: {},
    dragging: null,
    draggingOver: null,
    droppedOn: null,
  };
  componentWillReceiveProps(nextProps) {
    this.setState({ tree: nextProps.tree });
  }
  onDragEnd = () => {
    if (!this.state.droppedOn) return this.setState({ 
      dragging: null,
      draggingOver: null,
      droppedOn: null,
    });
    const draggedId = this.state.dragging.id;
    const destination = {
      index: this.state.droppedOn.index,
      id: this.state.droppedOn.parent,
    };
    let newTree = [...this.state.tree];
    let deletedCategory;
    const deleteCategory = data => {
      data.forEach((x, index) => {
        if (x.id === draggedId) {
          deletedCategory = x;
          data.splice(index, 1);
        }
      });
      return data;
    };
    const recursivelyDeleteCategory = data => {
      data = deleteCategory(data);
      data.map(x => recursivelyDeleteCategory(x.__children));
      return data;
    };
    const putCategory = data => {
      const changedData = [...data];
      data.forEach((x, index) => {
        if (x.id === destination.id) {
          changedData[index].__children.splice(
            destination.index,
            0,
            deletedCategory,
          );
          this.props.changeCategory(destination.id, changedData[index].__children);
        }
      });
      return changedData;
    };
    const recursivelyPutCategory = data => {
      data = putCategory(data);
      if (destination.id === 0) {
        data.splice(destination.index, 0, deletedCategory);
        this.props.changeCategory(destination.id, data);
      } else {
        data.forEach(x => recursivelyPutCategory(x.__children));
      }
      return data;
    };
    newTree = recursivelyDeleteCategory(newTree);
    newTree = recursivelyPutCategory(newTree);
    this.setState({
      tree: newTree,
      dragging: null,
      draggingOver: null,
      droppedOn: null,
    });
  };
  setDraggingElement = element => this.setState({ dragging: element });
  setDraggingOverElement = element => this.setState({ draggingOver: element });
  setDroppedOn = result => this.setState({ droppedOn: result });
  handleExpandCategory = categoryId =>
    this.setState(prev => ({
      expandedCategories: {
        ...prev.expandedCategories,
        [categoryId]: !prev.expandedCategories[categoryId],
      },
    }));
  toggleDialog = category => {
    this.props.toggleDialog('add_category');
    this.props.setSelectedCategory(category);
  }

  updateCategory = category => () => {
    const updatedCategory = { id: category.id, name: category.name, is_publish: !category.is_publish }
    this.props.updateCategory(updatedCategory);
  }

  renderCategory = (item, index, level, parent) => {
    const { expandedCategories, dragging, draggingOver } = this.state;
    const { classes } = this.props;
    return (
      <div
        key={item.id}
        style={{ opacity: dragging && dragging.id === item.id ? 0.6 : 1 }}
      >
        <Dustbin
          element
          item={item}
          index={index}
          dragging={dragging}
          setDraggingOverElement={this.setDraggingOverElement}
          setDroppedOn={this.setDroppedOn}
          parent={parent}
        >
          <ListItem
            className={classes.listItem}
            style={{ paddingLeft: level ? `${24 + (level * 44)}px` : '24px' }}
          >
            <div className={classes.nameWithArrow}>
              <Box
                item={item}
                setDraggingElement={this.setDraggingElement}
                onDragEnd={this.onDragEnd}
              />
              {Boolean(item.__children.length) && (
                <IconButton
                  className={classes.icon}
                  title={expandedCategories[item.id] ? 'Свернуть' : 'Развернуть'}
                  onClick={() => this.handleExpandCategory(item.id)}
                >
                  {expandedCategories[item.id] ? (
                    <KeyboardArrowDown />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </IconButton>
              )}
            </div>
            <div>
              <IconButton
                title="Редактировать категорию"
                className={classes.icon}
                onClick={() => {
                  this.props.toggleCategoryDialog();
                  this.props.setSelectedCategory(item);
                }}
                color="secondary"
              >
                <Edit />
              </IconButton>
              <IconButton
                title={item.is_publish ? 'Скрыть категорию в магазине' : 'Отобразить категорию в магазине'}
                className={classes.icon}
                onClick={this.updateCategory(item)}
                color={item.is_publish ? 'secondary' : 'default'}
              >
                {item.is_publish ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <IconButton
                title="Добавить подкатегорию"
                className={classes.icon}
                onClick={() => this.toggleDialog(item)}
              >
                <Add />
              </IconButton>
            </div>
          </ListItem>
        </Dustbin>
        <Collapse in={expandedCategories[item.id]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.__children.map((child, childIndex) =>
              this.renderCategory(child, childIndex, level + 1, item.id))
            }
          </List>
        </Collapse>
        <Divider />
        <Dustbin
          afterElement
          item={item}
          index={index}
          dragging={dragging}
          parent={parent}
          setDraggingOverElement={this.setDraggingOverElement}
          setDroppedOn={this.setDroppedOn}
        />
        <Divider />
      </div>
    );
  };
  render() {
    const { tree, dragging } = this.state;
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <List className={classes.list}>
          <Divider />
          <Dustbin
            afterElement
            parent={0}
            index={0}
            setDroppedOn={this.setDroppedOn}
            item={{ id: 0 }}
            dragging={dragging}
            setDraggingOverElement={this.setDraggingOverElement}
          />
          <Divider />
          {tree.map((item, index) => this.renderCategory(item, index, 0, 0))}
        </List>
      </Paper>
    );
  }
}

Categories.propTypes = {
  classes: PropTypes.object.isRequired,
  changeCategory: PropTypes.func.isRequired,
  tree: PropTypes.array.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
};

export default DragDropContext(HTML5Backend)(withStyles(styles)(Categories));
