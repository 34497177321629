// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { type Order } from 'types/entities/Order';
import { assemblyReport } from 'services/orderApi';
import AssemblyOrderDialog from './view/AssemblyOrderDialog';
import { toggleAssemblyOrderDialog as toggleAssemblyOrderDialogAction } from './actions';

type Props = {|
  open: boolean,
  order: Order | null,
  toggleAssemblyOrderDialog: () => void,
  onSave: () => void,
|};

const AssemblyOrderDialogContainer = ({
  open,
  toggleAssemblyOrderDialog,
  order,
  onSave,
}: Props) => (
  <AssemblyOrderDialog
    open={open}
    toggleAssemblyOrderDialog={toggleAssemblyOrderDialog}
    order={order}
    assemblyReport={assemblyReport}
    onSave={onSave}
  />
);

const mapStateToProps = store => {
  const assemblyOrderDialog = store.getIn(['shop', 'assemblyOrderDialog']);
  const order = store.getIn(['shop', 'order']);
  return {
    open: assemblyOrderDialog.open,
    order: order.data,
  };
};

export default connect(
  mapStateToProps,
  { toggleAssemblyOrderDialog: toggleAssemblyOrderDialogAction },
)(AssemblyOrderDialogContainer);
