// @flow
import React, { type Node } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
  list: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  typesList: Node,
|};

const SeriesLayoutComponent = ({
  classes,
  typesList,
  filter,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => (
  <div className={classes.root}>
    {filter}
    <div className={classes.list}>{typesList}</div>
  </div>
);

export const ProductsTypesLayout: ReactComponent<Props> = withStyles(styles)(SeriesLayoutComponent);
