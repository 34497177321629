// @flow
// @flow-runtime enable
import { get, post, put, del } from 'lib/request';
import { API_HOST } from 'config';
import { type Warehouse } from 'types/entities/Warehouse';

export const fetchWarehouses = async () => {
  try {
    const response = await get(`${API_HOST}/warehouses`).then(res => res.data.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const fetchWarehouse = async (warehouseId: number) => {
  try {
    const response = await get(`${API_HOST}/warehouses/${warehouseId}`).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const putWarehouse = async (warehouseId: number, warehouse: Warehouse) => {
  try {
    const response = await put(`${API_HOST}/warehouses/${warehouseId}`, warehouse).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const fetchWarehousesDeliverySchedules = async (warehouseId: number) => {
  try {
    const response = await get(`${API_HOST}/upp_delivery_schedules`).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const putWarehousesDeliverySchedule = async schedule => {
  try {
    const response = await put(`${API_HOST}/upp_delivery_schedules`, schedule).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const uploadPrice = ({ url, file }: { file: File }) => {
  const form = new FormData();

  form.append(`price_list`, file);

  return post(`${API_HOST}${url}`, form);
};

export const putWarehouseShchedule = ({ warehouseId, schedule }) => {
  try {
    return put(`${API_HOST}/warehouses/${warehouseId}/schedule `, schedule);
  } catch (error) {
    return null;
  }
};

export const getImages = ({ warehouseId }) => {
  try {
    return get(`${API_HOST}/warehouses/${warehouseId}/images `);
  } catch (error) {
    return null;
  }
};

export const removeImage = ({ warehouseId, imageId }) => {
  try {
    return del(`${API_HOST}/warehouses/${warehouseId}/images/${imageId}`);
  } catch (error) {
    return null;
  }
};

export const addImages = ({ warehouseId, files }) => {
  try {
    const form = new FormData();

    files.forEach((file, index) => form.append(`images[${index}]`, file));

    return post(`${API_HOST}/warehouses/${warehouseId}/images`, form);
  } catch (error) {
    return null;
  }
};

export const setDefaultImage = ({ warehouseId, image }) => {
  try {
    return put(`${API_HOST}/warehouses/${warehouseId}/default-image`, image);
  } catch (error) {
    return null;
  }
};
