// @flow
import React from 'react';
import { type Product } from 'types/entities/Product';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import BarcodeSvg from './BarcodeSvg';

type Props = {
  classes: Object,
  product: Product,
  deleteEntityFromProduct: (name: string, productId: number) => void,
}

const styles = theme => ({
  barcodeBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  deleteBarcode: {
    marginLeft: theme.spacing.unit,
    width: '28px',
    height: '28px',
  },
  barcode: {
    display: 'flex',
    alignItems: 'center',
  },
});

const Barcodes = ({ classes, product, deleteEntityFromProduct }: Props) => {
  return (
    <div className={classes.barcodeBlock}>
      <BarcodeSvg />
      <Typography variant="title" title="Основной штрих-код">
        {product.barcode.value}
      </Typography>
      {product.barcodes.map(barcode => {
        if (barcode.id === product.barcode.id) return null;
        return (
          <Typography key={barcode.id} className={classes.barcode}>
            {/* <IconButton
              title="Удалить штрих-код"
              onClick={() => deleteEntityFromProduct('barcodes', product.id)}
              className={classes.deleteBarcode}
            >
              <Close />
            </IconButton> */}
            {barcode.value}
          </Typography>
        );
      })}
    </div>
  );
};

Barcodes.propTypes = {
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  deleteEntityFromProduct: PropTypes.func.isRequired,
};

export default withStyles(styles)(Barcodes);
