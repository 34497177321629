// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import {
  FormControl,
  InputLabel,
  Select,
  Input,
  ListItemText,
  Checkbox,
  MenuItem,
} from '@material-ui/core';

const styles = (theme: Theme) => ({
  root: {
    // marginTop: theme.spacing.unit * 2,
    minWidth: '150px',
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const CitySelectComponent = ({
  classes,
  cities,
  selectedCities,
  onChange,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => (
  <FormControl className={classes.root}>
    <InputLabel htmlFor="city">Город</InputLabel>
    <Select
      fullWidth
      multiple
      value={selectedCities}
      onChange={e => onChange(e.target.value)}
      input={<Input id="city" />}
      renderValue={selected => selected.map(city => city.locality.name).join(', ')}
      MenuProps={MenuProps}
    >
      {cities &&
        cities.map(city => (
          <MenuItem key={city.line} value={city}>
            {selectedCities && <Checkbox checked={selectedCities.includes(city)} />}
            <ListItemText primary={city.locality.name} />
          </MenuItem>
        ))}
    </Select>
  </FormControl>
);

export const CitySelect: ReactComponent<Props> = withStyles(styles)(CitySelectComponent);
