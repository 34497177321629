// @flow
import React, { useEffect } from 'react';
import { type TimePeriod, type Address } from 'types/entities/TimePeriod';
import { connect } from 'react-redux';
import { fetchDeliveryPeriods } from 'components/shop/delivery-periods/actions';
import DeliveryPeriodsDialog from './view/DeliveryPeriodsDialog';

type Props = {|
  open: boolean,
  isFetching: boolean,
  address: Address,
  onSelect: (timePeriod: TimePeriod) => void,
  fetchDeliveryPeriods: (params: { orderId: number }) => void,
  onClose: () => void,
  deliveryPeriods: Array<TimePeriod> | null,
|};

const DeliveryPeriodsDialogContainer = ({
  open,
  isFetching,
  address,
  onSelect,
  fetchDeliveryPeriods,
  onClose,
  deliveryPeriods,
  order,
}) => {
  useEffect(() => {
    fetchDeliveryPeriods({ address, orderId: order && order.id });
  }, [open, address]);

  const handleOnSelect = (timePeriod: TimePeriod) => () => onSelect(timePeriod);

  return (
    <DeliveryPeriodsDialog
      isFetching={isFetching}
      onClose={onClose}
      onSelect={handleOnSelect}
      open={open}
      deliveryPeriods={deliveryPeriods}
    />
  );
};

const mapStateToProps = store => {
  const state = store.getIn(['shop']);
  return {
    deliveryPeriods: state.deliveryPeriods.list,
    isFetching: state.deliveryPeriods.isFetching,
  };
};

export default connect(mapStateToProps, { fetchDeliveryPeriods })(DeliveryPeriodsDialogContainer);
