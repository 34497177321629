// @flow
import React from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import Progress from 'components/progress';
import { type Delivery as DeliveryType } from 'types/entities/Delivery';
import { type TimePeriod } from 'types/entities/TimePeriod';
import { type Warehouse } from 'types/entities/Warehouse';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import FilterList from '@material-ui/icons/FilterList';
import { withRouter } from 'react-router-dom';
import { CourierSelect } from 'components/shop/courier-select';
import { WarehouseFilter } from 'components/shop/warehouses-filter';
import { CourierAssignmentButton } from 'components/shop/courier-assignment-button';
import { setRightSideBarActions } from 'components/right_side_bar/actions';
import { Map } from 'components/map';
import queryString from 'query-string';
import DeliveriesLayout from './view/DeliveriesLayout';
import Delivery from './view/Delivery';
import { CopyToClipboardList } from './view/CopyToClipboardList';
import { Controls } from './view/Controls';
import { DeliveryPeriods } from './view/DeliveryPeriods';
import { ShipmentPlaces } from './view/ShipmentPlaces';
import { MapDialog } from './view/MapDialog';
import { CompleteStatusSwitcher } from './view/CompleteStatusSwitcher';
import {
  fetchDeliveries,
  completeDelivery,
  getListToCopy,
  getRouteListToCopy,
  getRouteListTableToCopy,
  groupByWarehouses,
} from './actions';
import { SaveTableButton } from 'components/save-table-button';
import { AssemblyList } from './view/AssemblyList';
import { Grid, Paper } from '@material-ui/core';
import { CitySelect } from 'components/shop/city-select';

type Props = {|
  fetchDeliveries: (params: Object) => Promise<any>,
  completeDelivery: ({ deliveryId: number }) => void,
  deliveries: Array<any> | null,
  isFetching: boolean,
|};
type State = {|
  start: Object,
  selectedDeliveryPeriods: Array<TimePeriod> | null,
  selected: {},
  selectedPlaces: Array<string>,
  selectedWarehouse: Warehouse | null,
  completed: boolean,
|};

export class DeliveriesContainer extends React.Component<Props, State> {
  state = {
    start: moment(),
    selectedDeliveryPeriods: [],
    selectedPlaces: [],
    selected: {},
    selectedWarehouse: null,
    completed: false,
    showFilters: null,
    showMap: false,
    selectedCouriers: [],
    selectedCities: [],
  };

  componentDidMount() {
    const { variant, day, completed } = queryString.parse(this.props.location.search);

    this.setState({ start: day ? moment(day) : moment(), completed: Boolean(completed) }, () =>
      this.props.fetchDeliveries({
        day: this.state.start.format('YYYY-MM-DD'),
        completed: this.state.completed,
      }),
    );

    const actionsForRightSideBar = [
      {
        key: 'toggleDeliveryFilters',
        icon: <FilterList />,
        name: 'Фильтры',
        action: () => this.setState({ showFilters: !this.state.showFilters }),
      },
    ];
    this.props.setRightSideBarActions(actionsForRightSideBar);
  }

  changeDate = (start: Object | null) => {
    const query = queryString.parse(this.props.location.search);

    query.day = start.format('YYYY-MM-DD');

    this.props.history.push(`/shop/deliveries?${queryString.stringify(query)}`);

    this.setState({
      start,
      selectedDeliveryPeriods: [],
      selectedPlaces: [],
      selectedCities: [],
      selected: {},
      selectedWarehouse: null,
    });
    if (start) {
      this.props.fetchDeliveries({ day: start.format('YYYY-MM-DD') });
    }
  };

  getData = () =>
    this.props
      .fetchDeliveries({
        day: this.state.start.format('YYYY-MM-DD'),
        completed: this.state.completed,
      })
      .then(() =>
        this.setState({
          selectedCouriers: [],
        }),
      );

  handleCompleteDelivery = data => {
    this.props.completeDelivery(data).then(() => this.getData());
  };

  changeCompete = () => {
    const query = queryString.parse(this.props.location.search);

    if (!this.state.completed) {
      query.completed = 'true';
      this.props.history.push(`/shop/deliveries?${queryString.stringify(query)}`);
      this.setState({ completed: true });
      return this.props.fetchDeliveries({
        day: this.state.start.format('YYYY-MM-DD'),
        completed: true,
      });
    }
    delete query.completed;
    this.props.history.push(`/shop/deliveries?${queryString.stringify(query)}`);
    this.setState({ completed: false });
    return this.props.fetchDeliveries({
      day: this.state.start.format('YYYY-MM-DD'),
    });
  };

  selectDelivery = (orderId: number) => () =>
    this.setState(({ selected }) => ({
      selected: { ...selected, [orderId]: !selected[orderId] },
    }));

  selectAll = deliveries => {
    if (!deliveries) return null;
    if (Object.keys(this.state.selected).length > 0) {
      return this.setState({ selected: {} });
    }
    const selected = {};
    deliveries.forEach(delivery => (selected[delivery.id] = true));
    this.setState({ selected });
  };

  getSelectedList = () => {
    const selectedList = [];
    Object.keys(this.state.selected).forEach(id =>
      this.state.selected[id] ? selectedList.push(id) : null,
    );
    return selectedList;
  };

  filterByPeriod = () => {
    if (!this.props.deliveries) return this.props.deliveries;
    return this.props.deliveries.filter(
      item =>
        !this.state.selectedDeliveryPeriods ||
        !this.state.selectedDeliveryPeriods.length ||
        this.state.selectedDeliveryPeriods.find(
          selectedPeriod =>
            item.delivery_period && item.delivery_period.start === selectedPeriod.start,
        ),
    );
  };

  filterByPlaces = () => {
    const filtredByPeriod = this.filterByPeriod();
    if (!filtredByPeriod) return filtredByPeriod;
    // console.log(this.state.selectedPlaces)
    return filtredByPeriod.filter(
      delivery =>
        !this.state.selectedPlaces.length ||
        this.getPlaces(delivery).filter(place => this.state.selectedPlaces.includes(place)).length,
    );
  };

  filterByCouriers = deliveries => {
    if (!deliveries) return deliveries;

    const result = [...deliveries];

    return result.filter(delivery =>
      this.state.selectedCouriers.length
        ? this.state.selectedCouriers.find(courier =>
            courier ? delivery.courier && delivery.courier.id === courier.id : !delivery.courier,
          ) === null
          ? true
          : this.state.selectedCouriers.find(courier =>
              courier ? delivery.courier && delivery.courier.id === courier.id : !delivery.courier,
            )
        : true,
    );
  };

  filterByCities = deliveries => {
    if (!deliveries) return deliveries;

    const result = [...deliveries];

    return result.filter(
      delivery =>
        !this.state.selectedCities.length ||
        this.state.selectedCities.find(
          city => delivery.address.locality.fias_id === city.locality.fias_id,
        ),
    );
  };

  getPlaces = (delivery: DeliveryType) => {
    let warehouses = [];
    Object.values(groupByWarehouses(delivery)).forEach(warehouse => warehouses.push(warehouse));

    const places = [];
    warehouses.forEach(warehouse =>
      warehouse.shipment_place_pointers.forEach(pointer => places.push(pointer)),
    );
    return places;
  };
  getWarehouses = (deliveries: Array<DeliveryType> | null) => {
    if (!deliveries) return deliveries;
    const warehouses = [];
    deliveries.forEach(delivery => {
      delivery &&
        delivery.orders.forEach(order =>
          order.plans.forEach(plan => warehouses.push(plan.warehouse_donor)),
        );
    });
    return warehouses;
  };

  filterByWarehouse = (deliveries: Array<DeliveryType> | null) => {
    if (!this.state.selectedWarehouse) {
      return deliveries;
    }
    return deliveries.filter(
      deivery =>
        deivery &&
        deivery.orders.find(order =>
          order.plans.find(
            plan => plan.warehouse_donor.vendor_id === this.state.selectedWarehouse.vendor_id,
          ),
        ),
    );
  };

  render() {
    const filtredByPeriod = this.filterByPeriod();
    const filtredByPlaces = this.filterByPlaces();
    const filtredByCouriers = this.filterByCouriers(filtredByPlaces);
    const filtredByCities = this.filterByCities(filtredByCouriers);
    const filtredByWarehouse = this.filterByWarehouse(filtredByCities);
    const selected =
      this.props.deliveries && this.props.deliveries.filter(({ id }) => this.state.selected[id]);

    const isCourier = Boolean(this.props.user && this.props.user.roles.includes('ROLE_COURIER'));

    const showFilters = isCourier ? this.state.showFilters : !this.state.showFilters;

    const variant =
      queryString.parse(this.props.location.search).variant ||
      (isCourier ? 'assembly' : 'delivery');

    const warehouses = this.getWarehouses(filtredByPlaces);
    const points =
      filtredByWarehouse &&
      filtredByWarehouse.map(delivery => ({
        data: {
          id: delivery.id,
          name: delivery.address.line,
        },
        coordinates: delivery.address.coordinates,
        selected: this.state.selected[delivery.id],
      }));

    const cities =
      this.props.deliveries &&
      this.props.deliveries.reduce((prev, curr) => {
        if (prev.find(item => item.locality.fias_id === curr.address.locality.fias_id)) {
          return prev;
        }
        return [...prev, curr.address];
      }, []);
    return (
      <DeliveriesLayout
        variantSelect={
          <React.Fragment>
            <Button
              color="secondary"
              variant={variant === 'assembly' ? 'raised' : null}
              onClick={() => this.props.history.push('/shop/deliveries?variant=assembly')}
            >
              Комплектация
            </Button>
            <Button
              color="secondary"
              variant={variant === 'delivery' ? 'raised' : null}
              onClick={() => this.props.history.push('/shop/deliveries?variant=delivery')}
            >
              Доставка
            </Button>
          </React.Fragment>
        }
        controls={
          showFilters && (
            <Controls
              citySelect={
                <CitySelect
                  cities={cities || []}
                  selectedCities={this.state.selectedCities}
                  onChange={selectedCities => this.setState({ selectedCities })}
                />
              }
              courierSelect={
                <CourierSelect
                  selectedCouriers={this.state.selectedCouriers}
                  onChange={selectedCouriers => this.setState({ selectedCouriers })}
                />
              }
              courierAssignmentButton={
                !isCourier && (
                  <CourierAssignmentButton deliveries={selected} onChange={this.getData} />
                )
              }
              completeStatusSwitcher={
                <CompleteStatusSwitcher
                  completed={this.state.completed}
                  onChange={this.changeCompete}
                />
              }
              warehousesFilter={
                <WarehouseFilter
                  warehouses={warehouses}
                  warehouse={this.state.selectedWarehouse}
                  onChange={(name: string, selectedWarehouse: Warehouse | null) =>
                    this.setState({ selectedWarehouse })
                  }
                />
              }
              mapDialog={
                <MapDialog
                  points={points}
                  onClick={action => {
                    if (!isCourier) {
                      return this.setState({ showMap: !this.state.showMap });
                    }
                    action();
                  }}
                />
              }
              onChangeDate={this.changeDate}
              start={this.state.start}
              selectAll={
                !isCourier && (
                  <Button onClick={() => this.selectAll(filtredByWarehouse)}>Выбрать всё</Button>
                )
              }
              saveTableButton={
                !isCourier && (
                  <SaveTableButton
                    name="delivery"
                    count={this.getSelectedList().length}
                    table={getRouteListToCopy(selected)}
                  />
                )
              }
              count={(filtredByWarehouse && filtredByWarehouse.length) || 0}
              shipmentPlaces={
                <ShipmentPlaces
                  onChange={selectedPlaces => this.setState({ selectedPlaces })}
                  selectedPlaces={this.state.selectedPlaces}
                  places={
                    (filtredByPeriod &&
                      Array.from(
                        new Set(filtredByPeriod.map(delivery => this.getPlaces(delivery)).flat()),
                      )) ||
                    []
                  }
                />
              }
              deliveryPeriods={
                <DeliveryPeriods
                  onChange={selectedDeliveryPeriods => this.setState({ selectedDeliveryPeriods })}
                  selectedDeliveryPeriods={this.state.selectedDeliveryPeriods}
                  deliveryPeriods={
                    this.props.deliveries &&
                    this.props.deliveries.reduce((prev, curr) => {
                      if (
                        prev.find(
                          item =>
                            item.start === curr.delivery_period.start &&
                            item.end === curr.delivery_period.end,
                        )
                      ) {
                        return prev;
                      }
                      return [...prev, curr.delivery_period];
                    }, [])
                  }
                />
              }
            />
          )
        }
        progress={<Progress isFetching={this.props.isFetching} color="primary" />}
        list={
          <React.Fragment>
            {variant === 'delivery' && (
              <Grid container spacing={8}>
                <Grid item xs={this.state.showMap ? 8 : 12}>
                  <Grid container spacing={8}>
                    {filtredByWarehouse &&
                      filtredByWarehouse
                        .sort((a, b) =>
                          a.delivery_period.start > b.delivery_period.start ? 1 : -1,
                        )
                        .map(delivery => (
                          <Grid item xs={12} key={delivery.id}>
                            <List disablePadding style={{ height: '100%' }}>
                              <Delivery
                                copyToClipboardButton={
                                  <CopyToClipboardList
                                    length={0}
                                    text={getListToCopy(
                                      this.props.deliveries &&
                                        this.props.deliveries.filter(
                                          ({ id }) => this.state.selected[id],
                                        ),
                                    )}
                                    routeList={getRouteListToCopy([delivery])}
                                  />
                                }
                                selected={Boolean(this.state.selected[delivery.id])}
                                delivery={delivery}
                                key={delivery.id}
                                onSelect={this.selectDelivery(delivery.id)}
                                completeDelivery={this.handleCompleteDelivery}
                              />
                            </List>
                          </Grid>
                        ))}
                  </Grid>
                </Grid>
                {this.state.showMap && (
                  <Grid item xs={4} style={{ position: 'relative', minHeight: '500px' }}>
                    <Paper style={{ position: 'fixed', minHeight: '300px', minWidth: '550px' }}>
                      <Map
                        points={points}
                        onClick={point => this.selectDelivery(point.data.id)()}
                      />
                    </Paper>
                  </Grid>
                )}
              </Grid>
            )}
            {variant === 'assembly' && (
              <AssemblyList deliveries={filtredByWarehouse} onConfirm={this.getData} />
            )}
          </React.Fragment>
        }
      />
    );
  }
}

const mapStateToProps = store => {
  const state = store.getIn(['shop', 'deliveries']);
  const user = store.get('user');
  return {
    isFetching: state.isFetching,
    deliveries: state.data,
    user: user.data,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchDeliveries,
    completeDelivery,
    setRightSideBarActions,
  })(DeliveriesContainer),
);
