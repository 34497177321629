// @flow
import ls from 'local-storage';

const localStorage = {};

localStorage.get = (key: string) => ls.get(key);

localStorage.set = (key: string, vaule: any) => ls.set(key, vaule);

localStorage.remove = (key: string) => ls.remove(key);

localStorage.clear = () => ls.clear();

localStorage.clear = () => ls.clear();

export default localStorage;
