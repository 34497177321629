// @flow
import { normalize, schema } from 'normalizr';
import { API_HOST } from 'config';
import { get, put, del } from 'lib/request';
import {
  FETCH_MANUFACTURERS_MARKUPS_REQUEST,
  FETCH_MANUFACTURERS_MARKUPS_SUCCESS,
  FETCH_BRANDS_MARKUPS_REQUEST,
  FETCH_BRANDS_MARKUPS_SUCCESS,
  type RequestManufacturersMarkups,
  type ReceiveManufacturersMarkups,
  type RequestBrandsMarkups,
  type ReceiveBrandsMarkups,
} from 'types/actions';
import { type Markup } from 'types/entities';
import { fetchCategoriesTree } from 'components/categories/actions';
import { addNotification } from '../notifications/actions';

export const manufacturersMarkupsEntity = new schema.Entity('manufacturersMarkups');
export const brandsMarkupsEntity = new schema.Entity('brandsMarkups');

export const requestManufacturersMarkups = (value: boolean): RequestManufacturersMarkups => ({
  type: FETCH_MANUFACTURERS_MARKUPS_REQUEST,
  value,
});

export const receiveManufacturersMarkups = (markups: {
  [key: string]: Markup,
}): ReceiveManufacturersMarkups => ({
  type: FETCH_MANUFACTURERS_MARKUPS_SUCCESS,
  markups,
});
export const requestBrandsMarkups = (value: boolean): RequestBrandsMarkups => ({
  type: FETCH_BRANDS_MARKUPS_REQUEST,
  value,
});

export const receiveBrandsMarkups = (markups: { [key: string]: Markup }): ReceiveBrandsMarkups => ({
  type: FETCH_BRANDS_MARKUPS_SUCCESS,
  markups,
});

export const fetchManufacturersMarkups = () => (dispatch: $FlowFixMe) => {
  dispatch(requestManufacturersMarkups(true));
  get(`${API_HOST}/manufacturers_markups`).then(
    res => dispatch(receiveManufacturersMarkups(res.data)),
    () => dispatch(addNotification('Неудачная попытка загрузки наценок производителей')),
  );
};

export const fetchBrandsMarkups = () => (dispatch: $FlowFixMe) => {
  dispatch(requestBrandsMarkups(true));
  get(`${API_HOST}/brands_markups`).then(
    res => {
      const payload = normalize(res.data, [brandsMarkupsEntity]);
      dispatch(receiveBrandsMarkups(payload.entities.brandsMarkups));
    },
    () => dispatch(addNotification('Неудачная попытка загрузки наценок брендов')),
  );
};

export const putMarkup = ({ endpoint, markup }) => (dispatch: $FlowFixMe) =>
  put(`${API_HOST}${endpoint}`, markup).then(
    () => {
      dispatch(fetchCategoriesTree(['markup']));
      dispatch(fetchBrandsMarkups());
      dispatch(fetchManufacturersMarkups());
    },
    () => {
      dispatch(addNotification('Неудачная попытка изменения наценки'));
    },
  );

export const deleteMarkup = ({ endpoint, markup }) => (dispatch: $FlowFixMe) =>
  del(`${API_HOST}${endpoint}`, markup).then(
    () => {
      dispatch(fetchCategoriesTree(['markup']));
      dispatch(fetchBrandsMarkups());
      dispatch(fetchManufacturersMarkups());
    },
    () => {
      dispatch(addNotification('Неудачная попытка удаления наценки'));
    },
  );
