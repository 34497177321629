import { combineReducers } from 'redux-immutable';
import { routerReducer } from 'react-router-redux';
import Immutable from 'immutable';
import notifications from 'components/notifications/reducers';
import manufacturers from 'components/manufacturers/reducers';
import brands from 'components/brands/reducers';
import undefinedManufacturers from 'components/undefined_manufacturers/reducers';
import undefinedBrands from 'components/undefined_brands/reducers';
import rightSideBar from 'components/right_side_bar/reducers';
import categories from 'components/categories/reducers';
import leftSideBar from 'components/left_side_bar/reducers';
import topBar from 'components/top_bar/reducers';
import products from 'components/products/reducers';
import suppliers from 'components/suppliers/reducers';
import product from 'components/product/reducers';
import productPricingReport from 'components/product_pricing_report/reducers';
import markups from 'components/markup/reducers';
import user from 'components/user/reducers';
import imagesLogs from 'components/images-logs/reducers';
import shop from 'components/shop/reducers';
import { series } from 'components/series/reducers';
import { productsTypes } from 'components/products-types/reducers';

const appReducer = combineReducers(
  {
    brands,
    manufacturers,
    undefinedManufacturers,
    notifications,
    undefinedBrands,
    rightSideBar,
    categories,
    leftSideBar,
    topBar,
    products,
    suppliers,
    router: routerReducer,
    product,
    productPricingReport,
    markups,
    user,
    imagesLogs,
    shop,
    series,
    productsTypes,
  },
  () => Immutable.Map(),
);

export default appReducer;
