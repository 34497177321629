// @flow
// @flow-runtime enable
import { type Address } from './Address';
import { type TimePeriod } from './TimePeriod';
import { type OrderUser } from './OrderUser';
import { COURIER } from './RealizationMethodType';

export type RealizationMethodCourierDelivery = {|
  id: number,
  recipient_name: string | null,
  recipient_phone: string | null,
  shipping_cost: number,
  total_cost: number,
  created_at: string,
  updated_at: string,
  type: typeof COURIER,
  address: Address,
  delivery_period: TimePeriod,
  user: OrderUser,
  orders: Array<any> | null,
|};
