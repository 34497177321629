// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import Lines from './Lines';
import moment from 'moment';

const styles = (theme: Theme) => ({
  root: {
    paddingTop: '1px',
    paddingBottom: theme.spacing.unit * 2,
  },
  lines: {
    marginTop: theme.spacing.unit * 3,
  },
  line: {
    marginLeft: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit,
    borderLeft: `1px solid ${theme.palette.grey[500]}`,
  },
  title: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(18),
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const PlansComponent = ({ classes, plans, renderLines }: {| ...Props, ...ProvidedProps |}) => (
  <div className={classes.root}>
    {plans &&
      plans.map(plan => (
        <div key={plan.id} className={classes.lines}>
          <div>
            <span className={classes.title}>
              {plan.warehouse_donor.name},{' '}
              {moment(plan.estimated_completion_time.substr(0, 19)).format('HH:mm, DD.MM.YYYY')}
            </span>
          </div>
          <div className={classes.line}>{renderLines(plan.lines)}</div>
        </div>
      ))}
  </div>
);

export const Plans: ReactComponent<Props> = withStyles(styles)(PlansComponent);
