// @flow
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { type ProductPricingReport } from 'types/entities';
import { type DialogType } from '../ProductPricingReportContainer';

type Props = {
  open: boolean,
  dialogType: DialogType,
  productMarkup: string,
  fixedPrice: string,
  handleChange: (name: string) => any,
  onClose: () => void,
  removeProductFixedPrice: () => void,
  setProductFixedPrice: () => void,
  removeProductMarkup: () => void,
  setProductMarkup: () => void,
  report: ProductPricingReport | null,
};

const PricingReportEditDialog = ({
  open,
  dialogType,
  onClose,
  removeProductFixedPrice,
  setProductFixedPrice,
  removeProductMarkup,
  setProductMarkup,
  fixedPrice,
  productMarkup,
  handleChange,
  report,
  supplier,
}: Props) => {
  const dialogContent = () => {
    if (dialogType === 'fixed_price_product') {
      return (
        <React.Fragment>
          <DialogContent>
            <TextField
              fullWidth
              autoFocus
              label="Фиксированная цена"
              placeholder={
                report && report.fixed_price !== null ? String(report.fixed_price) : 'не задана'
              }
              value={fixedPrice}
              onChange={handleChange('fixedPrice')}
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Отмена</Button>
            <Button
              disabled={report && report.fixed_price === null}
              onClick={removeProductFixedPrice}
              color="primary"
            >
              Удалить
            </Button>
            <Button onClick={setProductFixedPrice} color="secondary" disabled={!fixedPrice}>
              Сохранить
            </Button>
          </DialogActions>
        </React.Fragment>
      );
    }
    if (dialogType === 'product_markup') {
      const currentProductMarkup =
        report &&
        supplier &&
        report.markup_reports.find(item => item.supplier.alias === supplier.alias).markup_reports
          .product;
      return (
        <React.Fragment>
          <DialogContent>
            <TextField
              fullWidth
              autoFocus
              label="Наценка на продукт"
              placeholder={
                currentProductMarkup !== null ? String(currentProductMarkup) : 'не задана'
              }
              value={productMarkup}
              onChange={handleChange('productMarkup')}
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Отмена</Button>
            <Button
              onClick={removeProductMarkup}
              color="primary"
              disabled={currentProductMarkup === null}
            >
              Удалить
            </Button>
            <Button onClick={setProductMarkup} color="secondary" disabled={!productMarkup}>
              Сохранить
            </Button>
          </DialogActions>
        </React.Fragment>
      );
    }
    return <span />;
  };
  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        {dialogContent()}
      </Dialog>
    </div>
  );
};

PricingReportEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  dialogType: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  removeProductFixedPrice: PropTypes.func.isRequired,
  setProductMarkup: PropTypes.func.isRequired,
  productMarkup: PropTypes.string.isRequired,
  setProductFixedPrice: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default PricingReportEditDialog;
