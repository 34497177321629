import { FETCH_BRANDS_REQUEST, FETCH_BRANDS_SUCCESS } from 'types/actions';
import Immutable from 'immutable';

const brandsInitialState = Immutable.Map({
  isFetching: false,
  items: Immutable.Map(),
});

const brands = (state = brandsInitialState, action) => {
  switch (action.type) {
    case FETCH_BRANDS_REQUEST: {
      return state.set('isFetching', action.value);
    }
    case FETCH_BRANDS_SUCCESS: {
      return state.merge({
        isFetching: false,
        items: Immutable.Map(action.brands),
      });
    }
    default: {
      return state;
    }
  }
};

export default brands;
