// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import Dialog from '@material-ui/core/Dialog';
import { type Theme, type ReactComponent } from 'types/components';

const styles = (theme: Theme) => ({
  root: {
    // marginTop: theme.spacing.unit * 2,
  },
  progress: {
    padding: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  list: any,
  input: any,
  isFetching: boolean,
|};

const PickupTimePredictionLayoutComponent = ({
  classes,
  list,
  isFetching,
  input,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => (
  <div>
    {isFetching && (
      <div className={classes.progress}>
        <CircularProgress color="secondary" />
      </div>
    )}
    {!isFetching && (
      <React.Fragment>
        {input}
        <List className={classes.root}>{list}</List>
      </React.Fragment>
    )}
  </div>
);

export const PickupTimePredictionLayout: ReactComponent<Props> = withStyles(styles)(
  PickupTimePredictionLayoutComponent,
);
