// @flow
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { type ManufacturersInclude, type CategoriesTreeInclude } from 'types/actions';
import { type Manufacturer, type CategoriesTree, type Markup as MarkupType } from 'types/entities';
import { connect } from 'react-redux';
import { fetchManufacturersIfNeeded } from 'components/manufacturers/actions';
import { fetchCategoriesTree } from 'components/categories/actions';
import { setTopBarTitle } from 'components/top_bar/actions';
import {
  getCategoriesMarkups,
  getManufacturersMarkups,
  getBrandsMarkups,
} from 'services/markupsApi';
import { getSuppliers } from 'services/suppliersApi';
import Markup from './view/Markup';
import { fetchManufacturersMarkups, fetchBrandsMarkups, putMarkup, deleteMarkup } from './actions';
import MarkupCategories from './view/MarkupCategories';
import MarkupProducts from './view/MarkupProducts';
import MarkupCategoriesSkeleton from './view/MarkupCategoriesSkeleton';
import { Controls } from './view/Controls';

type Props = {
  fetchManufacturersIfNeeded: (include: ManufacturersInclude) => void,
  fetchCategoriesTree: (include: CategoriesTreeInclude) => void,
  isFetchingManufacturers: boolean,
  isFetchingCategories: boolean,
  manufacturers: Manufacturer,
  categoriesTree: CategoriesTree,
  putMarkup: (endpoint: string, value: string) => void,
  deleteMarkup: (endpoint: string) => void,
  fetchManufacturersMarkups: () => void,
  fetchBrandsMarkups: () => void,
  manufacturersMarkups: { [key: number]: MarkupType } | null,
  brandsMarkups: { [key: number]: MarkupType } | null,
  setTopBarTitle: (title: string) => void,
};

type State = {
  openedManufacturers: { [key: number]: boolean },
  openedCategories: { [key: number]: boolean },
  openedMarkupManufacturers: { [key: number]: boolean },
  openedMarkupBrands: { [key: number]: boolean },
  openedMarkupCategories: { [key: number]: boolean },
  manufacturersMarkupValue: { [key: number]: string },
  brandsMarkupValue: { [key: number]: string },
  categoriesMarkupValue: { [key: number]: string },
};

export type TogglableStateFeilds = 'openedManufacturers' | 'openedCategories';

export type Entity = 'manufacturer' | 'brand' | 'category';

const mapEntityToStateOpenedFeild = {
  manufacturer: 'openedMarkupManufacturers',
  brand: 'openedMarkupBrands',
  category: 'openedMarkupCategories',
};

const mapEntityToStateValueFeild = {
  manufacturer: 'manufacturersMarkupValue',
  brand: 'brandsMarkupValue',
  category: 'categoriesMarkupValue',
};

const MarkupContainer = props => {
  const [remoteData, setRemoteData] = useState({
    suppliers: null,
    categoriesMarkups: null,
    manufacturersMarkups: null,
    brandsMarkups: null,
  });

  const [state, changeState] = useState({
    openedManufacturers: {},
    openedCategories: {},
    openedMarkupManufacturers: {},
    openedMarkupBrands: {},
    openedMarkupCategories: {},
    brandsMarkupValue: {},
    manufacturersMarkupValue: {},
    categoriesMarkupValue: {},
    selectedSupplier: null,
  });

  const setState = data => changeState({ ...state, ...data });

  const getInitialData = async () => {
    const suppliers = await getSuppliers();
    const categoriesMarkups = await getCategoriesMarkups();
    const manufacturersMarkups = await getManufacturersMarkups();
    const brandsMarkups = await getBrandsMarkups();
    setRemoteData({
      ...remoteData,
      suppliers,
      categoriesMarkups,
      manufacturersMarkups,
      brandsMarkups,
    });
  };

  useEffect(() => {
    props.setTopBarTitle('Наценки');
    props.fetchManufacturersIfNeeded(['brand']);
    props.fetchCategoriesTree();
    props.fetchManufacturersMarkups();
    props.fetchBrandsMarkups();

    getInitialData();
  }, []);

  const toggleCollapsable = (name: TogglableStateFeilds, entityId: number) => {
    setState({
      [name]: {
        ...state[name],
        [entityId]: !state[name][entityId],
      },
    });
  };

  const toggleMarkup = (entityType: Entity, entityId: number) => {
    setState({
      [mapEntityToStateOpenedFeild[entityType]]: {
        ...state[mapEntityToStateOpenedFeild[entityType]],
        [entityId]: !state[mapEntityToStateOpenedFeild[entityType]][entityId],
      },
    });
  };

  const handleChangeMarkupValue = (entityType: Entity, entityId: number, value: string) =>
    setState({
      [mapEntityToStateValueFeild[entityType]]: {
        ...state[mapEntityToStateValueFeild[entityType]],
        [entityId]: value,
      },
    });

  const handlePutMarkup = data =>
    props
      .putMarkup({
        ...data,
        markup: { ...data.markup, supplier: data.markup.supplier || state.selectedSupplier },
      })
      .then(() => getInitialData());

  const handleDeleteMarkup = data =>
    props
      .deleteMarkup({
        ...data,
        markup: { ...data.markup, supplier: data.markup.supplier || state.selectedSupplier },
      })
      .then(() => getInitialData());

  const filteredCategoriesMarkups =
    state.selectedSupplier &&
    remoteData.categoriesMarkups &&
    remoteData.categoriesMarkups.filter(
      item => item.supplier.alias === state.selectedSupplier.alias,
    );

  const filteredManufacturersMarkups =
    state.selectedSupplier &&
    remoteData.manufacturersMarkups &&
    remoteData.manufacturersMarkups.filter(
      item => item.supplier.alias === state.selectedSupplier.alias,
    );

  const filteredBrandsMarkups =
    state.selectedSupplier &&
    remoteData.brandsMarkups &&
    remoteData.brandsMarkups.filter(item => item.supplier.alias === state.selectedSupplier.alias);

  return (
    <Markup
      controls={
        <Controls
          suppliers={remoteData.suppliers}
          selectedSupplier={state.selectedSupplier}
          setSelectedSupplier={selectedSupplier => setState({ selectedSupplier })}
        />
      }
      markupProducts={
        state.selectedSupplier && (
          <MarkupProducts
            isFetchingManufacturers={props.isFetchingManufacturers}
            manufacturers={props.manufacturers}
            toggleCollapsable={toggleCollapsable}
            toggleMarkup={toggleMarkup}
            handleChangeMarkupValue={handleChangeMarkupValue}
            openedManufacturers={state.openedManufacturers}
            openedMarkupManufacturers={state.openedMarkupManufacturers}
            openedMarkupBrands={state.openedMarkupBrands}
            manufacturersMarkupValue={state.manufacturersMarkupValue}
            brandsMarkupValue={state.brandsMarkupValue}
            putMarkup={handlePutMarkup}
            deleteMarkup={handleDeleteMarkup}
            manufacturersMarkups={filteredManufacturersMarkups}
            brandsMarkups={filteredBrandsMarkups}
          />
        )
      }
      markupCategories={
        state.selectedSupplier && (
          <MarkupCategories
            categories={props.categoriesTree}
            toggleCollapsable={toggleCollapsable}
            toggleMarkup={toggleMarkup}
            handleChangeMarkupValue={handleChangeMarkupValue}
            openedCategories={state.openedCategories}
            openedMarkupCategories={state.openedMarkupCategories}
            categoriesMarkupValue={state.categoriesMarkupValue}
            putMarkup={handlePutMarkup}
            deleteMarkup={handleDeleteMarkup}
            categoriesMarkups={filteredCategoriesMarkups}
          />
        )
      }
    />
  );
};

MarkupContainer.propTypes = {
  fetchManufacturersIfNeeded: PropTypes.func.isRequired,
  putMarkup: PropTypes.func.isRequired,
  deleteMarkup: PropTypes.func.isRequired,
  isFetchingManufacturers: PropTypes.bool.isRequired,
  isFetchingCategories: PropTypes.bool.isRequired,
};

const mapStateToProps = store => ({
  isFetchingManufacturers: store.getIn(['manufacturers', 'isFetching']),
  isFetchingCategories: store.getIn(['categories', 'isFetching']),
  manufacturers: store
    .getIn(['manufacturers', 'items'])
    .valueSeq()
    .toArray(),
  manufacturersMarkups:
    store.getIn(['markups', 'manufacturers']) && store.getIn(['markups', 'manufacturers']).toJS(),
  brandsMarkups: store.getIn(['markups', 'brands']) && store.getIn(['markups', 'brands']).toJS(),
  categoriesTree: store
    .getIn(['categories', 'tree'])
    .valueSeq()
    .toArray(),
});

export default connect(mapStateToProps, {
  fetchManufacturersIfNeeded,
  putMarkup,
  deleteMarkup,
  fetchCategoriesTree,
  fetchManufacturersMarkups,
  fetchBrandsMarkups,
  setTopBarTitle,
})(MarkupContainer);
