// @flow
import React, { useEffect, useState } from 'react';
import { type ReactComponent } from 'types/components';
import { type typeProductType } from 'types/entities/typeProductType';
import { connect } from 'react-redux';
import { setTopBarTitle } from 'components/top_bar/actions';
import { getProductsTypes as getProductsTypesAction } from './actions';
import { ProductsTypesList } from './view/ProductsTypesList';
import { ProductsTypesLayout } from './view/ProductsTypesLayout';
import fuzzyFilter from 'components/fuzzy_filter';
import { TextField } from '@material-ui/core';
import { useDebounce } from 'hooks/useDebounce';

const fuzzyOptions = {
  extract: el => el.name,
  returnAllOnEmptyString: true,
  returnOriginal: true,
};

type ProvidedProps = {|
  types: Array<typeProductType> | null,
  getProductsTypes: () => Promise<any>,
|};

type Props = {||};

const ProductsTypesComponent = ({ types, getProductsTypes }: { ...Props, ...ProvidedProps }) => {
  useEffect(() => {
    getProductsTypes();
  }, []);

  return (
    <ProductsTypesLayout
      typesList={<ProductsTypesList types={types} onChange={getProductsTypes} />}
    />
  );
};

const mapStateToProps = store => ({
  types: store.getIn(['productsTypes', 'list']),
});

export const ProductsTypes: ReactComponent<Props> = connect(mapStateToProps, {
  getProductsTypes: getProductsTypesAction,
  setTopBarTitle,
})(ProductsTypesComponent);
