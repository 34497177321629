// @flow
import {
  FETCH_PRODUCT_PRICING_REPORT_REQUEST,
  FETCH_PRODUCT_PRICING_REPORT_SUCCESS,
  type Action,
} from 'types/actions';
import Immutable from 'immutable';

const productPricingReportInitialState = Immutable.Map({
  isFetching: false,
  report: null,
});

type ProductPricingReportState = Object;

const productPricingReport = (
  state: ProductPricingReportState = productPricingReportInitialState,
  action: Action,
) => {
  switch (action.type) {
    case FETCH_PRODUCT_PRICING_REPORT_REQUEST: {
      return state.set('isFetching', true);
    }
    case FETCH_PRODUCT_PRICING_REPORT_SUCCESS: {
      return state.merge({
        isFetching: false,
        report: action.report,
      });
    }
    default: {
      return state;
    }
  }
};

export default productPricingReport;
