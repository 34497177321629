import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Choiser from 'components/choiser';
import LoadingWrapper from 'components/loading_wrapper';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  row: {
    display: 'flex',
  },
  withImage: {
    minWidth: '200px',
  },
  textField: {
    marginRight: theme.spacing.unit * 2,
    display: 'flex',
    alignItems: 'flex-end',
    minWidth: '200px',
  },
});

const Edit = ({
  classes,
  fetchCategoriesList,
  categories,
  categoriesIsFetching,
  handleChange,
  showAssignCategory,
  selectedCategoryForAssign,
  assignCategory,
  characteristics,
  type,
}) => {
  const fetchCategories = () => {
    if (!categories.length) {
      fetchCategoriesList();
    }
  };
  return (
    <div>
      <Paper className={classes.paper}>
        <div className={classes.row}>
          {showAssignCategory && (
            <React.Fragment>
              <span onClick={fetchCategories} className={classes.textField}>
                <LoadingWrapper loading={categoriesIsFetching}>
                  <Choiser
                    fullWidth={false}
                    inputLabel="Категория"
                    suggestions={[
                      { id: 0, name: 'Без категории', is_publish: true },
                      ...categories,
                    ]}
                    selected={selectedCategoryForAssign}
                    returnSuggestion={suggestion =>
                      handleChange('selectedCategoryForAssign', suggestion)
                    }
                  />
                </LoadingWrapper>
              </span>
              <span className={classes.textField}>
                <Button
                  variant="raised"
                  color="secondary"
                  onClick={assignCategory}
                  disabled={!selectedCategoryForAssign}
                >
                  назначить категорию
                </Button>
              </span>
              {type}
              {characteristics}
            </React.Fragment>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(Edit);
