// @flow
import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { type Theme } from "types/components/Theme";
import { type OrderPayment } from "types/entities/OrderPayment";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import green from "@material-ui/core/colors/green";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import cn from "classnames";

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit * 2
  },
  button: {
    display: "flex",
    alignItems: "center"
  },
  isPaid: {
    color: green[500]
  },
  payedIcon: {
    marginRight: theme.spacing.unit
  }
});
type Props = {|
  classes: Object,
  payments: Array<OrderPayment> | Array<any> | null,
  isPaid: boolean
|};

const Payments = ({ classes, payments, isPaid }: Props) => {
  const [open, toggle] = useState(false);
  const handleToggle = () => toggle(!open);
  if (!payments || payments.length === 0) return null;
  return (
    <div className={classes.root}>
      <Button
        className={cn(classes.button, isPaid ? classes.isPaid : null)}
        onClick={handleToggle}
      >
        {isPaid ? (
          <React.Fragment>
            <MonetizationOn className={classes.payedIcon} />
            Заказ оплачен
          </React.Fragment>
        ) : (
          "Оплаты"
        )}
      </Button>
      <Dialog open={open} onClose={handleToggle}>
        <DialogContent>
          <List>
            {payments &&
              payments.map((payment, index) => (
                <ListItem
                  key={payment.id}
                  divider={payments && payments.length > index + 1}
                >
                  <ListItemText
                    primary={<span>{payment.value} руб.</span>}
                    secondary={
                      <span>
                        {moment(payment.deposited_time).format(
                          "DD.MM.YYYY, HH:mm:ss"
                        )}
                        {", "}
                        {payment.type}{", "}{payment.status}
                        <br />
                        identificator: {payment.identificator}
                      </span>
                    }
                  />
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggle}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(Payments);
