// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { type Theme, type ReactComponent } from 'types/components';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  product: any,
  changeProduct: (name: string, value: any) => any
|};

const ModeratedCheckboxComponent = ({ classes, product, changeProduct }: {| ...Props, ...ProvidedProps |}) => (
  <FormControlLabel
  control={
    <Checkbox checked={product && product.moderated} onChange={() => changeProduct('moderated', Boolean(product && !product.moderated))}/>
  }
  label={product && product.moderated ? 'Проверен' : 'Непроверен'}
/>
);

export const ModeratedCheckbox: ReactComponent<Props> = withStyles(styles)(
  ModeratedCheckboxComponent,
);
