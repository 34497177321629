// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';

const styles = (theme: Theme) => ({
  root: {
    // marginTop: theme.spacing.unit * 2,
  },
});
type Props = {|
  classes: Object,
  details: Object,
  progress: Object,
  editor: Object,
  emailEditor: Object,
  participants: any,
|};

const PromotionLayout = ({
  classes,
  details,
  progress,
  editor,
  emailEditor,
  participants,
}: Props) => (
  <div className={classes.root}>
    {progress}
    {details}
    {editor}
    {emailEditor}
    {participants}
  </div>
);

PromotionLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PromotionLayout);
