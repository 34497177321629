// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Ruble from 'components/ruble';
import WithConfirm from 'components/with-confirm';
import Quantity from 'components/shop/quantity';
import { type OrderLine as OrderLineType } from 'types/entities/OrderLine';
import OrderLine from 'components/shop/order-line';

const styles = (theme: Theme) => ({
  quantity: {
    minWidth: '138px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '24px',
  },
  price: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
type Props = {|
  classes: Object,
  lines: Array<OrderLineType> | null,
  editable: boolean,
  changeQuantity: (lineId: number, quantity: number) => void,
  handleRemoveOrderLine: (lineId: number) => void,
|};

const Lines = ({
  classes,
  lines,
  editable,
  changeQuantity,
  handleRemoveOrderLine,
}: Props) => (
  <div className={classes.root}>
    {lines &&
      lines.sort((a, b) => b.id - a.id).map((line, index) => (
        <OrderLine
          key={line.id}
          index={index}
          line={line}
          priceBlock={(
            <div className={classes.price}>
              <span>
                <b>{line.offer_price}</b> <Ruble sizePx={10} verticalAlign="baseline" />
              </span>
              <span>
                {' * '}
              </span>
              <Quantity
                editable={editable}
                withLimitWarning={editable}
                limit={line.product.quantity}
                provided={line.provided_quantity}
                quantity={line.quantity}
                onChange={quantity => changeQuantity(line.id, quantity)}
              />
              <span>
                {' = '}
              </span>
              <span>
                <b>{line.total_price}</b> <Ruble sizePx={10} verticalAlign="baseline" />
              </span>
            </div>
          )}
          actions={(
            <WithConfirm confirmTitle="Удалить" disabled={!editable} onConfirm={handleRemoveOrderLine(line.id)}>
              <IconButton disabled={!editable} title="Удалить">
                <Close />
              </IconButton>
            </WithConfirm>
          )}
        />
      ))}
  </div>
);

Lines.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Lines);
