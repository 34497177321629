// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { type typeTheme } from 'types/components';
import { YMaps, Map as YandexMap, Placemark } from 'react-yandex-maps';
import queryString from 'query-string';

const styles = (theme: typeTheme) => ({
  root: {
    minHeight: '50vw',
  },
});

type Coordinates = {|
  latitude: number,
  longitude: number,
|};

type Point = {|
  coordinates: Coordinates,
  data: {
    id: number,
    name: string,
  },
|};

type Props = {
  classes: $Call<typeof styles, typeTheme>,
  onClick?: (point: Point) => void,
};

const mapProps = { width: '100%', height: '65vw' };

const MapComponent = ({ classes, points, onClick }: Props) => {
  const [state, setState] = useState({
    center: [43.1164904, 131.8823937],
    zoom: 12,
    controls: [],
  });
  // const handlePointClick = (pointData: Store) => onPointClick(pointData);
  const mapState = () => ({
    center: [43.1164904, 131.8823937],
    zoom: 12,
    controls: [],
  });
  const coordinatesIsSelected = (coordinates: Coordinates) =>
    selectedPoint &&
    selectedPoint.latitude === coordinates.latitude &&
    selectedPoint.longitude === coordinates.longitude;
  const onBoundsChange = e => {
    setState({ ...state, center: e.get('target').getCenter() });
  };
  return (
    <div className={classes.root}>
      <YMaps query={{ apikey: 'fc7561e3-dbe8-44b3-964c-be7c6a6a740a' }}>
        <YandexMap state={state} {...mapProps} onBoundsChange={onBoundsChange}>
          {/* Creating a geo object with the "Point" geometry type. */}
          {points &&
            points.map((point: Point) => (
              <Placemark
                key={point.data.id}
                onClick={() => onClick && onClick(point)}
                geometry={{
                  coordinates: [point.coordinates.latitude, point.coordinates.longitude],
                }}
                properties={{
                  hintContent: point.data.name,
                  // balloonContent: 'Это красивая метка',
                }}
                options={{
                  // Options. You must specify this type of layout.
                  preset: 'islands#icon',
                  iconColor: point.selected ? '#3d5afe' : '#FF7F00',
                }}
              />
            ))}
        </YandexMap>
      </YMaps>
    </div>
  );
};

export const Map = withRouter(withStyles(styles)(MapComponent));
