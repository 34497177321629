// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import { FormControlLabel, Checkbox as MuiCheckbox } from '@material-ui/core';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  label: string,
  onChange: any,
  value: any,
|};

const CheckboxComponent = ({
  classes,
  onChange,
  label,
  value,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => (
  <FormControlLabel
    onChange={onChange}
    checked={Boolean(value)}
    control={<MuiCheckbox color="secondary" indeterminate={value === 0} />}
    label={label}
  />
);

export const Checkbox: ReactComponent<Props> = withStyles(styles)(CheckboxComponent);
