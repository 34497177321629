// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { getProductsTypes } from 'services/productTypeApi';
import Choiser from 'components/choiser';
import LoadingWrapper from 'components/loading_wrapper';

const styles = theme => ({
  root: {},
});

const ProductTypeSelectComponent = ({ classes, type, onChange, onCreate, withEmptyType }) => {
  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState([]);

  const handleGetTypes = () => {
    if (!types.length) {
      setLoading(true);
      getProductsTypes().then(data => {
        withEmptyType ? setTypes([{ id: 0, name: 'Без типа' }, ...data]) : setTypes(data);
        setLoading(false);
      });
    }
  };
  return (
    <div className={classes.root} onClick={handleGetTypes}>
      <LoadingWrapper loading={loading}>
        <Choiser
          onAddNew={onCreate}
          fullWidth
          suggestions={types}
          inputLabel="Тип"
          selected={type}
          returnSuggestion={onChange}
          onHandleDeleteChip={onChange}
        />
      </LoadingWrapper>
    </div>
  );
};

export const ProductTypeSelect: ReactComponent<Props> = withStyles(styles)(
  ProductTypeSelectComponent,
);
