// @flow
import React, { useState, useEffect } from 'react';
import { type ReactComponent } from 'types/components';
import { type typeProductSeries } from 'types/entities/typeProductSeries';
import { connect } from 'react-redux';
import { setTopBarTitle as setTopBarTitleAction } from 'components/top_bar/actions';
import { getSeries as getSeriesAction } from './actions';
import { SeriesLayout } from './view/SeriesLayout';
import { SeriesList } from './view/SeriesList';

type ProvidedProps = {|
  series: Array<typeProductSeries> | null,
  getSeries: () => Promise<any>,
  setTopBarTitle: typeof setTopBarTitleAction,
|};

type Props = {||};

const SeriesComponent = ({ series, getSeries, setTopBarTitle }: { ...Props, ...ProvidedProps }) => {
  useEffect(() => {
    getSeries();
    setTopBarTitle('Серии')
  }, []);
  const groupByBrands = () => {
    const brandsMap = {};
    if (series) {
      series.forEach(item => {
        const seriesWithoutBrand = { ...item };
        delete seriesWithoutBrand.brand;
        if (brandsMap[item.brand.id]) {
          return brandsMap[item.brand.id].series.push(seriesWithoutBrand)
        }
        return brandsMap[item.brand.id] = { brand: item.brand, series: [seriesWithoutBrand] }
      });
    }
    return Object.keys(brandsMap).map(key => brandsMap[key]);
  }
  return <SeriesLayout seriesList={<SeriesList brands={groupByBrands()} />}/>;
};

const mapStateToProps = store => ({
  series: store.getIn(['series', 'list'])
});

export const Series: ReactComponent<Props> = connect(
  mapStateToProps,
  {
    getSeries: getSeriesAction,
    setTopBarTitle: setTopBarTitleAction,
  },
)(SeriesComponent);
