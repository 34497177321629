// @flow
import { type Promotion } from 'types/entities/Promotion';

export const FETCH_PROMOTIONS_REQUEST: 'FETCH_PROMOTIONS_REQUEST' = 'FETCH_PROMOTIONS_REQUEST';
export const FETCH_PROMOTIONS_SUCCESS: 'FETCH_PROMOTIONS_SUCCESS' = 'FETCH_PROMOTIONS_SUCCESS';

export type RequestPromotions = {|
  type: typeof FETCH_PROMOTIONS_REQUEST,
|};

export type ReceivePromotions = {|
  type: typeof FETCH_PROMOTIONS_SUCCESS,
  promotions: Array<Promotion> | null,
  totalCount: number,
|};

export const FETCH_PROMOTION_REQUEST: 'FETCH_PROMOTION_REQUEST' = 'FETCH_PROMOTION_REQUEST';
export const FETCH_PROMOTION_SUCCESS: 'FETCH_PROMOTION_SUCCESS' = 'FETCH_PROMOTION_SUCCESS';

export type RequestPromotion = {|
  type: typeof FETCH_PROMOTION_REQUEST,
|};

export type ReceivePromotion = {|
  type: typeof FETCH_PROMOTION_SUCCESS,
  promotion: Promotion | null,
|};
