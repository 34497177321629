// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect, type Store } from 'react-redux';
import { type RightSideBarAction } from 'types/components';
import { type Manufacturer } from 'types/entities';
import AddCircle from '@material-ui/icons/AddCircle';
import FilterList from '@material-ui/icons/FilterList';
import { withRouter } from 'react-router-dom';
import fuzzyFilter from 'components/fuzzy_filter';
import { setRightSideBarActions } from 'components/right_side_bar/actions';
import { setTopBarTitle } from 'components/top_bar/actions';
import Manufacturers from './view/Manufacturers';
import ManufacturersDialog from './view/ManufacturersDialog';
import ManufacturersFilter from './view/ManufacturersFilter';
import {
  fetchManufacturersIfNeeded,
  deleteAlias,
  addManufacturer,
  editManufacturer,
} from './actions';

const fuzzyOptions = {
  extract: (el: { name: string }) => el.name,
  returnAllOnEmptyString: true,
  returnOriginal: true,
};

type Props = {
  fetchManufacturersIfNeeded: Function,
  setRightSideBarActions: (acriosn: Array<RightSideBarAction>) => void,
  editManufacturer: Function,
  deleteAlias: Function,
  setTopBarTitle: (title: string) => void,
  addManufacturer: Function,
  isFetching: boolean,
  manufacturers: Array<Manufacturer>,
  history: { push: (url: string) => void },
};

type State = {
  addManufacturerDialogOpened: boolean,
  filterOpened: boolean,
  filterString: string,
  dialogType: string,
  name: string,
  slug: string,
  selectedManufacturer: Object | null,
};

export class ManufacturersContainer extends React.Component<Props, State> {
  state = {
    addManufacturerDialogOpened: false,
    filterOpened: false,
    filterString: '',
    dialogType: '',
    name: '',
    slug: '',
    selectedManufacturer: null,
  };
  componentDidMount() {
    const actionsForRightSideBar: Array<RightSideBarAction> = [
      {
        key: 'filterManufacturersOpened',
        icon: <FilterList />,
        name: 'Фильтрация',
        action: () => this.toggleFilterManufacturers(),
      },
      {
        key: 'addManufacturerDialogOpened',
        icon: <AddCircle />,
        name: 'Добавить производителя',
        action: () => this.toggleAddManufacturerDialog('add_manufacturer'),
      },
    ];
    this.props.fetchManufacturersIfNeeded(['alias']);
    this.props.setRightSideBarActions(actionsForRightSideBar);
    this.props.setTopBarTitle('Производители');
  }
  componentWillUnmount() {
    this.props.setRightSideBarActions([]);
  }
  toggleFilterManufacturers = () =>
    this.setState(prev => ({ filterOpened: !prev.filterOpened }));
  toggleAddManufacturerDialog = (
    dialogType: string | void,
    manufacturer: Manufacturer | void,
  ) => {
    this.setState(prev => ({
      addManufacturerDialogOpened: !prev.addManufacturerDialogOpened,
      dialogType,
    }));
    if (dialogType === 'edit_manufacturer' && manufacturer) {
      this.setState({
        name: manufacturer.name || '',
        slug: manufacturer.slug || '',
        selectedManufacturer: manufacturer,
      });
    }
    if (!dialogType) {
      this.setState({
        name: '',
        slug: '',
        selectedManufacturer: null,
        dialogType: '',
      });
    }
  };
  handleChangeFeild = (name: string, value: string) =>
    this.setState({ [name]: value });
  addManufacturer = () => {
    this.props.addManufacturer(this.state.name);
    this.toggleAddManufacturerDialog();
  };
  editManufacturer = () => {
    if (this.state.selectedManufacturer) {
      this.props.editManufacturer(
        this.state.selectedManufacturer.id,
        this.state.name,
        this.state.slug,
      );
    }
    this.toggleAddManufacturerDialog();
  };
  filterData = (data: Array<Object>) =>
    fuzzyFilter(this.state.filterString, data, fuzzyOptions);
  render() {
    return (
      <React.Fragment>
        {this.state.filterOpened && (
          <ManufacturersFilter
            handleChangeFeild={this.handleChangeFeild}
            filterString={this.state.filterString}
          />
        )}
        <Manufacturers
          isFetching={this.props.isFetching}
          history={this.props.history}
          manufacturers={this.filterData(this.props.manufacturers)}
          deleteAlias={this.props.deleteAlias}
          addManufacturerDialogOpened={this.state.addManufacturerDialogOpened}
          toggleAddManufacturerDialog={(manufacturer: Manufacturer) =>
            this.toggleAddManufacturerDialog('edit_manufacturer', manufacturer)
          }
        />
        <ManufacturersDialog
          opened={this.state.addManufacturerDialogOpened}
          type={this.state.dialogType}
          toggleDialog={() => this.toggleAddManufacturerDialog()}
          handleChangeFeild={this.handleChangeFeild}
          name={this.state.name}
          slug={this.state.slug}
          addManufacturer={this.addManufacturer}
          editManufacturer={this.editManufacturer}
          selectedManufacturer={this.state.selectedManufacturer}
        />
      </React.Fragment>
    );
  }
}

ManufacturersContainer.propTypes = {
  fetchManufacturersIfNeeded: PropTypes.func.isRequired,
  setRightSideBarActions: PropTypes.func.isRequired,
  editManufacturer: PropTypes.func.isRequired,
  deleteAlias: PropTypes.func.isRequired,
  setTopBarTitle: PropTypes.func.isRequired,
  addManufacturer: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  manufacturers: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps: Function = (
  store: Store,
): { isFetching: boolean, manufacturers: Array<Object> } => ({
  isFetching: store.getIn(['manufacturers', 'isFetching']),
  manufacturers: store
    .getIn(['manufacturers', 'items'])
    .valueSeq()
    .toArray(),
});

export default withRouter(connect(mapStateToProps, {
  fetchManufacturersIfNeeded,
  deleteAlias,
  setRightSideBarActions,
  addManufacturer,
  editManufacturer,
  setTopBarTitle,
})(ManufacturersContainer));
