// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { type Theme } from 'types/components/Theme';
import Order from 'components/shop/order';

const styles = (theme: Theme) => ({
});

type Props = {|
  classes: Object,
  handleClose: () => void,
  open: boolean,
  getOrders: Function,
|};

const OrderDialog = ({
  classes,
  handleClose,
  open,
  getOrders,
}: Props) => (
  <Dialog
    fullWidth
    maxWidth="md"
    open={open}
    onClose={handleClose}
  >
    <DialogContent>
      <Order getOrders={getOrders} />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>
        Закрыть
      </Button>
    </DialogActions>
  </Dialog>

);

OrderDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderDialog);
