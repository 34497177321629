// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { type Theme, type ReactComponent } from 'types/components';

const styles = (theme: Theme) => ({
  root: {
    minWidth: '150px',
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  selectedPlaces: Array<string> | [],
  places: Array<string> | [],
  onChange: (places: Array<string>) => any,
|};

const ShipmentPlacesComponent = ({
  classes,
  places,
  selectedPlaces,
  onChange,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => (
  <FormControl className={classes.root}>
    <InputLabel htmlFor="places">Места сборки</InputLabel>
    <Select
      fullWidth
      multiple
      value={selectedPlaces}
      onChange={e => onChange(e.target.value)}
      input={<Input id="places" />}
      renderValue={selected => selected.join(', ')}
      MenuProps={MenuProps}
    >
      {places.map(place => (
        <MenuItem key={place} value={place}>
          <Checkbox checked={selectedPlaces.indexOf(place) > -1} />
          <ListItemText primary={place} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export const ShipmentPlaces: ReactComponent<Props> = withStyles(styles)(
  ShipmentPlacesComponent,
);
