// @flow
// @flow-runtime enable
import { post, patch } from 'lib/request';
import { API_HOST } from 'config';

export const createRealizationMethod = async (realizationMethod: $FlowFixMe) => {
  try {
    const response = await post(`${API_HOST}/realization_methods`, realizationMethod).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const changeRealizationMethod = async (realizationMethod) => {
  try {
    const response = await patch(`${API_HOST}/realization_methods/${realizationMethod.id}`, realizationMethod).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    if (idx(error, _ => _.response.data.errors)) {
      return Promise.reject(idx(error, _ => _.response.data.errors));
    }
    return null;
  }
};
