// @flow
// @flow-runtime enable
import { post } from 'lib/request';
import { PUBLIC_API_HOST } from 'config';

export const authenticate = async (username: string, password: string) => {
  try {
    const res = await post(`${PUBLIC_API_HOST}/login`, {
      username,
      password,
    });
    res.data.result = 'success';
    res.data.message = 'Успешный вход';
    return res.data;
  } catch (error) {
    return error.response && error.response.status === 401
      ? { result: 'error', message: 'Неверный логин или пароль' }
      : { result: 'error', message: 'Неизвестная ошибка. Пожалуйста, попробуйте еще раз.' };
  }
};

export const a = () => {};
