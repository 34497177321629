// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { type Theme, type ReactComponent } from 'types/components';
import { type Warehouse } from 'types/entities/Warehouse';
import { PickupTimePrediction } from 'components/shop/pickup-time';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: Object,
|};

type Props = {|
  open: boolean,
  onClose: (warehouse: Warehouse) => void,
  onSelect: any,
|};

const PickupTimeDialogComponent = ({
  classes,
  onClose,
  onSelect,
  open,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => (
  <Dialog className={classes.root} open={open} onClose={onClose}>
    <PickupTimePrediction onSelect={onSelect} />
  </Dialog>
);

export const PickupTimeDialog: ReactComponent<Props> = withStyles(styles)(
  PickupTimeDialogComponent,
);
