// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';
import PriceBlock from 'components/price-block';
import { type Address } from 'types/entities/Address';
import { type Warehouse } from 'types/entities/Warehouse';
import { type TimePeriod } from 'types/entities/TimePeriod';
import { type Order } from 'types/entities/Order';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import idx from 'idx';
import { orderStates } from 'types/entities/OrderState';
import { fetchPickupTime } from 'components/shop/pickup-time/actions';
import Courier from './Courier';
import Post from './Post';
import { Cdek } from './Cdek';
import PickUp from './PickUp';
import PickerInfo from './PickerInfo';
import { RawCartDataDialog } from './RawCartDataDialog';
import { PrivateComment } from './PrivateComment';
import { Typography } from '@material-ui/core';
import { createRealizationMethod } from 'components/shop/realization-method/actions';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
  },
  actions: {
    marginTop: theme.spacing.unit,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  },
});

const emptyState = {
  selectedRealizationType: null,
  selectedName: null,
  selectedPhone: null,
  selectedDeliveryPeriod: null,
  selectedAddress: null,
  selectedWarehouse: null,
};

type RealizationType = 'courier' | 'pick_up' | null;

type Props = {|
  classes: Object,
  editable: boolean,
  edited: boolean,
  order: Order | null,
  changeRealizationMethod: (realizationMethod: $FlowFixMe) => Promise<any>,
  createRealizationMethod: (realizationMethod: $FlowFixMe) => Promise<any>,
  changeEditedStatus: (value: boolean) => void,
  changeOrder: (data: any) => void,
  fetchPickupTime: ({ orderId: number }) => void,
  getOrder: () => void,
|};

type State = {|
  selectedRealizationType: RealizationType | null,
  selectedDeliveryPeriod: TimePeriod | null,
  selectedAddress: Address | null,
  selectedWarehouse: Warehouse | null,
  selectedName: string | null,
  selectedPhone: string | null,
  private_comment: string | null,
  estimated_assembly_time: string | null,
|};

class Delivery extends React.Component<Props, State> {
  state = {
    selectedRealizationType: null,
    selectedName: null,
    selectedPhone: null,
    selectedDeliveryPeriod: null,
    selectedAddress: null,
    selectedWarehouse: null,
    private_comment: null,
    estimated_assembly_time: null,
  };

  componentDidUpdate() {
    const stateIsNotEmpty = Boolean(
      (this.props.order &&
        this.props.order.realization_method &&
        this.state.selectedRealizationType &&
        this.state.selectedRealizationType !== this.props.order.realization_method.type) ||
        this.state.selectedName ||
        this.state.selectedPhone ||
        this.state.selectedDeliveryPeriod ||
        this.state.selectedAddress ||
        this.state.selectedWarehouse,
    );
    if (this.props.edited !== stateIsNotEmpty) this.props.changeEditedStatus(stateIsNotEmpty);
  }

  handleChangeRealizationType = e => {
    if (e.target.value === 'empty') return this.setState({ selectedRealizationType: null });
    return this.setState({ selectedRealizationType: e.target.value });
  };

  handleChangePrivateComment = (private_comment: string) => this.setState({ private_comment });

  changeRealizationMethod = (order: Order, selectedRealizationType: RealizationType) => {
    let privateComment = null;

    try {
      privateComment =
        this.props.order &&
        this.props.order.private_comment &&
        JSON.parse(this.props.order.private_comment);
    } catch (error) {
      console.log(error);
    }

    let nextPrivateComment = {};

    if (privateComment) {
      nextPrivateComment = { ...privateComment };
    }

    if (this.state.private_comment) {
      nextPrivateComment = JSON.stringify({
        ...nextPrivateComment,
        private_comment: this.state.private_comment,
      });
      this.props.changeOrder({
        private_comment: nextPrivateComment,
      });
      this.setState({ private_comment: null });
    }
    const realizationType = selectedRealizationType || idx(order, _ => _.realization_method.type);
    let realizationMethod = {};
    if (idx(order, _ => _.realization_method)) {
      realizationMethod = idx(order, _ => _.realization_method);
    }
    realizationMethod = {
      ...realizationMethod,
      type: realizationType,
      recipient_name:
        this.state.selectedName ||
        idx(order, _ => _.realization_method.recipient_name) ||
        order.user.full_name,
      recipient_phone:
        this.state.selectedPhone ||
        idx(order, _ => _.realization_method.recipient_phone) ||
        order.user.phone,
      orders: [
        {
          ...order,
          realization_method: null,
          private_comment: JSON.stringify({
            ...nextPrivateComment,
          }),
        },
      ],
      user: order.user,
    };
    if (this.state.selectedAddress) {
      realizationMethod = {
        ...realizationMethod,
        address: this.state.selectedAddress,
      };
    }
    if (this.state.selectedDeliveryPeriod) {
      realizationMethod = {
        ...realizationMethod,
        delivery_period: this.state.selectedDeliveryPeriod,
      };
    }
    if (this.state.selectedWarehouse) {
      realizationMethod = {
        ...realizationMethod,
        pick_up_point: this.state.selectedWarehouse,
        estimated_assembly_time: this.state.estimated_assembly_time,
        address: null,
        delivery_period: null,
      };
    }

    if (
      order.realization_method &&
      order.realization_method.address &&
      realizationMethod.address &&
      order.realization_method.address.id === realizationMethod.address.id
    ) {
      return this.props
        .changeRealizationMethod(realizationMethod)
        .then(() => this.setState(emptyState))
        .then(() => {
          if (order) {
            this.props.fetchPickupTime({ orderId: order.id });
            this.props.getOrder();
          }
        });
    }
    if (
      this.state.selectedWarehouse ||
      this.state.selectedDeliveryPeriod ||
      this.state.selectedAddress
    ) {
      this.props
        .createRealizationMethod(realizationMethod)
        .then(() => this.setState(emptyState))
        .then(() => {
          if (order) {
            this.props.fetchPickupTime({ orderId: order.id });
            this.props.getOrder();
          }
        });
    }
  };

  handleUpdateOrderRealizationMethod =
    (order: Order | null, realizationType: RealizationType) => () => {
      if (!order) return null;
      // if (order.realization_method) return this.changeRealizationMethod(order);
      return this.changeRealizationMethod(order, realizationType);
    };

  handleSelectWarehouse = (data: {
    selectedWarehouse: Warehouse,
    estimated_assembly_time: string | null,
  }) => this.setState(data);

  handleSelectAddress = (selectedAddress: Address) => this.setState({ selectedAddress });

  handleSetName = (selectedName: string) => this.setState({ selectedName });

  handleSetPhone = (selectedPhone: string) => this.setState({ selectedPhone });

  handleSelectDeliveryPeriod = (selectedDeliveryPeriod: TimePeriod) =>
    this.setState({ selectedDeliveryPeriod });

  renderRealizationComponent = () => {
    if (this.state.selectedRealizationType) {
      if (this.state.selectedRealizationType === 'post') {
        return (
          <Post
            order={this.props.order}
            selectedAddress={this.state.selectedAddress}
            realizationMethod={
              idx(this.props.order, _ => _.realization_method.type === 'post')
                ? idx(this.props.order, _ => _.realization_method)
                : null
            }
            editable
            handleSelectAddress={this.handleSelectAddress}
          />
        );
      }
      if (this.state.selectedRealizationType === 'courier') {
        return (
          <Courier
            order={this.props.order}
            selectedDeliveryPeriod={this.state.selectedDeliveryPeriod}
            selectedAddress={this.state.selectedAddress}
            realizationMethod={
              idx(this.props.order, _ => _.realization_method.type === 'courier')
                ? idx(this.props.order, _ => _.realization_method)
                : null
            }
            editable
            handleSelectAddress={this.handleSelectAddress}
            handleSelectDeliveryPeriod={this.handleSelectDeliveryPeriod}
          />
        );
      }
      if (this.state.selectedRealizationType === 'pick_up') {
        return (
          <PickUp
            realizationMethod={
              idx(this.props.order, _ => _.realization_method.type === 'pick_up')
                ? idx(this.props.order, _ => _.realization_method)
                : null
            }
            editable
            selectedWarehouse={this.state.selectedWarehouse}
            handleSelectWarehouse={this.handleSelectWarehouse}
          />
        );
      }
    }
    if (idx(this.props.order, _ => _.realization_method.type)) {
      if (idx(this.props.order, _ => _.realization_method.type) === 'post') {
        return (
          <Post
            handleSelectAddress={this.handleSelectAddress}
            realizationMethod={idx(this.props.order, _ => _.realization_method)}
            order={this.props.order}
            editable={this.props.editable}
            selectedAddress={this.state.selectedAddress}
          />
        );
      }
      if (idx(this.props.order, _ => _.realization_method.type) === 'cdec') {
        return (
          <Cdek
            handleSelectAddress={this.handleSelectAddress}
            realizationMethod={idx(this.props.order, _ => _.realization_method)}
            order={this.props.order}
            editable={this.props.editable}
            selectedAddress={this.state.selectedAddress}
          />
        );
      }
      if (idx(this.props.order, _ => _.realization_method.type) === 'courier') {
        return (
          <Courier
            handleSelectAddress={this.handleSelectAddress}
            handleSelectDeliveryPeriod={this.handleSelectDeliveryPeriod}
            realizationMethod={idx(this.props.order, _ => _.realization_method)}
            order={this.props.order}
            editable={this.props.editable}
            selectedDeliveryPeriod={this.state.selectedDeliveryPeriod}
            selectedAddress={this.state.selectedAddress}
          />
        );
      }
      if (idx(this.props.order, _ => _.realization_method.type) === 'pick_up') {
        return (
          <PickUp
            handleSelectWarehouse={this.handleSelectWarehouse}
            realizationMethod={idx(this.props.order, _ => _.realization_method)}
            editable={this.props.editable}
            selectedWarehouse={this.state.selectedWarehouse}
          />
        );
      }
    }
    return null;
  };

  render() {
    const { classes, order, editable } = this.props;
    const {
      selectedRealizationType,
      selectedDeliveryPeriod,
      selectedAddress,
      selectedWarehouse,
      selectedName,
      selectedPhone,
      private_comment,
    } = this.state;

    return (
      <div className={classes.root}>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={6}>
            <Typography variant="title" className={classes.title}>
              Метод реализации{' '}
              {order && (
                <a
                  style={{ fontSize: '12px' }}
                  href={`https://crm.bonjour-dv.ru/orders/${order.id}/assembly-report`}
                  target="_blank"
                >
                  Отчет сборки
                </a>
              )}
            </Typography>
            <Select
              disabled={!this.props.editable}
              fullWidth
              onChange={this.handleChangeRealizationType}
              value={
                selectedRealizationType || idx(order, _ => _.realization_method.type) || 'empty'
              }
            >
              {!idx(order, _ => _.realization_method) && (
                <MenuItem value="empty">
                  <em>Доставка не установлена</em>
                </MenuItem>
              )}
              <MenuItem value="pick_up">Самовывоз</MenuItem>
              <MenuItem value="courier">Доставка</MenuItem>
              <MenuItem value="post">Почта</MenuItem>
              <MenuItem value="cdec">СДЕК</MenuItem>
            </Select>
            {this.renderRealizationComponent()}
            <RawCartDataDialog order={order} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PickerInfo
              editable={this.props.editable}
              handleSetName={this.handleSetName}
              handleSetPhone={this.handleSetPhone}
              name={idx(order, _ => _.realization_method.recipient_name)}
              phone={idx(order, _ => _.realization_method.recipient_phone)}
              comment={order && order.comment}
              selectedName={selectedName}
              selectedPhone={selectedPhone}
            />
            {
              <PrivateComment
                changedValue={private_comment}
                rawPrivateComment={order && order.private_comment}
                onChange={this.handleChangePrivateComment}
              />
            }
          </Grid>
          <Grid item xs={12}>
            <PriceBlock
              title="Доставка"
              cost={idx(order, _ => _.realization_method.shipping_cost)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <div className={classes.actions}>
              <Button
                onClick={this.handleUpdateOrderRealizationMethod(order, selectedRealizationType)}
                color="secondary"
                disabled={!editable}
              >
                Сохранить
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Delivery.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(() => ({}), { fetchPickupTime, createRealizationMethod })(
  withStyles(styles)(Delivery),
);
