// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import { type Theme, type ReactComponent } from 'types/components';
import LazyLoad from 'react-lazyload';
import { del } from 'lib/request';
import { API_HOST } from 'config';
import cn from 'classnames';

const styles = (theme: Theme) => ({
  box: {
    cursor: 'pointer',
    overflow: 'hidden',
    width: '150px',
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    margin: theme.spacing.unit / 2,
    border: '1px solid #fff',
    position: 'relative',
    '&:hover': {
      border: '1px solid #ccc',
    },
    '&:hover button': {
      display: 'block',
    },
  },
  button: {
    width: '48px',
    height: '48px',
    margin: '3px',
    top: 0,
    right: 0,
    position: 'absolute',
    display: 'none',
    backgroundColor: '#fff',
    color: '#8a1316',
  },
  deleted: {
    opacity: 0.1,
    border: '1px solid #8a1316',
  },
  img: {
    width: '100%',
    height: '100%',
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  image: any,
|};

const ImageComponent = ({
  classes,
  image,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const [deleted, setDeleted] = useState(false);
  const handleDelete = e => {
    e.stopPropagation();
    del(`${API_HOST}/product_images/${image.id}`).then(() => setDeleted(true));
  };
  return (
    <LazyLoad height={200} once unmountIfInvisible>
      {/* <div
        className={cn(classes.box, deleted ? classes.deleted : null)}
        onClick={deleted ? null : handleDelete}
        title="Удалить"
      >
        {!deleted && (
          <IconButton
            title="Открыть"
            className={classes.button}
            onClick={() => window.open(image.uri, '_blank')}
          >
            <Visibility />
          </IconButton>
        )}
        <img className={classes.img} src={`${image.uri}?h=200`} />
      </div> */}
    </LazyLoad>
  );
};
export const Image: ReactComponent<Props> = withStyles(styles)(ImageComponent);
