// @flow
import { GET_SERIES_SUCCESS, type ReceiveSeries } from 'types/actions/Series';
import { getProductSeries } from 'services/productSeriesApi';
import { type typeProductSeries } from 'types/entities/typeProductSeries';

const receiveSeries = (series: Array<typeProductSeries> | null): ReceiveSeries => ({
  type: GET_SERIES_SUCCESS,
  series,
});

export const getSeries = (params: { brand: string | number } | void) => (dispatch: $FlowFixMe) =>
  getProductSeries(params).then(series => dispatch(receiveSeries(series)));
