// @flow

import { type Order } from 'types/entities/Order';

export const FETCH_ORDERS_REQUEST: 'FETCH_ORDERS_REQUEST' = 'FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS: 'FETCH_ORDERS_SUCCESS' = 'FETCH_ORDERS_SUCCESS';

export type RequestOrders = {|
  type: typeof FETCH_ORDERS_REQUEST,
|};

export type ReceiveOrders = {|
  type: typeof FETCH_ORDERS_SUCCESS,
  orders: Array<Order> | null,
  totalCount: number,
|};

export const FETCH_ORDER_REQUEST: 'FETCH_ORDER_REQUEST' = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS: 'FETCH_ORDER_SUCCESS' = 'FETCH_ORDER_SUCCESS';

export type RequestOrder = {|
  type: typeof FETCH_ORDER_REQUEST,
|};

export type ReceiveOrder = {|
  type: typeof FETCH_ORDER_SUCCESS,
  order: Order | null,
|};
