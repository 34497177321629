import React, { Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';

const styles = theme => ({
  wrapper: {
    position: 'relative',
  },
  progress: {
    color: theme.palette.secondary[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

const LoadingWrapper = ({
  classes,
  loading,
  children,
  size,
}) => (
  <div className={classes.wrapper}>
    {children}
    {loading && <CircularProgress size={size} className={classes.progress} />}
  </div>
);

LoadingWrapper.defaultProps = {
  size: 24,
};

LoadingWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.object.isRequired,
  size: PropTypes.number
};

export default withStyles(styles)(LoadingWrapper);
