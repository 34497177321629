import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/icons/Link';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import { SHOP_HOST } from 'config';

const style = {
  display: 'inline-block',
  // border: '1px dashed gray',
  // backgroundColor: 'white',
  cursor: 'move',
  marginRight: '16px',
};

const styles = theme => ({
  a: {
    color: theme.palette.secondary[500],
  },
});

const boxSource = {
  beginDrag(props) {
    props.setDraggingElement(props.item);
    return {};
  },
  endDrag(props) {
    props.onDragEnd();
  },
};

class Box extends Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    item: PropTypes.object,
  };

  render() {
    const { connectDragSource, item } = this.props;
    if (!item) return null;

    return connectDragSource(
      <div style={style}>
        {item.name}
        <IconButton href={`${SHOP_HOST}/category/${item.id}`} target="_blank" title="Открыть товар" color="secondary">
          <Link />
        </IconButton>
      </div>,
    );
  }
}

export default DragSource('box', boxSource, connect => ({
  connectDragSource: connect.dragSource(),
}))(withStyles(styles)(Box));
