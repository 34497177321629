// @flow
import { get, put, del } from 'lib/request';
import { API_HOST } from 'config';
import {
  FETCH_PRODUCT_PRICING_REPORT_REQUEST,
  FETCH_PRODUCT_PRICING_REPORT_SUCCESS,
  type ReceiveProductPricingReport,
  type RequestProductPricingReport,
} from 'types/actions';
import { type ProductPricingReport } from 'types/entities';
import { addNotification } from '../notifications/actions';

export const requestProductPricingReport = (): RequestProductPricingReport => ({
  type: FETCH_PRODUCT_PRICING_REPORT_REQUEST,
});
export const receiveProductPricingReport = (
  report: ProductPricingReport,
): ReceiveProductPricingReport => ({
  type: FETCH_PRODUCT_PRICING_REPORT_SUCCESS,
  report,
});

export const fetchProductPricingReport = ({ productId, fias_id }) => (dispatch: $FlowFixMe) => {
  dispatch(requestProductPricingReport());
  get(`${API_HOST}/products/${productId}/pricingReport?fias_id=${fias_id}`).then(
    res => {
      if (res && res.data) {
        dispatch(receiveProductPricingReport(res.data));
      }
    },
    () => dispatch(addNotification('Неудачная попытка загрузки отчета по наценкам')),
  );
};

export const setProductFixedPrice = ({ productId, price, fias_id }) => (dispatch: $FlowFixMe) =>
  put(`${API_HOST}/products/${productId}/price`, { amount: price }).then(
    () => dispatch(fetchProductPricingReport({ productId, fias_id })),
    () => dispatch(addNotification('Неудачная попытка изменения фиксированной цены продукта')),
  );
export const setProductMarkup = ({ markup, fias_id }) => (dispatch: $FlowFixMe) =>
  put(`${API_HOST}/products_markups`, markup).then(
    () => dispatch(fetchProductPricingReport({ productId: markup.product.id, fias_id })),
    () => dispatch(addNotification('Неудачная попытка изменения наценки продукта')),
  );

export const removeProductFixedPrice = ({ productId, fias_id }) => (dispatch: $FlowFixMe) =>
  del(`${API_HOST}/products/${productId}/price`).then(
    () => dispatch(fetchProductPricingReport({ productId, fias_id })),
    () => dispatch(addNotification('Неудачная попытка удаления фиксированной цены продукта')),
  );

export const removeProductMarkup = ({ markup, fias_id }) => (dispatch: $FlowFixMe) =>
  del(`${API_HOST}/products_markups`, markup).then(
    () => dispatch(fetchProductPricingReport({ productId: markup.product.id, fias_id })),
    () => dispatch(addNotification('Неудачная попытка удаления наценки продукта')),
  );
