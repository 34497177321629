// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';
import { PriceUploadForm } from 'components/shop/price-upload-form';
import { type Address } from 'types/entities/Address';
import { type Warehouse as WarehouseType } from 'types/entities/Warehouse';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import { ListItemIcon } from '@material-ui/core';
import { Schedule } from './Schedule';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 0,
  },
});
type Props = {|
  classes: Object,
  warehouse: WarehouseType | null,
  selectedAddress: Address | null,
  selectedName: string | null,
  isPickPoint: boolean | null,
  toggleAddressDialog: () => void,
  toggleNameDialog: () => void,
  toggleIsPickupPoint: (value: boolean) => void,
|};

const Warehouse = ({
  classes,
  warehouse,
  toggleAddressDialog,
  toggleNameDialog,
  selectedName,
  selectedAddress,
  isPickPoint,
  toggleIsPickupPoint,
  schedule,
  images,
}: Props) => (
  <React.Fragment>
    <List className={classes.root}>
      <ListItem divider>
        <ListItemText primary={`id: ${warehouse ? warehouse.id : ''}`} />
      </ListItem>
      <ListItem button divider>
        <ListItemText
          secondary="название"
          primary={selectedName || (warehouse && warehouse.name)}
          onClick={toggleNameDialog}
        />
      </ListItem>
      <ListItem button divider>
        <ListItemText
          secondary="адрес"
          primary={
            (selectedAddress && selectedAddress.line) || (warehouse && warehouse.address.line)
          }
          onClick={toggleAddressDialog}
        />
      </ListItem>
      <ListItem divider>
        <ListItemIcon>
          <Checkbox disabled checked={Boolean(warehouse && warehouse.is_selling)} />
        </ListItemIcon>
        <ListItemText primary="Участвует в продажах" />
      </ListItem>
      <ListItem divider>
        <ListItemIcon>
          <Checkbox disabled checked={Boolean(warehouse && warehouse.is_pricing_participant)} />
        </ListItemIcon>
        <ListItemText primary="Участвует в ценообразовнии" />
      </ListItem>
      <ListItem divider>
        <ListItemIcon>
          <Checkbox disabled checked={Boolean(warehouse && warehouse.is_assembly_point)} />
        </ListItemIcon>
        <ListItemText primary="Участвует в комплектации" />
      </ListItem>
      <ListItem divider>
        <ListItemIcon>
          <Checkbox
            onChange={toggleIsPickupPoint(
              isPickPoint === null ? !(warehouse && warehouse.is_pick_point) : !isPickPoint,
            )}
            checked={Boolean(
              isPickPoint === null ? warehouse && warehouse.is_pick_point : isPickPoint,
            )}
          />
        </ListItemIcon>
        <ListItemText primary="Пункт выдачи" />
      </ListItem>
      <ListItem divider>
        <ListItemIcon>
          <Checkbox disabled checked={Boolean(warehouse && warehouse.is_online_payment)} />
        </ListItemIcon>
        <ListItemText
          primary="Онлайн оплата"
          secondary={warehouse && warehouse.sberbank_merchant && warehouse.sberbank_merchant.login}
        />
      </ListItem>
      {warehouse && ['polaris_vlad', 'dixi_vlad'].includes(warehouse.vendor_id) && (
        <ListItem divider>
          <ListItemIcon>
            <PriceUploadForm
              url={
                {
                  polaris_vlad: '/import_polaris',
                  dixi_vlad: '/import_dixi',
                }[warehouse.vendor_id]
              }
            />
          </ListItemIcon>
          <ListItemText
            primary="Загрузить прайс-лист"
            secondary={{
              polaris_vlad: (
                <div>
                  <div>
                    <b>Порядок столбцов:</b> 'Код номенклатуры', 'Наименование номенклатуры',
                    'Цвет', 'Доступно, шт', 'Цена 1', 'Скидка, %', 'Штрих-код 1'
                  </div>
                  <div>
                    <b>Разделитель:</b> ;
                  </div>
                  <div>
                    <b>Кодировка</b> Windows-1251
                  </div>
                </div>
              ),
              dixi_vlad: (
                <div>
                  <div>
                    <b>Порядок столбцов:</b> 'Штрихкод', 'Количество', 'Навазние', 'Цена'
                  </div>
                  <div>
                    <b>Разделитель:</b> ;
                  </div>
                  <div>
                    <b>Кодировка</b> Windows-1251
                  </div>
                </div>
              ),
            }[warehouse.vendor_id]}
          />
        </ListItem>
      )}
    </List>
    {schedule}
    {images}
  </React.Fragment>
);

Warehouse.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Warehouse);
