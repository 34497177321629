// @flow

import { type typeProductType } from 'types/entities/typeProductType';

export const GET_PRODUCTS_TYPES_SUCCESS: 'GET_PRODUCTS_TYPES_SUCCESS' = 'GET_PRODUCTS_TYPES_SUCCESS';

export type ReceiveProductsTypes = {|
  type: typeof GET_PRODUCTS_TYPES_SUCCESS,
  productsTypes: Array<typeProductType> | null,
|};
