// @flow
import { GET_PRODUCTS_TYPES_SUCCESS, type ReceiveProductsTypes } from 'types/actions/ProductsTypes';
import { getProductsTypes as getProductsTypesFromApi } from 'services/productTypeApi';
import { type typeProductType } from 'types/entities/typeProductType';

const receiveProductsTypes = (productsTypes: Array<typeProductType> | null): ReceiveProductsTypes => ({
  type: GET_PRODUCTS_TYPES_SUCCESS,
  productsTypes,
});

export const getProductsTypes = () => (dispatch: $FlowFixMe) =>
getProductsTypesFromApi().then(productsTypes => dispatch(receiveProductsTypes(productsTypes)));
