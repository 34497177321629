// @flow
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { type Theme } from 'types/components/Theme';
import { type Order } from 'types/entities/Order';

const styles = (theme: Theme) => ({
  button: {
    marginTop: theme.spacing.unit,
  },
});

type Props = {|
  classes: Object,
  order: Order | null,
|};

const RawCartDataDialogComponent = ({ classes, order }: Props) => {
  const [open, toggle] = useState(false);
  const handleToggle = () => toggle(!open);
  let parsedComment = null;

  try {
    parsedComment = order && order.private_comment && JSON.parse(order.private_comment);
  } catch (error) {
    console.log(error);
  }

  return (
    <React.Fragment>
      <Button onClick={handleToggle} className={classes.button} color="secondary">
        Данные из корзины
      </Button>
      <Dialog open={open} onClose={handleToggle}>
        {parsedComment && (
          <DialogContent>
            <div>Имя получателя: {parsedComment.recipient_name}</div>
            <div>Телефон получателя: {parsedComment.recipient_phone}</div>
            <div>Адрес доставки: {parsedComment.deliveryAddressString}</div>
            {parsedComment.delivery_period && (
              <div>
                {' '}
                Дата доставки: {moment(parsedComment.delivery_period.start).format('DD MMMM, YYYY')}
                {', '}
                {moment(parsedComment.delivery_period.start).format('HH:mm')}
                {' - '}
                {moment(parsedComment.delivery_period.end).format('HH:mm')}
              </div>
            )}
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleToggle}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export const RawCartDataDialog = withStyles(styles)(RawCartDataDialogComponent);
