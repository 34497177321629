// @flow

import { type Warehouse } from 'types/entities/Warehouse';

export const FETCH_WAREHOUSES_REQUEST: 'FETCH_WAREHOUSES_REQUEST' = 'FETCH_WAREHOUSES_REQUEST';
export const FETCH_WAREHOUSES_SUCCESS: 'FETCH_WAREHOUSES_SUCCESS' = 'FETCH_WAREHOUSES_SUCCESS';

export type RequestWarehouses = {|
  type: typeof FETCH_WAREHOUSES_REQUEST,
|};

export type ReceiveWarehouses = {|
  type: typeof FETCH_WAREHOUSES_SUCCESS,
  warehouses: Array<Warehouse> | null,
|};

export const FETCH_WAREHOUSE_REQUEST: 'FETCH_WAREHOUSE_REQUEST' = 'FETCH_WAREHOUSE_REQUEST';
export const FETCH_WAREHOUSE_SUCCESS: 'FETCH_WAREHOUSE_SUCCESS' = 'FETCH_WAREHOUSE_SUCCESS';

export type RequestWarehouse = {|
  type: typeof FETCH_WAREHOUSE_REQUEST,
|};

export type ReceiveWarehouse = {|
  type: typeof FETCH_WAREHOUSE_SUCCESS,
  warehouse: Warehouse | null,
|};
