// @flow
import { get, post, patch, del } from 'lib/request';
import { API_HOST } from 'config';
import {
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_IMAGES_SUCCESS,
  FETCH_PRODUCT_PRICING_REPORT_SUCCESS,
  type RequestProduct,
  type ReceiveProduct,
  type ReceiveProductImages,
  type ReceiveProductPricingReport,
} from 'types/actions';
import { type Name, type Image } from 'types/entities';
import { type Product } from 'types/entities/Product';
import { addNotification } from '../notifications/actions';

export const requestProduct = (value: boolean): RequestProduct => ({
  type: FETCH_PRODUCT_REQUEST,
  value,
});

export const receiveProduct = (product: Product): ReceiveProduct => ({
  type: FETCH_PRODUCT_SUCCESS,
  product,
});

export const receiveProductImages = (images: Array<Image> | Array<any>): ReceiveProductImages => ({
  type: FETCH_PRODUCT_IMAGES_SUCCESS,
  images,
});

export const fetchProduct = (productId: number) => (dispatch: $FlowFixMe) => {
  dispatch(requestProduct(true));
  return get(`${API_HOST}/products/${productId}`).then(
    res => {
      if (res && res.data.product) {
        dispatch(receiveProduct(res.data.product));
        return res.data.product;
      }
    },
    () => dispatch(addNotification('Неудачная попытка загрузки продукта')),
  );
};

export const fetchProductImages = (productId: number) => (dispatch: $FlowFixMe) => {
  dispatch(requestProduct(true));
  get(`${API_HOST}/products/${productId}/images`).then(
    res => {
      if (res && res.data.images) {
        const imagesWithFullPath = res.data.images.map(image => ({
          ...image,
          public_path: `${API_HOST}${image.public_path}`,
        }));
        dispatch(receiveProductImages(imagesWithFullPath));
      }
    },
    () => dispatch(addNotification('Неудачная попытка загрузки изображений продукта')),
  );
};

export const changeProductImage = ({ productId, image }) => (dispatch: $FlowFixMe) => {
  dispatch(requestProduct(true));
  return patch(`${API_HOST}/products/${productId}/images/${image.id}`, image).then(
    res => {
      if (res && res.data.images) {
        const imagesWithFullPath = res.data.images.map(image => ({
          ...image,
          public_path: `${API_HOST}${image.public_path}`,
        }));
        dispatch(receiveProductImages(imagesWithFullPath));
      }
    },
    () => dispatch(addNotification('Неудачная попытка загрузки изображений продукта')),
  );
};

export const addImages = (productId: number, data: Array<File>) => (dispatch: $FlowFixMe) => {
  const images = new FormData();
  data.forEach((file, index) => images.append(`image[${index}]`, file));
  post(`${API_HOST}/products/${productId}/images`, images).then(
    res => {
      dispatch(fetchProductImages(productId));
      if (res.data.errors) {
        res.data.errors.forEach(error => dispatch(addNotification(error.title)));
      }
    },
    () => {
      dispatch(addNotification('Неудачная попытка добавления изображений'));
      dispatch(fetchProduct(productId));
    },
  );
};

export const changeProduct = (productId: number, name: string, value: any) => (
  dispatch: $FlowFixMe,
) => patch(`${API_HOST}/products/${productId}`, { [name]: value }).then(
    () => dispatch(fetchProduct(productId)),
    () => {
      dispatch(addNotification('Неудачная попытка изменения продукта'));
      dispatch(fetchProduct(productId));
    },
  );

export const changeCompositeName = (
  productId: number,
  data: {
    composite_name_type?: string,
    composite_name_series?: string,
    composite_name_extended?: string,
    composite_name_properties?: string,
  },
) => (dispatch: $FlowFixMe) => {
  patch(`${API_HOST}/products/${productId}`, data).then(
    () => dispatch(fetchProduct(productId)),
    () => {
      dispatch(addNotification('Неудачная попытка изменения продукта'));
      dispatch(fetchProduct(productId));
    },
  );
};

export const deleteEntityFromProduct = (productId: number, name: string) => (
  dispatch: $FlowFixMe,
) => {
  del(`${API_HOST}/products/${productId}/${name}`).then(
    () => dispatch(fetchProduct(productId)),
    () => {
      dispatch(addNotification('Неудачная попытка изменения продукта'));
      dispatch(fetchProduct(productId));
    },
  );
};

export const addName = (productId: number, value: string) => (dispatch: $FlowFixMe) => {
  post(`${API_HOST}/products/${productId}/names`, { value }).then(
    () => dispatch(fetchProduct(productId)),
    () => {
      dispatch(addNotification('Неудачная попытка изменения продукта.'));
      dispatch(fetchProduct(productId));
    },
  );
};

export const setDefaultName = (productId: number, name: Name) => (dispatch: $FlowFixMe) => {
  patch(`${API_HOST}/products/${productId}`, { name }).then(
    () => dispatch(fetchProduct(productId)),
    () => {
      dispatch(addNotification('Неудачная попытка изменения продукта'));
      dispatch(fetchProduct(productId));
    },
  );
};

export const deleteName = (nameId: number) => (dispatch: $FlowFixMe, getState: $FlowFixMe) => {
  const product = getState().getIn(['product', 'data']);
  del(`${API_HOST}/product_names/${nameId}`).then(
    () => dispatch(fetchProduct(product.id)),
    () => {
      dispatch(addNotification('Неудачная попытка изменения продукта'));
      dispatch(fetchProduct(product.id));
    },
  );
};
export const removeImage = (productId: number, imageId: number) => (dispatch: $FlowFixMe) => {
  del(`${API_HOST}/products/${productId}/images/${imageId}`).then(
    () => {
      dispatch(fetchProductImages(productId));
      dispatch(fetchProduct(productId));
    },
    () => {
      dispatch(addNotification('Неудачная попытка удаления изображения'));
      dispatch(fetchProductImages(productId));
    },
  );
};
