import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Choiser from 'components/choiser';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
    display: 'flex',
    alignItems: 'flex-end',
  },
  textField: {
    marginRight: theme.spacing.unit * 3,
  },
});

const BrandsFilter = ({
  classes,
  handleChangeFeild,
  filterString,
  manufacturers,
  selectedManufacturer,
  setSelectedManufacturer,
}) => (
  <Paper className={classes.root}>
    <TextField
      label="Бренд"
      value={filterString}
      name="filterString"
      className={classes.textField}
      onChange={e => handleChangeFeild(e.target.name, e.target.value)}
      margin="none"
    />
    <Choiser
      fullWidth={false}
      inputLabel="Производитель"
      suggestions={manufacturers}
      selected={selectedManufacturer}
      returnSuggestion={
        suggestion => setSelectedManufacturer(suggestion)
      }
    />
  </Paper>
);

BrandsFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChangeFeild: PropTypes.func.isRequired,
  setSelectedManufacturer: PropTypes.func.isRequired,
  fetchManufacturersIfNeeded: PropTypes.func.isRequired,
  filterString: PropTypes.string.isRequired,
  manufacturers: PropTypes.array.isRequired,
  selectedManufacturer: PropTypes.any,
};

export default withStyles(styles)(BrandsFilter);
