// @flow
import React from 'react';
import { connect } from 'react-redux';
import { type ReactComponent } from 'types/components';
import fuzzyFilter from 'components/fuzzy_filter';
import { type PickupPoint as PickupPointType } from 'types/entities/PickupPoint';
import { type Warehouse } from 'types/entities/Warehouse';
import { PickupTimePredictionLayout } from './view/PickupTimePredictionLayout';
import { PickupPoint } from './view/PickupPoint';
import { FilterInput } from './view/FilterInput';
import { fetchPickupPrediction } from './actions';

const fuzzyOptions = {
  extract: (el: PickupPointType) => el.warehouse.name,
  returnAllOnEmptyString: true,
  returnOriginal: true,
};

type ProvidedProps = {|
  fetchPickupPrediction: typeof fetchPickupPrediction,
  orderId: number | null,
  pickupPoints: Array<PickupPointType> | null,
|};

type Props = {|
  onSelect: (warehouse: Warehouse) => void,
  isFetching: boolean,
|};

type State = {|
  query: string,
|};

export class ComponentContainer extends React.Component<{| ...Props, ...ProvidedProps |}, State> {
  state = {
    query: '',
  };

  componentDidMount() {
    if (this.props.orderId) {
      this.props.fetchPickupPrediction({ orderId: this.props.orderId });
    }
  }

  changeQuery = (query: string) => this.setState({ query });

  render() {
    const filtredList =
      this.props.pickupPoints &&
      fuzzyFilter(this.state.query, this.props.pickupPoints, fuzzyOptions);
    return (
      <PickupTimePredictionLayout
        isFetching={this.props.isFetching}
        input={<FilterInput onChange={this.changeQuery} query={this.state.query} />}
        list={
          filtredList &&
          filtredList
            .sort((a, b) => (a && a.readiness_time > b.readiness_time ? 1 : -1))
            .map(point => (
              <PickupPoint
                key={point.warehouse.id}
                pickupPoint={point}
                onClick={this.props.onSelect}
              />
            ))
        }
      />
    );
  }
}

const mapStateToProps = store => {
  const order = store.getIn(['shop', 'order']);
  const pickupTime = store.getIn(['shop', 'pickupTime']);
  return {
    orderId: order.data.id,
    pickupPoints: pickupTime.pickupPoints,
    isFetching: pickupTime.pickupPointsIsFetching,
  };
};

const mapDispatchToProps = { fetchPickupPrediction };

export const PickupTimePrediction: ReactComponent<Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentContainer);
