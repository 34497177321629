// @flow
// @flow-runtime enable
import { type Supplier } from './Supplier';

export type ProductName = {|
  id: number,
  value: string,
  supplier?: Supplier,
  created_time?: number,
|} | string;
