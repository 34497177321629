// @flow
import {
  FETCH_DELIVERY_PERIODS_REQUEST,
  FETCH_DELIVERY_PERIODS_SUCCESS,
} from 'types/actions/DeliveryPeriods';
import { type Action } from 'types/actions/Action';
import { type DeliveryPeriodsState } from 'types/store/DeliveryPeriods';

export const deliveryPeriodsInitialState: DeliveryPeriodsState = {
  isFetching: false,
  list: null,
};

const deliveryPeriods = (
  state: DeliveryPeriodsState = deliveryPeriodsInitialState,
  action: Action,
): DeliveryPeriodsState => {
  switch (action.type) {
    case FETCH_DELIVERY_PERIODS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_DELIVERY_PERIODS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: action.deliveryPeriods,
      };
    }
    default: {
      return state;
    }
  }
};

export default deliveryPeriods;
