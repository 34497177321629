// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { type Product } from 'types/entities/Product';
import { connect } from 'react-redux';
import { setTopBarTitle } from 'components/top_bar/actions';
import Products from 'components/products';

type Props = {|   
  history: Object,
  setTopBarTitle: (title: string) => void,
|};

export class ProductsPageContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.setTopBarTitle('Продукты');
  }

  // onSelect = (product: Product) => () => this.props.history.push(`/product/${product.id}`)
  onSelect = (product: Product) => () => null;

  render() {
    return (
      <Products
        onSelect={this.onSelect}
        withEdit
      />
    );
  }
}

ProductsPageContainer.propTypes = {
  history: PropTypes.object.isRequired,
};


export default withRouter(connect(() => ({}), { setTopBarTitle } )(ProductsPageContainer));
