import { TOGGLE_RIGHT_SIDE_BAR, SET_RIGHT_SIDE_BAR_ACTIONS } from 'types/actions';
import Immutable from 'immutable';

const rightSideBarInitialState = Immutable.Map({
  opened: false,
  actions: Immutable.List(),
});

const rightSideBar = (state = rightSideBarInitialState, action) => {
  switch (action.type) {
    case TOGGLE_RIGHT_SIDE_BAR: {
      return state.set(
        'opened',
        typeof action.value !== 'undefined' ?  action.value : !state.get('opened'),
      );
    }
    case SET_RIGHT_SIDE_BAR_ACTIONS: {
      return state.merge({
        actions: Immutable.List(action.actions),
        opened: Boolean(action.actions.length) ? state.get('opened') : false,
      });
    }
    default: {
      return state;
    }
  }
};

export default rightSideBar;
