// @flow
import {
  FETCH_PROMOTION_REQUEST,
  FETCH_PROMOTION_SUCCESS,
} from 'types/actions/Promotion';
import { type Action } from 'types/actions/Action';
import { type PromotionState } from 'types/store/Promotion';

export const promotionInitialState: PromotionState = {
  isFetching: false,
  data: null,
};

const promotion = (
  state: PromotionState = promotionInitialState,
  action: Action,
): any => {
  switch (action.type) {
    case FETCH_PROMOTION_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_PROMOTION_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.promotion,
      };
    }
    default: {
      return state;
    }
  }
};

export default promotion;
