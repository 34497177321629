// @flow
import { type Warehouse } from './Warehouse';

export type OrderLinePlanItem = {|
  status: string,
  warehouse_sender: Warehouse,
  warehouse_recipient: Warehouse,
  created_time: string,
  completed_time: string | null,
  failed_time: string | null,
  estimated_completion_time: string,
|};
