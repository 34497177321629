Comment; // @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import CommentIcon from '@material-ui/icons/Comment';
import { type Theme } from 'types/components/Theme';
import moment from 'moment';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
    display: 'flex',
  },
  iconBlock: {
    marginRight: theme.spacing.unit * 2,
  },
  date: {
    color: theme.palette.grey[500],
  },
});
type Props = {|
  classes: Object,
  disabled: boolean,
  onClick: () => void,
|};

const Comment = ({ classes, comment }: Props) => (
  <div className={classes.root}>
    <div className={classes.iconBlock}>
      <CommentIcon />
    </div>
    <div>
      <div className={classes.date}>{moment(comment.created_at).format('DD.MM.YYYY HH:mm')}</div>
      <div>
        {comment.comment}
      </div>
    </div>
  </div>
);

export default withStyles(styles)(Comment);
