// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { type Supplier } from 'types/entities';
import Progress from 'components/progress';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { SuppliersContainer } from '../SuppliersContainer';


const styles = theme => ({
});

type Props = {
  classes: Object,
  isFetching: boolean,
  suppliers: Array<Supplier> | Array<any>,
};

const Suppliers = ({ classes, suppliers, isFetching }: Props) => (
  <div>
    <Progress color="secondary" isFetching={isFetching} />
    <Grid container spacing={24}>
      {suppliers.map(supplier => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          key={supplier.alias}
        >
          <Card>
            <CardContent>
              <Typography variant="headline" component="h2">
                {supplier.name}
              </Typography>
              <Typography color="textSecondary">
                {supplier.alias}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary">Склады</Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  </div>
);

Suppliers.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  suppliers: PropTypes.array.isRequired,
};

export default withStyles(styles)(Suppliers);
