import { TOGGLE_RIGHT_SIDE_BAR, SET_RIGHT_SIDE_BAR_ACTIONS } from 'types/actions';

export const toggleRightSideBar = (value) => dispatch =>
  dispatch({
    type: TOGGLE_RIGHT_SIDE_BAR,
    value
  });

export const setRightSideBarActions = actions => dispatch =>
  dispatch({
    type: SET_RIGHT_SIDE_BAR_ACTIONS,
    actions,
  });
