// @flow
import { type TimePeriod } from 'types/entities/TimePeriod';

export const FETCH_DELIVERY_PERIODS_REQUEST: 'FETCH_DELIVERY_PERIODS_REQUEST' = 'FETCH_DELIVERY_PERIODS_REQUEST';
export const FETCH_DELIVERY_PERIODS_SUCCESS: 'FETCH_DELIVERY_PERIODS_SUCCESS' = 'FETCH_DELIVERY_PERIODS_SUCCESS';

export type RequestDeliveryPeriods = {|
  type: typeof FETCH_DELIVERY_PERIODS_REQUEST,
|};

export type ReceiveDeliveryPeriods = {|
  type: typeof FETCH_DELIVERY_PERIODS_SUCCESS,
  deliveryPeriods: Array<TimePeriod> | null,
|};
