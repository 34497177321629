// @flow
import React, { useState, useEffect } from 'react';
import { type ReactComponent } from 'types/components';
import { getReviews, patchReview, patchExternalReview } from 'services/reviewApi';
import { ReviewsLayout } from './view/ReviewsLayout';
import { Review } from './view/Review';
import { useGetExternalReviews } from 'hooks/api';
import { Tabs, Tab } from '@material-ui/core';

type ProvidedProps = {};

type Props = {
  productId?: number | null,
};

const ReviewsComponent = ({ productId }: { ...Props, ...ProvidedProps }) => {
  const [tab, setTab] = useState(0);
  const [state, changeState] = useState({
    reviews: null,
  });
  const setSate = (data: Object) => changeState({ ...state, ...data });

  const getData = () => {
    getReviews({ product: productId || null }).then(reviews => setSate({ reviews }));
  };

  const externalReviewsRequest = useGetExternalReviews();

  useEffect(() => getData(), []);

  const handleOnChange = (data: Object) => {
    patchReview(data).then(() => getData());
  };

  const handleOnChangeExternal = (data: Object) => {
    patchExternalReview(data).then(() => externalReviewsRequest.refetch());
  };

  return (
    <div>
      <Tabs value={tab} onChange={(e, value) => setTab(value)}>
        <Tab value={0} label="Отзывы на товары" />
        <Tab value={1} label="Внешние отзывы" />
      </Tabs>
      <ReviewsLayout
        reviews={
          <React.Fragment>
            {tab === 0 &&
              state.reviews &&
              state.reviews
                .sort((a, b) => (a.updated_at > b.updated_at ? -1 : 1))
                .map(review => (
                  <Review review={review} key={review.id} onChange={( enabled) => handleOnChange({ id: review.id, enabled })} />
                ))}
            {tab === 1 &&
              externalReviewsRequest.data &&
              externalReviewsRequest.data.map(review => (
                <Review review={review} key={review.id} onChange={( published) => handleOnChangeExternal({ id: review.id, published })} />
              ))}
          </React.Fragment>
        }
      />
    </div>
  );
};

export const Reviews: ReactComponent<Props> = ReviewsComponent;
