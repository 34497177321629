// @flow
import { type Route } from 'types/components';
import Home from 'components/Home';
import Manufacturers from 'components/manufacturers/';
import UndefinedManufacturers from 'components/undefined_manufacturers/';
import Brands from 'components/brands/';
import UndefinedBrands from 'components/undefined_brands';
import Categories from 'components/categories';
import ProductsPage from 'components/products-page';
import Suppliers from 'components/suppliers';
import Product from 'components/product';
import Markup from 'components/markup';
import Login from 'components/login';
import ImagesLogs from 'components/images-logs';
import Orders from 'components/shop/orders';
import OrderPage from 'components/shop/order-page';
import Warehouses from 'components/shop/warehouses';
import { WarehousesDeliverySchedules } from 'components/shop/warehouses-delivery-schedules';
import WarehousePage from 'components/shop/warehouse-page';
import Promotions from 'components/shop/promotions';
import PromotionPage from 'components/shop/promotion-page';
import Deliveries from 'components/shop/deliveries';
import { Series } from 'components/series';
import { ProductsTypes } from 'components/products-types';
import { Reviews } from 'components/reviews';
import { Map } from 'components/map';
import { Images } from 'components/images';
import { PricesMonitoring } from 'components/prices-monitoring';
import { ReportsList } from 'components/reports-list';
import { AttributesPage } from 'components/attributes/AttributesPage';
import { ProductsType } from 'components/products-type';

const routes: Array<Route> = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/manufacturers',
    component: Manufacturers,
  },
  {
    path: '/undefined-manufacturers',
    component: UndefinedManufacturers,
  },
  {
    path: '/brands',
    component: Brands,
  },
  {
    path: '/undefined-brands',
    component: UndefinedBrands,
  },
  {
    path: '/categories',
    component: Categories,
  },
  {
    path: '/products',
    component: ProductsPage,
  },
  {
    path: '/suppliers',
    component: Suppliers,
  },
  {
    path: '/product/:productId',
    component: Product,
  },
  {
    path: '/markup',
    component: Markup,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/reports/images-logs',
    component: ImagesLogs,
  },
  {
    path: '/reports/list',
    component: ReportsList,
  },
  {
    path: '/reports/prices',
    component: PricesMonitoring,
  },
  {
    path: '/shop/orders',
    component: Orders,
  },
  {
    path: '/shop/order/:id',
    component: OrderPage,
  },
  {
    path: '/shop/warehouses',
    component: Warehouses,
  },
  {
    path: '/shop/warehouses-delivery-schedules',
    component: WarehousesDeliverySchedules,
  },
  {
    path: '/shop/promotions',
    component: Promotions,
  },
  {
    path: '/attributes',
    component: AttributesPage,
  },
  {
    path: '/shop/promotion/:promotionId',
    component: PromotionPage,
  },
  {
    path: '/shop/warehouse/:warehouseId',
    component: WarehousePage,
  },
  {
    path: '/shop/deliveries',
    component: Deliveries,
  },
  {
    path: '/series',
    component: Series,
  },
  {
    path: '/products-types',
    component: ProductsTypes,
  },
  {
    path: '/products-type/:productTypeId',
    component: ProductsType,
  },
  {
    path: '/reviews',
    component: Reviews,
  },
  {
    path: '/map',
    component: Map,
  },
  {
    path: '/images',
    component: Images,
  },
];

export default routes;
