// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';
import { type Product } from 'types/entities/Product';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Products from 'components/products';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});
type Props = {|
  classes: Object,
  open: boolean,
  onClose: () => void,
  onSelect?: (product: Product) => void,
|};

const ProductsDialog = ({
  classes,
  open,
  onSelect,
  onClose,
}: Props) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullScreen
  >
    <DialogContent>
      <Products onSelect={onSelect} />
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={onClose}>
        Закрыть
      </Button>
    </DialogActions>
  </Dialog>
);

ProductsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductsDialog);
