// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import { type Theme } from 'types/components/Theme';
import { type Order } from 'types/entities/Order';
import { orderStates, type OrderState } from 'types/entities/OrderState';
import { OrderStateLabel } from 'components/shop/order-state-label';
import WithConfirm from 'components/with-confirm';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit,
    paddingRight: 0,
  },
  align: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: theme.spacing.unit,
    },
  },
  cancel: {
    color: red[500],
    backgroundColor: red[50],
    '&:hover': {
      backgroundColor: red[100],
    },
  },
  confirmed: {
    orange: orange[500],
    backgroundColor: orange[50],
    '&:hover': {
      backgroundColor: orange[100],
    },
  },
  assembled: {
    color: green[500],
    backgroundColor: green[50],
    '&:hover': {
      backgroundColor: green[100],
    },
  },
});
type Props = {|
  classes: Object,
  editable: boolean,
  edited: boolean,
  order: Order | null,
  handleChangeOrderState: (state: OrderState) => void,
  handleToggleAssemblyOrderDialog: () => void,
|};

const { processing, confirmed, completed, canceled } = orderStates;

const OrderControls = ({
  classes,
  order,
  handleChangeOrderState,
  editable,
  edited,
  handleToggleAssemblyOrderDialog,
}: Props) => (
  <div className={classes.root}>
    <Grid container>
      <Grid item xs={12} sm={4} className={classes.align}>
        <Typography variant="title">
          {'Заказ '}
          {order && order.id}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.align}>
        <Typography>
          {'статус: '}
          {order && <OrderStateLabel state={order.state} />}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.actions}>
        {order && ![completed, canceled].includes(order.state) && (
          <WithConfirm confirmTitle="Отменить" cancelTitle="Закрыть" onConfirm={handleChangeOrderState(canceled)}>
            <Button className={classes.canceled}>
              Отменить
            </Button>
          </WithConfirm>
        )}
        {order && order.state === processing && (
          <Button
            color="secondary"
            className={classes.confirmed}
            onClick={handleChangeOrderState(confirmed)}
            disabled={!editable}
          >
            {edited ? 'Изменения не сохранены ' : 'Подтвердить'}
          </Button>
        )}
        {/* {order && order.state === confirmed && (
          <Button
            color="secondary"
            onClick={handleToggleAssemblyOrderDialog}
            className={classes.assembled}
          >
            Собрать
          </Button>
        )} */}
      </Grid>
    </Grid>
  </div>
);

OrderControls.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderControls);
