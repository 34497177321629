// @flow
import fuzzy, { type FilterResult } from 'fuzzy';

type fuzzyOptions = {
  returnAllOnEmptyString?: boolean,
  returnOriginal?: boolean,
  extract: any
}

const fuzzyFilter = (
  string: string | number,
  data: Array<Object>,
  options: fuzzyOptions,
): Array<Object> => {
  if (options && options.returnAllOnEmptyString && !string) return data;
  const result: Array<FilterResult> = fuzzy.filter(string, data, options);
  if (options && options.returnOriginal) {
    return result.map((item: FilterResult) => item.original);
  }

  return result;
};

export default fuzzyFilter;
