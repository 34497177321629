import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {},
});

const Markup = ({ classes, markupProducts, markupCategories, controls }) => (
  <Grid className={classes.root} container spacing={24}>
    <Grid item xs={12}>
      {controls}
    </Grid>
    <Grid item xs={6}>
      {markupProducts}
    </Grid>
    <Grid item xs={6}>
      {markupCategories}
    </Grid>
  </Grid>
);

Markup.propTypes = {
  classes: PropTypes.object.isRequired,
  markupCategories: PropTypes.object.isRequired,
  markupProducts: PropTypes.object.isRequired,
};

export default withStyles(styles)(Markup);
