// @flow
import { GET_PRODUCTS_TYPES_SUCCESS } from 'types/actions/ProductsTypes';
import { type Action } from 'types/actions/Action';

const productsTypesInitialState = {
  isFetching: false,
  list: null,
};

export const productsTypes = (state = productsTypesInitialState, action: Action) => {
  switch (action.type) {
    case GET_PRODUCTS_TYPES_SUCCESS: {
      return {
        isFetching: false,
        list: action.productsTypes,
      };
    }
    default: {
      return state;
    }
  }
};
