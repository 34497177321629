// @flow
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { type Warehouse } from 'types/entities/Warehouse';
import Progress from 'components/progress';
import Paper from '@material-ui/core/Paper';
import WarehousesList from './view/WarehousesList';
import { fetchWarehouses } from './actions';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useState } from 'react';

type Props = {|
  isFetching: boolean,
  warehouses: Array<Warehouse> | null,
  fetchWarehouses: () => void,
  onSelect?: (warehouse: Warehouse) => void,
|};

const filters = [
  { key: 'is_selling', name: 'Участвует в продажах' },
  { key: 'is_pricing_participant', name: 'Участвует в ценообразовнии' },
  { key: 'is_assembly_point', name: 'Участвует в комплектации' },
  { key: 'is_pick_point', name: 'Самовывоз' },
  { key: 'is_online_payment', name: 'Онлайн оплата' },
];

const WareousesContainer = props => {
  const [state, setState] = useState(
    filters.reduce((prev, curr) => ({ ...prev, [curr.key]: null }), {}),
  );
  useEffect(() => {
    props.fetchWarehouses();
  }, []);

  const warehouses = props.warehouses && props.warehouses.filter(warehouse => {
    const actual = filters.reduce((prev, curr) => `${prev}${String(warehouse[curr.key])}`, '');
    const requested = filters.reduce(
      (prev, curr) =>
        `${prev}${
          state[curr.key] === null ? String(warehouse[curr.key]) : String(state[curr.key])
        }`,
      '',
    );

    return actual === requested;
  });

  return (
    <div>
      {filters.map(item => (
        <FormControlLabel
          key={item.key}
          control={
            <Checkbox
              checked={Boolean(state[item.key])}
              indeterminate={state[item.key] === false}
              onChange={() =>
                setState({
                  ...state,
                  [item.key]: { null: true, true: false, false: null }[state[item.key]],
                })
              }
            />
          }
          label={item.name}
        />
      ))}

      <Paper style={{ maxWidth: '1000px' }}>
        <Progress color="secondary" isFetching={props.isFetching} />
        <WarehousesList warehouses={warehouses} onSelect={props.onSelect} />
      </Paper>
    </div>
  );
};

WareousesContainer.propTypes = {
  fetchWarehouses: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  warehouses: PropTypes.any,
};

const mapStateToProps = store => {
  const state = store.getIn(['shop', 'warehouses']);
  return {
    isFetching: state.isFetching,
    warehouses: state.list,
  };
};

export default connect(mapStateToProps, { fetchWarehouses })(WareousesContainer);
