// @flow
import { GET_SERIES_SUCCESS } from 'types/actions/Series';
import { type Action } from 'types/actions/Action';
import Immutable from 'immutable';

const seriesInitialState = {
  isFetching: false,
  list: null,
};

export const series = (state = seriesInitialState, action: Action) => {
  switch (action.type) {
    case GET_SERIES_SUCCESS: {
      return {
        isFetching: false,
        list: action.series,
      };
    }
    default: {
      return state;
    }
  }
};
