// @flow
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocationOn from '@material-ui/icons/LocationOn';
import Schedule from '@material-ui/icons/Schedule';
import moment from 'moment';
import idx from 'idx';
import { type RealizationMethodCourierDelivery } from 'types/entities/RealizationMethodCourierDelivery';
import { type TimePeriod } from 'types/entities/TimePeriod';
import { type Address } from 'types/entities/Address';
import AddressDialog from 'components/shop/address-dialog';
import DeliveryPeriodDialog from 'components/shop/delivery-periods-dialog';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
  edited: {
    color: theme.palette.secondary[500],
    '& > span': {
      color: theme.palette.secondary[500],
    },
  },
  filled: {
    color: theme.palette.primary[700],
    '&  > span': {
      color: theme.palette.primary[700],
    },
  },
  after: {
    color: 'red',
    fontSize: theme.typography.pxToRem(12),
  },
  before: {
    color: '#18bb18',
    fontSize: theme.typography.pxToRem(12),
  },
});
type Props = {|
  classes: Object,
  editable: boolean,
  realizationMethod: RealizationMethodCourierDelivery | null,
  selectedAddress: Address | null,
  handleSelectAddress: (address: Address) => void,
|};

type State = {|
  openAddressDialog: boolean,
  openDeliveryPeriodDialog: boolean,
|};

class Post extends React.Component<Props, State> {
  state = {
    openAddressDialog: false,
    openDeliveryPeriodDialog: false,
  };

  getColor = entity => {
    if (!this.props.editable) return null;
    if (entity) return this.props.classes.edited;
    return this.props.classes.filled;
  };

  toggleAddressDialog = () =>
    this.setState(state => ({ openAddressDialog: !state.openAddressDialog }));

  callActionIfEditable = (action: Function, editable: boolean) => () => {
    if (editable) return action();
    return null;
  };

  handleSelectAddress = (address: Address) => {
    this.props.handleSelectAddress(address);
    return this.toggleAddressDialog();
  };


  render() {
    const {
      classes,
      realizationMethod,
      editable,
      selectedAddress,
    } = this.props;

    const address = selectedAddress || idx(realizationMethod, _ => _.address);

    return (
      <div className={classes.root}>
        <List disablePadding>
          <ListItem
            title="Адрес доставки"
            divider
            disableGutters
            onClick={this.callActionIfEditable(this.toggleAddressDialog, editable)}
            button={editable}
          >
            <ListItemIcon className={this.getColor(selectedAddress)}>
              <LocationOn />
            </ListItemIcon>
            <ListItemText
              className={this.getColor(selectedAddress)}
              primary={idx(address, _ => _.line)}
              secondary={!address && 'Адрес доставки'}
            />
          </ListItem>
        </List>
        <AddressDialog
          open={this.state.openAddressDialog}
          onClose={this.toggleAddressDialog}
          onSelect={this.handleSelectAddress}
        />
      </div>
    );
  }
}

const mapStateToProps = store => {
  const state = store.getIn(['shop']);
  return {
    deliveryPeriods: state.deliveryPeriods.list,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Post));
