// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import Lightbox from 'react-images';
import Paper from '@material-ui/core/Paper';
import { type Image, type Product } from 'types/entities';
import ButtonBase from '@material-ui/core/ButtonBase';
import Add from '@material-ui/icons/Add';
import CloudUpload from '@material-ui/icons/CloudUpload';
import green from '@material-ui/core/colors/green';
import ZoomIn from '@material-ui/icons/ZoomIn';
import Star from '@material-ui/icons/Star';
import ColorLens from '@material-ui/icons/ColorLens';
import { ListSubheader, ListItem, List } from '@material-ui/core';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 3,
    zIndex: 0,
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
  },
  imagePaper: {
    position: 'relative',
    display: 'inline-block',
    padding: '5px 5px 1px 5px',
    marginleft: '5px',
    marginRight: '5px',
    '&:hover  #zoomIn': {
      color: theme.palette.secondary[700],
    },
  },
  imagePaperButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80px',
    height: '80px',
  },
  mainImage: {
    objectFit: 'cover',
    width: '130px',
    height: '130px',
  },
  image: {
    objectFit: 'cover',
    width: '76px',
    height: '76px',
  },
  images: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& div:first-child': {
      marginRight: theme.spacing.unit,
    },
  },
  upload: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80px',
    height: '80px',
    backgroundColor: green[500],
    color: '#fff',
  },
  imageBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '5px',
    '&:hover #removeButton, &:hover  #zoomIn': {
      opacity: 1,
    },
    '&:hover #preActions': {
      opacity: 1,
    },
  },
  removeButton: {
    marginTop: '5px',
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(11),
    opacity: 0,
    '&:hover': {
      color: theme.palette.error[500],
    },
  },
  addButton: {
    marginBottom: '24px',
  },
  preActions: {
    paddingLeft: '4px',
    paddingRight: '4px',
    width: '100%',
    position: 'relative',
    display: 'flex',
    opacity: 0,
    justifyContent: 'space-between',
    color: theme.palette.grey[500],
    '& svg:hover': {
      color: theme.palette.secondary[500],
      cursor: 'pointer',
    },
  },
  zoomIn: {
    top: '28%',
    left: '28%',
    position: 'absolute',
    width: '50%',
    height: '50%',
    opacity: 0,
    color: theme.palette.grey[500],
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const ImagesListComponent = ({
  classes,
  images,
  defaultImage,
  onDelete,
  onSave,
  onSetDefault,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const [state, changeState] = useState({ lightbox: false, currentImage: 0, files: [] });

  const setState = data => changeState(state => ({ ...state, ...data }));

  const showImage = currentImage => {
    setState({ currentImage, lightbox: true });
  };

  const addSrcProperty = images => images.map(image => ({ ...image, src: image.public_path }));

  const attachFiles = (filesList: FileList) => {
    const files = [];

    Object.keys(filesList).forEach(key => files.push(filesList[Number(key)]));

    setState({ files });
  };

  const renderAddImagesButton = () => {
    return (
      <div className={classes.addButton}>
        {!state.files.length && (
          <ButtonBase htmlFor="attach" component="label">
            <Paper className={classes.imagePaperButton} elevation={2} title="Добавить фотографии">
              <Add />
            </Paper>
            <input
              type="file"
              id="attach"
              multiple
              style={{ display: 'none' }}
              onChange={event => attachFiles(event.target.files)}
            />
          </ButtonBase>
        )}
        {Boolean(state.files.length) && (
          <ButtonBase
            onClick={() => {
              if (state.files) {
                onSave(state.files);
                setState({ files: [] });
              }
            }}
          >
            <Paper className={classes.upload} elevation={2} title="Загрузить">
              <CloudUpload />
            </Paper>
          </ButtonBase>
        )}
      </div>
    );
  };

  const renderLightbox = () => {
    const { lightboxIsOpen, currentImage } = state;

    const imagesWithSrc = addSrcProperty(images);

    return (
      <Lightbox
        images={imagesWithSrc}
        isOpen={state.lightboxIsOpen}
        currentImage={state.currentImage}
        onClickPrev={() =>
          setState({ currentImage: state.currentImage ? state.currentImage - 1 : 0 })
        }
        onClickNext={() =>
          setState({
            currentImage:
              state.currentImage === images.length - 1
                ? state.currentImage
                : state.currentImage + 1,
          })
        }
        onClose={() => setState({ lightbox: !state.lightbox })}
      />
    );
  };

  const renderImages = () => {
    return (
      <div className={classes.images}>
        {defaultImage && (
          <div className={classes.imageBlock}>
            <ButtonBase
              onClick={() => showImage(images.findIndex(item => item.origin_path === defaultImage.origin_path))}
            >
              <Paper className={classes.imagePaper} elevation={2}>
                <img
                  className={classes.mainImage}
                  // alt={product.name && product.name.value}
                  src={defaultImage.origin_path}
                />
                <ZoomIn id="zoomIn" className={classes.zoomIn} />
              </Paper>
            </ButtonBase>
            <ButtonBase
              id="removeButton"
              className={classes.removeButton}
              onClick={() => defaultImage && onDelete(defaultImage)}
            >
              удалить
            </ButtonBase>
          </div>
        )}
        {images &&
          images
            .filter(item => (defaultImage ? item.origin_path !== defaultImage.origin_path : true))
            .map((image, index) => (
              <div className={classes.imageBlock} key={image.id}>
                <div className={classes.preActions} id="preActions">
                  <div title="Установить как главное изображение">
                    <Star onClick={() => onSetDefault(image)} />
                  </div>
                  {/* {(!product.color_image || product.color_image.uri !== image.uri) && (
                    <div title="Установить как изображение цвета">
                      <ColorLens onClick={() => props.changeProduct('color_image', image)} />
                    </div>
                  )} */}
                </div>
                <ButtonBase onClick={() => showImage(index)}>
                  <Paper onClick={() => {}} className={classes.imagePaper} elevation={2}>
                    {/* {product.color_image && product.color_image.uri === image.uri && (
                      <ColorLens className={classes.colorIcon} />
                    )} */}
                    <img className={classes.image} src={image.origin_path} />
                    <ZoomIn id="zoomIn" className={classes.zoomIn} />
                  </Paper>
                </ButtonBase>
                <ButtonBase
                  id="removeButton"
                  className={classes.removeButton}
                  onClick={() => onDelete(image)}
                >
                  удалить
                </ButtonBase>
              </div>
            ))}
        {renderAddImagesButton()}
      </div>
    );
  };

  return (
    <List component="nav" subheader={<ListSubheader component="div">Изображения</ListSubheader>}>
      <ListItem>
        {renderImages()}
        {Boolean(images && images.length) && renderLightbox()}
      </ListItem>
    </List>
  );
};

export const ImagesList: ReactComponent<Props> = withStyles(styles)(ImagesListComponent);
