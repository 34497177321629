// @flow
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import { CourierSelect } from 'components/shop/courier-select';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { getCouriers } from 'services/courierApi';
import { declOfNum } from 'lib/formatting';
import { setDeliveryToCourier } from 'services/deliveryApi';
import LoadingWrapper from 'components/loading_wrapper';

const styles = (theme: Theme) => ({
  root: {
    position: 'relative',
  },
  count: {
    borderRadius: '50%',
    width: '18px',
    height: '18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: theme.palette.grey[500],
    color: '#fff',
    top: '-6px',
    right: '-6px',
    position: 'absolute',
  },
  select: {
    '& > *': {
      width: '100%',
    },
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  count?: number,
|};

const CourierAssignmentButtonComponent = ({
  classes,
  deliveries,
  count,
  onChange,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const [open, toggle] = useState(false);
  const [courier, setCourier] = useState(null);
  const [loading, setLoading] = useState(null);

  const onSave = () => {
    if (!deliveries || !courier) return null;
    setLoading(true);
    Promise.all(
      deliveries.map(delivery => setDeliveryToCourier({ deliveryId: delivery.id, user: courier })),
    ).then(() => {
      setLoading(false);
      toggle(false);
      onChange();
    });
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Button onClick={() => toggle(true)} disabled={!deliveries || !deliveries.length}>
          Назначить курьера
        </Button>
        {deliveries && deliveries.length ? (
          <div className={classes.count}>{deliveries.length}</div>
        ) : null}
      </div>
      <Dialog open={open} onClose={() => toggle(false)}>
        <DialogContent>
          <div>
            Назначить курьеру на {deliveries && deliveries.length}{' '}
            {declOfNum(['заказ', 'заказа', 'заказов'])(deliveries && deliveries.length)}
          </div>
          <div>
            <CourierSelect selectedCourier={courier} onChange={data => setCourier(data)} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggle(false)}>Отмена</Button>
          <LoadingWrapper loading={loading}>
            <Button
              disabled={!courier || loading}
              color="secondary"
              variant="raised"
              onClick={onSave}
            >
              Сохранить
            </Button>
          </LoadingWrapper>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export const CourierAssignmentButton: ReactComponent<Props> = withStyles(styles)(
  CourierAssignmentButtonComponent,
);
