// @flow
import {
  TOGGLE_ASSEMBLY_ORDER_DIALOG,
  type ToggleAssemblyOrderDialog,
} from 'types/actions/AssemblyOrder';
import { type ThunkAction, type Dispatch } from 'types/actions';

const toggleAssemblyOrderDialogAction = (): ToggleAssemblyOrderDialog => ({
  type: TOGGLE_ASSEMBLY_ORDER_DIALOG,
});

export const toggleAssemblyOrderDialog = (): ThunkAction => (dispatch: Dispatch) =>
  dispatch(toggleAssemblyOrderDialogAction());
