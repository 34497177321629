// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'components/Root';

if (process && process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  console.log(
    `%c ${process.env.NODE_ENV.toUpperCase()} MODE `,
    'background: #222; color: #bada55; font-weight: bold',
  );
}
const node = document.getElementById('app');
if (node === null) {
  throw new Error('missing #app node');
} else {
  ReactDOM.render(<Root />, node);
}
