// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import { Select, MenuItem, IconButton } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ShowChart from '@material-ui/icons/ShowChart';
import Choiser from 'components/choiser';
import { CompetitorColor } from '../CompetitorColor';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing.unit * 2,
    '& > div': {
      marginRight: '10px',
      minWidth: '120px',
    },
  },
  name: {
    borderRadius: '3px',
    padding: '0px 6px',
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  hosts: Array<string>,
  selectedHost?: string,
  onChange: any,
|};

const ControlsComponent = ({
  classes,
  hosts,
  selectedHost,
  onChange,
  selectedCategory,
  categories,
  manufacturers,
  selectedManufacturer,
  datePicker,
  chart,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => (
  <div className={classes.root}>
    <FormControl>
      <InputLabel id="host">Источник</InputLabel>
      <Select
        labelId="host"
        value={selectedHost}
        onChange={e => onChange({ selectedHost: e.target.value })}
      >
        <MenuItem value="">Все</MenuItem>
        {hosts &&
          hosts
            .filter(host => host !== 'bonjour-dv.ru')
            .map(host => {
              const colors = CompetitorColor.find(item => host === item.host) || {
                bgColor: '#fff',
                textColor: '#000',
                abbr: host.substring(0, 3),
              };
              return (
                <MenuItem value={host}>
                  <div
                    className={classes.name}
                    style={{ color: colors.textColor, backgroundColor: colors.bgColor }}
                  >
                    {host}
                  </div>
                </MenuItem>
              );
            })}
      </Select>
    </FormControl>

    <Choiser
      fullWidth={false}
      returnAllOnEmptyString
      inputLabel="Категория"
      suggestions={categories}
      selected={selectedCategory}
      returnSuggestion={selectedCategory => onChange({ selectedCategory })}
    />
    <Choiser
      fullWidth={false}
      returnAllOnEmptyString
      inputLabel="Производитель"
      suggestions={manufacturers}
      selected={selectedManufacturer}
      returnSuggestion={selectedManufacturer => onChange({ selectedManufacturer })}
    />
    {datePicker}
    <IconButton
      color={chart ? 'secondary' : 'default'}
      onClick={selectedCategory => onChange({ chart: !chart })}
    >
      <ShowChart />
    </IconButton>
  </div>
);

export const Controls: ReactComponent<Props> = withStyles(styles)(ControlsComponent);
