// @flow
import axios from 'axios';
import { PUBLIC_API_HOST } from 'config';
import { addNotification } from 'components/notifications/actions';

export const publicRoutes = ['/login', '/map', '/shop/deliveries'];

const getUrl = (endpoint: string): string => endpoint;

const defaultOptions = { withCredentials: true };

const handleError = error => {
  if (error && error.response && error.response.data && error.response.data.errors) {
    error.response.data.errors.forEach(error =>
      window.globalReduxStore.dispatch(addNotification(error.title)),
    );
  }
};

export const get = (endpoint: string) =>
  axios.get(getUrl(endpoint), { withCredentials: true }).catch(error => handleError(error));

export const post = (endpoint: string, data: Object | Array<any> | void) =>
  axios.post(getUrl(endpoint), data, { withCredentials: true }).catch(error => handleError(error));

export const patch = (endpoint: string, data: Object | Array<any>) =>
  axios.patch(getUrl(endpoint), data, { withCredentials: true }).catch(error => handleError(error));

export const put = (
  endpoint: string,
  data: Object | Array<any> | string | number,
  options?: Object,
) =>
  axios
    .put(
      getUrl(endpoint),
      data,
      options ? { ...defaultOptions, ...options } : { withCredentials: true },
    )
    .catch(error => handleError(error));

export const del = (endpoint: string, data) =>
  axios
    .delete(getUrl(endpoint), { withCredentials: true, data })
    .catch(error => handleError(error));
