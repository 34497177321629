// @flow
import axios from 'axios';
import { SUGGESTIONS_HOST } from 'config';
import { type Address } from 'types/entities/Address';

const token = '9bae6ec99ea63e8e2b8db9ca65d313b9482cd6fd';

const options = {
  headers: {
    Authorization: `Token ${token}`,
  },
};

export const fetchSuggestionsAddresses = (query: string) =>
  axios.post(
    `${SUGGESTIONS_HOST}/suggestions/api/4_1/rs/suggest/address`,
    { query, count: 8 },
    options,
  );

export const fetchCleanAddress = (query: string) =>
  axios
    .post(
      `${SUGGESTIONS_HOST}/suggestions/api/4_1/rs/suggest/address`,
      { query, count: 1 },
      options,
    )
    .then(res => res.data.suggestions[0]);

export const convertAddress = (res: $FlowFixMe): Address => {
  const address = res.data;
  const getAddressUnit = (unit: $FlowFixMe) => {
    if (!unit.fias_id) return null;
    return {
      fias_id: unit.fias_id,
      type: unit.type,
      short_type: unit.short_type,
      name: unit.name,
    };
  };
  return {
    line: res.value,
    postcode: address.postal_code,
    region: {
      fias_id: address.region_fias_id,
      type: address.region_type_full,
      short_type: address.region_type,
      name: address.region,
    },
    area: getAddressUnit({
      fias_id: address.area_fias_id,
      type: address.area_type_full,
      short_type: address.area_type,
      name: address.area,
    }),
    city: getAddressUnit({
      fias_id: address.city_fias_id,
      type: address.city_type_full,
      short_type: address.city_type,
      name: address.city,
    }),
    street: {
      fias_id: address.street_fias_id,
      type: address.street_type_full,
      short_type: address.street_type,
      name: address.street,
    },
    settlement: getAddressUnit({
      fias_id: address.settlement_fias_id,
      type: address.settlement_type_full,
      short_type: address.settlement_type,
      name: address.settlement,
    }),
    house: address.house,
    flat: address.flat,
    coordinates: {
      latitude: address.geo_lat,
      longitude: address.geo_lon,
    },
  };
};
