// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { type Manufacturer, type Markup } from 'types/entities';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import FilterList from '@material-ui/icons/FilterList';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import blue from '@material-ui/core/colors/blue';
import { type TogglableStateFeilds, type Entity } from '../MarkupContainer';
import MarkupActions from './MarkupActions';

type Props = {
  classes: Object,
  manufacturers: Array<Manufacturer> | Array<any>,
  openedManufacturers: { [key: number]: boolean },
  openedMarkupManufacturers: { [key: number]: boolean },
  openedMarkupBrands: { [key: number]: boolean },
  manufacturersMarkupValue: { [key: number]: string },
  brandsMarkupValue: { [key: number]: string },
  toggleCollapsable: (name: TogglableStateFeilds, manufacturerId: number) => void,
  toggleMarkup: (name: Entity, entityId: number) => void,
  handleChangeMarkupValue: (entityType: Entity, entityId: number, value: string) => void,
  putMarkup: (endpoint: string, value: string) => void,
  deleteMarkup: (endpoint: string) => void,
  manufacturersMarkups: { [key: number]: Markup } | null,
  brandsMarkups: { [key: number]: Markup } | null,
};

const styles = theme => ({
  root: {
    // padding: theme.spacing.unit * 2,
  },
  icon: {
    width: '30px',
    height: '30px',
  },
  filter: {
    marginLeft: theme.spacing.unit,
  },
  filterOn: {
    color: blue[500],
  },
  expandIcon: {
    marginRight: theme.spacing.unit,
  },
  list: {
    padding: 0,
  },
  iconHolder: {
    width: '35px',
    height: '30px',
  },
  listItem: {
    paddingLeft: theme.spacing.unit,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  textWithFilter: {
    display: 'flex',
    alignItems: 'center',
  },
  openedListItem: {
    backgroundColor: theme.palette.grey[200],
  },
  brand: {
    minHeight: '47px',
    backgroundColor: theme.palette.grey[200],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
});

const MarkupProducts = ({
  classes,
  manufacturers,
  openedManufacturers,
  toggleCollapsable,
  openedMarkupManufacturers,
  handleChangeMarkupValue,
  manufacturersMarkupValue,
  openedMarkupBrands,
  brandsMarkupValue,
  toggleMarkup,
  putMarkup,
  deleteMarkup,
  manufacturersMarkups,
  brandsMarkups,
}: Props) => {
  const sortedManufacturers = manufacturers.sort((a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });
  return (
    <Paper className={classes.root}>
      <List className={classes.list}>
        {sortedManufacturers.map((manufacturer: Manufacturer, index: number) => {
          const manufacturerMarkup =
            manufacturersMarkups &&
            manufacturersMarkups.find(item => item.manufacturer.id === manufacturer.id);

          return (
            <React.Fragment key={manufacturer.id}>
              <ListItem
                className={cn(
                  classes.listItem,
                  openedManufacturers[manufacturer.id] ? classes.openedListItem : null,
                  manufacturer.brands && Boolean(manufacturer.brands.length)
                    ? classes.clickable
                    : null,
                )}
                dense
                divider={index + 1 !== sortedManufacturers.length}
                onClick={() => toggleCollapsable('openedManufacturers', manufacturer.id)}
              >
                <div className={classes.iconHolder}>
                  {manufacturer.brands && Boolean(manufacturer.brands.length) && (
                    <IconButton
                      className={cn(classes.icon, classes.expandIcon)}
                      onClick={() => toggleCollapsable('openedManufacturers', manufacturer.id)}
                    >
                      {manufacturer.brands && openedManufacturers[manufacturer.id] ? (
                        <ExpandLess
                          onClick={() => toggleCollapsable('openedManufacturers', manufacturer.id)}
                        />
                      ) : (
                        <ExpandMore
                          onClick={() => toggleCollapsable('openedManufacturers', manufacturer.id)}
                        />
                      )}
                    </IconButton>
                  )}
                </div>
                <div className={classes.textWithFilter}>
                  <ListItemText primary={manufacturer.name} />
                  {/* <IconButton
                  className={
                    cn(
                      classes.icon,
                      classes.filter,
                      openedManufacturers[manufacturer.id] ? classes.filterOn : null,
                    )
                  }
                >
                  <FilterList />
                </IconButton> */}
                </div>
                <ListItemSecondaryAction>
                  <MarkupActions
                    entity={manufacturerMarkup || { manufacturer }}
                    entityType="manufacturer"
                    opened={Boolean(openedMarkupManufacturers[manufacturer.id])}
                    value={manufacturersMarkupValue[manufacturer.id]}
                    toggleMarkup={toggleMarkup}
                    handleChangeMarkupValue={handleChangeMarkupValue}
                    putMarkup={() =>
                      putMarkup({
                        endpoint: '/manufacturers_markups',
                        markup: {
                          manufacturer,
                          ...manufacturerMarkup,
                          value: manufacturersMarkupValue[manufacturer.id],
                        },
                      })
                    }
                    deleteMarkup={() =>
                      deleteMarkup({
                        endpoint: '/manufacturers_markups',
                        markup: {
                          manufacturer,
                          ...manufacturerMarkup,
                        },
                      })
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse in={openedManufacturers[manufacturer.id]} timeout="auto" unmountOnExit>
                {manufacturer.brands &&
                  manufacturer.brands.map(brand => {
                    const brandMarkup =
                      brandsMarkups && brandsMarkups.find(item => item.brand.id === brand.id);
                    return (
                      <ListItem className={classes.brand} key={brand.id} dense divider>
                        <ListItemText inset primary={brand.name} />
                        <ListItemSecondaryAction>
                          <MarkupActions
                            entity={brandMarkup || { brand }}
                            entityType="brand"
                            opened={Boolean(openedMarkupBrands[brand.id])}
                            value={brandsMarkupValue[brand.id]}
                            toggleMarkup={toggleMarkup}
                            handleChangeMarkupValue={handleChangeMarkupValue}
                            putMarkup={() =>
                              putMarkup({
                                endpoint: '/brands_markups',
                                markup: {
                                  brand,
                                  ...brandMarkup,
                                  value: brandsMarkupValue[brand.id],
                                },
                              })
                            }
                            deleteMarkup={() =>
                              deleteMarkup({
                                endpoint: '/brands_markups',
                                markup: {
                                  brand,
                                  ...brandMarkup,
                                },
                              })
                            }
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
              </Collapse>
            </React.Fragment>
          );
        })}
      </List>
    </Paper>
  );
};

MarkupProducts.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleCollapsable: PropTypes.func.isRequired,
  deleteMarkup: PropTypes.func.isRequired,
  putMarkup: PropTypes.func.isRequired,
  handleChangeMarkupValue: PropTypes.func.isRequired,
  manufacturersMarkups: PropTypes.any,
  brandsMarkups: PropTypes.any,
};

export default withStyles(styles)(MarkupProducts);
