// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { type Brand, type Manufacturer } from 'types/entities';
import { type Product } from 'types/entities/Product';
import { type typeCategory } from 'types/entities/typeCategory';
import { fetchManufacturersIfNeeded } from 'components/manufacturers/actions';
import { SaveTableButton } from 'components/save-table-button';
import { getBrands } from 'components/brands/actions';
import { fetchCategoriesList } from 'components/categories/actions';
import { DatePicker } from 'components/date-picker';
import ProductsControls from './view/ProductsControls';
import ProductsTable from './view/ProductsTable';
import { FilterInStock } from './view/FilterInStock';
import Edit from './view/Edit';
import { fetchProducts, assignCategory } from './actions';
import { Checkbox } from './view/Checkbox';
import { CharacteristicsEdit } from './view/CharacteristicsEdit';
import { TypeEdit } from './view/TypeEdit';
import { ProductTypeSelect } from 'components/product-type-select';

type State = {|
  barcode: string,
  name: string,
  selectedManufacturer: Manufacturer | null,
  selectedBrand: Brand | null,
  selectedCategory: typeCategory | null,
  selectedCategoryForAssign: typeCategory | null,
  withImage: string,
  in_stock: boolean,
  created_from: Object | null,
  created_to: Object | null,
  selectedProducts: { [number]: boolean } | null,
|};

type Props = {|
  fetchProducts: typeof fetchProducts,
  fetchManufacturersIfNeeded: () => void,
  getBrands: () => void,
  fetchCategoriesList: () => void,
  manufacturers: Array<Manufacturer> | null,
  brands: Array<Brand> | null,
  categories: Array<typeCategory> | null,
  products: Array<Product> | void | null,
  manufacturersIsFetching: boolean,
  brandsIsFetching: boolean,
  categoriesIsFetching: boolean,
  isFetching: boolean,
  history: { push: (route: string) => void },
  onSelect: (product: Product) => void,
  withEdit?: boolean,
  assignCategory: (params: {
    categoryId: number,
    products: Array<{ id: number } | any>,
  }) => Promise<any>,
|};

export class ProductsContainer extends React.Component<Props, State> {
  state = {
    barcode: '',
    name: '',
    selectedManufacturer: null,
    selectedBrand: null,
    selectedCategory: null,
    selectedType: null,
    selectedCategoryForAssign: null,
    withImage: 'all',
    selectedProducts: null,
    in_stock: true,
    created_from: null,
    created_to: null,
    ingredients: null,
    description: null,
    howToUse: null,
    type: null,
    series: null,
    published: null,
  };

  setSelectedProducts = (selectedProducts: { [number]: boolean } | null) =>
    this.setState({ selectedProducts });

  assignCategory = () => {
    const { selectedCategoryForAssign, selectedProducts } = this.state;
    if (!selectedCategoryForAssign || !selectedProducts) return null;
    const products = [];
    Object.keys(selectedProducts).forEach(id =>
      selectedProducts[id] ? products.push({ id: Number(id) }) : null,
    );
    this.props.assignCategory({ categoryId: selectedCategoryForAssign.id, products }).then(res => {
      this.fetchProducts();
      this.setState({
        selectedCategoryForAssign: null,
        selectedProducts: null,
      });
    });
  };

  fetchProducts = () => {
    let params = {
      barcode: this.state.barcode,
      name: this.state.name,
      manufacturer: this.state.selectedManufacturer && this.state.selectedManufacturer.id,
      brand: this.state.selectedBrand && this.state.selectedBrand.id,
      category: this.state.selectedCategory ? this.state.selectedCategory.id : undefined,
      image: this.state.withImage === 'all' ? undefined : this.state.withImage,
      created_from: this.state.created_from && this.state.created_from.format('YYYY-MM-DD'),
      created_to: this.state.created_to && this.state.created_to.format('YYYY-MM-DD'),
      in_stock: this.state.in_stock ? this.state.in_stock : undefined,
      description: this.state.description !== null ? this.state.description : undefined,
      howToUse: this.state.howToUse !== null ? this.state.howToUse : undefined,
      ingredients: this.state.ingredients !== null ? this.state.ingredients : undefined,
      series: this.state.series !== null ? this.state.series : undefined,
      published: this.state.published !== null ? this.state.published : undefined,
    };
    if (this.state.selectedType) {
      params.type = this.state.selectedType.id;
    }
    return this.props.fetchProducts(params);
  };
  handleChange = (
    name: string,
    value: string | Manufacturer | Brand | typeCategory | boolean | null,
  ) => this.setState({ [name]: value });

  render() {
    const showEdit = Boolean(
      this.props.withEdit &&
        this.state.selectedProducts &&
        Object.keys(this.state.selectedProducts).reduce(
          (prev, id) => this.state.selectedProducts[id] || prev,
          false,
        ),
    );

    const selectedProductsList =
      this.props.products &&
      this.props.products.filter(
        item => this.state.selectedProducts && this.state.selectedProducts[item.id],
      );

    return (
      <React.Fragment>
        <ProductsControls
          saveTableButton={
            <SaveTableButton
              table={
                this.props.products &&
                this.props.products.map(item => ({
                  barcode: item.barcode && item.barcode.value,
                  title: item.title,
                }))
              }
            />
          }
          dateBetween={
            <DatePicker
              from={this.state.created_from}
              to={this.state.created_to}
              setFrom={created_from => this.setState({ created_from })}
              setTo={created_to => this.setState({ created_to })}
              labelFrom="Дата создания от"
              labelTo="Дата создания до"
            />
          }
          typeSelect={
            <ProductTypeSelect
              withEmptyType
              type={this.state.selectedType}
              onChange={selectedType => this.setState({ selectedType })}
            />
          }
          fetchManufacturersIfNeeded={this.props.fetchManufacturersIfNeeded}
          getBrands={this.props.getBrands}
          manufacturers={this.props.manufacturers}
          manufacturersIsFetching={this.props.manufacturersIsFetching}
          brandsIsFetching={this.props.brandsIsFetching}
          brands={this.props.brands}
          fetchCategoriesList={this.props.fetchCategoriesList}
          selectedCategory={this.state.selectedCategory}
          selectedCategoryForAssign={this.state.selectedCategoryForAssign}
          categoriesIsFetching={this.props.categoriesIsFetching}
          categories={this.props.categories}
          handleChange={this.handleChange}
          selectedManufacturer={this.state.selectedManufacturer}
          selectedBrand={this.state.selectedBrand}
          name={this.state.name}
          barcode={this.state.barcode}
          fetchProducts={this.fetchProducts}
          withImage={this.state.withImage}
          filterInStock={
            <FilterInStock
              checked={this.state.in_stock}
              onClick={() => this.handleChange('in_stock', !this.state.in_stock)}
            />
          }
          series={
            <Checkbox
              label="Серия"
              value={this.state.series}
              onChange={() => this.handleChange('series', this.state.series === null ? 0 : null)}
            />
          }
          description={
            <Checkbox
              label="Описание"
              value={this.state.description}
              onChange={() =>
                this.handleChange(
                  'description',
                  this.state.description === null ? 0 : this.state.description === 0 ? 1 : null,
                )
              }
            />
          }
          ingredients={
            <Checkbox
              label="Состав"
              value={this.state.ingredients}
              onChange={() =>
                this.handleChange(
                  'ingredients',
                  this.state.ingredients === null ? 0 : this.state.ingredients === 0 ? 1 : null,
                )
              }
            />
          }
          howToUse={
            <Checkbox
              label="Способ применения"
              value={this.state.howToUse}
              onChange={() =>
                this.handleChange(
                  'howToUse',
                  this.state.howToUse === null ? 0 : this.state.howToUse === 0 ? 1 : null,
                )
              }
            />
          }
          howToUse={
            <Checkbox
              label="Способ применения"
              value={this.state.howToUse}
              onChange={() =>
                this.handleChange(
                  'howToUse',
                  this.state.howToUse === null ? 0 : this.state.howToUse === 0 ? 1 : null,
                )
              }
            />
          }
          published={
            <Checkbox
              label="Опубликован"
              value={this.state.published}
              onChange={() =>
                this.handleChange(
                  'published',
                  this.state.published === null ? 0 : this.state.published === 0 ? 1 : null,
                )
              }
            />
          }
        />
        {showEdit && (
          <Edit
            fetchCategoriesList={this.props.fetchCategoriesList}
            selectedCategoryForAssign={this.state.selectedCategoryForAssign}
            categoriesIsFetching={this.props.categoriesIsFetching}
            categories={this.props.categories}
            handleChange={this.handleChange}
            assignCategory={this.assignCategory}
            showAssignCategory={showEdit}
            // characteristics={
            //   <CharacteristicsEdit products={selectedProductsList} onChange={this.fetchProducts} />
            // }
            // type={<TypeEdit products={selectedProductsList} onChange={this.fetchProducts} />}
          />
        )}
        <ProductsTable
          withEdit={this.props.withEdit}
          selectedProducts={this.state.selectedProducts}
          setSelectedProducts={this.setSelectedProducts}
          onSelect={this.props.onSelect}
          history={this.props.history}
          products={this.props.products}
          isFetching={this.props.isFetching}
        />
      </React.Fragment>
    );
  }
}

ProductsContainer.propTypes = {
  fetchManufacturersIfNeeded: PropTypes.func.isRequired,
  getBrands: PropTypes.func.isRequired,
  manufacturers: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  manufacturersIsFetching: PropTypes.bool.isRequired,
  brandsIsFetching: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  categoriesIsFetching: PropTypes.bool.isRequired,
  fetchProducts: PropTypes.func.isRequired,
  fetchCategoriesList: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = store => ({
  isFetching: store.getIn(['products', 'isFetching']),
  manufacturersIsFetching: store.getIn(['manufacturers', 'isFetching']),
  brandsIsFetching: store.getIn(['brands', 'isFetching']),
  categoriesIsFetching: store.getIn(['categories', 'isFetching']),
  categories: store.getIn(['categories', 'list']).valueSeq().toArray(),
  products: store.getIn(['products', 'items']).valueSeq().toArray(),
  manufacturers: store.getIn(['manufacturers', 'items']).valueSeq().toArray(),
  brands: store.getIn(['brands', 'items']).valueSeq().toArray(),
});

export default withRouter(
  connect(mapStateToProps, {
    fetchProducts,
    fetchManufacturersIfNeeded,
    getBrands,
    fetchCategoriesList,
    assignCategory,
  })(ProductsContainer),
);
