// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  orders: any,
  warehouses: any,
|};

const OrdersByWarehousesComponent = ({
  classes,
  orders,
  warehouses,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const warehousesMap = {};
  if (warehouses) {
    warehouses.forEach(item => (warehousesMap[item.vendor_id] = item));
  }
  const warehousesArray = Object.keys(warehousesMap)
    .map(key => warehousesMap[key])
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  return (
    <Table className={classes.table} size="small">
      <TableHead>
        <TableRow>
          <TableCell>Магазин</TableCell>
          <TableCell align="right">Кол-во заказов</TableCell>
          <TableCell align="right">Сумма</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {warehousesArray.map(warehouse => (
          <TableRow key={warehouse.name}>
            <TableCell component="th" scope="row">
              {warehouse.name}
            </TableCell>
            <TableCell>
              {orders &&
                orders.filter(
                  order =>
                    order.realization_method &&
                    order.realization_method.pick_up_point &&
                    order.realization_method.pick_up_point.vendor_id ===
                      warehouse.vendor_id,
                ).length}
            </TableCell>
            <TableCell>
              {orders &&
                orders
                  .filter(
                    order =>
                      order.realization_method &&
                      order.realization_method.pick_up_point &&
                      order.realization_method.pick_up_point.vendor_id ===
                        warehouse.vendor_id,
                  )
                  .reduce((prev, curr) => prev + curr.cost, 0)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export const OrdersByWarehouses: ReactComponent<Props> = withStyles(styles)(
  OrdersByWarehousesComponent,
);
