// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import Save from '@material-ui/icons/Save';
import { convertJsonToExcel, convertJsonToHtmlTable } from 'services/helperApi';

const styles = (theme: Theme) => ({
  root: {
    // marginTop: theme.spacing.unit * 2,
    position: 'relative',
  },
  count: {
    borderRadius: '50%',
    width: '18px',
    height: '18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: theme.palette.grey[500],
    color: '#fff',
    top: 0,
    right: 0,
    position: 'absolute',
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const SaveTableButtonComponent = ({ classes, table, count, name }: {| ...Props, ...ProvidedProps |}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, toggle] = useState(false);

  const handelToggle = e => {
    if (e) {
      setAnchorEl(e.currentTarget);
    }
    toggle(!open);
  };

  const handleDownload = () => {
    handelToggle();
    convertJsonToExcel({ table, name });
  };

  const handleCopy = () => {
    handelToggle();
    convertJsonToHtmlTable({ table });
  };

  return (
    <React.Fragment>
      <IconButton
        onClick={handelToggle}
        className={classes.root}
        title="Сохранить список"
        color="secondary"
        aria-controls="copy-menu"
      >
        <Save />
        {count ? <div className={classes.count}>{count}</div> : null}
      </IconButton>

      <Menu id="copy-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handelToggle}>
        <MenuItem onClick={handleCopy}>Копировать в буфер</MenuItem>
        <MenuItem onClick={handleDownload}>Сохранить на компьютер</MenuItem>
      </Menu>
    </React.Fragment>
  );
};
export const SaveTableButton: ReactComponent<Props> = withStyles(styles)(SaveTableButtonComponent);
