import { combineReducers } from 'redux';
import orders from './orders/reducers';
import order from './order/reducers';
import warehouses from './warehouses/reducers';
import warehouse from './warehouse/reducers';
import promotions from './promotions/reducers';
import promotion from './promotion/reducers';
import deliveryPeriods from './delivery-periods/reducers';
import pickupTime from './pickup-time/reducers';
import assemblyOrderDialog from './assembly-order-dialog/reducers';
import { deliveries } from './deliveries/reducers';

const shop = combineReducers({
  orders,
  order,
  warehouses,
  warehouse,
  promotions,
  promotion,
  deliveryPeriods,
  pickupTime,
  assemblyOrderDialog,
  deliveries,
});

export default shop;
