// @flow
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import Grid from '@material-ui/core/Grid';
import { type Theme, type ReactComponent } from 'types/components';
import ProductPreview from 'components/product-preview';
import ProductLabel from 'components/product-label';
import { getPricesHistory } from 'services/productApi';
import moment from 'moment';
import cn from 'classnames';
import { DatePicker } from 'components/date-picker';
import { Controls } from './components/Controls';
import { MonitoringItem } from './components/MonitoringItem';
import { CompetitorColor } from './CompetitorColor';
import { Typography } from '@material-ui/core';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },

  date: {
    color: theme.palette.grey[700],
    fontSize: '10px',
    textAlign: 'center',
  },
  bonjour: {
    // backgroundColor: blue[700],
    // color: '#fff',
  },
  content: {
    marginTop: theme.spacing.unit * 3,
  },
  columns: {
    display: 'flex',
  },
  pricesHistory: {
    borderLeft: `1px solid ${theme.palette.grey[400]}`,
    width: '135px',
    flexShrink: 0,
    height: '100%',
    padding: '3px',
  },

  title: {
    marginBottom: '10px',
    position: 'sticky',
    left: 0,
    backgroundColor: '#FAFAFA',
  },
  category: {
    padding: '8px',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '4px',
    marginBottom: '16px',
    overflow: 'auto',
  },
  table: {
    marginTop: theme.spacing.unit * 3,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const distinctByHost = prices => {
  const providedHostsSet = new Set(prices.map(({ host }) => host));

  const hostsArray = Array.from(providedHostsSet);

  return hostsArray && hostsArray.map(host => prices.find(item => item.host === host));
};

const groupByManufacturer = history => {
  let mapByManufacturer = {};

  history.forEach(item => {
    if (!item.product.manufacturer) {
      const nextItem = {
        ...item,
        product: { ...item.product, manufacturer: { id: 0, name: 'Без производителя' } },
      };
      mapByManufacturer[0]
        ? (mapByManufacturer[0] = [...mapByManufacturer[0], nextItem])
        : (mapByManufacturer[0] = [nextItem]);
      return null;
    }
    item.product.manufacturer && mapByManufacturer[item.product.manufacturer.id]
      ? (mapByManufacturer[item.product.manufacturer.id] = [
          ...mapByManufacturer[item.product.manufacturer.id],
          item,
        ])
      : (mapByManufacturer[item.product.manufacturer.id] = [item]);
  });

  return Object.keys(mapByManufacturer).map(key => ({
    manufacturer: mapByManufacturer[key][0] && mapByManufacturer[key][0].product.manufacturer,
    history: mapByManufacturer[key],
  }));
};

const groupByCategory = history => {
  if (!history) return history;

  let mapByCategory = {};

  history.forEach(item => {
    if (!item.product.category) {
      const nextItem = {
        ...item,
        product: { ...item.product, category: { id: 0, name: 'Без категории' } },
      };
      return mapByCategory[0]
        ? (mapByCategory[0] = [...mapByCategory[0], nextItem])
        : (mapByCategory[0] = [nextItem]);
    }
    mapByCategory[item.product.category.id]
      ? (mapByCategory[item.product.category.id] = [
          ...mapByCategory[item.product.category.id],
          item,
        ])
      : (mapByCategory[item.product.category.id] = [item]);
  });

  return Object.keys(mapByCategory).map(key => ({
    category: mapByCategory[key][0] && mapByCategory[key][0].product.category,
    history: groupByManufacturer(mapByCategory[key]),
  }));
};

const PricesMonitoringComponent = ({ classes }: {| ...Props, ...ProvidedProps |}) => {
  const [state, changeState] = useState({
    history: null,
    selectedHost: '',
    selectedManufacturer: null,
    selectedCategory: null,
    start_date: moment().subtract(7, 'days'),
    end_date: moment(),
    chart: false,
  });

  const setState = data => changeState({ ...state, ...data });

  const getHistory = () =>
    getPricesHistory({
      start_date: state.start_date.format('YYYY-MM-DD'),
      end_date: state.end_date.format('YYYY-MM-DD'),
    }).then(history =>
      setState({
        history: history,
      }),
    );

  useEffect(() => {
    getHistory();
  }, [state.start_date, state.end_date]);

  const filteredBySelected =
    state.history &&
    state.history.filter(item =>
      [
        state.selectedManufacturer
          ? item.product.manufacturer &&
            item.product.manufacturer.id === state.selectedManufacturer.id
          : true,
        state.selectedCategory
          ? item.product.category && item.product.category.id === state.selectedCategory.id
          : true,
      ].every(item => item),
    );

  const filteredByHost =
    filteredBySelected &&
    filteredBySelected
      .map(item => ({
        ...item,
        priceHistory: item.priceHistory.filter(historyItem =>
          state.selectedHost
            ? state.selectedHost === historyItem.host || historyItem.host === 'bonjour-dv.ru'
            : historyItem,
        ),
      }))
      .filter(item =>
        item.priceHistory && item.priceHistory.length === 1
          ? item.priceHistory[0].host !== 'bonjour-dv.ru'
          : true,
      )
      .filter(item => item.priceHistory && item.priceHistory.length);

  const datesSet = new Set();
  const hostsSet = new Set();

  if (filteredByHost) {
    filteredByHost.forEach(item => {
      if (item.priceHistory) {
        item.priceHistory.forEach(priceItem => {
          datesSet.add(moment(priceItem.create_time).format('YYYY-MM-DD'));
          hostsSet.add(priceItem.host);
        });
      }
    });
  }

  let manufacturers = [];
  let categories = [];

  if (state.history) {
    state.history.forEach(item => {
      if (item.priceHistory) {
        item.priceHistory.forEach(priceItem => {
          hostsSet.add(priceItem.host);
          manufacturers.push(item.product.manufacturer);
          categories.push(item.product.category);
        });
      }
    });
  }

  manufacturers = manufacturers.reduce(
    (prev, curr) =>
      prev.find(item => item && curr && item.id === curr.id) ? prev : [...prev, curr],
    [],
  );
  categories = categories.reduce(
    (prev, curr) =>
      prev.find(item => item && curr && item.id === curr.id) ? prev : [...prev, curr],
    [],
  );

  const datesArray = Array.from(datesSet);

  const datesList = datesArray.sort((a, b) => (a > b ? 1 : -1));

  const hosts = Array.from(hostsSet);

  const history = groupByCategory(filteredByHost);

  return (
    <div className={classes.root}>
      <Controls
        datePicker={
          <DatePicker
            from={state.start_date}
            to={state.end_date}
            setFrom={start_date => setState({ start_date })}
            setTo={end_date => setState({ end_date })}
            labelFrom="Начало периода"
            labelTo="Конец периода"
          />
        }
        hosts={hosts}
        selectedHost={state.selectedHost}
        onChange={setState}
        manufacturers={manufacturers}
        categories={categories}
        selectedCategory={state.selectedCategory}
        selectedManufacturer={state.selectedManufacturer}
        chart={state.chart}
      />
      {/* <Grid container spacing={4} className={classes.table}>
        <Grid item xs={12} sm={3}></Grid>
        <Grid item xs={12} sm={9} className={classes.columns}>
          {datesList.map(date => (
            <div key={date} className={cn(classes.pricesHistory, classes.date)}>
              {date}
            </div>
          ))}
        </Grid>
      </Grid> */}
      <div className={classes.content}>
        {history &&
          history.map(categoryGroup => (
            <Grid
              container
              spacing={4}
              key={categoryGroup.category.id}
              className={classes.category}
            >
              <Grid item xs={12} sm={3}>
                <Typography variant="headline" className={classes.title}>
                  {categoryGroup.category.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9} className={classes.columns}>
                {datesList.map(date => (
                  <div key={date} className={cn(classes.pricesHistory, classes.date)}>
                    {date}
                  </div>
                ))}
              </Grid>
              <Grid container key={categoryGroup.category.id}>
                {categoryGroup.history &&
                  categoryGroup.history.map(manufacturerGroup => (
                    <React.Fragment key={manufacturerGroup.manufacturer.id}>
                      <Grid item xs={12}>
                        <Typography variant="subheading" className={classes.title}>
                          {manufacturerGroup.manufacturer.name}
                        </Typography>
                      </Grid>
                      {manufacturerGroup.history.map(monitoringItem => (
                        <Grid item xs={12} key={monitoringItem.product.id}>
                          <MonitoringItem
                            monitoringItem={monitoringItem}
                            dates={datesList}
                            chart={state.chart}
                          />
                        </Grid>
                      ))}
                    </React.Fragment>
                  ))}
              </Grid>
            </Grid>
          ))}
      </div>
    </div>
  );
};
export const PricesMonitoring: ReactComponent<Props> = withStyles(styles)(
  PricesMonitoringComponent,
);
