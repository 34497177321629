// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';
import { fetchUser } from 'components/user/actions';
import TopBar from './top_bar';
import Store, { history } from '../Store/configureStore';
import Notifications from './notifications/';
import LeftSideBar from './left_side_bar/';
import RightSideBar from './right_side_bar/';
import routes from './routes';

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    minWidth: 0, // So the Typography noWrap works
  },
  toolbar: theme.mixins.toolbar,
  '@global': {
    body: {
      fontFamily: theme.typography.fontFamily,
    },
  },
  '@media print': {
    noPrint: {
      display: 'none !important',
    },
  },
});

const theme = createMuiTheme({
  palette: {
    primary: blueGrey,
    secondary: indigo,
    error: red,
  },
});

type Props = {
  classes: Object,
};

Store.dispatch(fetchUser());

window.globalReduxStore = Store;

const Root = ({ classes }: Props) => (
  <MuiThemeProvider theme={theme}>
    <Provider store={Store}>
      <BrowserRouter history={history}>
        <div className={classes.root}>
          <Notifications />
          <CssBaseline />
          <div className={classes.noPrint}>
            <TopBar />
          </div>
          <div className={classes.noPrint}>
            <LeftSideBar />
          </div>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {routes.map(route => (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            ))}
          </main>
          <RightSideBar />
        </div>
      </BrowserRouter>
    </Provider>
  </MuiThemeProvider>
);

Root.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Root);
