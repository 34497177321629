// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import { withRouter } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@material-ui/core';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const ReportsListComponent = ({ classes, history }: {| ...Props, ...ProvidedProps |}) => (
  <div className={classes.root}>
    <List>
      <ListItem button onClick={() => history.push('/reports/images-logs')}>
        <ListItemText primary="Изображения товаров" />
      </ListItem>

      <ListItem button onClick={() => history.push('/reports/prices')}>
        <ListItemText primary="Цены конкурентов" />
      </ListItem>
    </List>
  </div>
);

export const ReportsList: ReactComponent<Props> = withRouter(
  withStyles(styles)(ReportsListComponent),
);
