// @flow
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Attributes } from 'components/product/view/Attributes';
import { getProductsType } from 'services/productTypeApi';
import products from '../reducers';
import { changeProductsCharacteristicsBatch } from 'services/attributeApi';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const CharacteristicsEditComponent = ({
  classes,
  products,
  onChange,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const [dialog, setDialod] = useState(false);

  const product =
    products && products.length
      ? products.reduce((prev, curr) =>
          prev && curr && curr.type && prev.type.id === curr.type.id ? curr : null,
        )
      : null;

  const selectedType = product && product.type;

  let characteristicsMap = {};

  products.forEach(item =>
    item.characteristics.forEach(characteristic => {
      characteristicsMap[characteristic.attribute.id] = characteristicsMap[
        characteristic.attribute.id
      ]
        ? {
            ...characteristic,
            value:
              JSON.stringify(characteristicsMap[characteristic.attribute.id].value) ===
              JSON.stringify(characteristic.value)
                ? characteristic.value
                : null,
            values: [
              ...characteristicsMap[characteristic.attribute.id].values,
              characteristic.value,
            ],
          }
        : { ...characteristic, values: [characteristic.value] };
    }),
  );

  const characteristics = Object.values(characteristicsMap).map(item => ({
    ...item,
    value: item.values.length === products.length ? item.value : null,
  }));

  const handleOnChange = (attributeId, value) =>
    changeProductsCharacteristicsBatch(attributeId, {
      products: products.map(product => product.id),
      ...value,
    });

  return (
    <div className={classes.root}>
      <Dialog open={dialog} onClose={() => setDialod(false)} fullWidth maxWidth="sm">
        <DialogTitle>Выбрано товаров: {products && products.length}</DialogTitle>
        <DialogContent>
          {!selectedType && 'Все выбранные товары дожны иметь один тип'}
          {selectedType && (
            <Attributes
              typeId={selectedType.id}
              characteristics={characteristics}
              onChange={handleOnChange}
              onSave={onChange}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialod(false)}>Отмена</Button>
        </DialogActions>
      </Dialog>
      <Button color="secondary" variant="raised" onClick={() => setDialod(true)}>
        Характеристики
      </Button>
    </div>
  );
};

export const CharacteristicsEdit: ReactComponent<Props> = withStyles(styles)(
  CharacteristicsEditComponent,
);
