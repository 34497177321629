// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import { type Theme } from 'types/components/Theme';

const styles = (theme: Theme) => ({
  root: {
    // marginTop: theme.spacing.unit * 2,
  },
});
type Props = {|
  classes: Object,
  disabled: boolean,
  onClick: () => void,
|};

const AddElementButton = ({ classes, onClick, disabled }: Props) => (
  <Button
    className={classes.root}
    variant="fab"
    color="secondary"
    title="Добавить товар"
    onClick={onClick}
    disabled={disabled}
  >
    <Add />
  </Button>
);

AddElementButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddElementButton);
