// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import { DatePicker as MaterialUiDatePicker } from 'material-ui-pickers';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import 'moment/locale/ru';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import moment from 'moment';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  setFrom: Function,
  setTo?: Function,
  from: any,
  to?: any,
  labelFrom?: string,
  labelTo?: string,
|};

const DatePickerComponent = ({
  classes,
  from,
  to,
  setFrom,
  setTo,
  labelFrom,
  labelTo,
  formatFrom,
  formatTo,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => (
  <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
    <MaterialUiDatePicker
      format={formatFrom || 'YYYY.MM.DD'}
      value={from}
      autoOk
      onChange={value => setFrom(value)}
      animateYearScrolling
      cancelLabel="Отмена"
      label={labelFrom}
      InputProps={{
        endAdornment: from && (
          <InputAdornment position="end">
            <IconButton
              onClick={e => {
                e.stopPropagation();
                setFrom(null);
              }}
            >
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
    {setTo ? (
      <MaterialUiDatePicker
        format={formatTo || 'YYYY.MM.DD'}
        value={to}
        autoOk
        onChange={value => setTo(value)}
        animateYearScrolling
        cancelLabel="Отмена"
        label={labelTo}
        InputProps={{
          endAdornment: to && (
            <InputAdornment position="end">
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  setTo(null);
                }}
              >
                <Close />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    ) : null}
  </MuiPickersUtilsProvider>
);

export const DatePicker: ReactComponent<Props> = withStyles(styles)(DatePickerComponent);
