// @flow
// @flow-runtime enable
import { type Role } from './Role';

export type typeUser = {|
  id: number,
  roles: Array<Role>,
  phone: string,
  full_name: string | null,
  first_name: string | null,
  father_name: string | null,
  last_name: string | null,
  is_subscribed_email: boolean,
  email: string | null,
|};
