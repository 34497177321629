// @flow
// @flow-runtime enable
import { type typeMetaTagData } from './typeMetaTagData';

export type Brand = {|
  id: number,
  name: string,
  adapted_name?: string | null,
  slug?: string | null,
  updated_at?: string,
  meta_tag_data?: typeMetaTagData | null,
|};
