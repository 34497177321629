// @flow
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import { type Theme } from 'types/components/Theme';
import ProductPreview from 'components/product-preview';
import { type PromotionParticipant } from 'types/entities/PromotionParticipant';
import { getProducts } from 'services/productApi';
import {
  Divider,
  List,
  ListItem,
  Dialog,
  DialogContent,
  TextField,
  Button,
  DialogActions,
} from '@material-ui/core';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
  table: {
    marginTop: theme.spacing.unit * 2,
  },
  product: {
    padding: theme.spacing.unit,
  },
});
type Props = {|
  classes: Object,
  participants: Array<PromotionParticipant> | Array<any> | null,
|};

const Participants = ({ classes, participants, promotion, onChange }: Props) => {
  const [products, setProducts] = useState(null);
  const [rowsPerPage, changeRowsPerPage] = useState(15);
  const [page, changePage] = useState(0);

  const [barcodes, setBarcodes] = useState(null);
  const [dialog, setDialog] = useState(false);

  useEffect(
    function getPromotionProducts() {
      if (promotion && promotion.has_products) {
        getProducts({
          query: {
            'filter[promotions]': promotion.id,
            limit: rowsPerPage,
            offset: page * rowsPerPage,
          },
        }).then(res => setProducts(res));
      }
    },
    [promotion, rowsPerPage, page],
  );

  // if (!products) return null;

  const handleAddProducts = () => {
    onChange({ ...promotion, barcodes_filters: barcodes.split("\n") }).then(() => {
      setDialog(false);
      setBarcodes(null);
    });
  };

  const handleChangeRowsPerPage = (event: Object) => changeRowsPerPage(event.target.value);
  const handleChangePage = (event: Object, newPage: number) => changePage(newPage);
  const emptyRows = products
    ? rowsPerPage - Math.min(rowsPerPage, products.data.length - page * rowsPerPage)
    : 0;
  return (
    <div className={classes.root}>
      <Button onClick={() => setDialog(true)}>Добавить товары</Button>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogContent>
          <TextField multiline value={barcodes} onChange={e => setBarcodes(e.target.value)} />
        </DialogContent>{' '}
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Отмена</Button>
          <Button onClick={() => handleAddProducts()}>Добавить</Button>
        </DialogActions>{' '}
      </Dialog>
      <Paper className={classes.table}>
        <List>
          {products &&
            products.data.map(product => (
              <ListItem key={product.id} button divider>
                <ProductPreview product={product} />
              </ListItem>
            ))}
        </List>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50, 100]}
          component="div"
          count={products && products.total_count}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default withStyles(styles)(Participants);
