import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import InputAdornment from '@material-ui/core/InputAdornment';
import WithConfirm from 'components/with-confirm';
import fuzzyFilter from '../../fuzzy_filter';

const styles = theme => ({
  container: {},
  suggestionsContainerOpen: {},
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
});

function renderSuggestion(suggestion, { query }) {
  // Нахожу позиции совпавших символов
  const matchIndexes = [];
  // suggestion.suggestMatch.split('').forEach((s, index) => {
  //   if (s === '#') matchIndexes.push(index - matchIndexes.length);
  // });
  return (
    <MenuItem component="div" dense autoFocus={false}>
      <ListItemText
        primary={
          suggestion.pathway
            ? suggestion.pathway
                .slice(0)
                .reverse()
                .map((item, index) => (
                  <span>
                    {index === 0 ? (
                      <span style={{ fontWeight: 900, color: '#808080' }}>{item.name}</span>
                    ) : (
                      item.name
                    )}
                    {index + 1 < suggestion.pathway.length ? ' < ' : ''}{' '}
                  </span>
                ))
            : suggestion.name
        }
      />
      {/* {suggestion.name.split('').map((symbol, index) => {
        return matchIndexes.includes(index) ? (
          <strong key={index}>{symbol}</strong>
        ) : (
          <span key={index}>{symbol}</span>
        );
      })} */}
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

class Choiser extends Component {
  state = {
    value: '',
    suggestions: [],
    selectedSuggestion: undefined,
    anchorEl: null,
  };
  static getDerivedStateFromProps(props) {
    return {
      selectedSuggestion: props.selected,
    };
  }
  componentDidMount() {
    if (this.props.selected) {
      this.setState({ selectedSuggestion: this.props.selected });
    }
  }
  getSuggestions(value) {
    const options = {
      pre: '#',
      extract: el => el.name,
      returnOriginal: true,
      returnAllOnEmptyString: this.props.returnAllOnEmptyString,
    };

    const searchResult = fuzzyFilter(value, this.props.suggestions || [], options);
    return searchResult.slice(0, 8);
  }
  setAnchor = currentTarget => setTimeout(() => this.setState({ anchorEl: currentTarget }), 0);
  handleSuggestionsFetchRequested = ({ value }) =>
    this.setState({ suggestions: this.getSuggestions(value) });
  handleSuggestionsClearRequested = () => this.setState({ suggestions: [] });
  handleChange = (event, { newValue, method, ...other }) => {
    this.setState({ value: newValue, anchorEl: null });
    this.setAnchor(event.currentTarget);
  };
  suggestionSelected = (event, { suggestion, ...other }) => {
    const suggestionForReturn = { ...suggestion };
    delete suggestionForReturn.suggestMatch;
    this.props.returnSuggestion(suggestionForReturn);
    this.setState({
      value: '',
      selectedSuggestion: suggestion,
    });
  };
  handleDeleteChip = () => {
    this.setState({
      selectedSuggestion: undefined,
    });
    if (this.props.onHandleDeleteChip) {
      this.props.onHandleDeleteChip();
    }
    this.props.returnSuggestion(null);
  };
  handleClickChip = () => this.props.choiseHandler(this.state.selectedSuggestion);
  handleClose = () => this.setState({ anchorEl: null });
  renderSuggestionsContainer = options => {
    const { containerProps, children } = options;
    return (
      <Popover
        disableAutoFocus
        {...containerProps}
        id="simple-menu"
        // style={{ marginTop: `50vw` }}
        anchorEl={this.state.anchorEl}
        open={Boolean(this.state.suggestions.length) && Boolean(this.state.anchorEl)}
        onClose={this.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {children}
      </Popover>
    );
  };
  renderInput = inputProps => {
    const { classes, ref, inputLabel, placeholder, fullWidth, ...other } = inputProps;
    return (
      <TextField
        onClick={e => this.setState({ anchorEl: e.currentTarget })}
        label={inputLabel}
        placeholder={placeholder}
        fullWidth={fullWidth}
        InputProps={{
          inputRef: ref,
          classes: {
            input: classes.input,
          },
          endAdornment: this.props.onAddNew && this.state.value && (
            <WithConfirm
              onConfirm={() => this.props.onAddNew(this.state.value)}
              confirmTitle="Создать"
            >
              <InputAdornment position="end" title={`Создать "${this.state.value}"`}>
                <IconButton color="secondary">
                  <Add />
                </IconButton>
              </InputAdornment>
            </WithConfirm>
          ),
          ...other,
        }}
      />
    );
  };
  render() {
    const {
      classes,
      inputLabel,
      placeholder,
      fullWidth,
      selected = true,
      disabled = false,
    } = this.props;
    if (this.state.selectedSuggestion && Boolean(selected)) {
      return (
        <Chip
          label={this.state.selectedSuggestion.name}
          onDelete={
            disabled
              ? null
              : () => {
                  this.handleDeleteChip();
                  this.setState({ value: '' });
                }
          }
          className={this.props.classes.chip}
        />
      );
    }
    return (
      <Autosuggest
        disabled={disabled}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={this.renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          classes,
          value: this.state.value,
          onChange: this.handleChange,
          inputLabel,
          placeholder,
          fullWidth,
        }}
        onSuggestionSelected={this.suggestionSelected}
      />
    );
  }
}

Choiser.defaultProps = {
  placeholder: '',
  inputLabel: '',
  fullWidth: false,
  returnAllOnEmptyString: false,
};

Choiser.propTypes = {
  classes: PropTypes.object.isRequired,
  suggestions: PropTypes.array.isRequired,
  returnSuggestion: PropTypes.func.isRequired,
  inputLabel: PropTypes.string,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.any,
  selected: PropTypes.any,
  onHandleDeleteChip: PropTypes.any,
  disabled: PropTypes.bool,
  returnAllOnEmptyString: PropTypes.bool,
};

export default withStyles(styles)(Choiser);
