// @flow
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { type Theme, type ReactComponent } from 'types/components';
import { get } from 'lib/request';
import { API_HOST } from 'config';
import { Image } from './components/Image';

const styles = (theme: Theme) => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  page: {
    maxWidth: '50px',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

type ProvidedProps = {
  classes: any,
};

type Props = {};

const ImagesComponent = ({
  classes,
  image,
}: {
  ...Props,
  ...ProvidedProps,
}) => {
  const rowsPerPage = 44;
  const [isFetching, setFetching] = useState(true);
  const [page, changePage] = useState(1);
  const [images, setImages] = useState(null);
  useEffect(() => {
    get(`${API_HOST}/product_images`).then(res => {
      setImages(res.data.images);
      setFetching(false);
    });
  }, []);
  return (
    <div>
      <div className={classes.progress}>
        {isFetching && <CircularProgress color="secondary" />}
      </div>
      <div className={classes.list}>
        {images &&
          images
            .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
            .map(image => <Image key={image.id} image={image} />)}
      </div>
      <div className={classes.pagination}>
        страница
        <TextField
          className={classes.page}
          variant="outlined"
          type="number"
          onChange={e =>
            parseInt(e.target.value, 10) >= 0 ? changePage(parseInt(e.target.value, 10)) : null
          }
          value={page}
        />
        из {images && Math.ceil(images.length / rowsPerPage)}
        <IconButton
          onClick={() =>
            changePage(parseInt(page, 10) === 0 ? 0 : parseInt(page, 10) - 1)
          }
        >
          <ChevronLeft />
        </IconButton>
        <IconButton onClick={() => changePage(parseInt(page, 10) + 1)}>
          <ChevronRight />
        </IconButton>
      </div>
    </div>
  );
};

export const Images: ReactComponent<Props> = withStyles(styles)(
  ImagesComponent,
);
