// @flow
import { type TimePeriod } from 'types/entities/TimePeriod';
import { post } from 'lib/request';
import { API_HOST } from 'config';
import moment from 'moment';

export const fetchDeliveryPeriods = async ({
  address,
  orderId
}: {
  address: Address,
  orderId: number,
}): Promise<Array<TimePeriod> | null> => {
  try {
    const response = await post(`${API_HOST}/delivery-periods?order=${orderId}`, address).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const groupByDate = (deliveryPeriods: Array<TimePeriod> | null) => {
  if (!deliveryPeriods) return null;
  const result = {};
  deliveryPeriods.forEach((period: TimePeriod) => {
    const key = moment(period.start)
      .startOf('day')
      .format('YYYY-MM-DD');
    if (result[key]) {
      result[key] = result[key].concat(period);
      return null;
    }
    result[key] = [period];
    return null;
  });
  return result;
};
