// @flow
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';
import { type RealizationMethodPickUp } from 'types/entities/RealizationMethodPickUp';
import { type Warehouse as WarehouseType } from 'types/entities/Warehouse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Store from '@material-ui/icons/Store';
import LocationOn from '@material-ui/icons/LocationOn';
import { PickupTimeDialog } from 'components/shop/pickup-time-dialog';
import Schedule from '@material-ui/icons/Schedule';
import moment from 'moment';
import idx from 'idx';
import { fetchPickupTime } from 'components/shop/pickup-time/actions';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
  edited: {
    color: theme.palette.secondary[500],
    '& > span': {
      color: theme.palette.secondary[500],
    },
  },
  filled: {
    color: theme.palette.primary[700],
    '& > span': {
      color: theme.palette.primary[700],
    },
  },
  after: {
    color: 'red',
    fontSize: theme.typography.pxToRem(12),
  },
  before: {
    color: '#18bb18',
    fontSize: theme.typography.pxToRem(12),
  },
});

type Props = {|
  classes: Object,
  realizationMethod: RealizationMethodPickUp | null,
  editable: boolean,
  orderId: number | null,
  pickupTime: string | null,
  estimated_assembly_time: string | null,
  selectedWarehouse: WarehouseType | null,
  handleSelectWarehouse: (warehouse: WarehouseType) => void,
  fetchPickupTime: (params: { orderId: number }) => void,
|};

type State = {|
  open: boolean,
|};

class PickUp extends React.Component<Props, State> {
  state = {
    open: false,
  };

  componentDidMount() {
    if (this.props.orderId) this.props.fetchPickupTime({ orderId: this.props.orderId });
  }

  getColor = entity => {
    if (!this.props.editable) return null;
    if (entity) return this.props.classes.edited;
    return this.props.classes.filled;
  };

  getMinimalTimeStyle = (selectedTime: any) => {
    let classes = null;
    if (this.props.pickupTime && this.props.pickupTime < selectedTime) {
      classes = this.props.classes.before;
    }
    if (this.props.pickupTime && this.props.pickupTime > selectedTime) {
      classes = this.props.classes.after;
    }
    return classes;
  };

  handleToggleDialog = (editable: boolean) => () => {
    if (editable) return this.toggleDialog();
    return null;
  };

  handleSelectWarehouse = (data: { warehouse: WarehouseType, estimated_assembly_time: string }) => {
    this.props.handleSelectWarehouse({
      selectedWarehouse: data.warehouse,
      estimated_assembly_time: data.readiness_time,
    });
    return this.toggleDialog();
  };

  toggleDialog = () => this.setState(state => ({ open: !state.open }));

  render() {
    const {
      classes,
      realizationMethod,
      editable,
      selectedWarehouse,
      estimated_assembly_time,
    } = this.props;
    const pickUpPoint = selectedWarehouse || (realizationMethod && realizationMethod.pick_up_point);
    return (
      <div className={classes.root}>
        <List disablePadding>
          <ListItem
            title="Магазин"
            divider
            disableGutters
            button={editable}
            onClick={this.handleToggleDialog(editable)}
          >
            <ListItemIcon className={this.getColor(selectedWarehouse)}>
              <Store />
            </ListItemIcon>
            <ListItemText
              className={this.getColor(selectedWarehouse)}
              primary={idx(pickUpPoint, _ => _.name)}
              secondary={!pickUpPoint && 'Магазин'}
            />
          </ListItem>
          <ListItem title="Адрес магазина" divider disableGutters>
            <ListItemIcon>
              <LocationOn />
            </ListItemIcon>
            <ListItemText
              primary={idx(pickUpPoint, _ => _.address.line)}
              secondary={!pickUpPoint && 'Адрес'}
            />
          </ListItem>
          <ListItem title="Время готовности" disableGutters>
            <ListItemIcon>
              <Schedule />
            </ListItemIcon>
            <ListItemText
              primary={
                selectedWarehouse
                  ? moment(estimated_assembly_time).format('HH:mm, DD MMMM YYYY')
                  : realizationMethod &&
                    realizationMethod.estimated_assembly_time &&
                    moment(realizationMethod.estimated_assembly_time).format('HH:mm, DD MMMM YYYY')
              }
              secondary={
                <span
                  className={this.getMinimalTimeStyle(
                    realizationMethod && realizationMethod.estimated_assembly_time,
                  )}
                >
                  {selectedWarehouse || !realizationMethod
                    ? 'Время готовности'
                    : moment(this.props.pickupTime && this.props.pickupTime.estimated_assembly_time).format(
                        'HH:mm, DD MMMM YYYY',
                      )}
                </span>
              }
            />
          </ListItem>
        </List>
        <PickupTimeDialog
          onClose={this.toggleDialog}
          open={this.state.open}
          onSelect={this.handleSelectWarehouse}
        />
      </div>
    );
  }
}

const mapStateToProps = store => {
  const state = store.getIn(['shop']);
  return {
    isFetching: state.pickupTime.isFetching,
    pickupTime: state.pickupTime.date,
    orderId: state.order.data.id,
  };
};

export default connect(mapStateToProps, { fetchPickupTime })(withStyles(styles)(PickUp));
