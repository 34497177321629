import React from 'react';

const BarcodeSvg = () => (
  <svg height="35px" width="175">
    <g transform="translate(0)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(2)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(4)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(6)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(8)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(10)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(12)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(14)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(16)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(18)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(20)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(22)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(24)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(26)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(28)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(30)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(32)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(34)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(36)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(38)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(30)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(42)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(44)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(46)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(48)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(50)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(52)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(54)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(56)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(58)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(60)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(62)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(64)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(66)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(68)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(70)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(72)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(74)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(76)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(78)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(30)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(82)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(84)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(86)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(88)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(90)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(92)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(94)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(96)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(98)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(100)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(102)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(104)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(106)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(108)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(110)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(112)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(114)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(116)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(118)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(120)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(122)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(124)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(126)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(128)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(130)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(132)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(134)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(136)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(138)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(130)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(142)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(144)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(146)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(148)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(150)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(152)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(154)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(156)">
      <rect x="20" height="30" width="2" />
    </g>
    <g transform="translate(158)">
      <rect x="20" height="0" width="2" />
    </g>
    <g transform="translate(166)">
      <rect x="20" height="30" width="2" />
    </g>
  </svg>
);

export default BarcodeSvg;
