// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { type Theme, type ReactComponent } from 'types/components';

const styles = (theme: Theme) => ({
  checkbox: {
    width: '30px',
    height: '30px',
  },
  formControl: {
    marginLeft: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  checked: boolean,
  onClick: () => Function,
|};

const FilterInStockComponent = ({
  classes,
  onClick,
  checked,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => <FormControlLabel className={classes.formControl} control={<Checkbox className={classes.checkbox} onClick={onClick} checked={checked} />} label="В наличии" />;

export const FilterInStock: ReactComponent<Props> = withStyles(styles)(FilterInStockComponent);
