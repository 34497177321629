// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { type ProductPricingReport as ProductPricingReportType } from 'types/entities';
import queryString from 'query-string';
import ProductPricingReport from './view/ProductPricingReport';
import PricingReportEditDialog from './view/PricingReportEditDialog';
import {
  fetchProductPricingReport,
  setProductFixedPrice,
  removeProductFixedPrice,
  removeProductMarkup,
  setProductMarkup,
} from './actions';

type Props = {
  productId: number,
  fetchProductPricingReport: (productId: number) => void,
  setProductFixedPrice: (productId: number, price: number) => void,
  setProductMarkup: (productId: number, markup: number | string) => void,
  removeProductFixedPrice: (productId: number) => void,
  removeProductMarkup: (productId: number) => void,
  isFetching: boolean,
  report: ProductPricingReportType | null,
};

export type DialogType = 'fixed_price_product' | 'product_markup' | null;

type State = {|
  dialogType: DialogType,
  fixedPrice: string,
  productMarkup: string,
|};

export class ProductPricingReportContainer extends React.Component<Props, State> {
  state = {
    dialogType: null,
    fixedPrice: '',
    productMarkup: '',
    supplier: null,
  };

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    this.props.fetchProductPricingReport({
      productId: this.props.productId,
      fias_id: query.fias_id || '43909681-d6e1-432d-b61f-ddac393cb5da',
    });
  }

  componentDidUpdate(prevProps) {
    const prevQuery = queryString.parse(prevProps.location.search);
    const query = queryString.parse(this.props.location.search);
    if (prevQuery.fias_id !== query.fias_id) {
      this.props.fetchProductPricingReport({
        productId: this.props.productId,
        fias_id: query.fias_id || '43909681-d6e1-432d-b61f-ddac393cb5da',
      });
    }
  }

  setProductFixedPrice = (productId: number, price: number) => () => {
    const query = queryString.parse(this.props.location.search);
    this.toggleDialog({ dialogType: null, supplier: null })();
    this.props.setProductFixedPrice({
      productId,
      price,
      fias_id: query.fias_id || '43909681-d6e1-432d-b61f-ddac393cb5da',
    });
  };

  setProductMarkup = (productId: number, markup: number | string) => () => {
    const query = queryString.parse(this.props.location.search);
    this.toggleDialog({ dialogType: null, supplier: null })();
    this.props.setProductMarkup({
      markup: {
        supplier: this.state.supplier,
        product: { id: productId },
        value: markup,
      },
      fias_id: query.fias_id || '43909681-d6e1-432d-b61f-ddac393cb5da',
    });
  };
  removeProductFixedPrice = (productId: number) => () => {
    const query = queryString.parse(this.props.location.search);
    this.toggleDialog({ dialogType: null })();
    this.setState({ fixedPrice: '', supplier: null });
    this.props.removeProductFixedPrice({
      productId,
      fias_id: query.fias_id || '43909681-d6e1-432d-b61f-ddac393cb5da',
    });
  };
  removeProductMarkup = (productId: number) => () => {
    const query = queryString.parse(this.props.location.search);
    this.toggleDialog({ dialogType: null })();
    this.setState({ productMarkup: '' });
    this.props.removeProductMarkup({
      markup: {
        supplier: this.state.supplier,
        product: { id: productId },
      },
      fias_id: query.fias_id || '43909681-d6e1-432d-b61f-ddac393cb5da',
    });
  };
  toggleDialog = dialogData => () => this.setState(dialogData);
  handleChange = (name: string) => (e: Object) => this.setState({ [name]: e.target.value });
  render() {
    return (
      <React.Fragment>
        <ProductPricingReport
          isFetching={this.props.isFetching}
          report={this.props.report}
          toggleDialog={this.toggleDialog}
        />
        <PricingReportEditDialog
          report={this.props.report}
          supplier={this.state.supplier}
          open={Boolean(this.state.dialogType)}
          onClose={this.toggleDialog({ dialogType: null, supplier: null })}
          dialogType={this.state.dialogType}
          handleChange={this.handleChange}
          fixedPrice={this.state.fixedPrice}
          productMarkup={this.state.productMarkup}
          removeProductFixedPrice={this.removeProductFixedPrice(this.props.productId)}
          removeProductMarkup={this.removeProductMarkup(this.props.productId)}
          setProductFixedPrice={this.setProductFixedPrice(
            this.props.productId,
            Number(this.state.fixedPrice),
          )}
          setProductMarkup={this.setProductMarkup(this.props.productId, this.state.productMarkup)}
        />
      </React.Fragment>
    );
  }
}

ProductPricingReportContainer.propTypes = {
  fetchProductPricingReport: PropTypes.func.isRequired,
  removeProductFixedPrice: PropTypes.func.isRequired,
  setProductMarkup: PropTypes.func.isRequired,
  removeProductMarkup: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  report: PropTypes.object,
};

const mapStateToProps = store => ({
  report: store.getIn(['productPricingReport', 'report']),
  isFetching: store.getIn(['productPricingReport', 'isFetching']),
});

export default withRouter(
  connect(mapStateToProps, {
    fetchProductPricingReport,
    setProductFixedPrice,
    removeProductFixedPrice,
    setProductMarkup,
    removeProductMarkup,
  })(ProductPricingReportContainer),
);
