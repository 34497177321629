import {
  ADD_NOTIFICATION,
  CLOSE_NOTIFICATION,
} from 'types/actions';

export const addNotification = notification => ({
  type: ADD_NOTIFICATION,
  notification,
});

export const closeNotification = () => ({
  type: CLOSE_NOTIFICATION,
});
