// @flow
// @flow-runtime enable
import { get, post } from 'lib/request';
import { API_HOST, PUBLIC_API_HOST } from 'config';
import idx from 'idx';
import queryString from 'query-string';
import { type Brand } from 'types/entities/Brand';


export const getProductSeries = async (params: { brand?: string | number } | void) => {
  try {
    const response = await get(`${PUBLIC_API_HOST}/product-series?${queryString.stringify(params)}`).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const addProductSeries = async (params: { name: string, brand: Brand }) => {
  try {
    const response = await post(`${API_HOST}/product-series`, params).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};