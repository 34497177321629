// @flow
import { get, put, post } from 'lib/request';
import { API_HOST } from 'config';
import { normalize, schema } from 'normalizr';
import {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_TREE_SUCCESS,
  FETCH_CATEGORIES_LIST_SUCCESS,
  type RequestCategories,
  type ReceiveCategoriesTree,
  type ReceiveCategoriesList,
  type ThunkAction,
  type Dispatch,
  type CategoriesTreeInclude,
} from 'types/actions';
import { type typeCategory } from 'types/entities';
import { addNotification } from '../notifications/actions';

export const categoryEntity = new schema.Entity('categories');

export const requestCategories: Function = (
  value: boolean,
): RequestCategories => ({
  type: FETCH_CATEGORIES_REQUEST,
  value,
});

export const receiveCategoriesTree: Function = (
  tree: Array<typeCategory>,
): ReceiveCategoriesTree => ({
  type: FETCH_CATEGORIES_TREE_SUCCESS,
  tree,
});

export const receiveCategoriesList: Function = (
  list: { [key: string]: typeCategory },
): ReceiveCategoriesList => ({
  type: FETCH_CATEGORIES_LIST_SUCCESS,
  list,
});

export const fetchCategoriesTree: Function = (include: CategoriesTreeInclude): ThunkAction => (
  dispatch: Dispatch,
): any => {
  dispatch(requestCategories(true));
  const includeString = include ? include.reduce((prev, curr) => prev.concat(`${curr},`), '&include=') : '';
  get(`${API_HOST}/categories?tree${includeString}`)
    .then(
      res => dispatch(receiveCategoriesTree(res.data)),
      () => dispatch(addNotification('Неудачная попытка загрузки дерева категорий')),
    );
};

export const fetchCategoriesList: Function = (): ThunkAction => (
  dispatch: Dispatch,
): void => {
  dispatch(requestCategories(true));
  get(`${API_HOST}/categories`)
    .then(res => {
      const payload = normalize(res.data, {
        categories: [categoryEntity],
      });
      dispatch(receiveCategoriesList(payload.entities.categories));
    });
};

export const changeCategory: Function = (
  categoryId: number,
  children: Array<Object>,
): ThunkAction => (dispatch: Dispatch): void => {
  dispatch(requestCategories(true));
  put(`${API_HOST}/categories/${categoryId}/children`, children).then(
    () => dispatch(fetchCategoriesTree()),
    () => dispatch(addNotification('Неудачная попытка изменения порядка категорий')),
  );
};

export const addCategory: Function = (
  name: string,
  categoryId?: number | null,
): ThunkAction => (dispatch): Promise<Object> => {
  if (categoryId) {
    return post(`${API_HOST}/categories/${categoryId}/children`, { name }).then(
      () => dispatch(fetchCategoriesTree()),
      () => dispatch(addNotification('Неудачная попытка добавления категории')),
    );
  }
  return post(`${API_HOST}/categories/0/children`, { name }).then(
    () => dispatch(fetchCategoriesTree()),
    () => dispatch(addNotification('Неудачная попытка добавления категории')),
  );
};


export const updateCategory: Function = (
  category: typeCategory,
): ThunkAction => (dispatch): Promise<Object> =>
  put(`${API_HOST}/categories/${category.id}`, category).then(
    () => dispatch(fetchCategoriesTree()),
    () => dispatch(addNotification('Неудачная попытка изменения категории')),
  );
