// @flow
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocationOn from '@material-ui/icons/LocationOn';
import Schedule from '@material-ui/icons/Schedule';
import moment from 'moment';
import idx from 'idx';
import { type RealizationMethodCourierDelivery } from 'types/entities/RealizationMethodCourierDelivery';
import { type TimePeriod } from 'types/entities/TimePeriod';
import { type Address } from 'types/entities/Address';
import AddressDialog from 'components/shop/address-dialog';
import DeliveryPeriodDialog from 'components/shop/delivery-periods-dialog';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
  edited: {
    color: theme.palette.secondary[500],
    '& > span': {
      color: theme.palette.secondary[500],
    },
  },
  filled: {
    color: theme.palette.primary[700],
    '&  > span': {
      color: theme.palette.primary[700],
    },
  },
  after: {
    color: 'red',
    fontSize: theme.typography.pxToRem(12),
  },
  before: {
    color: '#18bb18',
    fontSize: theme.typography.pxToRem(12),
  },
});
type Props = {|
  classes: Object,
  editable: boolean,
  realizationMethod: RealizationMethodCourierDelivery | null,
  selectedDeliveryPeriod: TimePeriod | null,
  selectedAddress: Address | null,
  handleSelectAddress: (address: Address) => void,
  handleSelectDeliveryPeriod: (timePeriod: TimePeriod) => void,
  deliveryPeriods: Array<TimePeriod> | Array<any> | null,
|};

type State = {|
  openAddressDialog: boolean,
  openDeliveryPeriodDialog: boolean,
|};

class Courier extends React.Component<Props, State> {
  state = {
    openAddressDialog: false,
    openDeliveryPeriodDialog: false,
  };

  getColor = entity => {
    if (!this.props.editable) return null;
    if (entity) return this.props.classes.edited;
    return this.props.classes.filled;
  };

  toggleAddressDialog = () =>
    this.setState(state => ({ openAddressDialog: !state.openAddressDialog }));

  toggleDeliveryPeriodDialog = () =>
    this.setState(state => ({ openDeliveryPeriodDialog: !state.openDeliveryPeriodDialog }));

  callActionIfEditable = (action: Function, editable: boolean) => () => {
    if (editable) return action();
    return null;
  };

  handleSelectAddress = (address: Address) => {
    this.props.handleSelectAddress(address);
    return this.toggleAddressDialog();
  };

  handleSelectDeliveryPeriod = (timePeriod: TimePeriod) => {
    this.props.handleSelectDeliveryPeriod(timePeriod);
    return this.toggleDeliveryPeriodDialog();
  };

  formatTime = (timePeriod: TimePeriod | null | void) => {
    if (!timePeriod) return null;
    return `
      c 
      ${moment(idx(timePeriod, _ => _.start)).format('HH:mm')}
      до 
      ${moment(idx(timePeriod, _ => _.end)).format('HH:mm, DD.MM.YYYY')}
    `;
  };

  getMinimalTimeStyle = (sectedDeliveryPeriod: any) => {
    const minPeriod = idx(this.props.deliveryPeriods, _ => _[0]);
    let classes = null;
    if (minPeriod && minPeriod.start < sectedDeliveryPeriod.start) {
      classes = this.props.classes.before;
    }
    if (minPeriod && minPeriod.start > sectedDeliveryPeriod.start) {
      classes = this.props.classes.after;
    }
    return classes;
  };

  render() {
    const {
      classes,
      realizationMethod,
      editable,
      selectedDeliveryPeriod,
      selectedAddress,
    } = this.props;
    const address = selectedAddress || idx(realizationMethod, _ => _.address);
    const deliveryPeriod =
      selectedDeliveryPeriod || selectedAddress
        ? selectedDeliveryPeriod
        : idx(realizationMethod, _ => _.delivery_period);
    return (
      <div className={classes.root}>
        <List disablePadding>
          <ListItem
            title="Адрес доставки"
            divider
            disableGutters
            onClick={this.callActionIfEditable(this.toggleAddressDialog, editable)}
            button={editable}
          >
            <ListItemIcon className={this.getColor(selectedAddress)}>
              <LocationOn />
            </ListItemIcon>
            <ListItemText
              className={this.getColor(selectedAddress)}
              primary={idx(address, _ => _.line)}
              secondary={!address && 'Адрес доставки'}
            />
          </ListItem>
          <ListItem
            title="Период доставки"
            disableGutters
            button={editable}
            onClick={this.callActionIfEditable(this.toggleDeliveryPeriodDialog, editable)}
          >
            <ListItemIcon className={this.getColor(selectedDeliveryPeriod)}>
              <Schedule />
            </ListItemIcon>
            <ListItemText
              className={this.getColor(selectedDeliveryPeriod)}
              primary={deliveryPeriod && this.formatTime(deliveryPeriod)}
              secondary={
                deliveryPeriod ? (
                  <span
                    title="Минимальный доступный период доставки"
                    className={this.getMinimalTimeStyle(deliveryPeriod)}
                  >
                    {this.formatTime(idx(this.props.deliveryPeriods, _ => _[0]))}
                  </span>
                ) : (
                  'Период доставки'
                )
              }
            />
          </ListItem>
        </List>
        <AddressDialog
          open={this.state.openAddressDialog}
          onClose={this.toggleAddressDialog}
          onSelect={this.handleSelectAddress}
        />
        <DeliveryPeriodDialog
          order={this.props.order}
          address={address}
          open={this.state.openDeliveryPeriodDialog}
          onClose={this.toggleDeliveryPeriodDialog}
          onSelect={this.handleSelectDeliveryPeriod}
        />
      </div>
    );
  }
}

const mapStateToProps = store => {
  const state = store.getIn(['shop']);
  return {
    deliveryPeriods: state.deliveryPeriods.list,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Courier));
