// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Progress from 'components/progress';
import { type Theme } from 'types/components/Theme';
import cn from 'classnames';
import { RecalculateByDate } from './RecalculateByDate';

const styles = (theme: Theme) => ({
  paper: {
    border: `1px solid ${theme.palette.grey[400]}`,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  lines: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  fullHeight: {
    height: '100%',
  },
  addLineBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  paymentsBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  functions: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  delivery: {
    marginTop: theme.spacing.unit,
  },
});

type Props = {|
  classes: Object,
  customerInfo: Object,
  delivery: Object,
  orderControls: Object,
  lines: Object,
  total: Object,
  elevation: boolean,
  isFetching: boolean,
  addElementButton: Object,
  productsDialog: Object,
  payments: any,
  assemblyOrderDialog: any,
  giftLabel: any,
  reaclculateByDate: any,
|};

const OrderLayout = ({
  classes,
  isFetching,
  customerInfo,
  delivery,
  lines,
  elevation,
  total,
  orderControls,
  addElementButton,
  productsDialog,
  payments,
  assemblyOrderDialog,
  giftLabel,
  uncompletedDeliveries,
  comments,
  reaclculateByDate,
  coupon,
  apologizeCouponSend,
}: Props) => (
  <div>
    <Progress color="secondary" isFetching={isFetching} />
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Paper
          className={cn(classes.fullHeight, elevation ? null : classes.paper)}
          elevation={elevation ? 6 : 0}
        >
          {orderControls}
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper
          className={cn(classes.fullHeight, elevation ? null : classes.paper)}
          elevation={elevation ? 6 : 0}
        >
          {customerInfo}
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        <Paper
          className={cn(classes.fullHeight, elevation ? null : classes.paper)}
          elevation={elevation ? 6 : 0}
        >
          <div style={{ minHeight: '100px' }}>{uncompletedDeliveries}</div>
          <div className={classes.delivery}>{delivery}</div>
        </Paper>
      </Grid>
      {comments && (
        <Grid item xs={12}>
          <Paper
            className={elevation ? classes.lines : cn(classes.paper)}
            elevation={elevation ? 6 : 0}
          >
            <Grid container className={classes.paymentsBlock}>
              <Grid item xs={12}>
                {comments}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
      <Grid item xs={10} md={11}>
        <Paper
          className={elevation ? classes.lines : cn(classes.paper)}
          elevation={elevation ? 6 : 0}
        >
          <Grid container className={classes.paymentsBlock}>
            <Grid item xs={12} sm={6} className={classes.functions}>
              {payments}
              {giftLabel}
              {reaclculateByDate}
              {coupon}
              {apologizeCouponSend}
            </Grid>
            <Grid item xs={12} sm={6}>
              {total}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={2} md={1} className={classes.addLineBlock}>
        {addElementButton}
      </Grid>
      <Grid item xs={12}>
        <Paper
          className={elevation ? classes.lines : cn(classes.paper)}
          elevation={elevation ? 6 : 0}
        >
          {lines}
        </Paper>
      </Grid>
    </Grid>
    {productsDialog}
    {assemblyOrderDialog}
  </div>
);

OrderLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default withStyles(styles)(OrderLayout);
