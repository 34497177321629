import {
  FETCH_UNDEFINED_BRANDS_REQUEST,
  FETCH_UNDEFINED_BRANDS_SUCCESS,
} from 'types/actions';
import Immutable from 'immutable';

const undefinedBrandsInitialState = Immutable.Map({
  isFetching: false,
  items: Immutable.Map(),
});

const undefinedBrands = (state = undefinedBrandsInitialState, action) => {
  switch (action.type) {
    case FETCH_UNDEFINED_BRANDS_REQUEST: {
      return state.set('isFetching', action.value);
    }
    case FETCH_UNDEFINED_BRANDS_SUCCESS: {
      return state.merge({
        isFetching: false,
        items: Immutable.Map(action.undefinedBrands),
      });
    }
    default: {
      return state;
    }
  }
};

export default undefinedBrands;
