// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import { DatePicker } from 'material-ui-pickers';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import 'moment/locale/ru';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import moment from 'moment';
import { Button, IconButton, InputAdornment } from '@material-ui/core';
import Axios from 'axios';
import { recalculateToDate } from 'services/orderApi';
import { Check } from '@material-ui/icons';

moment.locale('ru');

const styles = theme => ({
  root: {},
});

const RecalculateByDateComponent = ({ classes, orderId, onChanage }) => {
  const [date, setDate] = useState(null);

  const handleReaclculate = () =>
    recalculateToDate(orderId, {
      date: moment(date).format('YYYY-MM-DD HH:mm:ss'),
    }).then(() => {
      onChanage();
      setDate(null);
    });

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
        <DatePicker
          label="Применить акции дня"
          format="YYYY.MM.DD"
          value={date ? date : new Date()}
          autoOk
          onChange={value => setDate(value)}
          animateYearScrolling
          cancelLabel="Отмена"
          InputProps={{
            endAdornment: date && (
              <InputAdornment position="end">
                <IconButton
                  color="secondary"
                  onClick={e => {
                    e.stopPropagation();
                    handleReaclculate();
                  }}
                >
                  <Check />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export const RecalculateByDate = withStyles(styles)(RecalculateByDateComponent);
