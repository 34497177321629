// @flow
import { type Delivery } from 'types/entities/Delivery';

export const FETCH_DELIVERIES_REQUEST: 'FETCH_DELIVERIES_REQUEST' = 'FETCH_DELIVERIES_REQUEST';
export const FETCH_DELIVERIES_SUCCESS: 'FETCH_DELIVERIES_SUCCESS' = 'FETCH_DELIVERIES_SUCCESS';

export type RequestDeliveries = {|
  type: typeof FETCH_DELIVERIES_REQUEST,
|};

export type ReceiveDeliveries = {|
  type: typeof FETCH_DELIVERIES_SUCCESS,
  deliveries: Array<Delivery> | Array<any> | null,
|};
