import axios from 'axios';
import { HELPER_HOST } from 'config';
import fileDownload from 'js-file-download';
import { copyToClipboard } from 'lib';

export const convertJsonToExcel = async ({
  table,
  name,
}: {
  table: { [key: string]: string | number },
  name?: string,
}): Promise<Array<TimePeriod> | null> => {
  try {
    const response = await axios.post(`${HELPER_HOST}/json-to-table?excel`, table, {
      responseType: 'blob',
    });

    fileDownload(response.data, `${name || 'list'}_${new Date().toISOString()}.xlsx`);

    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const convertJsonToHtmlTable = async ({
  table,
}: {
  table: { [key: string]: string | number },
}): Promise<Array<TimePeriod> | null> => {
  try {
    const response = await axios.post(`${HELPER_HOST}/json-to-table?table`, table);

    copyToClipboard(response.data);

    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
