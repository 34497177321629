// @flow
import { normalize, schema } from 'normalizr';
import { API_HOST } from 'config';
import { get, post } from 'lib/request';
import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  type RequestProducts,
  type ReceiveProducts,
} from 'types/actions';
import { type Product } from 'types/entities/Product';
import queryString from 'query-string';
import { addNotification } from '../notifications/actions';

export const productsEntity = new schema.Entity('products');

export const requestProducts = (value: boolean): RequestProducts => ({
  type: FETCH_PRODUCTS_REQUEST,
  value,
});

export const receiveProducts = (
  products: { [key: string]: Product } | void,
): ReceiveProducts => ({
  type: FETCH_PRODUCTS_SUCCESS,
  products,
});

export const fetchProducts = (params: {
  barcode: string,
  name: string,
  manufacturer: number | null,
  brand: number | null,
  category: number | null,
  image?: string,
  missing_retail?: boolean,
  in_stock?: boolean,
  from?: string | null,
  to?:  string | null,
}) => (dispatch: $FlowFixMe) => {
  dispatch(requestProducts(true));
  const query = queryString.stringify(params);
  get(`${API_HOST}/products?${query}`).then(
    res => {
      const payload = normalize(res.data, {
        products: [productsEntity],
      });
      dispatch(receiveProducts(payload.entities.products));
    },
    () => {
      dispatch(addNotification('Неудачная попытка загрузки продуктов'));
    },
  );
};

export const assignCategory = ({
  categoryId,
  products,
}: {
  categoryId: number,
  products: Array<{ id: number }>,
}) => (dispatch: $FlowFixMe) =>
  post(`${API_HOST}/categories/${categoryId}/products`, products).then(
    res => res,
    () => {
      dispatch(addNotification('Неудачная попытка изменения категории'));
    },
  );
