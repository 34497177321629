// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import {
  createAttributeItem,
  deleteAttribute,
  changeAttribute,
  deleteAttributeElement,
  chageAttributeElement,
} from 'services/attributeApi';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import WithConfirm from 'components/with-confirm';
import cn from 'classnames';
import { Add, Close, Edit } from '@material-ui/icons';
import ProductLabel from 'components/product-label';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
  name: {
    '& > div': {
      display: 'inline-block',
      marginRight: theme.spacing.unit,
    },
  },
  elementName: {
    '& > div': {
      display: 'inline-block',
      marginRight: theme.spacing.unit,
    },
  },
  listItem: {
    maxWidth: '400px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  types: {
    marginTop: theme.spacing.unit,
    '& > div': {
      marginRight: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const AttributeComponent = ({
  classes,
  item,
  onChange,
  edit,
  onClick,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const [openAddItemDialog, setOpenAddItemDialog] = useState(false);

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [newElementName, setNewElementName] = useState('');

  const [editElement, setEditElement] = useState(null);

  const [elementName, setElementName] = useState(null);

  const [itemName, setItemName] = useState(null);

  const handleCreateItem = () => {
    createAttributeItem(item.id, { value: newElementName }).then(() => {
      onChange();
      setOpenAddItemDialog(false);
      newElementName('');
    });
  };

  const handleChangeAttribute = () => {
    changeAttribute(item.id, { name: itemName }).then(() => {
      onChange();
      setOpenEditDialog(false);
      itemName(null);
    });
  };

  const handleDelete = id => deleteAttribute(id).then(() => onChange());

  const handleDeleteElement = id => deleteAttributeElement(id).then(() => onChange());

  const handleChangeElement = () =>
    chageAttributeElement(editElement.id, { value: elementName }).then(() => {
      onChange();
      setElementName(null);
      setEditElement(null);
    });

  const typesString = item.types && item.types.map(type => type.name).join(', ');

  return (
    <div className={cn(classes.root, onClick && classes.button)}>
      <Dialog open={openAddItemDialog} onClose={() => setOpenAddItemDialog(false)}>
        <DialogTitle>Добавление элемента к {item.name}</DialogTitle>
        <DialogContent>
          <TextField
            label="Название"
            fullWidth
            value={newElementName}
            onChange={e => setNewElementName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddItemDialog(false)}>Отмена</Button>
          <Button variant="raised" color="secondary" onClick={handleCreateItem}>
            Создать
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogContent>
          <TextField
            label="Название"
            fullWidth
            value={itemName !== null ? itemName : item.name}
            onChange={e => setItemName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)}>Отмена</Button>
          <Button variant="raised" color="secondary" onClick={handleChangeAttribute}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={Boolean(editElement)} onClose={() => setEditElement(false)}>
        <DialogContent>
          <TextField
            label="Название"
            fullWidth
            value={elementName !== null ? elementName : editElement && editElement.value}
            onChange={e => setElementName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditElement(null)}>Отмена</Button>
          <Button variant="raised" color="secondary" onClick={handleChangeElement}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.header}>
        <div className={classes.name}>
          <div>
            <Typography variant="caption">{item.id}</Typography>
          </div>
          <div>{item.name}</div>
          <div>
            <Chip label={item.type} />
          </div>
        </div>
        {edit && (
          <div>
            <IconButton title="Редактировать" onClick={() => setOpenEditDialog(true)} size="small">
              <Edit />
            </IconButton>
            <WithConfirm onConfirm={() => handleDelete(item.id)} confirmTitle="Удалить">
              <IconButton size="small" title="Удалить">
                <Close />
              </IconButton>
            </WithConfirm>
          </div>
        )}
      </div>
      {Boolean(item.types && item.types.length) && (
        <div className={classes.types}>
          {item.types && item.types.map(type => <ProductLabel type={type} />)}
        </div>
      )}

      {item.type === 'string' && (
        <div>
          <List className={classes.listItem} dense>
            {item.list_possible_values &&
              item.list_possible_values
                .sort((a, b) => (a.value > b.value ? 1 : -1))
                .map(value => (
                  <ListItem key={value.id} button divider>
                    <ListItemText
                      primary={
                        <div className={classes.elementName}>
                          <div>
                            <Typography variant="caption">{value.id}</Typography>
                          </div>
                          <div>{value.value}</div>
                        </div>
                      }
                    />
                    {edit && (
                      <ListItemSecondaryAction>
                        <IconButton
                          title="Редактировать"
                          onClick={() => setEditElement(value)}
                          size="small"
                        >
                          <Edit />
                        </IconButton>
                        <WithConfirm
                          onConfirm={() => handleDeleteElement(value.id)}
                          confirmTitle="Удалить"
                        >
                          <IconButton size="small" title="Удалить">
                            <Close />
                          </IconButton>
                        </WithConfirm>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
            {edit && (
              <ListItem button divider onClick={() => setOpenAddItemDialog(true)}>
                <ListItemText primary="Добавить элемент" color="primary" />
                <ListItemSecondaryAction>
                  <Add />
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </div>
      )}
    </div>
  );
};
export const Attribute: ReactComponent<Props> = withStyles(styles)(AttributeComponent);
