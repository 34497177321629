// @flow
import React, { type ElementType } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { type Product as ProductType } from 'types/entities/Product';
import LinearProgress from '@material-ui/core/LinearProgress';
import ProductStockItemsTable from './ProductStockItemsTable';
import { IconButton } from '@material-ui/core';
import { ShoppingBasket } from '@material-ui/icons';

const styles = theme => ({
  root: {
    marginTop: '-6px',
    position: 'relative',
  },
  paper: {
    padding: theme.spacing.unit * 2,
  },
  progress: {
    height: '5px',
  },
  barcodeBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  caption: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '130px',
    color: theme.palette.grey[600],
  },
  listBlock: {
    display: 'flex',
  },
  properties: {
    display: 'flex',
    alignItems: 'center',
  },
});

type Props = {
  classes: Object,
  isFetching: boolean,
  product: ProductType | null,
  manufacturerBrandCategory: ElementType,
  productNames: ElementType,
  barcodes: ElementType,
  slug: ElementType,
  images: ElementType,
  pricingReport: ElementType,
  description: ElementType,
  howToUse: ElementType,
  ingredients: ElementType,
  moderated: ElementType,
  reviews: ElementType,
  IsPublishedControl: ElementType,
};

const Product = ({
  classes,
  product,
  isFetching,
  manufacturerBrandCategory,
  productNames,
  barcodes,
  slug,
  images,
  pricingReport,
  description,
  howToUse,
  ingredients,
  moderated,
  reviews,
  isPublishedControl,
  regionSelect,
  priceSourceAdd,
  allowedForSaleProduct,
}: Props) => (
  <div className={classes.root}>
    <div className={classes.progress}>{isFetching && <LinearProgress color="secondary" />}</div>
    <Grid container spacing={24}>
      {product && product.id && (
        <React.Fragment>
          <Grid item xs={12} md={6}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Paper className={classes.paper} elevation={6}>
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography variant="display1">{product.title}</Typography>
                      <Typography variant="body1">
                        ID {product.id}
                        {', '}
                        <span title="Дата создания">
                          {new Date(product.created_time * 1000).toLocaleString('ru')}
                        </span>
                      </Typography>
                      <div className={classes.properties}>
                        {moderated}
                        {isPublishedControl}
                        {allowedForSaleProduct}
                        <IconButton
                          color="secondary"
                          href={`https://product.bonjour-dv.ru/products/${product.id}`}
                          target="_blank"
                        >
                          <ShoppingBasket />
                        </IconButton>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      {barcodes}
                    </Grid>
                    <Grid item xs={12}>
                      {images}
                    </Grid>
                    {/* <Grid item xs={12}>
                      {slug}
                    </Grid> */}
                    <Grid item xs={12}>
                      {manufacturerBrandCategory}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper} elevation={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      {productNames}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper} elevation={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      {description}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper} elevation={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      {howToUse}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper} elevation={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      {ingredients}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Paper elevation={6}>{regionSelect}</Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={6}>{pricingReport}</Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={6}>
                  <ProductStockItemsTable product={product} />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={6}>{priceSourceAdd}</Paper>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  </div>
);

Product.propTypes = {
  manufacturerBrandCategory: PropTypes.object.isRequired,
  barcodes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Product);
