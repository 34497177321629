// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Progress from 'components/progress';


const styles = theme => ({
});

type Props = {
  classes: Object,
  ordersTable: Object,
  controls: any,
  isFetching: boolean,
};

const Suppliers = ({
  classes,
  isFetching,
  ordersTable,
  controls,
  charts,
}: Props) => (
  <div>
    {controls}
    {charts}
    <Progress color="secondary" isFetching={isFetching} />
    {ordersTable}
  </div>
);

Suppliers.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Suppliers);
