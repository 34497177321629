// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});
type Props = {|
  classes: Object,
  progress: Object,
  warehouse: Object,
  actions: Object,
|};

const Warehouse = ({
  classes,
  progress,
  warehouse,
  actions,
}: Props) => (
  <div className={classes.root}>
    {progress}
    {warehouse}
    {actions}
  </div>
);

Warehouse.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Warehouse);
