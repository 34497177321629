// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { type Warehouse } from 'types/entities/Warehouse';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { type Theme, type ReactComponent } from 'types/components';

const styles = (theme: Theme) => ({
  root: {
    // marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  warehouse: Warehouse | null,
  warehouses: Array<Warehouse> | null,
  onChange: (name: string, value: any) => any,
|};

const WarehouseFilterComponent = ({
  classes,
  warehouse,
  warehouses,
  onChange,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const warehousesMap = {};
  if (warehouses) {
    warehouses.forEach(item => (warehousesMap[item.vendor_id] = item));
  }
  const warehousesArray = Object.keys(warehousesMap)
    .map(key => warehousesMap[key])
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  return (
    <FormControl className={classes.root}>
      <InputLabel htmlFor="warehouse">Магазин</InputLabel>
      <Select
        value={(warehouse && warehouse.vendor_id) || 'Все магазины'}
        renderValue={() => (warehouse && warehouse.name) || 'Все магазины'}
        onChange={e =>
          onChange(
            'selectedWarehouse',
            (warehouses && warehouses.find(item => item.vendor_id == e.target.value)) || null,
          )
        }
        inputProps={{
          name: 'age',
          id: 'warehouse',
        }}
      >
        <MenuItem value="Все магазины">
          <em>Все магазины</em>
        </MenuItem>
        {warehousesArray &&
          warehousesArray.map(item => (
            <MenuItem key={item.vendor_id} value={item.vendor_id}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
export const WarehouseFilter: ReactComponent<Props> = withStyles(styles)(WarehouseFilterComponent);
