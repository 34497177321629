// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { type Brand, type Manufacturer, type Name, type Image } from 'types/entities';
import { type Product as ProductType } from 'types/entities/Product';
import { type typeCategory } from 'types/entities/typeCategory';
import { type SetTopBarTitle } from 'types/actions';
import { connect } from 'react-redux';
import { getSeries } from 'components/series/actions';
import { PriceSourceAdd } from 'components/price-source-add';
import { withRouter } from 'react-router-dom';
import ProductPricingReport from 'components/product_pricing_report';
import { Reviews } from 'components/reviews';
import Product from './view/Product';
import Slug from './view/Slug';
import ManufacturerBrandCategory from './view/ManufacturerBrandCategory';
import ProductNames from './view/ProductNames';
import Barcodes from './view/Barcodes';
import ProductImages from './view/ProductImages';
import { Attributes } from './view/Attributes';
import {
  fetchProduct,
  changeProduct,
  deleteEntityFromProduct,
  addName,
  deleteName,
  setDefaultName,
  fetchProductImages,
  changeProductImage,
  addImages,
  removeImage,
  changeCompositeName,
} from './actions';
import { setTopBarTitle } from '../top_bar/actions';
import { fetchManufacturersIfNeeded } from '../manufacturers/actions';
import { getBrands } from '../brands/actions';
import { fetchCategoriesList } from '../categories/actions';
import { TextBlock } from './view/TextBlock';
import { CompositeName } from './view/CompositeName';
import { ModeratedCheckbox } from './view/ModeratedCheckbox';
import { IsPublishedControl } from './view/IsPublishedControl';
import { RegionSelect } from './view/RegionSelect';
import { AllowedForSaleProduct } from './view/AllowedForSaleProduct';
import { changeProductCharacteristic } from 'services/attributeApi';

type Props = {
  fetchProduct: (productId: number) => Promise<any>,
  fetchProductImages: (productId: number) => void,
  changeProduct: (productId: number, name: string, value: any) => Promise<any>,
  addName: (productId: number, name: string) => void,
  deleteEntityFromProduct: (productId: number, name: string) => void,
  deleteName: (nameId: number) => void,
  getSeries: typeof getSeries,
  setDefaultName: (productId: number, name: Name) => void,
  addImages: (productId: number, files: Array<File>) => void,
  removeImage: (productId: number, imageId: number) => void,
  match: Object,
  isFetching: boolean,
  changeCompositeName: typeof changeCompositeName,
  manufacturersIsFetching: boolean,
  brandsIsFetching: boolean,
  categoriesIsFetching: boolean,
  product: ProductType | null,
  setTopBarTitle: SetTopBarTitle,
  fetchCategoriesList: Function,
  fetchManufacturersIfNeeded: Function,
  getBrands: Function,
  categories: Array<typeCategory>,
  brands: Array<Brand>,
  manufacturers: Array<Manufacturer>,
  match: { params: { productId: number } },
  images: Array<Image> | Array<any>,
};

export class ProductContainer extends React.Component<Props> {
  fetchProduct = () => {
    this.props
      .fetchProduct(Number(this.props.match.params.productId))
      .then((product: ProductType) =>
        product ? (document.title = product.name && product.name.value) : null,
      );
    this.props.fetchProductImages(Number(this.props.match.params.productId));
    this.props.setTopBarTitle('Продукт');
  };

  componentDidMount() {
    this.fetchProduct();
  }

  changeProduct = (name: string, value: any) => {
    if (this.props.product) {
      this.props.changeProduct(this.props.product.id, name, value).then(product => {
        if (name === 'brand' && value) {
          this.props.getSeries({ brand: value.id });
        }
      });
    }
  };

  changePublished = image => {
    return this.props
      .changeProductImage({ productId: this.props.product.id, image })
      .then(() => this.props.fetchProductImages(Number(this.props.match.params.productId)));
  };

  render() {
    return (
      <React.Fragment>
        <Product
          isFetching={this.props.isFetching}
          product={this.props.product}
          manufacturerBrandCategory={
            <ManufacturerBrandCategory
              categoriesIsFetching={this.props.categoriesIsFetching}
              isFetching={this.props.isFetching}
              manufacturersIsFetching={this.props.manufacturersIsFetching}
              brandsIsFetching={this.props.brandsIsFetching}
              brands={this.props.brands}
              categories={this.props.categories}
              manufacturers={this.props.manufacturers}
              product={this.props.product}
              fetchManufacturersIfNeeded={this.props.fetchManufacturersIfNeeded}
              getBrands={this.props.getBrands}
              fetchCategoriesList={this.props.fetchCategoriesList}
              changeProduct={this.changeProduct}
            />
          }
          productNames={
            <ProductNames
              attributes={
                <Attributes
                  disabled
                  onlySelected
                  characteristics={this.props.product && this.props.product.characteristics}
                  typeId={
                    this.props.product && this.props.product.type && this.props.product.type.id
                  }
                  onChange={(attributeId, value) =>
                    changeProductCharacteristic(this.props.product.id, attributeId, value)
                  }
                  onSave={this.fetchProduct}
                />
              }
              compositeName={
                <CompositeName
                  product={this.props.product}
                  changeCompositeName={this.props.changeCompositeName}
                  changeProduct={this.changeProduct}
                />
              }
              product={this.props.product}
              addName={this.props.addName}
              deleteName={this.props.deleteName}
              setDefaultName={this.props.setDefaultName}
            />
          }
          barcodes={
            <Barcodes
              product={this.props.product}
              deleteEntityFromProduct={this.props.deleteEntityFromProduct}
            />
          }
          slug={<Slug product={this.props.product} changeProduct={this.changeProduct} />}
          images={
            <ProductImages
              disabled
              images={this.props.images}
              product={this.props.product}
              addImages={this.props.addImages}
              removeImage={this.props.removeImage}
              changeProduct={this.changeProduct}
              changePublished={this.changePublished}
            />
          }
          moderated={
            <ModeratedCheckbox product={this.props.product} changeProduct={this.changeProduct} />
          }
          isPublishedControl={
            <IsPublishedControl product={this.props.product} changeProduct={this.changeProduct} />
          }
          isPublish={
            <IsPublishedControl product={this.props.product} changeProduct={this.changeProduct} />
          }
          pricingReport={
            <ProductPricingReport productId={Number(this.props.match.params.productId)} />
          }
          description={
            <TextBlock
              value={this.props.product && this.props.product.description}
              onChange={value => this.changeProduct('description', value)}
              placeholder="Описание"
            />
          }
          howToUse={
            <TextBlock
              value={this.props.product && this.props.product.how_to_use}
              onChange={value => this.changeProduct('how_to_use', value)}
              placeholder="Способ применения"
            />
          }
          ingredients={
            <TextBlock
              value={this.props.product && this.props.product.ingredients}
              onChange={value => this.changeProduct('ingredients', value)}
              placeholder="Состав"
            />
          }
          allowedForSaleProduct={
            <AllowedForSaleProduct productId={this.props.product && this.props.product.id} />
          }
          regionSelect={<RegionSelect />}
          priceSourceAdd={
            <PriceSourceAdd productId={this.props.product && this.props.product.id} />
          }
        />
      </React.Fragment>
    );
  }
}

ProductContainer.propTypes = {
  fetchProduct: PropTypes.func.isRequired,
  fetchCategoriesList: PropTypes.func.isRequired,
  fetchManufacturersIfNeeded: PropTypes.func.isRequired,
  getBrands: PropTypes.func.isRequired,
  brandsIsFetching: PropTypes.bool.isRequired,
  categoriesIsFetching: PropTypes.bool.isRequired,
  manufacturersIsFetching: PropTypes.bool.isRequired,
  manufacturers: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  changeProduct: PropTypes.func.isRequired,
  deleteEntityFromProduct: PropTypes.func.isRequired,
  addName: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  addImages: PropTypes.func.isRequired,
  deleteName: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
};

const mapStateToProps = store => ({
  isFetching: store.getIn(['product', 'isFetching']),
  product: store.getIn(['product', 'data']),
  images: store.getIn(['product', 'images']).valueSeq().toArray(),
  manufacturers: store.getIn(['manufacturers', 'items']).valueSeq().toArray(),
  brands: store.getIn(['brands', 'items']).valueSeq().toArray(),
  categories: store.getIn(['categories', 'list']).valueSeq().toArray(),
  manufacturersIsFetching: store.getIn(['manufacturers', 'isFetching']),
  brandsIsFetching: store.getIn(['brands', 'isFetching']),
  categoriesIsFetching: store.getIn(['categories', 'isFetching']),
});

export default withRouter(
  connect(mapStateToProps, {
    fetchProduct,
    setTopBarTitle,
    fetchCategoriesList,
    getBrands,
    fetchManufacturersIfNeeded,
    changeProduct,
    deleteEntityFromProduct,
    addName,
    deleteName,
    setDefaultName,
    fetchProductImages,
    addImages,
    removeImage,
    changeCompositeName,
    getSeries,
    changeProductImage,
  })(ProductContainer),
);
