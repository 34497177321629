// @flow
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { signIn } from 'lib/auth';
import { fetchUser } from 'components/user/actions';
import Login from './view/Login';

export type State = {
  username: { value: string, error: boolean },
  password: { value: string, error: boolean },
  result: { message: string, result: string } | null,
};

type Props = {
  open: boolean,
  redirectTarget: string | void,
  fetchUser: (session: string | void) => void,
};

const defaultState = {
  username: { value: '', error: false },
  password: { value: '', error: false },
  result: null,
};

export class LoginContainer extends React.Component<Props, State> {
  state = {
    username: { value: '', error: false },
    password: { value: '', error: false },
    result: null,
  };

  componentDidMount() {
  }

  toggleLoginDialog = () => {
    this.setState(defaultState);
  };

  handleChange = (name: 'username' | 'password', value: string) => this.setState(prev => ({
    ...prev,
    [name]: { ...prev[name], value },
  }));

  signIn = (username: string, password: string) => () => signIn(username, password)
    .then(res => {
      if (res && res.result === 'success') {
        this.toggleLoginDialog();
        this.props.fetchUser();
        // location.href = '/';
      }
      this.setState({ result: res });
    });

  render() {
    return (
      <Login
        open={this.props.open}
        username={this.state.username}
        password={this.state.password}
        toggleLoginDialog={this.toggleLoginDialog}
        handleChange={this.handleChange}
        signIn={this.signIn}
        result={this.state.result}
      />
    );
  }
}

LoginContainer.propTypes = {
  fetchUser: PropTypes.func.isRequired,
};

const mapStateToProps = store => ({
});

export default connect(
  mapStateToProps,
  {
    fetchUser,
  },
)(LoginContainer);
