// @flow
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import { fetchWarehousesDeliverySchedules } from 'services/warehouseApi';
import { DeliverySchedule } from './components/DeliverySchedule';
import { FormControl, InputLabel, LinearProgress, MenuItem, Select } from '@material-ui/core';

const styles = (theme: Theme) => ({
  progress: {
    height: '4px',
  },
  list: {
    marginTop: theme.spacing * 2,
    maxWidth: '800px',
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const WarehousesDeliverySchedulesComponent = ({ classes }: {| ...Props, ...ProvidedProps |}) => {
  const [state, setState] = useState(null);
  const [selectedSupplierAlias, setSupplierAlias] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    getShedules();
  }, []);

  const getShedules = () => {
    setLoading(true);
    return fetchWarehousesDeliverySchedules().then(data => {
      setLoading(false);
      setState(data);
      return data;
    });
  };

  const suppliers =
    state &&
    Object.values(
      state.reduce(
        (prev, curr) => (prev[curr.supplier.alias] ? prev : { ...prev, [curr.supplier.alias]: curr.supplier }),
        {},
      ),
    );
  console.log(suppliers)
  return (
    <div>
      <div className={classes.progress}>{loading && <LinearProgress color="secondary" />}</div>
      <div>
        <FormControl className={classes.root}>
          <InputLabel htmlFor="supplier" shrink>Поставщик</InputLabel>
          <Select
            inputProps={{
              id: 'supplier',
            }}
            value={selectedSupplierAlias}
            onChange={e => setSupplierAlias(e.target.value)}
          >
            {suppliers &&
              suppliers.map(item => (
                <MenuItem key={item.alias} value={item.alias}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.list}>
        {state &&
          state
            .filter(item =>
              selectedSupplierAlias ? item.supplier.alias === selectedSupplierAlias : item,
            )
            .sort((a, b) => a.warehouse.id - b.warehouse.id)
            .map(item => <DeliverySchedule key={item.id} schedule={item} onSave={getShedules} />)}
      </div>
    </div>
  );
};
export const WarehousesDeliverySchedules: ReactComponent<Props> = withStyles(styles)(
  WarehousesDeliverySchedulesComponent,
);
