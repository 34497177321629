// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import { type Warehouse } from 'types/entities/Warehouse';
import { type Theme, type ReactComponent } from 'types/components';
import { type PickupPoint as PickupPointType } from 'types/entities/PickupPoint';

const styles = (theme: Theme) => ({
  time: {
    color: theme.palette.primary[900],
    fontSize: theme.typography.pxToRem(16),
  },
});

type ProvidedProps = {|
  classes: Object,
|};

type Props = {|
  pickupPoint: PickupPointType,
  onClick: (pickupPoint: PickupPointType) => void,
|};

const PickupPointComponent = ({
  classes,
  pickupPoint,
  onClick,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const handleOnClick = () => onClick(pickupPoint);
  return (
    <ListItem className={classes.root} dense button onClick={handleOnClick}>
      <ListItemText
        primary={pickupPoint.warehouse.name}
        secondary={
          <span>
            <span>{pickupPoint.warehouse.address && pickupPoint.warehouse.address.line}</span>
            <br />
            <span className={classes.time}>
              {pickupPoint.readiness_time
                ? moment(pickupPoint.readiness_time).format('DD MMMM, dddd')
                : 'проверьте наличие'}
            </span>
          </span>
        }
      />
    </ListItem>
  );
};
export const PickupPoint: ReactComponent<Props> = withStyles(styles)(PickupPointComponent);
