// @flow
// @flow-runtime enable
import { type Address } from './Address';
import { type Supplier } from './Supplier';

export type Warehouse = {|
  id?: number,
  name?: string,
  address?: Address | any,
  supplier?: Supplier,
  vendor_id?: string,
  is_pick_point?: boolean,
  is_pricing_participant?: boolean,
  is_selling?: boolean,
|};
