// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';
import { type TimePeriod } from 'types/entities/TimePeriod';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { groupByDate } from 'services/deliveryPeriodApi';
import moment from 'moment';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
  proggress: {
    display: 'flex',
    justifyContent: 'center',
  },
});
type Props = {|
  classes: Object,
  open: boolean,
  isFetching: boolean,
  deliveryPeriods: Array<TimePeriod> | null,
  onSelect: (timePeriod: TimePeriod) => void,
  onClose: () => void,
|};

type State = {|
  open: any,
|};

class DeliveryPeriodsDialog extends React.Component<Props, State> {
  state = {
    open: {},
  };

  getDates = (deliveryPeriods: Array<TimePeriod> | null) => groupByDate(deliveryPeriods);

  toggleDay = (date: string) => () =>
    this.setState(state => ({
      open: {
        ...state.open,
        [date]: !state.open[date],
      } 
    }));

  render() {
    const {
      classes,
      open,
      isFetching,
      onSelect,
      onClose,
      deliveryPeriods,
    } = this.props;
    const dates = this.getDates(deliveryPeriods);
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        className={classes.root}
        open={open}
        onClose={onClose}
      >
        <List component="nav">
          {dates &&
            Object.keys(dates).map(key => (
              <React.Fragment key={key}>
                <ListItem
                  button
                  onClick={this.toggleDay(key)}
                >
                  <ListItemText primary={moment(key).format('DD MMMM YYYY, dddd')} />
                  {this.state.open[key] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.open[key]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {dates &&
                      dates[key].map(date => (
                        <ListItem
                          button
                          className={classes.nested}
                          key={date.start}
                          onClick={onSelect(date)}
                        >
                          <ListItemText
                            inset
                            primary={`c ${moment(date.start).format('HH:mm')} до ${moment(date.end).format('HH:mm')}`}
                            />
                        </ListItem>
                      ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
        </List>
        <DialogActions>
          <Button onClick={onClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DeliveryPeriodsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeliveryPeriodsDialog);
