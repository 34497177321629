import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddCircle from '@material-ui/icons/AddCircle';
import FilterList from '@material-ui/icons/FilterList';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Brands from './view/Brands';
import fuzzyFilter from '../fuzzy_filter';
import BrandsDialog from './view/BrandsDialog';
import BrandsFilter from './view/BrandsFilter';
import { getBrands, editBrand, deleteAlias, addBrandWithUpdate } from './actions';
import { setTopBarTitle } from '../top_bar/actions';
import { fetchManufacturersIfNeeded } from '../manufacturers/actions';
import { setRightSideBarActions } from '../right_side_bar/actions';

const fuzzyOptions = {
  extract: el => el.name,
  returnAllOnEmptyString: true,
  returnOriginal: true,
};

export class BrandsContainer extends React.Component {
  state = {
    selectedBrand: null,
    brandDialogOpened: false,
    selectedManufacturer: null,
    nextManufacturer: null,
    slug: '',
    name: '',
    adapted_name: '',
    dialogType: '',
    filterOpened: false,
    filterString: '',
  }
  componentDidMount() {
    const actionsForRightSideBar = [
      {
        key: 'filterManufacturersOpened',
        icon: <FilterList />,
        name: 'Фильтрация',
        action: () => this.toggleFilterManufacturers(),
      },
      {
        key: 'addBrand',
        icon: <AddCircle />,
        name: 'Добавить бренд',
        action: () => this.toggleBrandDialog('add_brand'),
      },
    ];
    this.props.setRightSideBarActions(actionsForRightSideBar);
    this.props.setTopBarTitle('Бренды');
    this.props.getBrands();
    this.props.fetchManufacturersIfNeeded();
    if (this.props.location.search) {
      const { manufacturer } = queryString.parse(this.props.location.search);
      this.setState({ selectedManufacturer: { id: Number(manufacturer) } });
    }
  }
  componentWillUnmount() {
    this.props.setRightSideBarActions([]);
  }
  setSelectedManufacturer = selectedManufacturer => this.setState({ selectedManufacturer });
  toggleFilterManufacturers = () =>
    this.setState(prev => ({ filterOpened: !prev.filterOpened }));
  toggleBrandDialog = (dialogType = '', brand) => {
    this.setState(prev => ({
      brandDialogOpened: !prev.brandDialogOpened,
      dialogType,
    }));
    if (dialogType === 'edit_brand') {
      this.setState({
        name: brand.name || '',
        adapted_name: brand.adapted_name || '',
        selectedBrand: brand,
        nextManufacturer: brand.manufacturer,
        slug: brand.slug,
      });
    }
    if (!dialogType) {
      this.setState({
        name: '',
        adapted_name: '',
        selectedBrand: null,
        dialogType: '',
        nextManufacturer: null,
      });
    }
  }
  editBrand =() => {
    this.props.editBrand(
      this.state.selectedBrand.id,
      this.state.name,
      this.state.slug,
      this.state.adapted_name,
      this.state.nextManufacturer.id,
    );
    this.toggleBrandDialog();
  }
  handleChangeFeild = (name, value) => this.setState({ [name]: value });
  addBrand = () => {
    this.props.addBrandWithUpdate(this.state.name, this.state.nextManufacturer.id);
    this.toggleBrandDialog();
  }
  filterData = data => {
    let filtredData = fuzzyFilter(this.state.filterString, data, fuzzyOptions);
    if (this.state.selectedManufacturer) {
      filtredData =
        filtredData.filter(brand => brand.manufacturer.id === this.state.selectedManufacturer.id);
    }
    return filtredData;
  }
  render() {
    return (
      <React.Fragment>
        {this.state.filterOpened && (
          <BrandsFilter
            handleChangeFeild={this.handleChangeFeild}
            filterString={this.state.filterString}
            manufacturers={this.props.manufacturers}
            selectedManufacturer={this.state.selectedManufacturer}
            setSelectedManufacturer={this.setSelectedManufacturer}
            fetchManufacturersIfNeeded={this.props.fetchManufacturersIfNeeded}
          />
        )}
        <Brands
          isFetching={this.props.isFetching}
          brands={this.filterData(this.props.brands)}
          toggleEditBrandDialog={brand => this.toggleBrandDialog('edit_brand', brand)}
          deleteAlias={this.props.deleteAlias}
        />
        <BrandsDialog
          type={this.state.dialogType}
          name={this.state.name}
          slug={this.state.slug}
          adapted_name={this.state.adapted_name}
          opened={this.state.brandDialogOpened}
          editBrand={this.editBrand}
          addBrand={this.addBrand}
          toggleDialog={() => this.toggleBrandDialog()}
          handleChangeFeild={this.handleChangeFeild}
          manufacturers={this.props.manufacturers}
          setSelectedManufacturer={nextManufacturer => this.setState({ nextManufacturer })}
          selectedManufacturer={this.state.nextManufacturer}
        />
      </React.Fragment>
    );
  }
}

BrandsContainer.propTypes = {
  getBrands: PropTypes.func.isRequired,
  editBrand: PropTypes.func.isRequired,
  fetchManufacturersIfNeeded: PropTypes.func.isRequired,
  setTopBarTitle: PropTypes.func.isRequired,
  deleteAlias: PropTypes.func.isRequired,
  setRightSideBarActions: PropTypes.func.isRequired,
  addBrandWithUpdate: PropTypes.func.isRequired,
  brands: PropTypes.array.isRequired,
  manufacturers: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = store => {
  const brandsState = store.get('brands');
  const manufacturersState = store.get('manufacturers');
  return {
    isFetching: brandsState.get('isFetching'),
    brands: brandsState.get('items').valueSeq().toArray(),
    manufacturers: manufacturersState.get('items').valueSeq().toArray(),
  };
};

export default withRouter(connect(
  mapStateToProps,
  {
    getBrands,
    editBrand,
    setTopBarTitle,
    fetchManufacturersIfNeeded,
    deleteAlias,
    addBrandWithUpdate,
    setRightSideBarActions,
  },
)(BrandsContainer));
