// @flow
import { API_HOST } from 'config';
import { get } from 'lib/request';
import queryString from 'query-string';

export const getCouriers = async () => {
  try {
    const response = await get(`${API_HOST}/couriers`).then(res => res.data);
    return response;
  } catch (error) {
    return Promise.reject(null);
  }
};
