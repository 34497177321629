import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import localStorage from 'lib/local-storage';

const styles = theme => ({
  root: {
  },
  progress: {
    height: '5px',
  },
  button: {
    width: '40px',
    height: '40px',
  },
  openedAliases: {
    backgroundColor: theme.palette.grey[200],
  },
  aliasesCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chip: {
    margin: '3px',
  },
});

class Brands extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'name',
    page: 0,
    rowsPerPage: 14,
    showAllAliases: {},
  };
  componentDidMount() {
    const rowsPerPage = localStorage.get('rowsPerPage');
    if (rowsPerPage) this.setState({ rowsPerPage });
  }
  sort = data => {
    if (this.state.order === 'desc') {
      return data.sort((a, b) => (b[this.state.orderBy] < a[this.state.orderBy] ? -1 : 1));
    }
    return data.sort((a, b) => (a[this.state.orderBy] < b[this.state.orderBy] ? -1 : 1));
  };
  handleRequestSort = property => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };
  handleChangePage = (event, page) => this.setState({ page });
  handleChangeRowsPerPage = event => {
    localStorage.set('rowsPerPage', event.target.value);
    return this.setState({ rowsPerPage: event.target.value });
  }
  showAllAliases = brandId =>
    this.setState(prev => ({
      showAllAliases: {
        ...prev,
        [brandId]: !prev.showAllAliases[brandId],
      },
    }));
  render() {
    const {
      classes,
      isFetching,
      brands,
      toggleEditBrandDialog,
      deleteAlias,
    } = this.props;
    const {
      order,
      orderBy,
      page,
      rowsPerPage,
      showAllAliases,
    } = this.state;
    const sortedData = this.sort(brands);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, sortedData.length - (page * rowsPerPage));
    return (
      <div className={classes.root}>
        <div className={classes.progress}>
          {isFetching &&
            <LinearProgress color="secondary" />
          }
        </div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell numeric sortDirection={orderBy === 'id' ? order : false}>
                  <Tooltip
                    title="Сортировать"
                    placement="bottom-start"
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === 'id'}
                      direction={order}
                      onClick={() => this.handleRequestSort('id')}
                    >
                      id
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell sortDirection={orderBy === 'name' ? order : false}>
                  <Tooltip
                    title="Сортировать"
                    placement="bottom-start"
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === 'name'}
                      direction={order}
                      onClick={() => this.handleRequestSort('name')}
                    >
                      Бренд
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell sortDirection={orderBy === 'manufacturer' ? order : false}>
                  <Tooltip
                    title="Сортировать"
                    placement="bottom-start"
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === 'manufacturer'}
                      direction={order}
                      onClick={() => this.handleRequestSort('manufacturer')}
                    >
                      Производитель
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell sortDirection={orderBy === 'adapted_name' ? order : false}>
                  <Tooltip
                    title="Сортировать"
                    placement="bottom-start"
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === 'adapted_name'}
                      direction={order}
                      onClick={() => this.handleRequestSort('adapted_name')}
                    >
                      Адаптированое название
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  aliases
                </TableCell>
                <TableCell numeric>
                  Действия
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData
                .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                .map(brand => (
                  <React.Fragment key={brand.id}>
                    <TableRow
                      hover
                      className={showAllAliases[brand.id] ? classes.openedAliases : null}
                    >
                      <TableCell numeric>{brand.id}</TableCell>
                      <TableCell>{brand.name}</TableCell>
                      <TableCell>{brand.manufacturer.name}</TableCell>
                      <TableCell>{brand.adapted_name}</TableCell>
                      <TableCell>
                        <div className={classes.aliasesCell}>
                          <span>
                            {!showAllAliases[brand.id] &&
                            brand.aliases.map((alias, index) => {
                              if (index > 1 && !showAllAliases[brand.id]) return null;
                              return (
                                <Chip
                                  className={classes.chip}
                                  key={alias.id}
                                  label={alias.value}
                                  onDelete={() => deleteAlias(alias.id)}
                                />
                              );
                            })}
                          </span>
                          <span />
                          {brand.aliases.length > 2 && !showAllAliases[brand.id] &&
                            <IconButton
                              className={classes.button}
                              color="primary"
                              size="small"
                              onClick={() => this.showAllAliases(brand.id)}
                            >
                              <ExpandMore />
                            </IconButton>
                          }
                          {showAllAliases[brand.id] &&
                          <IconButton
                            className={classes.button}
                            color="primary"
                            size="small"
                            onClick={() => this.showAllAliases(brand.id)}
                          >
                            <ExpandLess />
                          </IconButton>
                          }
                        </div>
                      </TableCell>
                      <TableCell numeric>
                        <IconButton
                          onClick={() => toggleEditBrandDialog(brand)}
                          className={classes.button}
                          color="primary"
                          size="small"
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    {showAllAliases[brand.id] &&
                      brand.aliases.map(alias => (
                        <TableRow
                          key={`all_${alias.id}`}
                          className={showAllAliases[brand.id] ? classes.openedAliases : null}
                        >
                          <TableCell numeric>{alias.id}</TableCell>
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell>
                            <Chip
                              className={classes.chip}
                              key={alias.id}
                              label={alias.value}
                              onDelete={() => deleteAlias(alias.id)}
                            />
                          </TableCell>
                          <TableCell numeric />
                        </TableRow>
                  ))}
                  </React.Fragment>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Брендов на странице"
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPageOptions={[14, 25, 50, sortedData.length]}
          />
        </Paper>
      </div>
    );
  }
}
Brands.propTypes = {
  classes: PropTypes.object.isRequired,
  brands: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  toggleEditBrandDialog: PropTypes.func.isRequired,
  deleteAlias: PropTypes.func.isRequired,
};

export default withStyles(styles)(Brands);
