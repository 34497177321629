// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import { IconButton } from '@material-ui/core';
import { Mood, MoodBad } from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const LikedComponent = ({ classes, liked, onChange }: {| ...Props, ...ProvidedProps |}) => {
  const handleOnChnage = () => {
    if (liked === true) onChange(false);
    if (liked === false) onChange(null);
    if (liked === null) onChange(true);
  };

  let title = '';
  if (liked === true) title = 'Лайк';
  if (liked === false) title = 'Дизлайк';
  if (liked === null) title = 'С отзывами и без';
  return (
    <div className={classes.root}>
      <IconButton onClick={handleOnChnage} title={title}>
        {liked === true && <Mood style={{ color: green[700] }} />}
        {liked === false && <MoodBad style={{ color: red[700] }} />}
        {liked === null && <Mood />}
      </IconButton>
    </div>
  );
};
export const Liked: ReactComponent<Props> = withStyles(styles)(LikedComponent);
