// @flow
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import { type Theme, type ReactComponent } from 'types/components';
import { fetchDeliveries, addOrderToDelivery } from 'services/deliveryApi';
import { addNotification } from 'components/notifications/actions';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Input,
  IconButton,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { connect } from 'react-redux';
import WithConfirm from 'components/with-confirm';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit,
    paddingBottom: 0,
  },
  form: {
    minWidth: '300px',
  },
  orders: {
    marginTop: theme.spacing.unit,
  },
  order: {
    marginRight: theme.spacing.unit,
    color: theme.palette.secondary[400],
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const UncompletedDeliveriesComponent = ({
  classes,
  order,
  onSave,
  addNotification,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const [deliveries, setDeliveries] = useState(null);
  const [selectedDelivery, setSelectedDelivery] = useState(null);

  useEffect(() => {
    getDeliveries();
  }, [order]);

  const getDeliveries = () => {
    if (order) {
      fetchDeliveries({ user: order.user.id }).then(res => {
        const withoutCurrent = res.sort((a, b) =>
          moment(a.delivery_period.start).isAfter(moment(b.delivery_period.start)) ? 1 : -1,
        );
        setDeliveries(withoutCurrent);
        setSelectedDelivery(withoutCurrent[0]);
      });
    }
  };

  const handleAddOrderToDelivery = () => {
    addOrderToDelivery({ deliveryId: selectedDelivery.id, order }).then(
      () => {
        getDeliveries();
        onSave();
      },
      errors => errors.forEach(error => addNotification(error.title)),
    );
  };

  const deliveryWithOrders =
    order &&
    deliveries &&
    deliveries.find(({ id }) => order.realization_method && order.realization_method.id === id);

  return (
    <div className={classes.root}>
      <FormControl className={classes.form}>
        <InputLabel htmlFor="deliveries" shrink>
          Невыполненные доставки клиента{' '}
          {deliveries && deliveries.length ? `(${deliveries.length})` : ''}
        </InputLabel>
        <Select
          value={selectedDelivery}
          inputProps={{
            id: 'deliveries',
          }}
          onChange={e => setSelectedDelivery(e.target.value)}
          renderValue={value =>
            `${moment(value.delivery_period.start).format('DD.MM.YYYY HH:mm')} ${
              value.address.street.name
            }, д. ${value.address.house}${value.address.flat ? `, кв. ${value.address.flat}` : ''}`
          }
        >
          {deliveries &&
            deliveries.map(delivery => (
              <MenuItem value={delivery} key={delivery.id}>
                <div className={classes.menuItem}>
                  <div>
                    {moment(delivery.delivery_period.start).format('DD.MM.YYYY HH:mm')}
                    {' - '}
                    {moment(delivery.delivery_period.end).format('HH:mm')} {delivery.address.line}
                  </div>
                  <div>Заказы: {delivery.orders.map(order => order.id).join(', ')}</div>
                </div>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <WithConfirm onConfirm={handleAddOrderToDelivery} confirmTitle="Добавить">
        <IconButton color="secondary" title="Добавить заказ к доставке">
          <Add />
        </IconButton>
      </WithConfirm>
      {Boolean(deliveryWithOrders && deliveryWithOrders.orders.length) && (
        <div className={classes.orders}>
          <Typography variant="title">
            Заказы в текущей доставке{' '}
            {deliveryWithOrders &&
              deliveryWithOrders.orders.map(order => (
                <a href={`/shop/order/${order.id}`} className={classes.order} target="_blank">
                  {order.id}
                </a>
              ))}
          </Typography>
        </div>
      )}
    </div>
  );
};

export const UncompletedDeliveries: ReactComponent<Props> = connect(() => ({}), {
  addNotification,
})(withStyles(styles)(UncompletedDeliveriesComponent));
