import React, { useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import LocalActivity from '@material-ui/icons/LocalActivity';
import { withStyles } from '@material-ui/core/styles';
import { orderApologize } from 'services/orderApi';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
    display: 'flex',
    fontSize: theme.typography.pxToRem(18),
  },
  popover: {
    padding: theme.spacing.unit * 2,
  },
});
type Props = {|
  classes: Object,
  onChange: () => void,
|};

const ApologizeCouponSendComponent = ({ orderId, classes, onChange }: Props) => {
  const handleClick = () => {
    orderApologize(orderId).then(() => onChange());
  };

  return (
    <IconButton
      className={classes.root}
      onClick={handleClick}
      title="Создать купон на скидку по заказ"
    >
      <LocalActivity />
    </IconButton>
  );
};

export const ApologizeCouponSend = withStyles(styles)(ApologizeCouponSendComponent);
