import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Edit from '@material-ui/icons/Edit';
import LinearProgress from '@material-ui/core/LinearProgress';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LocalOffer from '@material-ui/icons/LocalOffer';
import localStorage from 'lib/local-storage';

const styles = theme => ({
  aliasesCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  openedAliases: {
    backgroundColor: theme.palette.grey[200],
  },
  button: {
    width: '40px',
    height: '40px',
  },
  chip: {
    margin: '3px',
  },
  progress: {
    height: '5px',
  },
});

class Manufacturers extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'id',
    page: 0,
    rowsPerPage: 14,
    showAllAliases: {},
  };
  componentDidMount() {
    const rowsPerPage = localStorage.get('rowsPerPage');
    if (rowsPerPage) this.setState({ rowsPerPage });
  }
  handleChangePage = (event, page) => this.setState({ page });
  handleChangeRowsPerPage = event => {
    localStorage.set('rowsPerPage', event.target.value);
    return this.setState({ rowsPerPage: event.target.value });
  }
  sort = data => {
    if (this.state.order === 'desc') {
      return data.sort((a, b) => (b[this.state.orderBy] < a[this.state.orderBy] ? -1 : 1));
    }
    return data.sort((a, b) => (a[this.state.orderBy] < b[this.state.orderBy] ? -1 : 1));
  }
  handleRequestSort = property => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };
  showAllAliases = manufacturerId =>
    this.setState(prev => ({
      showAllAliases: {
        ...prev,
        [manufacturerId]: !prev.showAllAliases[manufacturerId],
      },
    }));
  render() {
    const {
      page,
      rowsPerPage,
      orderBy,
      order,
      showAllAliases,
    } = this.state;
    const {
      classes,
      manufacturers,
      deleteAlias,
      isFetching,
      toggleAddManufacturerDialog,
      history,
    } = this.props;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, manufacturers.length - (page * rowsPerPage));
    const sortedManufacturers = this.sort(manufacturers);
    return (
      <Paper className={classes.root}>
        <div className={classes.progress}>
          {isFetching &&
            <LinearProgress color="secondary" />
          }
        </div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell
                numeric
                sortDirection={orderBy === 'id' ? order : false}
              >
                <Tooltip
                  title="Сортировать"
                  placement="bottom-start"
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === 'id'}
                    direction={order}
                    onClick={() => this.handleRequestSort('id')}
                  >
                    id
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell
                sortDirection={orderBy === 'name' ? order : false}
                onClick={() => this.handleRequestSort('name')}
              >
                <Tooltip
                  title="Сортировать"
                  placement="bottom-start"
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={order}
                    onClick={() => this.handleRequestSort('name')}
                  >
                    Производитель
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell
                sortDirection={orderBy === 'slug' ? order : false}
                onClick={() => this.handleRequestSort('slug')}
              >
                <Tooltip
                  title="Сортировать"
                  placement="bottom-start"
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === 'slug'}
                    direction={order}
                    onClick={() => this.handleRequestSort('slug')}
                  >
                    slug
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell>Alias</TableCell>
              <TableCell numeric>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedManufacturers
              .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
              .map(manufacturer => (
                <React.Fragment key={manufacturer.id}>
                  <TableRow
                    hover
                    tabIndex={-1}
                    className={showAllAliases[manufacturer.id] ? classes.openedAliases : null}
                  >
                    <TableCell numeric>{manufacturer.id}</TableCell>
                    <TableCell>{manufacturer.name}</TableCell>
                    <TableCell>{manufacturer.slug}</TableCell>
                    <TableCell>
                      <div className={classes.aliasesCell}>
                        <span>
                          {manufacturer.aliases
                          && !showAllAliases[manufacturer.id]
                          && manufacturer.aliases.map((alias, index) => {
                            if (index > 1 && !showAllAliases[manufacturer.id]) return null;
                            return (
                              <Chip
                                className={classes.chip}
                                key={alias.id}
                                label={alias.value}
                                onDelete={() => deleteAlias(alias.id)}
                              />
                            );
                          })}
                        </span>
                        <span />
                        {manufacturer.aliases
                          && manufacturer.aliases.length > 2
                          && !showAllAliases[manufacturer.id] &&
                          <IconButton
                            className={classes.button}
                            color="primary"
                            size="small"
                            onClick={() => this.showAllAliases(manufacturer.id)}
                          >
                            <ExpandMore />
                          </IconButton>
                        }
                        {showAllAliases[manufacturer.id] &&
                        <IconButton
                          className={classes.button}
                          color="primary"
                          size="small"
                          onClick={() => this.showAllAliases(manufacturer.id)}
                        >
                          <ExpandLess />
                        </IconButton>
                        }
                      </div>
                    </TableCell>
                    <TableCell numeric>
                      <IconButton
                        onClick={() => history.push(`/brands?manufacturer=${manufacturer.id}`)}
                        className={classes.button}
                        color="primary"
                        size="small"
                        title="Бренды"
                      >
                        <LocalOffer />
                      </IconButton>
                      <IconButton
                        onClick={() => toggleAddManufacturerDialog(manufacturer)}
                        className={classes.button}
                        color="primary"
                        size="small"
                        title="Редактировать"
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {manufacturer.aliases
                    && showAllAliases[manufacturer.id]
                    && manufacturer.aliases.map(alias => (
                    <TableRow
                      key={`all_${alias.id}`}
                      className={showAllAliases[manufacturer.id] ? classes.openedAliases : null}
                    >
                      <TableCell numeric>{alias.id}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell>
                        <Chip
                          className={classes.chip}
                          key={alias.id}
                          label={alias.value}
                          onDelete={() => deleteAlias(alias.id)}
                        />
                      </TableCell>
                      <TableCell numeric />
                    </TableRow>
                  ))}
                </React.Fragment>
                ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={manufacturers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Производителей на странице"
          backIconButtonProps={{
            'aria-label': 'Предыдущая страница',
          }}
          nextIconButtonProps={{
            'aria-label': 'Следующая страница',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          rowsPerPageOptions={[14, 25, 50, manufacturers.length]}
        />
      </Paper>
    );
  }
}

Manufacturers.propTypes = {
  classes: PropTypes.object.isRequired,
  manufacturers: PropTypes.array.isRequired,
  deleteAlias: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  toggleAddManufacturerDialog: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles)(Manufacturers);
