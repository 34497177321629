import {
  ADD_NOTIFICATION,
  CLOSE_NOTIFICATION,
} from 'types/actions';
import Immutable from 'immutable';

const notificationsInitialState = Immutable.Map({
  items: Immutable.List(),
});

const notifications = (state = notificationsInitialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return state.updateIn(['items'], list =>
        list.push({ message: action.notification }));
    }
    case CLOSE_NOTIFICATION: {
      return state.updateIn(['items'], list => list.pop());
    }
    default: {
      return state;
    }
  }
};

export default notifications;
