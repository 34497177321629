// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { type Theme, type ReactComponent } from 'types/components';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: Object,
|};

type Props = {|
  classes: any,
  query: string,
  onChange: (query: string) => void,
|};

const FilterInputComponent = ({ classes, query, onChange }: {| ...Props, ...ProvidedProps |}) => {
  const handleChange = e => onChange(e.target.value);
  return (
    <div className={classes.root}>
      <TextField fullWidth value={query} onChange={handleChange} margin="none" placeholder="Название" />
    </div>
  );
};
export const FilterInput: ReactComponent<Props> = withStyles(styles)(FilterInputComponent);
