// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import ProductPreview from 'components/product-preview';
import Grid from '@material-ui/core/Grid';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import moment from 'moment';
import cn from 'classnames';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  CartesianAxis,
} from 'recharts';
import { CompetitorColor } from '../CompetitorColor';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
  item: {
    marginBottom: theme.spacing.unit,
    '&:nth-child(even)': {
      backgroundColor: theme.palette.grey[200],
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  columns: {
    display: 'flex',
  },
  percent: {
    top: 0,
    marginLeft: '6px',
    position: 'absolute',
    fontSize: theme.typography.pxToRem(11),
    fontWeight: 700,
    whiteSpace: 'pre',
  },
  abbr: {
    fontSize: theme.typography.pxToRem(12),
    borderRadius: '3px',
    padding: '3px',
    width: '33px',
    height: '22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    color: theme.palette.grey[700],
  },
  pricesHistoryItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cheaper: {
    color: green[700],
  },
  expensive: {
    color: red[700],
  },
  pricesHistory: {
    borderLeft: `1px solid ${theme.palette.grey[400]}`,
    width: '135px',
    flexGrow: 0,
    height: '100%',
    padding: '3px',
    flexShrink: 0,
  },
  price: {
    whiteSpace: 'pre',
    maxWidth: '60px',
    fontSize: theme.typography.pxToRem(12),
    position: 'relative',
    textAlign: 'right',
    padding: '3px',
    margin: '3px',
    lineHeight: 1,
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid transparent',
    marginRight: '35px',
  },
  productName: {
    fontSize: theme.typography.pxToRem(12),
  },
  productBarcode: {
    fontSize: theme.typography.pxToRem(12),
  },
  productBlock: {
    position: 'sticky',
    left: 0,
    backgroundColor: '#FAFAFA',
    zIndex: 1,
  },
  // product: {
  //   position: 'sticky',
  //   left: 0,
  //   backgroundColor: '#FAFAFA'
  // },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const distinctByHost = prices => {
  const providedHostsSet = new Set(prices.map(({ host }) => host));

  const hostsArray = Array.from(providedHostsSet);

  return hostsArray && hostsArray.map(host => prices.find(item => item.host === host));
};

const MonitoringItemComponent = ({
  classes,
  monitoringItem,
  dates,
  chart,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const renderPercent = (index, prices) => {
    if (prices.length < 2) return null;

    if (index === 0) {
      return (
        <span className={classes.cheaper}>{`- ${Math.round(
          (prices[1].value - prices[0].value) / (prices[1].value / 100),
        )}%`}</span>
      );
    }

    return (
      <span className={classes.expensive}>{`+ ${Math.round(
        (prices[index].value - prices[0].value) / (prices[index].value / 100),
      )}%`}</span>
    );
  };
  const hostsSet = new Set();

  monitoringItem.priceHistory.forEach(item => hostsSet.add(item.host));

  const hostsArray = Array.from(hostsSet);

  return (
    <Grid container className={classes.item} spacing={4} key={monitoringItem.product.id}>
      <Grid item xs={12} sm={3} className={classes.productBlock}>
        <div className={classes.product}>
          <ProductPreview
            manufacturer={false}
            series={false}
            type={false}
            product={monitoringItem.product}
            classes={{
              name: classes.productName,
              barcode: classes.productBarcode,
            }}
          />
        </div>
      </Grid>

      <Grid item xs={12} sm={9} className={classes.columns}>
        {chart && (
          <LineChart width={1200} height={150} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <XAxis dataKey="create_time" type="category" allowDuplicatedCategory={false} />
            <YAxis dataKey="value" reversed />
            <CartesianGrid width="5" height="5"/>
            <Tooltip itemSorter={() => -1} />
            {hostsArray.map(host => (
              <Line
                strokeWidth={2}
                key={host}
                dataKey="value"
                stroke={CompetitorColor.find(item => item.host === host).bgColor}
                name={host}
                data={monitoringItem.priceHistory
                  .filter(item => item.host === host && item.value)
                  .sort((a, b) => (a.create_time > b.create_time ? 1 : -1))
                  .map(item => ({
                    ...item,
                    create_time: moment(item.create_time).format('DD.MM.YYYY'),
                  }))}
              />
            ))}
          </LineChart>
        )}
        {!chart &&
          dates.map(date => {
            const filteredByDate =
              monitoringItem.priceHistory &&
              monitoringItem.priceHistory.filter(
                historyItem => moment(historyItem.create_time).format('YYYY-MM-DD') === date && historyItem.value,
              );

            const distinctedByHost =
              filteredByDate && distinctByHost(filteredByDate).sort((a, b) => a.value - b.value);

            return (
              <div key={date} className={cn(classes.pricesHistory)}>
                {distinctedByHost &&
                  distinctedByHost.map((price, index) => {
                    const colors = CompetitorColor.find(item => price.host === item.host) || {
                      bgColor: '#fff',
                      textColor: '#000',
                      abbr: price.host.substring(0, 3),
                    };
                    return (
                      <div
                        key={price.host}
                        className={cn(classes.pricesHistoryItem)}
                        title={price.host}
                      >
                        <div>
                          <div
                            className={classes.abbr}
                            style={
                              price.host === 'bonjour-dv.ru'
                                ? {
                                    color: '#fff',
                                    backgroundColor: '#E90024',
                                  }
                                : null
                            }
                          >
                            {colors.abbr}
                          </div>
                        </div>
                        <div className={cn(classes.price)}>
                          {price.value} ₽
                          {price.host === 'bonjour-dv.ru' && (
                            <span className={classes.percent}>
                              {renderPercent(index, distinctedByHost)}
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </Grid>
    </Grid>
  );
};
export const MonitoringItem: ReactComponent<Props> = withStyles(styles)(MonitoringItemComponent);
