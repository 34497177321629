// @flow
import { type ThunkAction, type Dispatch } from 'types/actions';
import {
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  type RequestOrders,
  type ReceiveOrders,
} from 'types/actions/Order';
import { type Order } from 'types/entities/Order';
import { fetchOrders as fetchOrdersFromApi } from 'services/orderApi';
import { addNotification } from 'components/notifications/actions';

export const requestOrders = (): RequestOrders => ({
  type: FETCH_ORDERS_REQUEST,
});

export const receiveOrders = (ordersRes: {
  data: Array<Order>,
  total_count: number,
} | null): ReceiveOrders => ({
  type: FETCH_ORDERS_SUCCESS,
  orders: ordersRes && ordersRes.data,
  totalCount: (ordersRes && ordersRes.total_count) || 0,
});

export const fetchOrders = (query: any): ThunkAction => (dispatch: Dispatch) => {
  dispatch(requestOrders());
  return fetchOrdersFromApi(query).then(
    ordersRes => dispatch(receiveOrders(ordersRes)),
    () => {
      dispatch(addNotification('Неудачная попытка загрузки заказов'));
    },
  );
};
