import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Settings from '@material-ui/icons/Settings';
import LocalOffer from '@material-ui/icons/LocalOffer';
import Search from '@material-ui/icons/Search';
import CallSplit from '@material-ui/icons/CallSplit';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import AttachMoney from '@material-ui/icons/AttachMoney';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import LocalShipping from '@material-ui/icons/LocalShipping';
import Tune from '@material-ui/icons/Tune';
import Timeline from '@material-ui/icons/Timeline';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Store from '@material-ui/icons/Store';
import Star from '@material-ui/icons/Star';
import ViewModule from '@material-ui/icons/ViewModule';
import ViewComfy from '@material-ui/icons/ViewComfy';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import Message from '@material-ui/icons/Message';
import Schedule from '@material-ui/icons/Schedule';
import classNames from 'classnames';
import { withWidth } from '@material-ui/core';
import { SupervisorAccount } from '@material-ui/icons';

export const LeftSideBardWidth = 210;

const styles = (theme) => ({
  selectedRoute: {
    backgroundColor: theme.palette.primary[100],
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
  },
  drawerPaper: {
    position: 'relative',
    width: LeftSideBardWidth,
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  toolbar: {
    padding: '8px',
  },
});

const captionStyle = { fontWeight: 500, fontSize: '13px', marginTop: '-5px' };

const listItems = [
  {
    type: 'externalLink',
    link: 'https://product.bonjour-dv.ru',
    name: 'Продукт',
    icon: <ShoppingBasket />,
  },
  // {
  //   type: 'link',
  //   link: '/undefined-manufacturers',
  //   name: 'Новые производители',
  //   icon: (
  //     <span>
  //       <Settings />
  //       <span style={captionStyle}>new</span>
  //     </span>
  //   ),
  // },
  // {
  //   type: 'link',
  //   link: '/undefined-brands',
  //   name: 'Новые бренды',
  //   icon: (
  //     <span>
  //       <LocalOffer />
  //       <span style={captionStyle}>new</span>
  //     </span>
  //   ),
  // },
  // { type: 'divider', link: 'divider1', icon: <Settings /> },
  // {
  //   type: 'link',
  //   link: '/manufacturers',
  //   name: 'Производители',
  //   icon: <Settings />,
  // },
  // {
  //   type: 'link',
  //   link: '/brands',
  //   name: 'Бренды',
  //   icon: <LocalOffer />,
  // },
  // {
  //   type: 'link',
  //   link: '/series',
  //   name: 'Серии',
  //   icon: <CallSplit />,
  // },
  // {
  //   type: 'externalLink',
  //   link: 'https://product.bonjour-dv.ru/product-types',
  //   name: 'Типы товаров',
  //   icon: <ViewComfy />,
  // },
  {
    type: 'link',
    link: '/products',
    name: 'Продукты',
    icon: <Search />,
  },
  {
    type: 'link',
    link: '/categories',
    name: 'Категории',
    icon: <FormatListNumbered />,
  },
  // {
  //   type: 'externalLink',
  //   link: 'https://product.bonjour-dv.ru/attributes',
  //   name: 'Характеристики',
  //   icon: <Tune />,
  // },
  {
    type: 'externalLink',
    link: 'https://crm.bonjour-dv.ru/users',
    name: 'Пользователи',
    icon: <SupervisorAccount />,
  },
  {
    type: 'externalLink',
    link: 'https://product.bonjour-dv.ru/reviews',
    name: 'Отзывы',
    icon: <Message />,
  },
  { type: 'divider', link: 'divider2', icon: <Settings /> },
  // {
  //   type: 'link',
  //   link: '/suppliers',
  //   name: 'Поставщики',
  //   icon: <LocalShipping />,
  // },
  {
    type: 'externalLink',
    link: 'https://crm.bonjour-dv.ru/warehouses',
    name: 'Склады',
    icon: <Store />,
  },
  {
    type: 'link',
    link: '/shop/warehouses-delivery-schedules',
    name: 'Расписания складов',
    icon: (
      <div style={{ position: 'relative' }}>
        <Store />{' '}
        <Schedule
          style={{
            top: '-10px',
            right: '-10px',
            position: 'absolute',
            width: '16px',
            height: '16px',
          }}
        />
      </div>
    ),
  },
  { type: 'divider', link: 'divider3', icon: <Settings /> },
  {
    type: 'link',
    link: '/markup',
    name: 'Наценки',
    icon: <AttachMoney />,
  },
  {
    type: 'link',
    link: '/shop/promotions?present=1',
    name: 'Акции',
    icon: <Star />,
  },
  { type: 'divider', link: 'divider4', icon: <Settings /> },
  {
    type: 'link',
    link: '/shop/orders',
    name: 'Заказы',
    icon: <ShoppingCart />,
  },
  {
    type: 'link',
    link: '/shop/deliveries',
    name: 'Доставки',
    icon: <LocalShipping />,
  },
  {
    type: 'link',
    link: '/reports/list',
    name: 'Отчеты',
    icon: <Timeline />,
  },
];

const LeftSideBar = ({ classes, location, toggleLeftSideBar, opened, width }) => (
  <Drawer
    anchor="left"
    variant={['md', 'lg', 'xl'].includes(width) ? 'permanent' : null}
    classes={{
      paper: classNames(classes.drawerPaper, !opened && classes.drawerPaperClose),
    }}
    open={opened}
  >
    <div className={classes.toolbar}>
      <IconButton onClick={toggleLeftSideBar}>
        <ChevronLeft />
      </IconButton>
    </div>
    <Divider />
    <List>
      {listItems.map((item) => {
        if (item.type === 'divider') return <Divider key={item.link} />;
        if (item.type === 'link') {
          return (
            <ListItem
              title={item.name}
              button
              dense
              key={item.link}
              component={Link}
              to={item.link}
              className={location.pathname === item.link ? classes.selectedRoute : null}
            >
              <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          );
        }
        if (item.type === 'externalLink') {
          return (
            <ListItem
              title={item.name}
              button
              dense
              key={item.link}
              href={item.link}
              target="_blank"
              component="a"
              className={location.pathname === item.link ? classes.selectedRoute : null}
            >
              <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          );
        }
        return null;
      })}
    </List>
  </Drawer>
);

LeftSideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  toggleLeftSideBar: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
};

export default withWidth()(withStyles(styles)(LeftSideBar));
