import { SET_TOP_BAR_TITLE } from 'types/actions';
import Immutable from 'immutable';

const topBarInitialState = Immutable.Map({
  title: '',
});

const topBar = (state = topBarInitialState, action) => {
  switch (action.type) {
    case SET_TOP_BAR_TITLE: {
      return state.set('title', action.title);
    }
    default: {
      return state;
    }
  }
};

export default topBar;
