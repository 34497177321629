// @flow
// @flow-runtime enable
import { type ProductName } from './ProductName';
import { type Barcode } from './Barcode';
import { type Brand } from './Brand';
import { type typeCategory } from './typeCategory';
import { type Manufacturer } from './Manufacturer';
import { type ProductImage } from './ProductImage';
import { type typeStockItem } from './typeStockItem';
import { type typeProductType } from './typeProductType';
import { type typeProductSeries } from './typeProductSeries';

export type Product = {
  id: number,
  name?: ProductName,
  title: string,
  names?: Array<ProductName>,
  barcode: Barcode,
  barcodes?: Array<Barcode>,
  brand: Brand | null,
  preview?: ProductImage | null,
  images?: Array<ProductImage> | null,
  category: typeCategory | null,
  manufacturer: Manufacturer | null,
  offer_price: number | null,
  base_price: number | null,
  slug?: string | null,
  created_time?: number,
  stock_items?: Array<typeStockItem>,
  quantity: number,
  new: boolean,
  moderated?: boolean,
  description?: string | null,
  how_to_use?: string | null,
  ingredients?: string | null,
  type?: typeProductType | null,
  series?: typeProductSeries | null,
  composite_name?: string | null,
  composite_name_type?: string | null,
  composite_name_series?: string | null,
  composite_name_extended?: string | null,
  composite_name_properties?: string | null,
};
