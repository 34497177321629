// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';
import { type Address } from 'types/entities/Address';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';


const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
  proggress: {
    display: 'flex',
    justifyContent: 'center',
  },
});
type Props = {|
  classes: Object,
  open: boolean,
  address: string | null,
  isFetching: boolean,
  suggestedAddresses: $FlowFixMe | null,
  handleChange: (name: string, value: any) => void,
  changeAddressValue: (query: string) => void,
  onSelect: (query: string) => void,
  onClose: () => void,
|};

const AddressDialog = ({
  classes,
  open,
  suggestedAddresses,
  isFetching,
  changeAddressValue,
  onClose,
  handleChange,
  address,
  onSelect,
}: Props) => (
  <Dialog
    fullWidth
    maxWidth="sm"
    className={classes.root}
    open={open}
    onClose={onClose}
  >
    <DialogContent>
      <TextField
        fullWidth
        autoFocus
        id="standard-name"
        label="Адрес"
        margin="none"
        value={address || ''}
        onChange={e => {
          if (e.target.value) {
            changeAddressValue(e.target.value);
            handleChange('addressesIsFetching', true);
          }
          handleChange('address', e.target.value);
          handleChange('suggestedAddresses', null);
        }}
      />
    </DialogContent>
    <List className={classes.list}>
      {isFetching && !suggestedAddresses && (
      <ListItem className={classes.proggress}>
        <CircularProgress size={24} />
      </ListItem>
      )}
      {suggestedAddresses && suggestedAddresses.map(item => {
        if (!item.data.house) return null;
        return (
          <ListItem
            key={item.data.fias_id}
            button
            onClick={onSelect(item.value)}
            divider
          >
            <ListItemText primary={item.value} />
          </ListItem>
        );
      })}

    </List>
    <DialogActions>
      <Button
        onClick={onClose}
      >
        Закрыть
      </Button>
    </DialogActions>
  </Dialog>
);

AddressDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddressDialog);
