// @flow
import { type ThunkAction, type Dispatch } from 'types/actions';
import { addNotification } from 'components/notifications/actions';
import { type Order } from 'types/entities/Order';
import {
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
  type RequestOrder,
  type ReceiveOrder,
} from 'types/actions/Order';
import {
  fetchOrder as fetchOrderFromApi,
  changeOrderState as changeOrderStateByApi,
  changeOrderLineQuantity as changeOrderLineQuantityByApi,
  addProduct as addProductByApi,
  removeOrderLine as removeOrderLineByApi,
  changeOrder as changeOrderByApi,
} from 'services/orderApi';
import { type OrderState } from 'types/entities/OrderState';
import { type Product } from 'types/entities/Product';

export const requestOrder = (): RequestOrder => ({
  type: FETCH_ORDER_REQUEST,
});

export const receiveOrder = (order: Order | null): ReceiveOrder => ({
  type: FETCH_ORDER_SUCCESS,
  order,
});

export const fetchOrder = (orderId: number): ThunkAction => (dispatch: Dispatch) => {
  dispatch(requestOrder());
  return fetchOrderFromApi(orderId).then(order => dispatch(receiveOrder(order)));
};

export const changeOrderState = (orderId: number, state: OrderState): ThunkAction => (
  dispatch: Dispatch,
) =>
  changeOrderStateByApi(orderId, state).then(
    () => dispatch(fetchOrder(orderId)),
    message => dispatch(addNotification(message)),
  );

export const changeOrder = (orderId: number, data: any): ThunkAction => (dispatch: Dispatch) =>
  changeOrderByApi(orderId, data).then(
    () => dispatch(fetchOrder(orderId)),
    message => dispatch(addNotification(message)),
  );
export const addProduct = (product: Product, orderId: number): ThunkAction => (
  dispatch: Dispatch,
) => addProductByApi(product, orderId).then(() => dispatch(fetchOrder(orderId)));

export const removeOrderLine = (orderId: number, lineId: number): ThunkAction => (
  dispatch: Dispatch,
) => removeOrderLineByApi(orderId, lineId).then(() => dispatch(fetchOrder(orderId)));

export const changeOrderLineQuantity = (
  orderId: number,
  lineId: number,
  quantity: number,
): ThunkAction => (dispatch: Dispatch) =>
  changeOrderLineQuantityByApi(orderId, lineId, quantity).then(() => dispatch(fetchOrder(orderId)));
