// @flow
import { type ThunkAction, type Dispatch } from 'types/actions';
import { type Warehouse } from 'types/entities/Warehouse';
import {
  FETCH_WAREHOUSE_REQUEST,
  FETCH_WAREHOUSE_SUCCESS,
  type RequestWarehouse,
  type ReceiveWarehouse,
} from 'types/actions/Warehouse';
import {
  fetchWarehouse as fetchWarehouseFromApi,
  putWarehouse as putWarehouseByApi,
} from 'services/warehouseApi';

export const requestWarehouse = (): RequestWarehouse => ({
  type: FETCH_WAREHOUSE_REQUEST,
});

export const receiveWarehouse = (
  warehouse: Warehouse | null,
): ReceiveWarehouse => ({
  type: FETCH_WAREHOUSE_SUCCESS,
  warehouse,
});

export const fetchWarehouse = (warehouseId: number): ThunkAction => (
  dispatch: Dispatch,
) => {
  dispatch(requestWarehouse());
  return fetchWarehouseFromApi(warehouseId).then(warehouse =>
    dispatch(receiveWarehouse(warehouse)));
};

export const putWarehouse = (warehouseId: number, warehouse: Warehouse): ThunkAction => (
  dispatch: Dispatch,
) =>
  putWarehouseByApi(warehouseId, warehouse).then(() =>
    dispatch(fetchWarehouse(warehouseId)));

