// @flow
import React, { useEffect, useState } from 'react';
import { type ReactComponent } from 'types/components';
import { setRightSideBarActions } from 'components/right_side_bar/actions';
import { connect } from 'react-redux';
import { Attributes } from './Attributes';
import { AddCircle } from '@material-ui/icons';

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const initNewAttribute = { name: null, type: 'string' };

const AttributesPageComponent = ({ setRightSideBarActions }: {| ...Props, ...ProvidedProps |}) => {
  const [openAddAttributeDialog, setOpenAddAttributeDialog] = useState(false);

  const actionsForRightSideBar = [
    {
      key: 'addAttributeDialogOpened',
      icon: <AddCircle />,
      name: 'Добавить характеристику',
      action: () => setOpenAddAttributeDialog(true),
    },
  ];

  useEffect(() => {
    setRightSideBarActions(actionsForRightSideBar);
  }, []);

  return <Attributes openAddAttributeDialog={openAddAttributeDialog} setOpenAddAttributeDialog={setOpenAddAttributeDialog}/>;
};

export const AttributesPage: ReactComponent<Props> = connect(undefined, {
  setRightSideBarActions,
})(AttributesPageComponent);
