// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { type Warehouse } from 'types/entities/Warehouse';
import { withRouter } from 'react-router-dom';
import {
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  Link,
} from '@material-ui/core';
import { Store } from '@material-ui/icons';

const styles = theme => ({
  a: {
    color: theme.palette.secondary[800],
    textDecoration: 'none',
    fontSize: theme.typography.pxToRem(16),
  },
  actions: {
    minWidth: '200px',
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.grey[700],
  },
});
type Props = {|
  classes: Object,
  history: Object,
  location: Object,
  match: Object,
  staticContext: any,
  warehouses: Array<Warehouse> | null,
  onSelect?: (store: Warehouse) => void,
|};

const WarehousesList = ({ classes, warehouses, onSelect, history }: Props) => {
  const handleClick = (warehouse: Warehouse) => () => {
    if (onSelect) return onSelect(warehouse);
    return history.push(`/shop/warehouse/${warehouse.id}`);
  };
  return (
    <List className={classes.root} disablePadding>
      {warehouses &&
        warehouses
          .sort((a, b) => a.id - b.id)
          .map(warehouse => (
            <ListItem dense divider key={warehouse.id}>
              <div>
                <ListItemText
                  primary={
                    <a
                      href={`/shop/warehouse/${warehouse.id}`}
                      target="_blank"
                      className={classes.a}
                    >
                      {warehouse.name}
                    </a>
                  }
                  secondary={
                    <div>
                      <div>{warehouse.address.line}</div>
                      <div title="Sberbank merchant">
                        {warehouse.sberbank_merchant && warehouse.sberbank_merchant.login}
                      </div>
                    </div>
                  }
                />
              </div>
              <ListItemSecondaryAction>
                <div className={classes.actions}>
                  {warehouse.is_selling && <div>Участвует в продажах</div>}
                  {warehouse.is_pricing_participant && <div>Участвует в ценообразовнии</div>}
                  {warehouse.is_assembly_point && <div>Участвует в комплектации</div>}
                  {warehouse.is_pick_point && <div>Самовывоз</div>}
                  {warehouse.is_online_payment && <div>Онлайн оплата</div>}
                </div>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
    </List>
  );
};

WarehousesList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(WarehousesList));
