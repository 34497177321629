// @flow
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import {
  getAttributes as getAttributesFromApi,
  createAttribute,
  deleteAttribute,
  createAttributeItem,
} from 'services/attributeApi';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Paper,
  TextField,
  Select,
  FormControl,
  InputLabel,
  DialogTitle,
  Fab,
  Typography,
  Chip,
} from '@material-ui/core';
import { setRightSideBarActions } from 'components/right_side_bar/actions';
import { connect } from 'react-redux';
import { Add, AddCircle } from '@material-ui/icons';
import WithConfirm from 'components/with-confirm';
import { Attribute } from './Attribute';
import cn from 'classnames';
import fuzzyFilter from 'components/fuzzy_filter';

const fuzzyOptions = {
  extract: el => el.name,
  returnAllOnEmptyString: true,
  returnOriginal: true,
};

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
  item: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    '&:hover': {
      boxShadow: theme.shadows[6],
    },
  },
  button: {
    cursor: 'pointer',
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const initNewAttribute = { name: null, type: 'string' };

const AttributesComponent = ({
  classes,
  onClick,
  edit = true,
  openAddAttributeDialog,
  setOpenAddAttributeDialog,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemName, setItemName] = useState('');

  const [attributes, setAttributes] = useState(null);
  const [newAttribute, setNewAttribute] = useState(initNewAttribute);
  const [query, setQuery] = useState('');

  const getAttributes = () => getAttributesFromApi().then(data => setAttributes(data));

  useEffect(() => {
    getAttributes();
  }, []);

  const handleCreate = () => {
    createAttribute(newAttribute).then(() => {
      setNewAttribute(initNewAttribute);
      getAttributes();
      setOpenAddAttributeDialog(false);
    });
  };
  const filteredAttibutes = fuzzyFilter(query, attributes, fuzzyOptions);
  return (
    <div className={classes.root}>
      <Dialog open={openAddAttributeDialog} onClose={() => setOpenAddAttributeDialog(false)}>
        <DialogContent>
          <TextField
            label="Название"
            fullWidth
            value={newAttribute.name}
            onChange={e => setNewAttribute({ ...newAttribute, name: e.target.value })}
          />
          <FormControl className={classes.root}>
            <InputLabel htmlFor="type">Тип</InputLabel>
            <Select
              inputProps={{
                id: 'type',
              }}
              value={newAttribute.type}
              onChange={e => setNewAttribute({ ...newAttribute, type: e.target.value })}
            >
              <MenuItem value="bool">bool</MenuItem>
              <MenuItem value="numeric">numeric</MenuItem>
              <MenuItem value="string">string</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddAttributeDialog(false)}>Отмена</Button>
          <Button variant="raised" color="secondary" onClick={handleCreate}>
            Создать
          </Button>
        </DialogActions>
      </Dialog>
      <TextField label="Фильтр" fullWidth value={query} onChange={e => setQuery(e.target.value)} />
      {filteredAttibutes &&
        filteredAttibutes
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map(item => (
            <Paper
              key={item.id}
              className={cn(classes.item, onClick && classes.button)}
              onClick={() => onClick && onClick(item)}
            >
              <Attribute item={item} onChange={getAttributes} edit={edit} />
            </Paper>
          ))}
    </div>
  );
};

export const Attributes: ReactComponent<Props> = withStyles(styles)(AttributesComponent);
