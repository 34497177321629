// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Promotion from 'components/shop/promotion';
import idx from 'idx';
import { withRouter } from 'react-router-dom';
import { fetchPromotion } from 'components/shop/promotion/actions';

type Props = {|
  fetchPromotion: (promotionId: number) => void,
  promotionId: number | null | void,
  history: any,
  location: any,
  match: any,
  staticContext: any,
|};

export class PromotionPageContainer extends React.Component<Props> {
  componentDidMount() {
    if (idx(this.props.match, _ => _.params.promotionId)) {
      this.props.fetchPromotion(Number(idx(this.props.match, _ => _.params.promotionId)));
    }
  }

  render() {
    return (
      <Promotion />
    );
  }
}

PromotionPageContainer.propTypes = {
  promotionId: PropTypes.any,
  fetchPromotion: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default withRouter(connect(
  mapStateToProps,
  { fetchPromotion },
)(PromotionPageContainer));
