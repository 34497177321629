// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { type Theme, type ReactComponent } from 'types/components';
import TextField from '@material-ui/core/TextField';

const styles = (theme: Theme) => ({
  button: {
    color: theme.palette.secondary.main,
    cursor: 'pointer,',
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  onChange: (value: string) => any,
  value?: string | null,
  placeholder?: string,
|};

type State = {|
  open: boolean,
  changedValue: string | null,
|};

const TextBlockComponent = ({ classes, value, onChange, placeholder }: {| ...Props, ...ProvidedProps |}) => {
  const [state, setState]: [State, any] = useState({ open: false, changedValue: null });
  const handleOnCancel = () => setState({ changedValue: null, open: false });
  const handleOnSave = () => {
    if (state.changedValue || state.changedValue === '') {
      onChange(state.changedValue);
      handleOnCancel();
    }
  };
  return (
    <div>
      {!state.open && (
        <div
          className={classes.button}
          onClick={() => setState({ changedValue: null, open: true })}
        >
          <div>
            {value &&
              value.split(/\n/).map(line => (
                <span key={line}>
                  {line}
                  <br />
                </span>
              ))}
          </div>
        </div>
      )}
      {(state.open || !value) && (
        <React.Fragment>
          <TextField
            disabled
            fullWidth
            margin="none"
            placeholder={placeholder}
            value={state.changedValue !== null ? state.changedValue : value || ''}
            multiline
            onChange={e => setState({ ...state, changedValue: e.target.value })}
          />
          {/* <DialogActions>
            {!value && <Button onClick={handleOnCancel}>Отмена</Button>}
            <Button color="secondary" onClick={handleOnSave}>
              Сохранить
            </Button>
          </DialogActions> */}
        </React.Fragment>
      )}
    </div>
  );
};
export const TextBlock: ReactComponent<Props> = withStyles(styles)(TextBlockComponent);
