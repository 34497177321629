import {
  FETCH_MANUFACTURERS_MARKUPS_REQUEST,
  FETCH_MANUFACTURERS_MARKUPS_SUCCESS,
  FETCH_BRANDS_MARKUPS_REQUEST,
  FETCH_BRANDS_MARKUPS_SUCCESS,
} from 'types/actions';
import Immutable from 'immutable';

const markupsInitialState = Immutable.Map({
  manufacturersIsFetching: false,
  manufacturers: null,
  brandsIsFetching: false,
  brands: null,
});

const markups = (state = markupsInitialState, action) => {
  switch (action.type) {
    case FETCH_MANUFACTURERS_MARKUPS_REQUEST: {
      return state.set('manufacturersIsFetching', true);
    }
    case FETCH_MANUFACTURERS_MARKUPS_SUCCESS: {
      return state.merge({
        manufacturersIsFetching: false,
        manufacturers: Immutable.Map(action.markups),
      });
    }
    case FETCH_BRANDS_MARKUPS_REQUEST: {
      return state.set('brandsIsFetching', true);
    }
    case FETCH_BRANDS_MARKUPS_SUCCESS: {
      return state.merge({
        brandsIsFetching: false,
        brands: Immutable.Map(action.markups),
      });
    }
    default: {
      return state;
    }
  }
};

export default markups;
