import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { logout } from 'services/userApi';
import { RightSideBardWidth } from '../../right_side_bar/view/RightSideBar';
import { LeftSideBardWidth } from '../../left_side_bar/view/LeftSideBar';

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logoLink: {
    textDecoration: 'none',
    color: theme.palette.background.default,
    marginLeft: theme.spacing.unit * 2,
  },
  hide: {
    display: 'none',
  },
  menuButton: {
    marginLeft: '12px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '12px',
    paddingRight: '10px',
  },
  appBarShiftRight: {
    marginRight: RightSideBardWidth,
    width: `calc(100% - ${RightSideBardWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftLeft: {
    marginLeft: LeftSideBardWidth,
    width: `calc(100% - ${LeftSideBardWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  leftBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  rightBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  exit: {
    color: '#fff',
    marginLeft: theme.spacing.unit,
  },
});

const TopBar = ({
  classes,
  rightSideBarOpened,
  leftSideBarOpened,
  toggleRightSideBar,
  toggleLeftSideBar,
  actions,
  title,
  user,
}) => (
  <div className={classes.root}>
    <AppBar
      position="absolute"
      className={classNames(
        classes.appBar,
        rightSideBarOpened && classes.appBarShiftRight,
        leftSideBarOpened && classes.appBarShiftLeft,
      )}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.leftBlock}>
          {!rightSideBarOpened && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleLeftSideBar}
              className={classNames(leftSideBarOpened && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="title" color="inherit" noWrap className={classes.logoLink}>
            {title}
          </Typography>
        </div>
        <div className={classes.rightBlock}>
          {user && (
            <div>
              {user.full_name || user.phone}
              <IconButton
                title="Выйти"
                className={classes.exit}
                onClick={() =>
                  logout().then(() => {
                    location.href = '/';
                  })
                }
              >
                <ExitToApp />
              </IconButton>
            </div>
          )}

          {Boolean(actions.length) && !leftSideBarOpened && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleRightSideBar}
              className={classNames(classes.menuButton, rightSideBarOpened && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          )}
        </div>
      </Toolbar>
    </AppBar>
  </div>
);

TopBar.propTypes = {
  classes: PropTypes.object.isRequired,
  rightSideBarOpened: PropTypes.bool.isRequired,
  leftSideBarOpened: PropTypes.bool.isRequired,
  toggleRightSideBar: PropTypes.func.isRequired,
  toggleLeftSideBar: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(TopBar);
