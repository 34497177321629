// @flow
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  Dialog,
  Grid,
} from '@material-ui/core';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const dayseNames = {
  workday: 'понедельник-пятница',
  saturday: 'суббота',
  sunday: 'воскресенье',
};

const ScheduleComponent = ({ classes, schedule, onSave }: {| ...Props, ...ProvidedProps |}) => {
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [localSchedule, setSchedule] = useState(null);
  const [start_minute, setStartMinute] = useState(null);
  const [end_hour, setEndHour] = useState(null);
  const [end_minute, setEndMinute] = useState(null);

  const handleSetShcnedule = data => setSchedule(state => ({ ...state, ...data }));

  useEffect(() => {
    setSchedule(schedule);
  }, [schedule]);

  const handleOnSave = () => {
    onSave(localSchedule);
    setDialog(false);
  };

  const renderString = scheduleItem => {
    if (
      Object.keys(scheduleItem).length &&
      Object.keys(scheduleItem).every(key => typeof scheduleItem[key] === 'number')
    ) {
      return `с ${scheduleItem.start_hour}:${
        scheduleItem.start_hour < 10 ? `0${scheduleItem.start_minute}` : scheduleItem.start_minute
      } до ${scheduleItem.end_hour}:${
        scheduleItem.end_minute < 10 ? `0${scheduleItem.end_minute}` : schedule.end_minute
      }`;
    }

    return 'выходной';
  };

  if (!localSchedule) return null;

  const renderSelect = ({ value, onChange, elementsCount }) => (
    <Select
      value={value}
      onChange={e => onChange(e.target.value)}
      renderValue={value => (value < 10 ? `0${value}` : value)}
    >
      {new Array(elementsCount).fill(0).map((item, index) => (
        <MenuItem key={index} value={item + index}>
          {item + index < 10 ? `0${item + index}` : item + index}
        </MenuItem>
      ))}
    </Select>
  );

  const renderDayEditRow = key => (
    <Grid container spacing={8} alignItems="center">
      <Grid item xs={12} style={{ whiteSpace: 'nowrap', marginTop: '16px' }}>
        {dayseNames[key]}
      </Grid>
      <Grid item xs={1}>
        c
      </Grid>
      <Grid item xs={4}>
        {renderSelect({
          value: localSchedule[key].start_hour,
          onChange: start_hour =>
            handleSetShcnedule({ [key]: { ...localSchedule[key], start_hour } }),
          elementsCount: 24,
        })}
        :{' '}
        {renderSelect({
          value: localSchedule[key].start_minute,
          onChange: start_minute =>
            handleSetShcnedule({ [key]: { ...localSchedule[key], start_minute } }),
          elementsCount: 60,
        })}
      </Grid>
      <Grid item xs={1}>
        до
      </Grid>
      <Grid item xs={5}>
        {renderSelect({
          value: localSchedule[key].end_hour,
          onChange: end_hour => handleSetShcnedule({ [key]: { ...localSchedule[key], end_hour } }),
          elementsCount: 24,
        })}
        :{' '}
        {renderSelect({
          value: localSchedule[key].end_minute,
          onChange: end_minute =>
            handleSetShcnedule({ [key]: { ...localSchedule[key], end_minute } }),
          elementsCount: 60,
        })}
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      <List
        component="nav"
        subheader={<ListSubheader component="div">Расписание</ListSubheader>}
        className={classes.root}
      >
        <ListItem button onClick={() => setDialog(!dialog)}>
          <ListItemText primary={renderString(schedule.workday)} secondary={dayseNames.workday} />
          <ListItemText primary={renderString(schedule.saturday)} secondary={dayseNames.saturday} />
          <ListItemText primary={renderString(schedule.sunday)} secondary={dayseNames.sunday} />
        </ListItem>
      </List>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogContent>
          {renderDayEditRow('workday')}
          {renderDayEditRow('saturday')}
          {renderDayEditRow('sunday')}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Отмена</Button>
          <Button variant="raised" color="secondary" onClick={handleOnSave}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export const Schedule: ReactComponent<Props> = withStyles(styles)(ScheduleComponent);
