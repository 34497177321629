import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import green from '@material-ui/core/colors/green';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import localStorage from 'lib/local-storage';
import Choiser from '../../choiser';

const styles = () => ({
  root: {
  },
  table: {
    minWidth: 700,
  },
  cellId: {
    width: 30,
  },
  progress: {
    height: '5px',
  },
  actions: {
    minWidth: '220px',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
});

class UndefinedManufacturers extends Component {
  state = {
    selectedManufacturer: null,
    selectedSuggestion: null,
    page: 0,
    rowsPerPage: 14,
    orderBy: 'id',
    order: 'asc',
  }
  componentDidMount() {
    const rowsPerPage = localStorage.get('rowsPerPage');
    if (rowsPerPage) this.setState({ rowsPerPage });
  }
  setSelectedManufacturer = (selectedManufacturer, selectedSuggestion) => {
    if (!selectedSuggestion) {
      return this.setState({ selectedManufacturer: null, selectedSuggestion });
    }
    return this.setState({ selectedManufacturer, selectedSuggestion });
  }
  sort = data => {
    if (this.state.orderBy === 'supplier') {
      if (this.state.order === 'desc') {
        return data.sort((a, b) =>
          (b.supplier.name[this.state.orderBy] < a.supplier.name[this.state.orderBy] ? -1 : 1));
      }
      return data.sort((a, b) =>
        (a.supplier.name[this.state.orderBy] < b.supplier.name[this.state.orderBy] ? -1 : 1));
    }
    if (this.state.order === 'desc') {
      return data.sort((a, b) => (b[this.state.orderBy] < a[this.state.orderBy] ? -1 : 1));
    }
    return data.sort((a, b) => (a[this.state.orderBy] < b[this.state.orderBy] ? -1 : 1));
  }
  handleRequestSort = property => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };
  handleChangePage = (event, page) => this.setState({ page });
  handleChangeRowsPerPage = event => {
    localStorage.set('rowsPerPage', event.target.value);
    return this.setState({ rowsPerPage: event.target.value });
  }
  render() {
    const {
      classes,
      undefinedManufacturers,
      addManufacturer,
      addToManufacturer,
      isFetching,
      manufacturers,
    } = this.props;
    const {
      selectedManufacturer,
      selectedSuggestion,
      page,
      rowsPerPage,
      orderBy,
      order,
    } = this.state;
    const sortedUndefinedManufacturers = this.sort(undefinedManufacturers);
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, undefinedManufacturers.length - (page * rowsPerPage));
    return (
      <Paper className={classes.root}>
        <div className={classes.progress}>
          {isFetching &&
            <LinearProgress color="secondary" />
          }
        </div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell
                numeric
                sortDirection={orderBy === 'id' ? order : false}
              >
                <Tooltip
                  title="Сортировать"
                  placement="bottom-start"
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === 'id'}
                    direction={order}
                    onClick={() => this.handleRequestSort('id')}
                  >
                    id
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell
                sortDirection={orderBy === 'value' ? order : false}
              >
                <Tooltip
                  title="Сортировать"
                  placement="bottom-start"
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === 'value'}
                    direction={order}
                    onClick={() => this.handleRequestSort('value')}
                  >
                    Название
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell
                sortDirection={orderBy === 'supplier' ? order : false}
              >
                <Tooltip
                  title="Сортировать"
                  placement="bottom-start"
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === 'supplier'}
                    direction={order}
                    onClick={() => this.handleRequestSort('supplier')}
                  >
                    Источник
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell>Поиск подходящего производителя</TableCell>
              <TableCell numeric>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedUndefinedManufacturers
              .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
              .map(undefinedManufacturer => (
                <TableRow key={undefinedManufacturer.id} hover>
                  <TableCell numeric className={classes.cellId}>
                    {undefinedManufacturer.id}
                  </TableCell>
                  <TableCell>{undefinedManufacturer.value}</TableCell>
                  <TableCell>{undefinedManufacturer.supplier.name}</TableCell>
                  <TableCell>
                    <Choiser
                      placeholder="Выбор производителя"
                      suggestions={manufacturers}
                      selected={
                        selectedManufacturer && selectedManufacturer === undefinedManufacturer.id
                        ? selectedSuggestion
                        : null
                      }
                      returnSuggestion={suggestion =>
                        this.setSelectedManufacturer(undefinedManufacturer.id, suggestion)
                      }
                    />
                  </TableCell>
                  <TableCell className={classes.actions} numeric>
                    <div className={classes.wrapper}>
                      {this.state.selectedManufacturer !== undefinedManufacturer.id &&
                      <Button
                        variant="raised"
                        size="small"
                        disabled={undefinedManufacturer.processin}
                        onClick={() => addManufacturer(undefinedManufacturer)}
                      >
                        Это новый производитель
                      </Button>
                      }
                      {this.state.selectedManufacturer === undefinedManufacturer.id &&
                      <Button
                        variant="raised"
                        color="primary"
                        size="small"
                        disabled={undefinedManufacturer.processin}
                        onClick={() =>
                          addToManufacturer(this.state.selectedSuggestion.id, undefinedManufacturer)
                        }
                      >
                        Добавить синоним
                      </Button>
                      }
                      {undefinedManufacturer.processing &&
                        <CircularProgress size={24} className={classes.buttonProgress} />
                      }
                    </div>
                  </TableCell>
                </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={undefinedManufacturers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Брендов на странице"
          backIconButtonProps={{
            'aria-label': 'Предыдущая страница',
          }}
          nextIconButtonProps={{
            'aria-label': 'Следующая страница',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          rowsPerPageOptions={[14, 25, 50, undefinedManufacturers.length]}
        />
      </Paper>
    );
  }
}

UndefinedManufacturers.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  undefinedManufacturers: PropTypes.array.isRequired,
  manufacturers: PropTypes.array.isRequired,
  addManufacturer: PropTypes.func.isRequired,
  addToManufacturer: PropTypes.func.isRequired,
};

export default withStyles(styles)(UndefinedManufacturers);
