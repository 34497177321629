// @flow
import { type OrdersState } from './Orders';
import { type OrderState } from './Order';
import { type WarehousesState } from './Warehouses';
import { type PromotionsState } from './Promotions';
import { type PromotionState } from './Promotion';
import { type DeliveryPeriodsState } from './DeliveryPeriods';
import { type PickupTimesState } from './PickupTimes';
import { type AssemblyOrderDialogState } from './AssemblyOrderDialog';
import { type DeliveriesState } from './Deliveries';

export type AppStore = {
  shop: {
    orders: OrdersState,
    order: OrderState,
    warehouses: WarehousesState,
    promotions: PromotionsState,
    promotion: PromotionState,
    deliveryPeriods: DeliveryPeriodsState,
    pickupTimes: PickupTimesState,
    assemblyOrderDialog: AssemblyOrderDialogState,
    deliveries: DeliveriesState,
  },
};
