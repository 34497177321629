import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { type Name } from 'types/entities';
import { type Product } from 'types/entities/Product';
import ListItem from '@material-ui/core/ListItem';
import Radio from '@material-ui/core/Radio';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Done from '@material-ui/icons/Done';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Save from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

const styles = theme => ({
  root: {},
  adddName: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 2,
  },
  title: {
    marginTop: theme.spacing.unit * 2,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

type Props = {
  classes: Object,
  product: Product,
  addName: (productId: number, name: string) => void,
  setDefaultName: (productId: number, name: Name) => void,
  deleteName: (nameId: number) => void,
  compositeName: any,
  attributes: any,
};

class ProductNames extends React.Component<Props> {
  state = {
    showAdd: false,
    name: '',
  };
  addNameAndResetInput = () => {
    this.props.addName(this.props.product.id, this.state.name);
    this.setState({ showAdd: false, name: '' });
  };
  render() {
    const { classes, product, deleteName, setDefaultName, compositeName, attributes } = this.props;
    const { composite_name_type } = this.state;
    if (!product) return null;
    return (
      <div className={classes.root}>
        {compositeName}
        <Typography className={classes.title}>Характеристики</Typography>
        {attributes}
        <Typography className={classes.title}>Простое название</Typography>
        <List>
          {product.names.map((name: Name) => (
            <ListItem
              className={classes.listItem}
              key={name.id}
              dense
              // button
              divider
              disableGutters
              // onClick={() => setDefaultName(product.id, name)}
            >
              {/* <Radio checked={product.name && product.name.id && (product.name.id === name.id)} /> */}
              <ListItemText
                primary={name.value}
                secondary={
                  <span title="Источник названия">
                    {name.supplier.name}
                    {', '}
                    {new Date(name.created_time * 1000).toLocaleString('ru')}
                  </span>
                }
              />
              {/* <ListItemSecondaryAction
                title={product.name.id === name.id ? 'Удаление активного названия запрещено' : null}
              >
                <IconButton
                  title="Копировать название"
                  onClick={() => this.setState({ name: name.value, showAdd: true })}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  disabled={product.name.id === name.id}
                  title="Удалить название"
                  onClick={() => deleteName(name.id)}
                >
                  <Close />
                </IconButton>
              </ListItemSecondaryAction> */}
            </ListItem>
          ))}
        </List>
        {/* <div className={classes.adddName}>
          {!this.state.showAdd && (
            <Button
              variant="fab"
              color="secondary"
              className={classes.button}
              title="Добавить название"
              onClick={() => this.setState({ showAdd: true })}
            >
              <AddIcon />
            </Button>
          )}
          {this.state.showAdd && (
            <TextField
              fullWidth
              autoFocus
              label="Новое название"
              className={classes.textField}
              value={this.state.name}
              onChange={e => this.setState({ name: e.target.value })}
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!this.state.name}
                      color="secondary"
                      title="Сохранить"
                      onClick={() => this.addNameAndResetInput()}
                    >
                      <Done />
                    </IconButton>
                    <IconButton
                      title="Отмена"
                      onClick={() => this.setState({ showAdd: false, name: '' })}
                    >
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </div> */}
      </div>
    );
  }
}

export default withStyles(styles)(ProductNames);
