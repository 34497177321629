import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RightSideBar from './view/RightSideBar';
import { toggleRightSideBar } from './actions';

export class RightSideBarContainer extends React.Component {
  componentDidMount() {
  }
  render() {
    if (!this.props.actions.length) {
      return null;
    }
    return (
      <RightSideBar
        actions={this.props.actions}
        opened={this.props.opened}
        toggleRightSideBar={this.props.toggleRightSideBar}
      />
    );
  }
}

RightSideBarContainer.propTypes = {
  actions: PropTypes.array.isRequired,
  opened: PropTypes.bool.isRequired,
  toggleRightSideBar: PropTypes.func.isRequired,
};

const mapStateToProps = store => {
  const state = store.get('rightSideBar');
  return {
    opened: state.get('opened'),
    actions: state.get('actions').valueSeq().toArray(),
  };
};

export default connect(
  mapStateToProps,
  { toggleRightSideBar },
)(RightSideBarContainer);
