// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const styles = (theme: Theme) => ({
  root: {
    // marginTop: theme.spacing.unit * 2,
  },
});
type Props = {|
  classes: Object,
  value: string,
  title: string | null,
  open: boolean,
  handleClose: () => string,
  handleChange: () => void,
  handleSave: () => void,
|};

const OneFieldFormDialog = ({
  classes,
  open,
  handleSave,
  value,
  handleChange,
  handleClose,
  title,
}: Props) => (
  <Dialog
    className={classes.root}
    open={open}
    onClose={handleClose}
  >
    <DialogContent>
      <TextField
        autoFocus
        margin="none"
        fullWidth
        label={title || ''}
        value={value}
        onChange={handleChange}
        onKeyPress={e => {
          if (e.key === 'Enter') handleSave();
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>
        Закрыть
      </Button>
      <Button disabled={!value} color="secondary" onClick={handleSave}>
        Сохранить
      </Button>
    </DialogActions>
  </Dialog>
);

OneFieldFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OneFieldFormDialog);
