import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import CommentIcon from '@material-ui/icons/Comment';
import { type Theme } from 'types/components/Theme';
import moment from 'moment';
import { Icon, IconButton, Popover, Typography } from '@material-ui/core';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
    display: 'flex',
    fontSize: theme.typography.pxToRem(18),
  },
  popover: {
    padding: theme.spacing.unit * 2,
  },
});

type Props = {|
  classes: Object,
  disabled: boolean,
  onClick: () => void,
|};

const CouponComponent = ({ classes, coupon }: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    coupon && (
      <React.Fragment>
        <IconButton
          aria-describedby={id}
          className={classes.root}
          onClick={handleClick}
          title="Примененный к заказу купон"
        >
          <b>-{coupon.discount_percent}%</b>
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={classes.popover}>
            <Typography>
              <b>Купон {coupon.code}</b>
            </Typography>
            <Typography>
              {moment(coupon.start_at).format('YYYY-MM-DD HH:mm:ss')}
              {' - '}
              {moment(coupon.end_at).format('YYYY-MM-DD HH:mm:ss')}
            </Typography>
          </div>
        </Popover>
      </React.Fragment>
    )
  );
};
export const Coupon = withStyles(styles)(CouponComponent);
