import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import queryString from 'querystring';
import { useMemo } from 'react';

const parseQueryItems = (queryObj: { [key: string]: string | string[] }) =>
  Object.entries(queryObj).reduce(
    (prev, curr) => ({
      ...prev,
      [curr[0]]: Array.isArray(curr[1]) ? curr[1] : curr[1].split(','),
    }),
    {},
  );

const stringifyQueryItems = (queryObj: { [key: string]: string | string[] }) =>
  Object.entries(queryObj).reduce(
    (prev, curr) => ({
      ...prev,
      [curr[0]]: Array.isArray(curr[1]) ? curr[1].join(',') : curr[1],
    }),
    {},
  );

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  console.log(queryString.parse(location.search.replace('?', '')));
  // Return our custom router object
  // Memoize so that a new object is only returned if something changes

  const currentQuery = queryString.parse(location.search.replace('?', ''));

  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      push: (path: string | null, query?: any, options: { merge: boolean } = { merge: false }) => {
        const nextPath = path || location.pathname;

        return history.push(
          queryString.stringify(query)
            ? `${nextPath}?${queryString.stringify({
                ...(options.merge ? currentQuery : {}),
                ...query,
              })}`
            : nextPath,
        );
      },
      replace: history.replace,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...currentQuery, // Convert string to object
        ...params,
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history,
    };
  }, [params, match, location, history]);
}
