// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { type ReactComponent } from 'types/components/ReactComponent';
import { copyToClipboard } from 'lib';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ContentCopy from '@material-ui/icons/ContentCopy';

const styles = theme => ({
  root: {
    display: 'inline-block',
  },
  button: {
    padding: '8px',
    minWidth: '50px',
  },
});

type PassedProps = {|
  classes: Object,
|};

type Props = {|
  text: string,
  routeList: string,
  length: number,
|};

const copyType = {
  full: 'full',
  routeList: 'routeList',
};

const CopyToClipboardListComponent = ({
  classes,
  text,
  routeList,
  length,
}: {|
  ...PassedProps,
  ...Props,
|}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenMenu = (e: any) => {
    copyToClipboard(text);
    setAnchorEl(e.currentTarget);
  };
  const handleCopy = (type: any) => {
    if (type === copyType.full) {
      copyToClipboard(text);
    }
    if (type === copyType.routeList) {
      copyToClipboard(routeList);
    }
    setAnchorEl(null);
  };
  return (
    <div className={classes.root}>
      <Button className={classes.button} onClick={handleOpenMenu}>
        <ContentCopy /> {length ? `(${length})` : ''}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleCopy(copyType.full)}>
          Полный список
        </MenuItem>
        <MenuItem onClick={() => handleCopy(copyType.routeList)}>
          Маршрутный лист
        </MenuItem>
      </Menu>
    </div>
  );
};

export const CopyToClipboardList: ReactComponent<Props> = withStyles(styles)(
  CopyToClipboardListComponent,
);
