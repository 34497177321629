// @flow
// @flow-runtime enable
import { type Product } from './Product';
import { type OrderLinePlanItem } from './OrderLinePlanItem';

export type OrderLine = {|
  id: number,
  product: Product,
  quantity: number,
  original_single_price: number,
  total_price: number,
  created_at: string,
  updated_at: string,
  provided_quantity: number | null,
|};
