import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { Portal } from '@material-ui/core';

const styles = theme => ({
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
  },
});

const Notification = ({ classes, notifications, closeNotification }) => {
  if (!notifications.length) return null;
  return (
    <Portal>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={null}
        open={Boolean(notifications.length)}
        onClose={closeNotification}
        message={<span id="message-id">{notifications[notifications.length - 1].message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="secondary"
            className={classes.close}
            onClick={closeNotification}
          >
            <Close />
          </IconButton>,
        ]}
      />
    </Portal>
  );
};

Notification.propTypes = {
  classes: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
  closeNotification: PropTypes.func.isRequired,
};

export default withStyles(styles)(Notification);
