// @flow
import React, { type Node } from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { type ReactComponent } from 'types/components/ReactComponent';
import { type OrderState } from 'types/entities/OrderState';
import Paper from '@material-ui/core/Paper';
import cn from 'classnames';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import DatePicker from 'material-ui-pickers/DatePicker';
import { declOfNum } from 'lib/formatting';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    '& > div': {
      marginRight: theme.spacing.unit * 2,
      minHeight: '32px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  right: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  date: {
    '& input': {
      maxWidth: '95px',
    },
  },
});

type PassedProps = {|
  classes: Object,
|};

type Props = {|
  start: Object | null,
  copyToClipboard: any,
  selectAll: any,
  deliveryPeriods: Node,
  shipmentPlaces: Node,
  mapDialog: Node,
  warehousesFilter: Node,
  completeStatusSwitcher: Node,
  saveTableButton: Node,
  courierAssignmentButton: Node,
  count: number,
  onChangeDate: (date: Object | null) => void,
|};

const ControlsComponent = ({
  classes,
  start,
  onChangeDate,
  copyToClipboard,
  selectAll,
  deliveryPeriods,
  shipmentPlaces,
  count,
  mapDialog,
  warehousesFilter,
  completeStatusSwitcher,
  saveTableButton,
  courierAssignmentButton,
  courierSelect,
  citySelect,
}: {|
  ...PassedProps,
  ...Props,
|}) => {
  const handleChangeDate = (date: Object | null) => onChangeDate(date);
  const handleClear = (e: Object) => {
    e.stopPropagation();
    onChangeDate(null);
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.date}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            format="D MMMM"
            label="Дата"
            value={start}
            onChange={handleChangeDate}
            autoOk
            InputProps={
              start && {
                endAdornment: (
                  <InputAdornment position="end" onClick={handleClear}>
                    <IconButton>
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ),
              }
            }
          />
        </MuiPickersUtilsProvider>
      </div>
      {citySelect}
      {courierSelect}
      {deliveryPeriods}
      {shipmentPlaces}
      <div>{warehousesFilter}</div>
      {completeStatusSwitcher}
      <div>{mapDialog}</div>
      <div>Заказов: {count}</div>
      <div className={classes.right}>
        {selectAll}
        {courierAssignmentButton}
        {saveTableButton}
      </div>
    </Paper>
  );
};
export const Controls: ReactComponent<Props> = withStyles(styles)(ControlsComponent);
