import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import ProductPreview from 'components/product-preview';

const styles = theme => ({
  root: {},
  progress: {
    height: '5px',
  },
  button: {
    width: '40px',
    height: '40px',
  },
  openedAliases: {
    backgroundColor: theme.palette.grey[200],
  },
  aliasesCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chip: {
    margin: '3px',
  },
  tableRow: {
    cursor: 'pointer',
  },
  a: {
    color: theme.palette.secondary[500],
    textDecoration: 'none',
  },
});

const tableColumns = [
  {
    key: 'id',
    name: 'id',
    sortable: true,
    numeric: true,
  },
  {
    key: 'title',
    name: 'Название',
    sortable: true,
    numeric: false,
  },
  {
    key: 'manufacturer',
    name: 'Производитель',
    sortable: true,
    numeric: false,
  },
  {
    key: 'brand',
    name: 'Бренд',
    sortable: true,
    numeric: false,
  },
  {
    key: 'barcode',
    name: 'Штрих-код',
    sortable: true,
    numeric: true,
  },
  {
    key: 'category',
    name: 'Категория',
    sortable: true,
    numeric: false,
  },
  // {
  //   key: 'slug',
  //   name: 'slug',
  //   sortable: true,
  //   numeric: true,
  // },
  {
    key: 'offer_price',
    name: 'Цена',
    sortable: true,
    numeric: true,
  },
];

class ProductsTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'name',
    page: 0,
    rowsPerPage: 11,
  };
  sort = data => {
    if (['brand', 'manufacturer', 'category'].includes(this.state.orderBy)) {
      if (this.state.order === 'desc') {
        return data.sort((a, b) =>
          b[this.state.orderBy].name < a[this.state.orderBy].name ? -1 : 1,
        );
      }
      return data.sort((a, b) =>
        a[this.state.orderBy].name < b[this.state.orderBy].name ? -1 : 1,
      );
    }
    if (this.state.orderBy === 'title') {
      if (this.state.order === 'desc') {
        return data.sort((a, b) => (b.title < a.title ? -1 : 1));
      }
      return data.sort((a, b) => (a.title < b.title ? -1 : 1));
    }
    if (this.state.order === 'desc') {
      return data.sort((a, b) => (b[this.state.orderBy] < a[this.state.orderBy] ? -1 : 1));
    }
    return data.sort((a, b) => (a[this.state.orderBy] < b[this.state.orderBy] ? -1 : 1));
  };
  handleRequestSort = property => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };
  handleChangePage = (event, page) => {
    this.props.setSelectedProducts(null);
    return this.setState({ page });
  };
  handleChangeRowsPerPage = event => {
    this.props.setSelectedProducts(null);
    return this.setState({ rowsPerPage: event.target.value });
  };
  handleToggleVisibleProducts = products => () => {
    if (!products) return null;
    const prevSelectedProducts = this.props.selectedProducts || {};
    const prevCondition = Object.keys(prevSelectedProducts).reduce(
      (prev, id) => prevSelectedProducts[id] || prev,
      false,
    );
    const selectedProducts = {};
    products.forEach(product => {
      selectedProducts[product.id] = !prevCondition;
    });
    return this.props.setSelectedProducts(selectedProducts);
  };
  handleToggleProduct = (e, productId) => {
    e.stopPropagation();
    const prevSelectedProducts = this.props.selectedProducts || {};
    return this.props.setSelectedProducts({
      ...prevSelectedProducts,
      [productId]: !prevSelectedProducts[productId],
    });
  };
  render() {
    const {
      classes,
      isFetching,
      products,
      onSelect,
      history,
      selectedProducts,
      withEdit,
    } = this.props;
    const { order, orderBy, page, rowsPerPage } = this.state;
    const sortedData = this.sort(products.sort((a, b) => a.id - b.id));
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, sortedData.length - page * rowsPerPage);
    const cuttedList = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return (
      <div className={classes.root}>
        <div className={classes.progress}>{isFetching && <LinearProgress color="secondary" />}</div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                {withEdit && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={Boolean(
                        selectedProducts &&
                          Object.keys(selectedProducts).reduce(
                            (prev, id) => selectedProducts[id] || prev,
                            false,
                          ),
                      )}
                      onChange={this.handleToggleVisibleProducts(cuttedList)}
                    />
                  </TableCell>
                )}
                {tableColumns.map(column => {
                  if (column.sortable) {
                    return (
                      <TableCell
                        numeric={column.numeric}
                        key={column.key}
                        sortDirection={orderBy === column.key ? order : false}
                      >
                        <Tooltip title="Сортировать" placement="bottom-start" enterDelay={300}>
                          <TableSortLabel
                            active={orderBy === column.key}
                            direction={order}
                            onClick={() => this.handleRequestSort(column.key)}
                          >
                            {column.name}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    );
                  }
                  return <TableCell numeric={column.numeric}>{column.name}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {cuttedList.map(product => (
                <React.Fragment key={product.id}>
                  <TableRow
                    hover
                    className={classes.tableRow}
                    key={product.id}
                    onClick={onSelect(product)}
                  >
                    {withEdit && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={Boolean(selectedProducts && selectedProducts[product.id])}
                          onClick={e => this.handleToggleProduct(e, product.id)}
                        />
                      </TableCell>
                    )}
                    <TableCell colSpan={5}>
                      <ProductPreview product={product} />
                    </TableCell>
                    <TableCell>{product.category && product.category.name}</TableCell>
                    <TableCell numeric>{product.offer_price}</TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Строк на странице"
            backIconButtonProps={{
              'aria-label': 'Предыдущая страница',
            }}
            nextIconButtonProps={{
              'aria-label': 'Следующая страница',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPageOptions={[11, 50, 100, sortedData.length]}
          />
        </Paper>
      </div>
    );
  }
}
ProductsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ProductsTable);
