// @flow
import {
  FETCH_WAREHOUSES_REQUEST,
  FETCH_WAREHOUSES_SUCCESS,
} from 'types/actions/Warehouse';
import { type Action } from 'types/actions/Action';
import { type WarehousesState } from 'types/store/Warehouses';

export const warehousesInitialState: WarehousesState = {
  isFetching: false,
  list: null,
};

const warehouses = (
  state: WarehousesState = warehousesInitialState,
  action: Action,
): any => {
  switch (action.type) {
    case FETCH_WAREHOUSES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_WAREHOUSES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: action.warehouses,
      };
    }
    default: {
      return state;
    }
  }
};

export default warehouses;
