// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Store from '@material-ui/icons/Store';
import { type Theme } from 'types/components/Theme';
import { type Product } from 'types/entities/Product';
import ProductLabel from 'components/product-label';
import { SHOP_HOST } from 'config';
import cn from 'classnames';
import { ShoppingBasket } from '@material-ui/icons';

const styles = (theme: Theme) => ({
  a: {
    color: theme.palette.secondary[800],
    textDecoration: 'none',
    fontSize: theme.typography.pxToRem(16),
  },
  lineNumber: {
    fontWeight: 900,
    marginRight: theme.spacing.unit,
    color: theme.palette.grey[600],
  },
  goToStore: {
    marginLeft: theme.spacing.unit,
    width: '18px',
    height: '18px',
    '& svg': {
      width: '18px',
      height: '18px',
    },
  },
  label: {
    marginRight: theme.spacing.unit,
  },
  barcode: {
    marginTop: theme.spacing.unit * 0.5,
    color: theme.palette.grey[600],
  },
  productManufacturer: {
    '& > div': {
      marginTop: theme.spacing.unit * 1.5,
    },
  },
});
type Props = {|
  classes: Object,
  product: Product,
  index?: number,
|};

const ProductPreview = ({
  classes,
  product,
  index,
  category = true,
  brand = true,
  manufacturer = true,
  series = true,
  type = true,
}: Props) => {
  return (
    <div>
      <Typography>
        {index && <span className={classes.lineNumber}>{index}.</span>}
        <a
          className={cn(classes.a, classes.name)}
          title="Открыть продукт"
          target="_blank"
          href={`/product/${product.id}`}
        >
          {product.title}
        </a>
        <IconButton
          className={classes.goToStore}
          color="primary"
          title="Открыть продукт в магазине"
          target="_blank"
          href={`${SHOP_HOST}/product/${product.id}`}
        >
          <Store />
        </IconButton>
        <IconButton
          className={classes.goToStore}
          color="secondary"
          href={`https://product.bonjour-dv.ru/products/${product.id}`}
          target="_blank"
        >
          <ShoppingBasket />
        </IconButton>
      </Typography>
      {product.barcode && (
        <div className={classes.barcode} title="Штрих-код">
          {product.barcode.value}
        </div>
      )}
      <div className={classes.productManufacturer}>
        {manufacturer && product.manufacturer && (
          <ProductLabel manufacturer={product.manufacturer} className={classes.label} />
        )}
        {brand && product.brand && <ProductLabel brand={product.brand} className={classes.label} />}
        {type && product.type && <ProductLabel type={product.type} className={classes.label} />}
        {series && product.series && (
          <ProductLabel series={product.series} className={classes.label} />
        )}
      </div>
    </div>
  );
};
export default withStyles(styles)(ProductPreview);
