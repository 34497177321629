import {
  FECTH_IMAGES_LOGS_REQUEST,
  FECTH_IMAGES_LOGS_SUCCESS,
} from 'types/actions';
import Immutable from 'immutable';

const imagesLogsInitialState = Immutable.Map({
  isFetching: false,
  list: null,
});

const imagesLogs = (state = imagesLogsInitialState, action) => {
  switch (action.type) {
    case FECTH_IMAGES_LOGS_REQUEST: {
      return state.set('isFetching', true);
    }
    case FECTH_IMAGES_LOGS_SUCCESS: {
      return state.merge({
        isFetching: false,
        list: action.logs,
      });
    }
    default: {
      return state;
    }
  }
};

export default imagesLogs;
