// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import LinkIcon from '@material-ui/icons/Link';
import Paper from '@material-ui/core/Paper';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import IconButton from '@material-ui/core/IconButton';
import Mood from '@material-ui/icons/Mood';
import MoodBad from '@material-ui/icons/MoodBad';
import { withRouter } from 'react-router-dom';
import { type Order } from 'types/entities/Order';
import { OrderStateLabel } from 'components/shop/order-state-label';
import { green, red } from '@material-ui/core/colors';
import { formatNumber } from 'lib/formatting';
import cn from 'classnames';

const styles = theme => ({
  table: {
    marginTop: theme.spacing.unit * 2,
  },
  row: {
    cursor: 'pointer',
  },
  cost: {
    whiteSpace: 'nowrap',
    fontWeight: 900,
  },
  number: {
    fontWeight: 900,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.grey[600],
  },
  paidIcon: {
    color: green[500],
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  local: {
    color: theme.palette.secondary[800],
    fontWeight: 900,
  },
});

type Props = {|
  classes: Object,
  list: Array<Order> | null,
  history: Object,
  match: Object,
  location: Object,
  staticContext: any,
  handleOpenOrder: (orderId: number) => void,
|};

const deliveryType = {
  courier: 'Доставка',
  pick_up: 'Самовывоз',
  cdec: 'СДЕК',
};

const OrdersTable = ({ classes, list, history, handleOpenOrder }: Props) => (
  <Paper className={classes.root}>
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Дата</TableCell>
          <TableCell numeric>Заказ</TableCell>
          <TableCell>Статус</TableCell>
          <TableCell>Имя</TableCell>
          <TableCell numeric>Телефон</TableCell>
          <TableCell />
          <TableCell />
          <TableCell numeric>Отзыв</TableCell>
          <TableCell />
          <TableCell numeric>Сумма</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list &&
          list
            .sort((a, b) => b.id - a.id)
            .map(i => (
              <TableRow key={i.id} onClick={handleOpenOrder(i.id)} hover className={classes.row}>
                <TableCell component="th" scope="row">
                  {moment(i.created_at).format('DD.MM.YYYY HH:mm:ss')}
                </TableCell>
                <TableCell numeric className={classes.number}>
                  {i.id}
                </TableCell>
                <TableCell>
                  <div className={classes.align}>
                    <OrderStateLabel state={i.state} />
                    {i.realization_method && i.realization_method.is_paid && (
                      <MonetizationOn className={classes.paidIcon} title="Заказ оплачен" />
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  {(i.realization_method && i.realization_method.recipient_name) ||
                    i.user.first_name}
                </TableCell>
                <TableCell numeric>
                  {(i.realization_method && i.realization_method.recipient_phone) || i.user.phone}
                </TableCell>
                <TableCell>
                  {i.realization_method && deliveryType[i.realization_method.type]}
                </TableCell>
                <TableCell
                  className={cn(
                    i.realization_method &&
                      i.realization_method.address &&
                      i.realization_method.address.street.name === 'Днепровская' &&
                      i.realization_method.address.house.toLowerCase() === '21б' &&
                      classes.local,
                  )}
                >
                  {(i.realization_method &&
                    i.realization_method.pick_up_point &&
                    i.realization_method.pick_up_point.name) ||
                    (i.realization_method &&
                      i.realization_method.address && i.realization_method.address.line)}
                </TableCell>
                <TableCell numeric>
                  {typeof i.liked === 'boolean' ?  i.liked && <Mood style={{ color: green[700] }}/> || <MoodBad style={{ color: red[700] }}/>: null}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      history.push(`/shop/order/${i.id}`);
                    }}
                  >
                    <LinkIcon />
                  </IconButton>
                </TableCell>
                <TableCell numeric className={classes.cost}>
                  {formatNumber(i.cost || 0)}
                  {' ₽'}
                </TableCell>
              </TableRow>
            ))}
        <TableRow>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell numeric />
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell numeric className={classes.cost}>
            {formatNumber((list && list.reduce((prev, curr) => prev + (curr.cost || 0), 0)) || 0)}
            {' ₽'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Paper>
);

OrdersTable.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.any,
};

export default withRouter(withStyles(styles)(OrdersTable));
