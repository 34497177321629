// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import { type Theme } from 'types/components/Theme';
import red from '@material-ui/core/colors/red';
import NumberFormat from 'react-number-format';
import cn from 'classnames';
import debounce from 'lodash.debounce';

const styles = (theme: Theme) => ({
  quantity: {
    width: '130px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '24px',
  },
  warning: {
    backgroundColor: red[100],
  },
  providedWarning: {
    backgroundColor: theme.palette.secondary[100],
  },
  iconButton: {
    width: '36px',
    height: '36px',
  },
  quantityValue: {
    cursor: 'point',
    fontSize: theme.typography.pxToRem(14),
  },
});
type Props = {|
  classes: Object,
  onChange: (quantity: number) => void,
  editable?: boolean,
  quantity: number,
  provided?: number | null,
  limit: number,
  zeroIsAllowed?: boolean,
  withLimitWarning?: boolean,
|};

const Quantity = ({
  classes,
  onChange,
  quantity,
  editable,
  limit,
  zeroIsAllowed,
  withLimitWarning,
  provided,
}: Props) => {
  const [inputOpen, toggleInput] = useState(false);
  const handleChangeQuantity = (quantity: number) => () => onChange(quantity);
  const handleToggleInput = (value: boolean) => () => {
    if (editable) toggleInput(value);
  };
  const changeQuantityWithDebounce = debounce(onChange, 1250);
  const tooltipEnabled = () =>
    !((withLimitWarning && quantity > limit) || (provided !== null && provided !== quantity));
  const tooltipText = () => {
    if (quantity > limit) {
      return `Фактическое наличие (${limit}шт.) меньше необходимого`;
    }
    if (typeof provided === 'number' && provided !== quantity) {
      return `Фактическое собранное количество (${provided}шт.) меньше необходимого (${quantity}шт.)`;
    }
    return `Фактическое наличие ${limit}`;
  };
  const getWarningClass = () => {
    if (withLimitWarning && quantity > limit) {
      return classes.warning;
    }
    if (provided !== null && provided !== quantity) {
      return classes.providedWarning;
    }
    return null;
  };
  return (
    <Tooltip
      disableFocusListener={tooltipEnabled()}
      disableHoverListener={tooltipEnabled()}
      disableTouchListener={tooltipEnabled()}
      title={tooltipText()}
    >
      <span className={cn(classes.quantity, getWarningClass())}>
        <IconButton
          className={classes.iconButton}
          color="secondary"
          disabled={!editable}
          onClick={handleChangeQuantity(quantity <= 1 ? (zeroIsAllowed ? 0 : 1) : quantity - 1)}
        >
          -
        </IconButton>
        {!inputOpen && (
          <span onClick={handleToggleInput(true)} className={classes.quantityValue}>
            {typeof provided === 'number' && provided !== quantity ? `${provided} / ` : ''}
            {quantity}
            {' шт.'}
          </span>
        )}
        {inputOpen && (
          <NumberFormat
            onBlur={handleToggleInput(false)}
            customInput={TextField}
            className={classes.textField}
            fullWidth
            margin="none"
            value={quantity}
            onValueChange={e => changeQuantityWithDebounce(Number(e.value))}
          />
        )}
        <IconButton
          className={classes.iconButton}
          color="secondary"
          disabled={!editable || quantity >= limit}
          onClick={handleChangeQuantity(quantity + 1)}
        >
          +
        </IconButton>
      </span>
    </Tooltip>
  );
};

Quantity.defaultProps = {
  editable: true,
  withLimitWarning: false,
};

export default withStyles(styles)(Quantity);
