// @flow

import { type typeProductSeries } from 'types/entities/typeProductSeries';

export const GET_SERIES_SUCCESS: 'GET_SERIES_SUCCESS' = 'GET_SERIES_SUCCESS';

export type ReceiveSeries = {|
  type: typeof GET_SERIES_SUCCESS,
  series: Array<typeProductSeries> | null,
|};
