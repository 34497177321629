import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Notifications from './view/Notifications';
import { closeNotification } from './actions';

export const NotificationsContainer = props => (
  <Notifications
    notifications={props.notifications}
    closeNotification={props.closeNotification}
  />
);

NotificationsContainer.propTypes = {
  notifications: PropTypes.array.isRequired,
  closeNotification: PropTypes.func.isRequired,
};

const mapStateToProps = store => {
  const state = store.get('notifications');
  return {
    notifications: state.get('items').valueSeq().toArray(),
  };
};

export default connect(
  mapStateToProps,
  { closeNotification },
)(NotificationsContainer);
