// @flow
import {
  type CategoriesTree,
  type Supplier,
  type Image,
  type ImageLog,
  type Markup,
  type User,
} from 'types/entities';
import { type Product } from 'types/entities/Product';
import { type typeCategory } from 'types/entities/typeCategory';
import { type typeUser } from 'types/entities/typeUser';
import { type LocationChangeAction } from 'react-router-redux';

// Manufacturers
export const FETCH_MANUFACTURERS_REQUEST: 'FETCH_MANUFACTURERS_REQUEST' = 'FETCH_MANUFACTURERS_REQUEST';
export const FETCH_MANUFACTURERS_FAILURE: 'FETCH_MANUFACTURERS_FAILURE' = 'FETCH_MANUFACTURERS_FAILURE';
export const FETCH_MANUFACTURERS_SUCCESS: 'FETCH_MANUFACTURERS_SUCCESS' = 'FETCH_MANUFACTURERS_SUCCESS';

export type ManufacturersInclude = Array<'alias' | 'brand' | 'markup'> | void;

// UndefinedManufacturers
export const FETCH_UNDEFINED_MANUFACTURERS_REQUEST: 'FETCH_UNDEFINED_MANUFACTURERS_REQUEST' = 'FETCH_UNDEFINED_MANUFACTURERS_REQUEST';
export const FETCH_UNDEFINED_MANUFACTURERS_FAILURE: 'FETCH_UNDEFINED_MANUFACTURERS_FAILURE' = 'FETCH_UNDEFINED_MANUFACTURERS_FAILURE';
export const FETCH_UNDEFINED_MANUFACTURERS_SUCCESS: 'FETCH_UNDEFINED_MANUFACTURERS_SUCCESS' = 'FETCH_UNDEFINED_MANUFACTURERS_SUCCESS';
export const PROCESSING_UNDEFINED_MANUFACTURER: 'PROCESSING_UNDEFINED_MANUFACTURER' = 'PROCESSING_UNDEFINED_MANUFACTURER';

// Notifications
export const ADD_NOTIFICATION: 'ADD_NOTIFICATION' = 'ADD_NOTIFICATION';
export const CLOSE_NOTIFICATION: 'CLOSE_NOTIFICATION' = 'CLOSE_NOTIFICATION';

// Brands
export const FETCH_BRANDS_REQUEST: 'FETCH_BRANDS_REQUEST' = 'FETCH_BRANDS_REQUEST';
export const FETCH_BRANDS_SUCCESS: 'FETCH_BRANDS_SUCCESS' = 'FETCH_BRANDS_SUCCESS';

// UndefinedBrands
export const FETCH_UNDEFINED_BRANDS_REQUEST: 'FETCH_UNDEFINED_BRANDS_REQUEST' = 'FETCH_UNDEFINED_BRANDS_REQUEST';
export const FETCH_UNDEFINED_BRANDS_SUCCESS: 'FETCH_UNDEFINED_BRANDS_SUCCESS' = 'FETCH_UNDEFINED_BRANDS_SUCCESS';

// RightSideBar
export const TOGGLE_RIGHT_SIDE_BAR: 'TOGGLE_RIGHT_SIDE_BAR' = 'TOGGLE_RIGHT_SIDE_BAR';
export const SET_RIGHT_SIDE_BAR_ACTIONS: 'SET_RIGHT_SIDE_BAR_ACTIONS' = 'SET_RIGHT_SIDE_BAR_ACTIONS';

// LeftSideBar
export const TOGGLE_LEFT_SIDE_BAR: 'TOGGLE_LEFT_SIDE_BAR' = 'TOGGLE_LEFT_SIDE_BAR';

// Categories
export const FETCH_CATEGORIES_REQUEST: 'FETCH_CATEGORIES_REQUEST' = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_LIST_SUCCESS: 'FETCH_CATEGORIES_LIST_SUCCESS' = 'FETCH_CATEGORIES_LIST_SUCCESS';
export const FETCH_CATEGORIES_TREE_SUCCESS: 'FETCH_CATEGORIES_TREE_SUCCESS' = 'FETCH_CATEGORIES_TREE_SUCCESS';

export type CategoriesTreeInclude = Array<'markup'> | void

export type RequestCategories = {|
  type: typeof FETCH_CATEGORIES_REQUEST,
  value: boolean,
|};
export type ReceiveCategoriesTree = {|
  type: 'FETCH_CATEGORIES_TREE_SUCCESS',
  tree: CategoriesTree,
|};
export type ReceiveCategoriesList = {|
  type: 'FETCH_CATEGORIES_LIST_SUCCESS',
  list: {[key: string]: typeCategory},
|};

// Product
export const FETCH_PRODUCT_REQUEST: 'FETCH_PRODUCT_REQUEST' = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_SUCCESS: 'FETCH_PRODUCT_SUCCESS' = 'FETCH_PRODUCT_SUCCESS';
export type RequestProduct = {|
  type: typeof FETCH_PRODUCT_REQUEST,
  value: boolean,
|};
export type ReceiveProduct = {|
  type: typeof FETCH_PRODUCT_SUCCESS,
  product: Product,
|};

// Product Images
export const FETCH_PRODUCT_IMAGES_SUCCESS: 'FETCH_PRODUCT_IMAGES_SUCCESS' = 'FETCH_PRODUCT_IMAGES_SUCCESS';
export type ReceiveProductImages = {|
  type: typeof FETCH_PRODUCT_IMAGES_SUCCESS,
  images: Array<Image> | Array<any>,
|};

// Product Pricing Report
export const FETCH_PRODUCT_PRICING_REPORT_REQUEST: 'FETCH_PRODUCT_PRICING_REPORT_REQUEST' = 'FETCH_PRODUCT_PRICING_REPORT_REQUEST';
export const FETCH_PRODUCT_PRICING_REPORT_SUCCESS: 'FETCH_PRODUCT_PRICING_REPORT_SUCCESS' = 'FETCH_PRODUCT_PRICING_REPORT_SUCCESS';
export type RequestProductPricingReport = {
  type: typeof FETCH_PRODUCT_PRICING_REPORT_REQUEST,
};
export type ReceiveProductPricingReport = {|
  type: typeof FETCH_PRODUCT_PRICING_REPORT_SUCCESS,
  report: any,
|};

// TopBar
export const SET_TOP_BAR_TITLE: string = 'SET_TOP_BAR_TITLE';
export type SetTopBarTitle = (title: string) => void;

// Products
export const FETCH_PRODUCTS_REQUEST: 'FETCH_PRODUCTS_REQUEST' = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS: 'FETCH_PRODUCTS_SUCCESS' = 'FETCH_PRODUCTS_SUCCESS';

export type RequestProducts = {|
  type: typeof FETCH_PRODUCTS_REQUEST,
  value: boolean,
|}

export type ReceiveProducts = {|
  type: typeof FETCH_PRODUCTS_SUCCESS,
  products: {[key: string]: Product} | void,
|}

// Suppliers
export const FETCH_SUPPLIERS_REQUEST: 'FETCH_SUPPLIERS_REQUEST' = 'FETCH_SUPPLIERS_REQUEST';
export const FETCH_SUPPLIERS_SUCCESS: 'FETCH_SUPPLIERS_SUCCESS' = 'FETCH_SUPPLIERS_SUCCESS';
export type RequestSuppliers = {
  type: typeof FETCH_SUPPLIERS_REQUEST,
  value: boolean,
};
export type ReceiveSuppliers = {|
  type: typeof FETCH_SUPPLIERS_SUCCESS,
  suppliers: { [key: string]: Supplier },
|};

// User
export const FETCH_USER_REQUEST: 'FETCH_USER_REQUEST' = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS' = 'FETCH_USER_SUCCESS';

export type RequestUser = {|
  type: typeof FETCH_USER_REQUEST,
|};

export type ReceiveUser = {|
  type: typeof FETCH_USER_SUCCESS,
  user: typeUser | null,
|};

// ImagesLogs
export const FECTH_IMAGES_LOGS_REQUEST: 'FECTH_IMAGES_LOGS_REQUEST' = 'FECTH_IMAGES_LOGS_REQUEST';
export const FECTH_IMAGES_LOGS_SUCCESS: 'FECTH_IMAGES_LOGS_SUCCESS' = 'FECTH_IMAGES_LOGS_SUCCESS';

export type RequestImagesLogs = {|
  type: typeof FECTH_IMAGES_LOGS_REQUEST,
|};

export type ReceivImagesLogs = {|
  type: typeof FECTH_IMAGES_LOGS_SUCCESS,
  logs: Array<ImageLog>,
|};

// Markups
export const FETCH_MANUFACTURERS_MARKUPS_REQUEST: 'FETCH_MANUFACTURERS_MARKUPS_REQUEST' = 'FETCH_MANUFACTURERS_MARKUPS_REQUEST';
export const FETCH_MANUFACTURERS_MARKUPS_SUCCESS: 'FETCH_MANUFACTURERS_MARKUPS_SUCCESS' = 'FETCH_MANUFACTURERS_MARKUPS_SUCCESS';
export const FETCH_BRANDS_MARKUPS_REQUEST: 'FETCH_BRANDS_REQUEST' = 'FETCH_BRANDS_REQUEST';
export const FETCH_BRANDS_MARKUPS_SUCCESS: 'FETCH_BRANDS_SUCCESS' = 'FETCH_BRANDS_SUCCESS';
export type RequestManufacturersMarkups = {|
  type: typeof FETCH_MANUFACTURERS_MARKUPS_REQUEST,
  value: boolean,
|}
export type ReceiveManufacturersMarkups = {|
  type: typeof FETCH_MANUFACTURERS_MARKUPS_SUCCESS,
  markups: { [key: string]: Markup },
|}
export type RequestBrandsMarkups = {|
  type: typeof FETCH_BRANDS_MARKUPS_REQUEST,
  value: boolean,
|}
export type ReceiveBrandsMarkups = {|
  type: typeof FETCH_BRANDS_MARKUPS_SUCCESS,
  markups: { [key: string]: Markup },
|}

// Redux
type ReduxInternalActionType =
{
  type: '@@redux/INIT' | '@@INIT',
};

type State = Object;

export type Action =
  | ReduxInternalActionType
  | ReceiveCategoriesTree
  | ReceiveCategoriesList
  | RequestCategories
  | LocationChangeAction
  | ReceiveProduct
  | RequestProduct
  | ReceiveProductImages
  | ReceiveProductPricingReport
  | RequestProductPricingReport
  | RequestManufacturersMarkups
  | RequestBrandsMarkups
  | ReceiveBrandsMarkups;

export type GetState = () => State;
export type ThunkAction = (dispatch: Dispatch) => any;
type PromiseAction = Promise<Action>;
export type Dispatch = (action: Action | ThunkAction | PromiseAction) => any;
