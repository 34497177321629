// @flow
import React, { useEffect, useState } from 'react';
import { type Product } from 'types/entities/Product';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import BarcodeSvg from './BarcodeSvg';
import { Select, MenuItem } from '@material-ui/core';
import { fetchWarehouses } from 'services/warehouseApi';

type Props = {
  classes: Object,
  product: Product,
  deleteEntityFromProduct: (name: string, productId: number) => void,
};

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
});

export const RegionSelectComponent = ({ classes, match, history, location }: Props) => {
  const [regions, setRegions] = useState(null);
  useEffect(() => {
    fetchWarehouses().then(warehouses => {
      if (warehouses) {
        setRegions(
          warehouses.reduce(
            (prev, curr) => ({ ...prev, [curr.address.region.fias_id]: curr.address.region }),
            {},
          ),
        );
      }
    });
  }, []);
  const query = queryString.parse(location.search);
  return (
    <div className={classes.root}>
      <Select
        value={query.fias_id || '43909681-d6e1-432d-b61f-ddac393cb5da'}
        onChange={e =>
          history.push(
            `${location.pathname}?${queryString.stringify({
              ...query,
              fias_id: e.target.value,
            })}`,
          )
        }
      >
        {regions &&
          Object.keys(regions).map(fias_id => (
            <MenuItem value={fias_id} key={fias_id}>
              {regions[fias_id].name} {regions[fias_id].type}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
};

export const RegionSelect = withStyles(styles)(withRouter(RegionSelectComponent));
