// @flow
import { get } from 'lib/request';
import { API_HOST } from 'config';

export const fetchPickupTime = async ({
  orderId,
}: {
  orderId: number,
}): Promise<string | null> => {
  try {
    const response = await get(`${API_HOST}/pick-up-prediction/${orderId}`)
      .then(res => res.data.estimated_time);
    return response;
  } catch (error) {
    return null;
  }
};

export const fetchPickupPrediction = async ({
  orderId,
}: {
  orderId: number,
}): Promise<any> => {
  try {
    const response = await get(`${API_HOST}/orders/${orderId}/readiness_times_by_pick_up_points`)
      .then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};
