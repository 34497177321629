// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const ControlsComponent = ({
  classes,
  selectedSupplier,
  setSelectedSupplier,
  suppliers,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => (
  <div className={classes.root}>
    <FormControl>
      <InputLabel htmlFor="supplier" shrink>
        Поставщик
      </InputLabel>
      <Select
        displayEmpty
        autoWidth
        inputProps={{
          id: 'supplier',
        }}
        value={selectedSupplier}
        renderValue={value => value && value.name}
        onChange={e => setSelectedSupplier(e.target.value)}
      >
        {suppliers &&
          suppliers.map(item => (
            <MenuItem key={item.alias} value={item}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  </div>
);

export const Controls: ReactComponent<Props> = withStyles(styles)(ControlsComponent);
