// @flow
import {
  FETCH_PICKUP_TIME_REQUEST,
  FETCH_PICKUP_TIME_SUCCESS,
  FETCH_PICKUP_POINTS_REQUEST,
  FETCH_PICKUP_POINTS_SUCCESS,
  type RequestPickupTime,
  type ReceivePickupTime,
  type RequestPickupPoints,
  type ReceivePickupPoints,
} from 'types/actions/PickupTime';
import { type PickupPoint } from 'types/entities/PickupPoint';
import {
  fetchPickupTime as fetchPickupTimeFromApi,
  fetchPickupPrediction as fetchPickupPredictionFromApi,
} from 'services/pickupTimeApi';

const requestDeliveryPeriods = (): RequestPickupTime => ({
  type: FETCH_PICKUP_TIME_REQUEST,
});

const receiveDeliveryPeriods = (
  pickupTime: string | null,
): ReceivePickupTime => ({
  type: FETCH_PICKUP_TIME_SUCCESS,
  pickupTime,
});

const requestPickupPoints = (): RequestPickupPoints => ({
  type: FETCH_PICKUP_POINTS_REQUEST,
});

const receivePickupPoints = (
  pickupPoints: Array<PickupPoint> | null,
): ReceivePickupPoints => ({
  type: FETCH_PICKUP_POINTS_SUCCESS,
  pickupPoints,
});

export const fetchPickupTime = (params: { orderId: number }) => (dispatch: $FlowFixMe) => {
  dispatch(requestDeliveryPeriods());
  fetchPickupTimeFromApi(params).then(pickupTime =>
    dispatch(receiveDeliveryPeriods(pickupTime)),
  );
};

export const fetchPickupPrediction = (params: { orderId: number }) => (dispatch: $FlowFixMe) => {
  dispatch(requestPickupPoints());
  fetchPickupPredictionFromApi(params).then(pickupPoints =>
    dispatch(receivePickupPoints(pickupPoints)),
  );
};
