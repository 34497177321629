import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { DatePicker } from 'material-ui-pickers';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import 'moment/locale/ru';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import moment from 'moment';

moment.locale('ru');

const styles = theme => ({
  root: {
    '& div': {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
  },
});

const Controls = ({
  classes,
  after,
  before,
  handleDateChange,
  fetchImagesLogs,
}) => (
  <div className={classes.root}>
    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
      <DatePicker
        format="YYYY.MM.DD"
        value={after}
        autoOk
        onChange={value => handleDateChange('after', value)}
        animateYearScrolling
        cancelLabel="Отмена"
      />
      <DatePicker
        format="YYYY.MM.DD"
        value={before}
        autoOk
        onChange={value => handleDateChange('before', value)}
        animateYearScrolling
        cancelLabel="Отмена"
      />
      <Button variant="raised" color="secondary" onClick={fetchImagesLogs}>
        Показать
      </Button>
    </MuiPickersUtilsProvider>
  </div>
);

Controls.propTypes = {
  classes: PropTypes.object.isRequired,
  after: PropTypes.object.isRequired,
  before: PropTypes.object.isRequired,
  handleDateChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(Controls);
