// @flow
// @flow-runtime enable
import { get, post, del } from 'lib/request';
import { API_HOST, PUBLIC_API_HOST } from 'config';
import queryString from 'query-string';

export const getProductItems = async ({ productId }) => {
  try {
    const response = await get(`${API_HOST}/products/${productId}/items`).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const getProductUrlSources = async ({ productId }) => {
  try {
    const response = await get(`${API_HOST}/products/${productId}/url_sources`).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const setProductUrlSource = async ({ productId, data }) => {
  try {
    const response = await post(`${API_HOST}/products/${productId}/url_sources`, data).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteProductUrlSource = async ({ productId, host }) => {
  try {
    const response = await del(`${API_HOST}/products/${productId}/url_sources/${host}`).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getPricesHistory = async ({ end_date, start_date }) => {
  try {
    const response = await get(
      `${API_HOST}/price-history?filter[end_date]=${end_date}&filter[start_date]=${start_date}`,
    ).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};
export const getAllowedForSaleProduct = async ({ productId }) => {
  try {
    const response = await get(
      `${API_HOST}/allowed_for_sale_product/${productId}`,
    ).then(res => res.status === 200 ? true : false);
    return response;
  } catch (error) {
    return null;
  }
};

export const getProducts = async ({ query }) => {
  try {
    const response = await get(`${PUBLIC_API_HOST}/products?${queryString.stringify(query)}`).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return null;
  }
};
