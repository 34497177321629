      // @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchOrder } from 'components/shop/order/actions';
import { setTopBarTitle } from 'components/top_bar/actions';
import { withRouter } from 'react-router-dom';
import Order from 'components/shop/order';

type Props = {|
  fetchOrder: (orderId: number) => void,
  setTopBarTitle: (title: string) => void,
  match: Object,
|};

export class OrderPageContainer extends React.Component<Props> {
  componentDidMount() {
    if (this.props.match.params && this.props.match.params.id) {
      this.props.setTopBarTitle(`Заказ ${this.props.match.params.id}`);
      this.props.fetchOrder(Number(this.props.match.params.id));
    }
  }

  render() {
    return (
      <Order elevation />
    );
  }
}

OrderPageContainer.propTypes = {
  fetchOrder: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({

});

export default withRouter(connect(
  mapStateToProps,
  {
    fetchOrder,
    setTopBarTitle,
  },
)(OrderPageContainer));
