// @flow
import {
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_IMAGES_SUCCESS,
  FETCH_PRODUCT_PRICING_REPORT_SUCCESS,
  type Action,
} from 'types/actions';
import Immutable from 'immutable';

const productInitialState = Immutable.Map({
  isFetching: false,
  data: null,
  pricingReport: null,
  images: Immutable.List(),
});

type ProductState = Object;

const product = (state: ProductState = productInitialState, action: Action) => {
  switch (action.type) {
    case FETCH_PRODUCT_REQUEST: {
      return state.set('isFetching', action.value);
    }
    case FETCH_PRODUCT_SUCCESS: {
      return state.merge({
        isFetching: false,
        data: action.product,
      });
    }
    case FETCH_PRODUCT_IMAGES_SUCCESS: {
      return state.merge({
        isFetching: false,
        images: Immutable.List(action.images),
      });
    }
    case FETCH_PRODUCT_PRICING_REPORT_SUCCESS: {
      return state.merge({
        isFetching: false,
        pricingReport: action.report,
      });
    }
    default: {
      return state;
    }
  }
};

export default product;
