// @flow
// @flow-runtime enable
import { get, put, post, del } from 'lib/request';
import { API_HOST } from 'config';
import idx from 'idx';
import { type Promotion } from 'types/entities/Promotion';
import queryString from 'query-string';

export const fetchPromotions = async (query) => {
  try {
    const response = await get(`${API_HOST}/promotions?${queryString.stringify(query)}`).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const fetchPromotion = async (promotionId: number) => {
  try {
    const response = await get(`${API_HOST}/promotions/${promotionId}`).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const putPromotion = async (promotionId: number, promotion: Promotion) => {
  try {
    const response = await put(`${API_HOST}/promotions/${promotionId}`, promotion).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const createPromotion = async (promotion: Promotion) => {
  try {
    const response = await post(`${API_HOST}/promotions`, promotion).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const uploadPromotionBanner = async (promotionId: number, banner: any) => {
  try {
    const response = await post(`${API_HOST}/promotions/${promotionId}/banner`, banner).then(res => res.data);
    return response;
  } catch (error) {
    if (idx(error, _ => _.response.data.errors)) {
      return Promise.reject(idx(error, _ => _.response.data.errors));
    }
    return null;
  }
};

export const uploadPromotionVobler = async (promotionId: number, banner: any) => {
  try {
    const response = await post(`${API_HOST}/promotions/${promotionId}/vobler`, banner).then(res => res.data);
    return response;
  } catch (error) {
    if (idx(error, _ => _.response.data.errors)) {
      return Promise.reject(idx(error, _ => _.response.data.errors));
    }
    return null;
  }
};

export const deletePromotionVobler = async (promotionId: number) => {
  try {
    const response = await del(`${API_HOST}/promotions/${promotionId}/vobler`).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};


export const deletePromotionBanner = async (promotionId: number) => {
  try {
    const response = await del(`${API_HOST}/promotions/${promotionId}/banner`).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

