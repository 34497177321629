import { useEffect, useState } from 'react';
import { fetchPromotions } from 'services/promotionApi';
import { getExternalReviews } from 'services/reviewApi';

export const useGetPromotions = ({ variables = {}, skip = false }) => {
  const [data, setData] = useState({ data: [], totalCount: 0 });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!skip) {
      setLoading(true);
      fetchPromotions(variables).then(res => {
        setLoading(false);
        setData(res);
      });
    }
  }, [variables.past, variables.present, variables.future, variables.email, skip]);

  return { data, loading };
};

export const useGetExternalReviews = () => {
  const [data, setData] = useState({ data: [], totalCount: 0 });

  const [loading, setLoading] = useState(false);

  const refetch = () => {
    setLoading(true);
    getExternalReviews().then(res => {
      setLoading(false);
      setData(res);
    });
  };

  useEffect(refetch, []);

  return { data, loading, refetch };
};
