// @flow
// @flow-runtime enable
import { get } from 'lib/request';
import { API_HOST } from 'config';
import queryString from 'query-string';

type fetchImagesLogsQuery = {|
  'filter[start]'?: string,
  'filter[end]'?: string,
|}

export const fetchImagesLogs = (query: fetchImagesLogsQuery) =>
  get(`${API_HOST}/images-logs?${queryString.stringify(query)}`).then(res => res.data);

export const a = {};
