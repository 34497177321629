import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { setTopBarTitle } from 'components/top_bar/actions';
import ImagesLogs from './view/ImagesLogs';
import ImagesLogsTable from './view/ImagesLogsTable';
import Controls from './view/Controls';
import { fetchImagesLogs } from './actions';

class ImagesLogsContainer extends React.Component {
  state = {
    after: moment(),
    before: moment(),
  }

  componentDidMount() {
    this.props.setTopBarTitle('Отчет по добалвенным фотографиям');
  }

  fetchImagesLogs = () =>
    this.props.fetchImagesLogs(
      this.state.after.startOf('day').toISOString(),
      this.state.before.endOf('day').toISOString(),
    );

  handleDateChange = (name, value) => this.setState({ [name]: value })

  render() {
    return (
      <ImagesLogs
        isFetching={this.props.isFetching}
        controls={(
          <Controls
            after={this.state.after}
            before={this.state.before}
            handleDateChange={this.handleDateChange}
            fetchImagesLogs={this.fetchImagesLogs}
          />
        )}
        table={<ImagesLogsTable list={this.props.list} />}
      />
    );
  }
}

ImagesLogsContainer.propTypes = {
  fetchImagesLogs: PropTypes.func.isRequired,
  setTopBarTitle: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  list: PropTypes.any,
};

const mapStateToProps = store => {
  const state = store.get('imagesLogs');
  return {
    list: state.get('list'),
    isFetching: state.get('isFetching'),
  };
};

export default connect(
  mapStateToProps,
  { fetchImagesLogs, setTopBarTitle },
)(ImagesLogsContainer);
