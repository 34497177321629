import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FilterList from '@material-ui/icons/FilterList';
import UndefinedBrands from './view/UndefinedBrands';
import UndefinedBrandsFilter from './view/UndefinedBrandsFilter';
import {
  fetchUndefinedBrands,
  addToBrand,
  addBrandWithUpdate,
} from './actions';
import fuzzyFilter from '../fuzzy_filter';
import { fetchManufacturersIfNeeded } from '../manufacturers/actions';
import { getBrands } from '../brands/actions';
import { setTopBarTitle } from '../top_bar/actions';
import { setRightSideBarActions } from '../right_side_bar/actions';

const fuzzyOptionsByName = {
  extract: el => el.value,
  returnAllOnEmptyString: true,
  returnOriginal: true,
};

const fuzzyOptionsBySupplier = {
  ...fuzzyOptionsByName,
  extract: el => el.supplier.alias,
};

export class UndefinedBrandsContainer extends React.Component {
  state = {
    filterOpened: false,
    filterString: '',
    sources: [],
    selectedSource: '',
  }
  componentDidMount() {
    const actionsForRightSideBar = [
      {
        key: 'filterManufacturersOpened',
        icon: <FilterList />,
        name: 'Фильтрация',
        action: () => this.toggleFilterUndefinedBrands(),
      },
    ];
    this.props.fetchUndefinedBrands();
    this.props.fetchManufacturersIfNeeded();
    this.props.getBrands();
    this.props.setTopBarTitle('Новые бренды');
    this.props.setRightSideBarActions(actionsForRightSideBar);
  }
  setSources = undefinedBrands => {
    const sources = {};
    undefinedBrands.forEach(undBr => {
      sources[undBr.supplier.alias] = undBr.supplier.name;
    });
    this.setState({
      sources: Object.keys(sources).map(supplierAlias => (
        { alias: supplierAlias, name: sources[supplierAlias] }
      )),
    });
  }
  toggleFilterUndefinedBrands = () => {
    this.setState(prev => ({ filterOpened: !prev.filterOpened }));
    this.setSources(this.props.undefinedBrands);
  }
  handleChangeFeild = (name, value) => this.setState({ [name]: value });
  filterData = data => {
    let filtredData = [...data];
    filtredData = fuzzyFilter(this.state.filterString, filtredData, fuzzyOptionsByName);
    filtredData = fuzzyFilter(this.state.selectedSource, filtredData, fuzzyOptionsBySupplier);
    return filtredData;
  }
  render() {
    return (
      <React.Fragment>
        {this.state.filterOpened &&
          <UndefinedBrandsFilter
            handleChangeFeild={this.handleChangeFeild}
            filterString={this.state.filterString}
            sources={this.state.sources}
            selectedSource={this.state.selectedSource}
          />
        }
        <UndefinedBrands
          isFetching={this.props.isFetching}
          undefinedBrands={this.filterData(this.props.undefinedBrands)}
          manufacturers={this.props.manufacturers}
          brands={this.props.brands}
          addBrandWithUpdate={this.props.addBrandWithUpdate}
          addToBrand={this.props.addToBrand}
        />
      </React.Fragment>
    );
  }
}

UndefinedBrandsContainer.propTypes = {
  fetchManufacturersIfNeeded: PropTypes.func.isRequired,
  fetchUndefinedBrands: PropTypes.func.isRequired,
  getBrands: PropTypes.func.isRequired,
  addToBrand: PropTypes.func.isRequired,
  setRightSideBarActions: PropTypes.func.isRequired,
  setTopBarTitle: PropTypes.func.isRequired,
  addBrandWithUpdate: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  undefinedBrands: PropTypes.array.isRequired,
  manufacturers: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
};

const mapStateToProps = store => ({
  isFetching: store.getIn(['undefinedBrands', 'isFetching']),
  undefinedBrands: store.getIn(['undefinedBrands', 'items']).valueSeq().toArray(),
  manufacturers: store.getIn(['manufacturers', 'items']).valueSeq().toArray(),
  brands: store.getIn(['brands', 'items']).valueSeq().toArray(),
});

export default connect(
  mapStateToProps,
  {
    fetchManufacturersIfNeeded,
    fetchUndefinedBrands,
    getBrands,
    addToBrand,
    addBrandWithUpdate,
    setTopBarTitle,
    setRightSideBarActions,
  },
)(UndefinedBrandsContainer);
