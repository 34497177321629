// @flow
import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  type RequestUser,
  type ReceiveUser,
} from 'types/actions';
import { type typeUser } from 'types/entities/typeUser';
import { getCurrentUser } from 'services/userApi';
// import userData from 'tests/json/towns_response.json';

const requestUser = (): RequestUser => ({
  type: FETCH_USER_REQUEST,
});

const receiveUser = (user: typeUser | null): ReceiveUser => ({
  type: FETCH_USER_SUCCESS,
  user,
});

export const fetchUser = () => (dispatch: $FlowFixMe) => {
  dispatch(requestUser());
  return getCurrentUser()
    .then(
      res => {
        if (res.status === 200) {
          return dispatch(receiveUser(res.data));
        }
      },
    )
    // .catch(() => dispatch(receiveUser(null)));
};

export const a = {};
