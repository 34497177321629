// @flow

import { get, patch } from 'lib/request';
import { API_HOST } from 'config';
import queryString from 'query-string';

export const getReviews = async (params: { product: any }) => {
  try {
    const response = await get(`${API_HOST}/reviews?${queryString.stringify(params)}`).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const patchReview = async (data: { id: number, enabled?: boolean }) => {
  try {
    const response = await patch(`${API_HOST}/reviews/${data.id}`, data).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const patchExternalReview = async (data: { id: number, enabled?: boolean }) => {
  try {
    const response = await patch(`${API_HOST}/review_shop/${data.id}`, data).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const getExternalReviews = async () => {
  try {
    const response = await get(`${API_HOST}/review_shop`).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};
