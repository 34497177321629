// @flow
import { type typeMetaTagData} from './typeMetaTagData';

export type typeCategory = {
  id: number,
  name: string,
  is_publish?: boolean,
  title?: string | null,
  pathway?: Array<any>,
  meta_tag_data?: typeMetaTagData | Object | null,
};
