// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Warehouse from 'components/shop/warehouse';
import idx from 'idx';
import { withRouter } from 'react-router-dom';
import { fetchWarehouse } from 'components/shop/warehouse/actions';

type Props = {|
  fetchWarehouse: (warehouseId: number) => void,
  warehouseId: number | null | void,
  history: any,
  location: any,
  match: any,
  staticContext: any,
|};

export class WarehousePageContainer extends React.Component<Props> {
  componentDidMount() {
    if (idx(this.props.match, _ => _.params.warehouseId)) {
      this.props.fetchWarehouse(Number(idx(this.props.match, _ => _.params.warehouseId)));
    }
  }

  render() {
    return (
      <Warehouse />
    );
  }
}

WarehousePageContainer.propTypes = {
  warehouseId: PropTypes.any,
  fetchWarehouse: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default withRouter(connect(
  mapStateToProps,
  { fetchWarehouse },
)(WarehousePageContainer));
