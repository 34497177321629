// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import { ProductTypeSelect } from 'components/product-type-select';
import { Button } from '@material-ui/core';
import { addProductsToType } from 'services/productTypeApi';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
});

const TypeEditComponent = ({ classes, products, onChange }) => {
  const [type, setType] = useState(null);

  const handleOnChange = () => {
    if (type && products && products.length) {
      addProductsToType({ typeId: type.id, products: products.map(product => product.id) }).then(
        onChange,
      );
    }
  };
  return (
    <div className={classes.root}>
      <ProductTypeSelect type={type} onChange={setType} />
      <Button
        className={classes.button}
        variant="raised"
        color="secondary"
        onClick={handleOnChange}
        disabled={!type}
      >
        назначить тип
      </Button>
    </div>
  );
};
export const TypeEdit: ReactComponent<Props> = withStyles(styles)(TypeEditComponent);
