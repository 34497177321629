// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import LocationOn from '@material-ui/icons/LocationOn';
import { type Theme, type ReactComponent } from 'types/components';
import { Map } from 'components/map';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    width: '36px',
    height: '36px',
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  points: Array<any> | null,
  onClick?: Function,
|};

const MapDialogComponent = ({ classes, points, onClick }: {| ...Props, ...ProvidedProps |}) => {
  const [state, setState] = useState(false);
  const handleOnClick = () => {
    if (onClick) {
      return onClick(() => setState(!state));
    }
    setState(!state);
  };
  return (
    <React.Fragment>
      <IconButton className={classes.button} onClick={handleOnClick}>
        <LocationOn />
      </IconButton>
      <Dialog fullScreen open={state} onClose={handleOnClick}>
        <DialogContent>
          <Map points={points} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClick}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export const MapDialog: ReactComponent<Props> = withStyles(styles)(MapDialogComponent);
