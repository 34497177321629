import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TopBar from './view/TopBar';
import { toggleRightSideBar } from '../right_side_bar/actions';
import { toggleLeftSideBar } from '../left_side_bar/actions';

const TopBarContainer = props => (
  <TopBar
    toggleRightSideBar={props.toggleRightSideBar}
    toggleLeftSideBar={props.toggleLeftSideBar}
    rightSideBarOpened={props.rightSideBarOpened}
    leftSideBarOpened={props.leftSideBarOpened}
    actions={props.actions}
    title={props.title}
    user={props.user}
  />
);

TopBarContainer.propTypes = {
  toggleRightSideBar: PropTypes.func.isRequired,
  toggleLeftSideBar: PropTypes.func.isRequired,
  rightSideBarOpened: PropTypes.bool.isRequired,
  leftSideBarOpened: PropTypes.bool.isRequired,
  actions: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

const mapStateToProps = store => {
  const rightSideBarState = store.get('rightSideBar');
  const leftSideBarState = store.get('leftSideBar');
  const topBarState = store.get('topBar');
  const user = store.get('user');
  return {
    rightSideBarOpened: rightSideBarState.get('opened'),
    leftSideBarOpened: leftSideBarState.get('opened'),
    actions: rightSideBarState.get('actions').valueSeq().toArray(),
    title: topBarState.get('title'),
    user: user.data,
  };
};

export default connect(
  mapStateToProps,
  { toggleRightSideBar, toggleLeftSideBar },
)(TopBarContainer);
