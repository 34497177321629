// @flow
import React from 'react';
import PropTypes from 'prop-types';
import WithConfirm from './view/WithConfirm';

type Props = {|
  onConfirm?: () => void,
  onCancel?: () => void,
  onClose?: () => void,
  confirmTitle?: string,
  cancelTitle?: string,
  children: Object,
  disabled?: boolean,
|};

type State = {|
  anchorEl: Object | null,
|};

export class WithConfirmContainer extends React.Component<Props, State> {
  state = {
    anchorEl: null,
  };

  handleClose = () => {
    if (this.props.onClose) this.props.onClose();
    return this.setState({ anchorEl: null });
  };

  handleOpen = (e: Object) => {
    if (!this.props.disabled) this.setState({ anchorEl: e.currentTarget });
  };

  handleCancel = () => {
    if (this.props.onCancel) this.props.onCancel();
    return this.setState({ anchorEl: null });
  };
  handleConfirm = () => {
    if (this.props.onConfirm) this.props.onConfirm();
    return this.setState({ anchorEl: null });
  };

  render() {
    return (
      <React.Fragment>
        <div onClick={this.handleOpen} style={{ display: 'inline-block' }}>
          {this.props.children}
        </div>
        <WithConfirm
          handleClose={this.handleClose}
          handleConfirm={this.handleConfirm}
          handleCancel={this.handleCancel}
          anchorEl={this.state.anchorEl}
          confirmTitle={this.props.confirmTitle}
          cancelTitle={this.props.cancelTitle}
        />
      </React.Fragment>
    );
  }
}

WithConfirmContainer.propTypes = {
  onConfirm: PropTypes.any,
  onCancel: PropTypes.any,
  onClose: PropTypes.any,
  confirmTitle: PropTypes.any,
  children: PropTypes.object.isRequired,
};

export default WithConfirmContainer;
