// @flow
// @flow-runtime enable
import { type Alias } from './Alias';

export type Manufacturer = {|
  id: number,
  name: string,
  slug?: string | null,
  aliases?: Array<Alias>,
|};
