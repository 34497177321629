// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Settings from '@material-ui/icons/Settings';
import LocalOffer from '@material-ui/icons/LocalOffer';
import ViewComfy from '@material-ui/icons/ViewComfy';
import CallSplit from '@material-ui/icons/CallSplit';
import { type Theme } from 'types/components/Theme';
import { type Brand } from 'types/entities/Brand';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import { type Manufacturer } from 'types/entities/Manufacturer';
import { type typeProductType } from 'types/entities/typeProductType';
import { type typeProductSeries } from 'types/entities/typeProductSeries';
import { type typeCategory } from 'types/entities/typeCategory';
import cn from 'classnames';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';

const styles = (theme: Theme) => ({
  avatar: {
    width: '22px',
    height: '22px',
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
  chip: {
    height: '22px',
  },
  type: {
    backgroundColor: green[100],
  },
  series: {
    backgroundColor: blue[100],
  },
});
type Props = {|
  classes: Object,
  brand?: Brand,
  type?: typeProductType,
  series?: typeProductSeries,
  manufacturer?: Manufacturer,
  category?: typeCategory,
  className?: string,
|};

const labels = {
  brand: { name: 'Бренд', icon: <LocalOffer /> },
  manufacturer: { name: 'Производитель', icon: <Settings /> },
  series: { name: 'Производитель', icon: <Settings /> },
  type: { name: 'Тип', icon: <ViewComfy /> },
  series: { name: 'Серия', icon: <CallSplit /> },
  category: { name: 'Категория', icon: <FormatListNumbered /> },
};

const ProductLabel = ({
  classes,
  brand,
  manufacturer,
  series,
  type,
  category,
  className,
}: Props) => {
  const brandKey = brand && 'brand';
  const manufacturerKey = manufacturer && 'manufacturer';
  const seriesKey = series && 'series';
  const typeKey = type && 'type';
  const categoryKey = category && 'category';
  const variant = brandKey || manufacturerKey || seriesKey || typeKey || categoryKey;
  const item = brand || manufacturer || type || series || category;
  if (!labels[variant]) return null;
  return (
    <Chip
      title={labels[variant] && labels[variant].name}
      className={cn(className, classes.chip, classes[variant])}
      avatar={
        <Avatar className={(classes.avatar, classes[variant])}>
          {labels[variant] && labels[variant].icon}
        </Avatar>
      }
      label={item.name}
    />
  );
};

export default withStyles(styles)(ProductLabel);
