// @flow
// @flow-runtime enable
import { get, post } from 'lib/request';
import { API_HOST } from 'config';
import idx from 'idx';

export const getProductsTypes = async () => {
  try {
    const response = await get(`${API_HOST}/products-types`).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const getProductsType = async id => {
  try {
    const response = await get(`${API_HOST}/products-types/${id}`).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const addProductsType = async (params: { name: string }) => {
  try {
    const response = await post(`${API_HOST}/products-types`, params).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const addProductsToType = async (params: { typeId: number, products: number[] }) => {
  try {
    const response = await post(
      `${API_HOST}/products-types/${params.typeId}/products`,
      params.products,
    ).then(res => res.data);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
