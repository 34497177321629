// @flow
import {
  type RequestOrders,
  type ReceiveOrders,
  type RequestOrder,
  type ReceiveOrder,
} from './Order';
import {
  type RequestWarehouses,
  type ReceiveWarehouses,
} from './Warehouse';
import {
  type RequestPromotions,
  type ReceivePromotions,
} from './Promotion';
import { type LOCATION_CHANGE } from './Router';
import { type ToggleAssemblyOrderDialog } from './AssemblyOrder';
import { type RequestDeliveryPeriods, type ReceiveDeliveryPeriods } from './DeliveryPeriods';
import { type ReceiveSeries } from './Series';
import { type ReceiveProductsTypes } from './ProductsTypes';

export type Action =
  | ReceiveProductsTypes
  | ReceiveSeries
  | LOCATION_CHANGE
  | RequestOrders
  | ReceiveOrders
  | RequestOrder
  | ReceiveOrder
  | RequestWarehouses
  | ReceiveWarehouses
  | RequestPromotions
  | ReceivePromotions
  | RequestDeliveryPeriods
  | ReceiveDeliveryPeriods
  | ToggleAssemblyOrderDialog
  | any;
