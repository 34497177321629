import { TOGGLE_LEFT_SIDE_BAR } from 'types/actions';
import Immutable from 'immutable';

const leftSideBarInitialState = Immutable.Map({
  opened: false,
});

const leftSideBar = (state = leftSideBarInitialState, action) => {
  switch (action.type) {
    case TOGGLE_LEFT_SIDE_BAR: {
      return state.set('opened', !state.get('opened'));
    }
    default: {
      return state;
    }
  }
};

export default leftSideBar;
