// @flow
import React, { type Node } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  reviews: Node,
|};

const ReviewsLayoutComponent = ({ classes, reviews }: {| ...Props, ...ProvidedProps |}) => (
  <div className={classes.root}>{reviews}</div>
);

export const ReviewsLayout: ReactComponent<Props> = withStyles(styles)(ReviewsLayoutComponent);
