// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type ReactComponent } from 'types/components/ReactComponent';
import { type OrderState, orderStatesList, orderStates } from 'types/entities/OrderState';
import { type Order } from 'types/entities/Order';
import Paper from '@material-ui/core/Paper';
import Close from '@material-ui/icons/Close';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import cn from 'classnames';
import { COURIER, PICK_UP, CDEC } from 'types/entities/RealizationMethodType';
import { OrderStateLabel } from 'components/shop/order-state-label';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    display: 'flex',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  states: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  stateBlock: {
    padding: theme.spacing.unit / 2,
    paddingLeft: 0,
  },
  state: {
    opacity: 0.2,
  },
  controlsItem: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit * 2,
  },
  between: {
    '& > div': {
      marginRight: theme.spacing.unit,
      maxWidth: '140px',
    },
  },
  selected: {
    opacity: 1,
  },
  ordersCount: {
    fontSize: theme.typography.pxToRem(12),
  },
  phone: {
    maxWidth: '140px',
  },
  getOrders: {
    '& > button': {
      minHeight: '28px',
      height: '100%',
    },
  },
});

const realizationMethods = [
  { type: 'all', name: 'Все' },
  { type: COURIER, name: 'Доставка' },
  { type: PICK_UP, name: 'Самовывоз' },
  { type: CDEC, name: 'СДЕК' },
];

type PassedProps = {|
  classes: Object,
|};

type Props = {|
  selectedStates: Array<OrderState>,
  selectedRealizationMethod: 'all' | typeof COURIER | typeof PICK_UP,
  changeSelectedStates: (state: OrderState) => void,
  changePhone: (value: string) => void,
  phone: string,
  changeRealizationMethod: (realizationMethod: 'all' | typeof COURIER | typeof PICK_UP) => void,
  orders: Array<Order> | null,
  datePicker: any,
  readinessTimePicker: any,
  getOrdersButton: any,
  warehouseFilter: any,
  liked: any,
  onLinePayed: any,
|};

const ControlsComponent = ({
  classes,
  selectedStates,
  selectedRealizationMethod,
  changeRealizationMethod,
  changeSelectedStates,
  orders,
  phone,
  changePhone,
  datePicker,
  getOrdersButton,
  warehouseFilter,
  readinessTimePicker,
  addressFilter,
  liked,
  onLinePayed,
}: {|
  ...PassedProps,
  ...Props,
|}) => {
  const handleChangeSelectedStates = (state: OrderState) => () => {
    changeSelectedStates(state);
  };

  return (
    <Paper className={classes.root}>
      <div>
        <div className={classes.row}>
          <div className={classes.controlsItem}>
            <div className={classes.states}>
              {orderStatesList.map(orderState => (
                <div key={orderState} className={classes.stateBlock}>
                  <span
                    className={cn(
                      classes.state,
                      selectedStates.includes(orderState) ? classes.selected : null,
                    )}
                  >
                    <OrderStateLabel
                      state={orderState}
                      onClick={handleChangeSelectedStates(orderState)}
                    />
                  </span>

                  {orders && (
                    <span className={classes.ordersCount}>
                      {' × '}
                      {orderState === orderStates.all
                        ? orders && orders.length
                        : orders && orders.filter(({ state }) => state === orderState).length}
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={cn(classes.controlsItem, classes.between)}>{datePicker}</div>
          <div className={classes.controlsItem}>{readinessTimePicker}</div>
          <div className={classes.controlsItem}>
            <TextField
              className={classes.phone}
              label="Телефон"
              margin="none"
              value={phone}
              onChange={({ target }) => changePhone(target.value)}
              InputProps={{
                endAdornment: phone && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => changePhone('')}
                    >
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.controlsItem}>
            <Select
              value={selectedRealizationMethod}
              onChange={e => changeRealizationMethod(e.target.value)}
            >
              {realizationMethods.map(item => (
                <MenuItem key={item.type} value={item.type}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.controlsItem}>{warehouseFilter}</div>
          <div className={classes.controlsItem}>{addressFilter}</div>
          <div className={classes.controlsItem}>{liked}</div>
          <div className={classes.controlsItem}>{onLinePayed}</div>
        </div>
      </div>
      <div className={classes.getOrders}>{getOrdersButton}</div>
    </Paper>
  );
};
export const Controls: ReactComponent<Props> = withStyles(styles)(ControlsComponent);
