import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Choiser from '../../choiser';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

const BrandsDialog = ({
  classes,
  opened,
  type,
  toggleDialog,
  handleChangeFeild,
  name,
  adapted_name,
  editBrand,
  addBrand,
  slug,
  manufacturers,
  setSelectedManufacturer,
  selectedManufacturer,
}) => {
  const content = () => {
    if (type === 'add_brand') {
      return (
        <React.Fragment>
          <DialogTitle id="form-dialog-title">Создание нового бренда</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Название бренда"
              type="text"
              value={name}
              name="name"
              fullWidth
              onChange={e => handleChangeFeild(e.target.name, e.target.value)}
            />
            <Choiser
              fullWidth
              inputLabel="Выбор производителя"
              suggestions={manufacturers}
              selected={selectedManufacturer}
              returnSuggestion={suggestion =>
                setSelectedManufacturer(suggestion)
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialog}>
              Отмена
            </Button>
            <Button
              onClick={addBrand}
              color="primary"
              disabled={!name.length || !selectedManufacturer}
            >
              Создать
            </Button>
          </DialogActions>
        </React.Fragment>
      );
    }
    if (type === 'edit_brand') {
      return (
        <React.Fragment>
          <DialogTitle id="form-dialog-title">Редакторование бренда</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Название бренда"
              type="text"
              value={name}
              name="name"
              fullWidth
              onChange={e => handleChangeFeild(e.target.name, e.target.value)}
            />
            <TextField
              margin="dense"
              label="Адаптированое название"
              type="text"
              value={adapted_name || ''}
              name="adapted_name"
              fullWidth
              onChange={e => handleChangeFeild(e.target.name, e.target.value)}
            />
            <TextField
              margin="dense"
              label="slug"
              type="text"
              value={slug || ''}
              name="slug"
              fullWidth
              onChange={e => handleChangeFeild(e.target.name, e.target.value)}
            />
            <Choiser
              fullWidth
              inputLabel="Выбор производителя"
              suggestions={manufacturers}
              selected={selectedManufacturer}
              returnSuggestion={suggestion =>
                setSelectedManufacturer(suggestion)
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialog}>
              Отмена
            </Button>
            <Button onClick={editBrand} color="primary">
              Сохранить
            </Button>
          </DialogActions>
        </React.Fragment>
      );
    }
    return <div />;
  };
  return (
    <Dialog
      open={opened}
      onClose={toggleDialog}
      aria-labelledby="form-dialog-title"
    >
      {content()}
    </Dialog>
  );
};

BrandsDialog.defaultProps = {
  selectedManufacturer: null,
};

BrandsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  editBrand: PropTypes.func.isRequired,
  addBrand: PropTypes.func.isRequired,
  handleChangeFeild: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  adapted_name: PropTypes.string.isRequired,
  manufacturers: PropTypes.array.isRequired,
  setSelectedManufacturer: PropTypes.func.isRequired,
  selectedManufacturer: PropTypes.object,
};

export default withStyles(styles)(BrandsDialog);
