// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { type Theme, type ReactComponent } from 'types/components';

const styles = (theme: Theme) => ({
  root: {
    height: '32px',
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  completed: boolean,
  onChange: Function,
|};

const CompleteStatusSwitcherComponent = ({
  classes,
  completed,
  onChange,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => (
  <FormControlLabel
    className={classes.root}
    control={<Switch checked={completed} onChange={onChange} />}
    label={completed ? 'Выполненные' : 'Невыполненные'}
  />
);

export const CompleteStatusSwitcher: ReactComponent<Props> = withStyles(styles)(
  CompleteStatusSwitcherComponent,
);
