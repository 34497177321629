import { normalize, schema } from 'normalizr';
import { API_HOST } from 'config';
import { get, post } from 'lib/request';
import {
  FETCH_UNDEFINED_BRANDS_REQUEST,
  FETCH_UNDEFINED_BRANDS_SUCCESS,
} from 'types/actions';
import { addNotification } from '../notifications/actions';
import { addBrand } from '../brands/actions';

export const undefinedBrandsEntity = new schema.Entity('undefinedBrands');

export const requestUndefinedBrands = value => ({
  type: FETCH_UNDEFINED_BRANDS_REQUEST,
  value,
});

export const receiveUndefinedBrands = undefinedBrands => ({
  type: FETCH_UNDEFINED_BRANDS_SUCCESS,
  undefinedBrands,
});

export const fetchUndefinedBrands = () => dispatch => {
  dispatch(requestUndefinedBrands(true));
  get(`${API_HOST}/undefinedBrands`)
    .then(
      res => {
        const payload = normalize(res.data, {
          undefinedBrands: [undefinedBrandsEntity],
        });
        dispatch(receiveUndefinedBrands(payload.entities.undefinedBrands));
      },
      () => { dispatch(addNotification('Неудачная попытка загрузки неизвестных брендов')); },
    );
};

export const addToBrand = (idBrand, idUndefBrand, value) => dispatch =>
  post(`${API_HOST}/brands/${idBrand}/aliases`, { id: idUndefBrand, value })
    .then(
      () => dispatch(fetchUndefinedBrands()),
      () => { dispatch(addNotification('Неудачная попытка добавить синоним к бренду')); },
    );
export const addBrandWithUpdate = (name, manufacturer) => dispatch =>
  dispatch(addBrand(name, manufacturer, fetchUndefinedBrands));
