import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { AccountCircle, Phone, Store } from '@material-ui/icons';
import idx from 'idx';

export const Cdek = ({ realizationMethod, ...props }) => {
  console.log({ realizationMethod, ...props });
  return (
    <List disablePadding>
      <ListItem
        title="ФИО"
        divider
        disableGutters
      >
        <ListItemIcon
        >
          <AccountCircle />
        </ListItemIcon>
        <ListItemText
          primary={idx(realizationMethod, _ => _.recipient_name)}
        />
      </ListItem>
      <ListItem
        title="Телефон"
        divider
        disableGutters
      >
        <ListItemIcon
        >
          <Phone />
        </ListItemIcon>
        <ListItemText
          primary={idx(realizationMethod, _ => _.recipient_phone)}
        />
      </ListItem>
      <ListItem
        title="Телефон"
        divider
        disableGutters
      >
        <ListItemIcon
        >
          <Store />
        </ListItemIcon>
        <ListItemText
          primary={`${idx(realizationMethod, _ => _.cdec_delivery_point.name)}, ${idx(realizationMethod, _ => _.cdec_delivery_point.code)}`}
          secondary={idx(realizationMethod, _ => _.cdec_delivery_point.address.line)}
        />
      </ListItem>
    </List>
  );
};

// cdec_delivery_point: Object { code: "NSK321", name: "4880 Постамат Халва", uuid: "af75c74a-36f9-433b-b36c-8021cb43a505", … }
// ​​​
// address: Object { line: "630123, Новосибирская область, г. Новосибирск", id: 17660, postcode: 630123, … }
// ​​​
// address_full: "630123, Россия, Новосибирская область, Новосибирск, ш. Мочищенское, 16"
// ​​​
// code: "NSK321"
// ​​​
// height_max: 450
// ​​​
// length_max: 380
// ​​​
// name: "4880 Постамат Халва"
// ​​​
// type: "POSTAMAT"
// ​​​
// uuid: "af75c74a-36f9-433b-b36c-8021cb43a505"
// ​​​
// weight_max: 15000
// ​​​
// weight_min: 0
// ​​​
// width_max: 350