// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FilterList from '@material-ui/icons/FilterList';
import blue from '@material-ui/core/colors/blue';
import classnames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';

const styles = theme => ({
  list: {
    padding: 0,
  },
  icon: {
    width: '30px',
    height: '30px',
  },
  listItem: {
    minHeight: '47px',
    paddingLeft: theme.spacing.unit,
    display: 'flex',
    alignItems: 'center',
  },
  openedListItem: {
    backgroundColor: theme.palette.grey[200],
  },
  textWithFilter: {
    display: 'flex',
    alignItems: 'center',
  },
  filter: {
    marginLeft: theme.spacing.unit,
  },
  filterOn: {
    color: blue[500],
  },
  iconPlace: {
    width: '30px',
    height: '30px',
    marginRight: theme.spacing.unit,
  },
});

type Props = {
  classes: Object,
}

const getCategories = length => {
  const categories = [];
  let i = 0;
  while (length > i) {
    categories.push({ id: i });
    i += 1;
  }
  return categories;
};

const categories = getCategories(15);

const MarkupCategoriesSkeleton = ({
  classes,
}: Props) => {
  const item = (category, level: number) => (
    <React.Fragment key={category.id}>
      <ListItem
        style={level ? { paddingLeft: 48 * level } : null}
        dense
        divider
        className={classes.listItem}
      >
        <div className={classes.iconPlace}>
          <IconButton
            disabled
            className={classes.icon}
          >
            <ExpandMore />
          </IconButton>
        </div>
        <div className={classes.textWithFilter}>
          <ListItemText
            style={{ width: '200px' }}
            primary={<Skeleton />}
          />
          <IconButton
            disabled
            className={
              classnames(
                classes.icon,
                classes.filter,
              )
            }
          >
            <FilterList />
          </IconButton>
        </div>
        <ListItemSecondaryAction>
          <IconButton
            disabled
            className={
              classnames(
                classes.icon,
              )
            }
          >
            <AddCircleOutline />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </React.Fragment>
  );
  return (
    <Paper className={classes.root}>
      <List className={classes.list}>
        {categories.map(category => item(category, 0))}
      </List>
    </Paper>
  );
};

MarkupCategoriesSkeleton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MarkupCategoriesSkeleton);
