// @flow
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import {
  getProductUrlSources,
  setProductUrlSource,
  deleteProductUrlSource,
} from 'services/productApi';
import { connect } from 'react-redux';
import { addNotification as addNotificationAction } from 'components/notifications/actions';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
  },
  eclipsedLink: {
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});

type ProvidedProps = {|
  classes: any,
  addNotification: (message: string) => void,
|};

type Props = {|
  productId: number,
|};

const PriceSourceAddComponent = ({
  classes,
  productId,
  addNotification,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const [url, setUrl] = useState('');

  const [urls, setUrls] = useState(null);

  const getProductUrls = () => {
    getProductUrlSources({ productId }).then(urls => setUrls(urls));
    setUrl('');
  };

  useEffect(() => {
    getProductUrls();
  }, []);

  const handleAdd = () => {
    setProductUrlSource({ productId, data: { url } }).then(res => {
      getProductUrls();
      if (res.errors) {
        res.errors.forEach(error => addNotification(error.title));
      }
    });
  };

  const handleDeleteSource = ({ host }) => {
    deleteProductUrlSource({ productId, host }).then(() => getProductUrls());
  };

  const urlIsValid = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
    url,
  );

  return (
    <div className={classes.root}>
      <Typography variant="headline">Мониторинг цен</Typography>
      <TextField
        label="Cсылка"
        value={url}
        fullWidth
        margin="normal"
        helperText={url && !urlIsValid ? 'Проверьте url' : null}
        onChange={e => setUrl(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={!url || !urlIsValid}
                title="Добавить"
                color="secondary"
                onClick={handleAdd}
              >
                <Add />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <List>
        {urls &&
          urls.map(source => (
            <ListItem>
              <ListItemText
                className={classes.eclipsedLink}
                primary={
                  <a href={source.url} target="_blank">
                    {source.url}
                  </a>
                }
              />
              <ListItemSecondaryAction>
                <IconButton onClick={() => handleDeleteSource(source)} title="Удалить">
                  <Close />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
    </div>
  );
};
export const PriceSourceAdd: ReactComponent<Props> = connect(() => {}, {
  addNotification: addNotificationAction,
})(withStyles(styles)(PriceSourceAddComponent));
