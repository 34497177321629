import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Photo from '@material-ui/icons/Photo';
import { API_HOST } from 'config';

const styles = theme => ({
  table: {
    marginTop: theme.spacing.unit * 2,
  },
  count: {
    marginTop: theme.spacing.unit * 2,
  },
});

const ImagesLogs = ({ classes, list }) => {
  if (!list) return null;
  const results = {};
  list.forEach(item => {
    const { user } = item;
    if (results[user.id]) {
      results[user.id].count += 1;
      return null;
    }
    results[user.id] = { user, count: 1 };
    return null;
  });
  return (
    <div>
      {list && (
      <Typography variant="subheading" className={classes.count}>
        {Object.keys(results).map((id, index) => {
          const result = results[id];
          return `${index > 0 ? ', ' : ''}${result.user.full_name ? result.user.full_name : result.user.phone}: ${result.count}`;
        })}
      </Typography>
      )}
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Дата</TableCell>
              <TableCell>Пользователь</TableCell>
              <TableCell numeric>Фото</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list && list.map(i => (
              <TableRow key={i.image_product.public_path}>
                <TableCell component="th" scope="row">
                  {moment(i.create_time).format('YYYY.MM.DD HH:mm:ss')}
                </TableCell>
                <TableCell>
                  {i.user.full_name ? i.user.full_name : i.user.phone}
                </TableCell>
                <TableCell numeric>
                  <IconButton href={`${API_HOST}${i.image_product.public_path}`} target="_blank">
                    <Photo />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow />
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

ImagesLogs.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.any,
};

export default withStyles(styles)(ImagesLogs);
