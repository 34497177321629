// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import { uploadPrice } from 'services/warehouseApi';
import {
  IconButton,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import NoteAdd from '@material-ui/icons/NoteAdd';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const reportProps = {
  import_id: 'id',
  start_time: 'Начало импорта',
  supplier: 'Поставщик',
  count_new_sku: 'Новые sku',
  count_updated_sku: 'Обновлено sku',
  count_skip_sku: 'Пропущено sku',
  count_new_product: 'Новые товары',
  errors: 'Ошибки',
  undefined_brands_names: 'Неизвестные бренды',
  undefined_manufacturers: 'Неизвестные производители',
  reseted_items: 'Обнулено',
};

const PriceUploadFormComponent = ({ classes, url }: {| ...Props, ...ProvidedProps |}) => {
  const [result, setResult] = useState(null);

  const setAttachedFile = (filesList: FileList | null) => {
    if (filesList && filesList[0]) {
      uploadPrice({ url, file: filesList[0] }).then(res => {
        // @ts-ignore
        setResult(res.data);
        document.getElementById('attachFile').value = '';
      });
    }
  };

  const renderReslutItem = (key, item) => {
    if (!reportProps[key]) return null;

    if (key === 'supplier') {
      return (
        <ListItem key={key}>
          {reportProps[key]}: {item.name}
        </ListItem>
      );
    }

    if (key === 'errors') {
      return (
        <React.Fragment key={key}>
          <ListItem>{reportProps[key]}:</ListItem>
          {Object.keys(item).map(errorKey => (
            <ListItem key={errorKey}>
              <ListItemText
                inset
                primary={
                  <span>
                    {errorKey}: {item[errorKey]}
                  </span>
                }
              />
            </ListItem>
          ))}
        </React.Fragment>
      );
    }

    if (['undefined_brands_names', 'undefined_manufacturers'].includes(key)) {
      return (
        <ListItem key={key}>
          {reportProps[key]}: {item.join(', ')}
        </ListItem>
      );
    }

    return (
      <ListItem key={key}>
        {reportProps[key]}: {item}
      </ListItem>
    );
  };

  return (
    <div className={classes.root}>
      <IconButton htmlFor="attachFile" component="label">
        <NoteAdd />
        <input
          type="file"
          id="attachFile"
          multiple
          style={{ display: 'none' }}
          onChange={event => setAttachedFile(event.target.files)}
        />
      </IconButton>
      <Dialog open={Boolean(result)} onClose={() => setResult(null)}>
        <DialogContent>
          <List>
            {result && Object.keys(result).map(key => renderReslutItem(key, result[key]))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setResult(null)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export const PriceUploadForm: ReactComponent<Props> = withStyles(styles)(PriceUploadFormComponent);
