// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import { Collapse, ButtonBase, Paper, Grid, Divider, IconButton, Hidden } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import WithConfirm from 'components/with-confirm';
import CheckCircle from '@material-ui/icons/CheckCircle';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import cn from 'classnames';
import { confirmReceiveAssemblyPlan } from 'services/deliveryApi';
import moment from 'moment';

const styles = (theme: Theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    // marginTop: theme.spacing.unit * 2,
  },
  warehouse: {
    marginBottom: theme.spacing.unit,
    '& > button': {
      textAlign: 'left',
      fontSize: theme.typography.pxToRem(18),
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing.unit,
    },
    '& > button > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  orders: {
    padding: theme.spacing.unit,
  },
  confirmButton: {
    width: '38px',
    height: '38px',
  },
  confirmButtonBlock: {
    position: 'relative',
  },
  confirmButtonWrapper: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  order: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
  success: {
    color: green[500],
  },
  fail: {
    color: red[500],
  },
  successBg: {
    backgroundColor: green[50],
  },
  failBg: {
    backgroundColor: red[50],
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const AssemblyListComponent = ({
  classes,
  deliveries,
  onConfirm,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const [opendedWarehouse, setOpened] = useState(null);

  const getWarehouses = deliveries => {
    if (!deliveries) return deliveries;
    const warehouses = {};
    deliveries.forEach(delivery => {
      delivery &&
        delivery.orders.forEach(order =>
          order.plans.forEach(plan =>
            warehouses[plan.warehouse_donor.id]
              ? (warehouses[plan.warehouse_donor.id] = {
                  ...plan.warehouse_donor,
                  orders: {
                    ...warehouses[plan.warehouse_donor.id].orders,
                    [order.id]: {
                      ...order,
                      plan,
                      delivery,
                      completed: plan.courier_loading_time,
                    },
                  },
                })
              : (warehouses[plan.warehouse_donor.id] = {
                  ...plan.warehouse_donor,
                  orders: {
                    [order.id]: {
                      ...order,
                      delivery,
                      plan,
                      completed: plan.courier_loading_time,
                    },
                  },
                }),
          ),
        );
    });
    return warehouses;
  };

  const warehouses = getWarehouses(deliveries);

  const handleConfirmReceive = ({ warehouse, order }) => () => {
    const { orders, ...rest } = warehouse;

    confirmReceiveAssemblyPlan({ orderId: order.id, warehouse: rest }).then(() => onConfirm());
  };

  return (
    <div className={classes.root}>
      {warehouses &&
        Object.keys(warehouses).map(warehouseId => {
          const warehouse = warehouses[warehouseId];
          const ordersCount = Object.keys(warehouse.orders).length;
          const completedOrdersCount = Object.keys(warehouse.orders).filter(
            orderId => warehouse.orders[orderId].completed,
          ).length;
          return (
            <Paper key={warehouseId} className={classes.warehouse}>
              <ButtonBase
                className={cn(
                  ordersCount === completedOrdersCount ? classes.successBg : classes.failBg,
                )}
                disableRipple
                onClick={() => setOpened(opendedWarehouse === warehouseId ? null : warehouseId)}
              >
                {warehouse.name}
                <div>
                  <div
                    className={cn(
                      ordersCount === completedOrdersCount ? classes.success : classes.fail,
                    )}
                  >
                    {completedOrdersCount}
                    {'/'}
                    {ordersCount}
                  </div>
                  {opendedWarehouse === warehouseId ? <ExpandLess /> : <ExpandMore />}
                </div>
              </ButtonBase>
              <Collapse in={opendedWarehouse === warehouseId} key={warehouseId}>
                <div className={classes.orders}>
                  <Grid container spacing={16}>
                    {Object.keys(warehouse.orders).map(orderId => {
                      const order = warehouse.orders[orderId];

                      return (
                        <React.Fragment key={orderId}>
                          <Grid
                            item
                            xs={12}
                            className={cn(classes.order, !order.completed && classes.failBg)}
                          >
                            <Grid container spacing={8}>
                              <Grid item xs={12} sm={1} className={classes.confirmButtonBlock}>
                                <a href={`/shop/order/${orderId}`} target="_blank">
                                  {orderId}
                                </a>
                                {/* {!order.completed && (
                                  <Hidden implementation="css" smUp>
                                    <div className={classes.confirmButtonWrapper}>
                                      <WithConfirm
                                        confirmTitle="Подтвердить получение"
                                        onConfirm={handleConfirmReceive({ warehouse, order })}
                                      >
                                        <IconButton
                                          color="secondary"
                                          className={classes.confirmButton}
                                        >
                                          <CheckCircle />
                                        </IconButton>
                                      </WithConfirm>
                                    </div>
                                  </Hidden>
                                )} */}
                              </Grid>
                              <Grid item xs={4} sm={2}>
                                {order.delivery.delivery_period && (
                                  <span>
                                    {' c '}
                                    {moment(order.delivery.delivery_period.start).format('HH:mm')}
                                    {' до '}
                                    {moment(order.delivery.delivery_period.end).format('HH:mm')}
                                  </span>
                                )}
                              </Grid>
                              <Grid item xs={4} sm={2}>
                                Место {order.plan.shipment_place_pointer}
                              </Grid>
                              <Grid item xs={8} sm={2}>
                                {order.delivery &&
                                  order.delivery.delivery_zone &&
                                  order.delivery.delivery_zone.name}
                              </Grid>
                              <Grid item xs={12} sm={2} className={classes.confirmButtonBlock}>
                                {order.plan.invoice}
                                {/* {!order.completed && (
                                  <Hidden implementation="css" xsDown>
                                    <div className={classes.confirmButtonWrapper}>
                                      <WithConfirm
                                        confirmTitle="Подтвердить получение"
                                        onConfirm={handleConfirmReceive({ warehouse, order })}
                                      >
                                        <IconButton
                                          color="secondary"
                                          className={classes.confirmButton}
                                        >
                                          <CheckCircle />
                                        </IconButton>
                                      </WithConfirm>
                                    </div>
                                  </Hidden>
                                )} */}
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                {order.delivery.courier && order.delivery.courier.full_name}
                              </Grid>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </div>
              </Collapse>
            </Paper>
          );
        })}
    </div>
  );
};
export const AssemblyList: ReactComponent<Props> = withStyles(styles)(AssemblyListComponent);
