// @flow
import {
  FETCH_PICKUP_TIME_REQUEST,
  FETCH_PICKUP_TIME_SUCCESS,
  FETCH_PICKUP_POINTS_REQUEST,
  FETCH_PICKUP_POINTS_SUCCESS,
} from 'types/actions/PickupTime';
import { type Action } from 'types/actions/Action';
import { type PickupTimesState } from 'types/store/PickupTimes';

export const pickupTimeInitialState: PickupTimesState = {
  isFetching: false,
  pickupPointsIsFetching: false,
  date: null,
  pickupPoints: null,
};

const pickupTime = (
  state: PickupTimesState = pickupTimeInitialState,
  action: Action,
): PickupTimesState => {
  switch (action.type) {
    case FETCH_PICKUP_TIME_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_PICKUP_TIME_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        date: action.pickupTime,
      };
    }
    case FETCH_PICKUP_POINTS_REQUEST: {
      return {
        ...state,
        pickupPointsIsFetching: true,
      };
    }
    case FETCH_PICKUP_POINTS_SUCCESS: {
      return {
        ...state,
        pickupPointsIsFetching: false,
        pickupPoints: action.pickupPoints,
      };
    }
    default: {
      return state;
    }
  }
};

export default pickupTime;
