// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { type Theme, type ReactComponent } from 'types/components';
import { type Brand } from 'types/entities/Brand';
import { type typeProductSeries } from 'types/entities/typeProductSeries';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
  count: {
    marginLeft: theme.spacing.unit * 2,
    color: theme.palette.grey[600],
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  brands: Array<{
    brand: Brand,
    series: Array<{ id: number, name: string }>,
  }>,
|};

const SeriesListComponent = ({ classes, brands }: {| ...Props, ...ProvidedProps |}) => {
  const [state, setState] = useState({ open: null });
  return (
    <List>
      {brands &&
        brands.sort((a, b) => a.brand.name > b.brand.name ? 1 : -1).map(item => (
          <React.Fragment key={item.brand.id}>
            <ListItem
              button
              onClick={() => setState({ ...state, open: state.open === item.brand.id ? null : item.brand.id })}
            >
              <ListItemText primary={<span>{item.brand.name}<span className={classes.count}>({item.series.length})</span></span>} />
            </ListItem>
            {state.open === item.brand.id &&
              item.series.sort((a, b) => a.name > b.name ? 1 : -1).map(series => (
                <ListItem
                  key={series.id}
                >
                  <ListItemText inset primary={series.name} />
                </ListItem>
              ))}
          </React.Fragment>
        ))}
    </List>
  );
};
export const SeriesList: ReactComponent<Props> = withStyles(styles)(SeriesListComponent);
