// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { type Address } from 'types/entities/Address';
import debounce from 'lodash.debounce';
import {
  fetchSuggestionsAddresses,
  fetchCleanAddress,
  convertAddress,
} from 'services/suggestApi';
import AddressDialog from './view/AddressDialog';

type Props = {|
 open: boolean,
 onlyVladivostok?: boolean,
 onSelect: (address: Address) => void,
 onClose: () => void,
|};

type State = {|
 addressesIsFetching: boolean,
 address: string | null,
 suggestedAddresses: $FlowFixMe | null,
|};

export class AddressDialogContainer extends React.Component<Props, State> {
  state = {
    suggestedAddresses: null,
    addressesIsFetching: false,
    address: null,
  }

  onSelect = (query: string) => () =>
    fetchCleanAddress(query).then(address =>
      this.props.onSelect(convertAddress(address)));

  getAddressSuggestion = (query: string) => {
    fetchSuggestionsAddresses(`${this.props.onlyVladivostok ? 'владивосток ' : ''}${query}`)
      .then(res =>
        this.setState({
          suggestedAddresses: res.data.suggestions,
        }));
  };


  changeAddressValue = debounce(value => this.getAddressSuggestion(value), 800);

  handleChange = (name: string, value: any) => this.setState({ [name]: value });

  render() {
    return (
      <AddressDialog
        suggestedAddresses={this.state.suggestedAddresses}
        isFetching={this.state.addressesIsFetching}
        address={this.state.address}
        changeAddressValue={this.changeAddressValue}
        onClose={this.props.onClose}
        handleChange={this.handleChange}
        onSelect={this.onSelect}
        open={this.props.open}
      />
    );
  }
}

AddressDialogContainer.propTypes = {
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = () => ({

});

export default connect(
  mapStateToProps,
  {},
)(AddressDialogContainer);
