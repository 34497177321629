// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FilterList from '@material-ui/icons/FilterList';
import blue from '@material-ui/core/colors/blue';
import classnames from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import { type CategoriesTree } from 'types/entities';
import { type typeCategory } from 'types/entities/typeCategory';
import { type TogglableStateFeilds, type Entity } from '../MarkupContainer';
import MarkupActions from './MarkupActions';

const styles = theme => ({
  list: {
    padding: 0,
  },
  icon: {
    width: '30px',
    height: '30px',
  },
  listItem: {
    minHeight: '47px',
    paddingLeft: theme.spacing.unit,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
      cursor: 'pointer',
    },
  },
  openedListItem: {
    backgroundColor: theme.palette.grey[200],
  },
  textWithFilter: {
    display: 'flex',
    alignItems: 'center',
  },
  filter: {
    marginLeft: theme.spacing.unit,
  },
  filterOn: {
    color: blue[500],
  },
  iconPlace: {
    width: '30px',
    height: '30px',
    marginRight: theme.spacing.unit,
  },
});

type Props = {
  classes: Object,
  categories: CategoriesTree,
  openedCategories: { [key: number]: boolean },
  openedMarkupCategories: { [key: number]: boolean },
  categoriesMarkupValue: { [key: number]: string },
  toggleCollapsable: (name: TogglableStateFeilds, manufacturerId: number) => void,
  toggleMarkup: (name: Entity, entityId: number) => void,
  handleChangeMarkupValue: (entityType: Entity, entityId: number, value: string) => void,
  deleteMarkup: (endpoint: string) => void,
  putMarkup: (endpoint: string, value: string) => void,
};

/* eslint-disable no-underscore-dangle */

const MarkupCategories = ({
  classes,
  categories,
  openedCategories,
  toggleMarkup,
  toggleCollapsable,
  openedMarkupCategories,
  categoriesMarkupValue,
  handleChangeMarkupValue,
  deleteMarkup,
  putMarkup,
  categoriesMarkups,
}: Props) => {
  const item = (category: typeCategory, level: number, opened: boolean) => {
    const categoryMarkup =
      categoriesMarkups && categoriesMarkups.find(item => item.category.id === category.id);
    return (
      <React.Fragment key={category.id}>
        <ListItem
          style={level ? { paddingLeft: 48 * level } : null}
          dense
          divider
          className={classnames(
            classes.listItem,
            openedCategories[category.id] || opened ? classes.openedListItem : null,
          )}
          onClick={() => toggleCollapsable('openedCategories', category.id)}
        >
          <div className={classes.iconPlace}>
            {category.__children && Boolean(category.__children.length) && (
              <IconButton
                className={classes.icon}
                onClick={() => toggleCollapsable('openedCategories', category.id)}
              >
                {openedCategories[category.id] ? (
                  <ExpandLess onClick={() => toggleCollapsable('openedCategories', category.id)} />
                ) : (
                  <ExpandMore onClick={() => toggleCollapsable('openedCategories', category.id)} />
                )}
              </IconButton>
            )}
          </div>
          <div className={classes.textWithFilter}>
            <ListItemText
              // eslint-disable-line no-underscore-dangle
              primary={category.name}
            />
            {/* <IconButton
            className={classnames(
              classes.icon,
              classes.filter,
              openedCategories[category.id] ? classes.filterOn : null,
            )}
          >
            <FilterList />
          </IconButton> */}
          </div>
          <ListItemSecondaryAction>
            <MarkupActions
              entity={categoryMarkup || { category }}
              entityType="category"
              opened={Boolean(openedMarkupCategories[category.id])}
              value={categoriesMarkupValue[category.id]}
              toggleMarkup={toggleMarkup}
              handleChangeMarkupValue={handleChangeMarkupValue}
              deleteMarkup={() =>
                deleteMarkup({
                  endpoint: '/categories_markups',
                  markup: {
                    category,
                    ...categoryMarkup,
                    value: categoriesMarkupValue[category.id],
                  },
                })
              }
              putMarkup={() =>
                putMarkup({
                  endpoint: '/categories_markups',
                  markup: {
                    category,
                    ...categoryMarkup,
                    value: categoriesMarkupValue[category.id],
                  },
                })
              }
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={openedCategories[category.id]} timeout="auto" unmountOnExit>
          {category.__children &&
            category.__children.map((
              subCategory, // eslint-disable-line no-underscore-dangle
            ) => item(subCategory, level + 1, Boolean(openedCategories[category.id] || opened)))}
        </Collapse>
      </React.Fragment>
    );
  };
  return (
    <Paper className={classes.root}>
      <List className={classes.list}>{categories.map(category => item(category, 0, false))}</List>
    </Paper>
  );
};

MarkupCategories.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  openedCategories: PropTypes.object.isRequired,
  toggleMarkup: PropTypes.func.isRequired,
  toggleCollapsable: PropTypes.func.isRequired,
  deleteMarkup: PropTypes.func.isRequired,
  putMarkup: PropTypes.func.isRequired,
};

export default withStyles(styles)(MarkupCategories);
