import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
});

const ImagesLogs = ({
  classes,
  isFetching,
  table,
  controls,
}) => (
  <div>
    {isFetching &&
      <LinearProgress color="secondary" />
    }
    <Paper className={classes.paper}>
      {controls}
    </Paper>
    {table}
  </div>
);

ImagesLogs.propTypes = {
  classes: PropTypes.object.isRequired,
  controls: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  table: PropTypes.any,
};

export default withStyles(styles)(ImagesLogs);
