// @flow
import { type TimePeriod, type Address } from 'types/entities/TimePeriod';
import {
  FETCH_DELIVERY_PERIODS_REQUEST,
  FETCH_DELIVERY_PERIODS_SUCCESS,
  type RequestDeliveryPeriods,
  type ReceiveDeliveryPeriods,
} from 'types/actions/DeliveryPeriods';
import { fetchDeliveryPeriods as fetchDeliveryPeriodsFromApi } from 'services/deliveryPeriodApi';

const requestDeliveryPeriods = (): RequestDeliveryPeriods => ({
  type: FETCH_DELIVERY_PERIODS_REQUEST,
});

const receiveDeliveryPeriods = (
  deliveryPeriods: Array<TimePeriod> | null,
): ReceiveDeliveryPeriods => ({
  type: FETCH_DELIVERY_PERIODS_SUCCESS,
  deliveryPeriods,
});

export const fetchDeliveryPeriods = (params: { address: Address, orderId: number }) => (dispatch: $FlowFixMe) => {
  dispatch(requestDeliveryPeriods());
  fetchDeliveryPeriodsFromApi(params).then(deliveryPeriods =>
    dispatch(receiveDeliveryPeriods(deliveryPeriods)),
  );
};
