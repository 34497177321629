// @flow
import {
  FETCH_DELIVERIES_REQUEST,
  FETCH_DELIVERIES_SUCCESS,
} from 'types/actions/Delivery';
import { type Action } from 'types/actions/Action';
import { type DeliveriesState } from 'types/store/Deliveries';
import { type AppStore } from 'types/store/AppStore';

export const deliveriesInitialState: DeliveriesState = {
  isFetching: false,
  data: null,
};

export const deliveries = (
  state: DeliveriesState = deliveriesInitialState,
  action: Action,
): any => {
  switch (action.type) {
    case FETCH_DELIVERIES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_DELIVERIES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.deliveries,
      };
    }
    default: {
      return state;
    }
  }
};
