// @flow
import { type PromotionParticipant } from './PromotionParticipant';

export type Promotion = {
  id: number,
  title: string,
  banner: string | null,
  start: string,
  end: string,
  description: string | null,
  id_klipper: string | null,
  type: string,
  is_enabled: boolean,
  has_products: boolean,
  discount_percentage?: number,
  is_promoted_by_email: boolean,
  email_body: string | null,
  participants?: any,
  background_color: string | null,
  caption: string | null,
};
