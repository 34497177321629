// @flow
import { normalize, schema } from 'normalizr';
import { get } from 'lib/request';
import {
  FETCH_SUPPLIERS_REQUEST,
  FETCH_SUPPLIERS_SUCCESS,
  type RequestSuppliers,
  type ReceiveSuppliers,
  type ThunkAction,
  type Dispatch,
  type GetState,
} from 'types/actions';
import { type Supplier } from 'types/entities';
import { addNotification } from '../notifications/actions';

export const suppliersEntity = new schema.Entity('suppliers', {}, { idAttribute: 'alias' });

export const requestSuppliers = (value: boolean): RequestSuppliers => ({
  type: FETCH_SUPPLIERS_REQUEST,
  value,
});

export const receiveSuppliers = (suppliers: {[key: string]: Supplier }): ReceiveSuppliers => ({
  type: FETCH_SUPPLIERS_SUCCESS,
  suppliers,
});

const normalizeResponse = json => normalize(json, { suppliers: [suppliersEntity] });

export const fetchSuppliers = (): ThunkAction => (dispatch: Dispatch) => {
  dispatch(requestSuppliers(true));
  get('/suppliers')
    .then(
      response => response.json(),
      () => { dispatch(addNotification('Неудачная попытка загрузки поставщиков')); },
    )
    .then((res) => {
      const payload = normalizeResponse(res.data);
      dispatch(receiveSuppliers(payload.entities.suppliers));
    });
};
