// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { type Promotion } from 'types/entities/Promotion';
import moment from 'moment';
import { Checkbox, IconButton, ListItemIcon, MuiThemeProvider } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const styles = theme => ({
  banner: {
    display: 'flex',
    justifyContent: 'center',
  },
  vobler: {
    marginTop: theme.spacing.unit * 2,
  },
});

type Props = {|
  classes: Object,
  title: any,
  caption: any,
  background_color: any,
  promotion: Promotion | null,
  promotionIsEnabled: boolean | null,
  is_promoted_by_email: boolean | null,
  uploadPromotionBanner: (promotionId: number, file: any) => void,
  changeState: (name: string, value: any) => void,
|};

const PromotionDetails = ({
  classes,
  promotion,
  uploadPromotionBanner,
  uploadPromotionVobler,
  deletePromotionVobler,
  deletePromotionBanner,
  changeState,
  promotionIsEnabled,
  title,
  is_promoted_by_email,
  is_promoted_by_web_push,
  caption,
  background_color,
  deleteVobler,
}: Props) => {
  const handleUploadPromotionBanner = (e: any) => {
    if (promotion && e.target.files[0]) {
      return uploadPromotionBanner(promotion.id, e.target.files[0]);
    }
    return null;
  };
  const handleUploadVobler = (e: any) => {
    if (promotion && e.target.files[0]) {
      return uploadPromotionVobler(promotion.id, e.target.files[0]);
    }
    return null;
  };
  const changeIsEnabled = value => () => changeState('promotionIsEnabled', value);
  const changeIsPromotedByEmail = value => () => changeState('is_promoted_by_email', value);
  const changeIsPromotedByWebPush = value => () => changeState('is_promoted_by_web_push', value);
  return (
    <div className={classes.root}>
      <div
        className={classes.banner}
        style={{ backgroundColor: promotion && promotion.background_color }}
      >
        {promotion && promotion.banner && <img src={promotion.banner} alt="Банер акции" />}
      </div>
      <div className={classes.vobler}>
        {promotion && promotion.vobler && <img src={promotion.vobler.origin_path} alt="Воблер" />}
      </div>
      <List disablePadding>
        <ListItem divider>
          <ListItemText primary={promotion ? `id: ${promotion.id}` : null} />
        </ListItem>
        <ListItem divider>{title}</ListItem>
        <ListItem divider>{caption}</ListItem>
        <ListItem divider>
          <ListItemText primary={promotion && promotion.id_klipper} secondary="код 1С" />
        </ListItem>
        <ListItem divider>
          <ListItemText primary={promotion && promotion.type} secondary="Тип акции" />
        </ListItem>
        <ListItem divider>
          <ListItemIcon>
            <Checkbox
              onClick={changeIsEnabled(
                promotionIsEnabled !== null
                  ? !promotionIsEnabled
                  : !Boolean(promotion && promotion.is_enabled),
              )}
              checked={Boolean(
                promotionIsEnabled !== null
                  ? promotionIsEnabled
                  : Boolean(promotion && promotion.is_enabled),
              )}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              promotion
                ? `${moment(promotion.start).format('DD.MM.YYYY, HH:mm')} - ${moment(
                    promotion.end,
                  ).format('DD.MM.YYYY, HH:mm')}`
                : null
            }
            secondary="период действия"
          />
        </ListItem>
        <ListItem divider>
          <ListItemIcon>
            <Checkbox
              checked={Boolean(
                is_promoted_by_email !== null
                  ? is_promoted_by_email
                  : Boolean(promotion && promotion.is_promoted_by_email),
              )}
              onClick={changeIsPromotedByEmail(
                is_promoted_by_email !== null
                  ? !is_promoted_by_email
                  : !Boolean(promotion && promotion.is_promoted_by_email),
              )}
            />
          </ListItemIcon>
          <ListItemText primary="участвует в Email рассылке" />
        </ListItem>
        <ListItem divider>
          <ListItemIcon>
            <Checkbox
              checked={Boolean(
                is_promoted_by_web_push !== null
                  ? is_promoted_by_web_push
                  : Boolean(promotion && promotion.is_promoted_by_web_push),
              )}
              onClick={changeIsPromotedByWebPush(
                is_promoted_by_web_push !== null
                  ? !is_promoted_by_web_push
                  : !Boolean(promotion && promotion.is_promoted_by_web_push),
              )}
            />
          </ListItemIcon>
          <ListItemText primary="Push-уведомления" />
        </ListItem>
        <ListItem divider button htmlFor="banner" component="label">
          <ListItemText primary={promotion && promotion.banner} secondary="баннер" />
          <input
            type="file"
            id="banner"
            multiple
            style={{ display: 'none' }}
            onChange={handleUploadPromotionBanner}
          />
          <ListItemSecondaryAction>
            <IconButton
              title="Удалить баннер"
              onClick={e => {
                e.stopPropagation();
                deletePromotionBanner(promotion.id);
              }}
            >
              <Close />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem divider button htmlFor="vobler" component="label">
          <ListItemText
            primary={promotion && promotion.vobler && promotion.vobler.origin_path}
            secondary="воблер"
          />
          <input
            type="file"
            id="vobler"
            multiple
            style={{ display: 'none' }}
            onChange={handleUploadVobler}
          />
          {promotion && promotion.vobler && (
            <ListItemSecondaryAction onClick={deleteVobler}>
              <IconButton
                title="Удалить воблер"
                onClick={() => deletePromotionVobler(promotion.id)}
              >
                <Close />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
        <ListItem divider>{background_color}</ListItem>
        {promotion &&
          promotion.regions.map &&
          promotion.regions.map(item => (
            <ListItem divider>
              <ListItemIcon>
                <Checkbox checked disabled />
              </ListItemIcon>
              <ListItemText
                primary={
                  <span>
                    {item.name} {item.type}
                  </span>
                }
              />
            </ListItem>
          ))}
      </List>
    </div>
  );
};

PromotionDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PromotionDetails);
