// @flow
import React from 'react';
import PropTypes from 'prop-types';
import AddCircle from '@material-ui/icons/AddCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { type typeCategory } from 'types/entities';
import { Category } from 'components/category';
import { type RightSideBarAction } from 'types/components';
import Categories from './view/Categories';
import CategoriesDialog from './view/CategoriesDialog';
import {
  fetchCategoriesTree,
  changeCategory,
  addCategory,
  fetchCategoriesList,
  updateCategory,
} from './actions';
import { setTopBarTitle } from '../top_bar/actions';
import { setRightSideBarActions } from '../right_side_bar/actions';

export type Dialogs = 'add_category' | null;
type Props = {
  setTopBarTitle: (title: string) => void,
  addCategory: (name: string, categoryId: number) => void,
  updateCategory: Function,
  fetchCategoriesTree: () => void,
  fetchCategoriesList: () => void,
  setRightSideBarActions: (actions: Array<RightSideBarAction>) => void,
  changeCategory: (categoryId: number, children: Array<CategoryType>) => void,
  tree: CategoryType,
  list: Array<CategoryType> | Array<any>,
};
type State = {|
  categoriesDialogOpened: boolean,
  categoryDialogOpened: boolean,
  selectedCategory: CategoryType | null,
  type: Dialogs,
  name: string,
|};

export class CategoriesContainer extends React.Component<Props, State> {
  state = {
    categoriesDialogOpened: false,
    categoryDialogOpened: false,
    selectedCategory: null,
    type: null,
    name: '',
    descriptions: '',
    additional_descriptions: '',
  };
  componentDidMount() {
    const actionsForRightSideBar: Array<RightSideBarAction> = [
      {
        key: 'addCategoryDialogOpened',
        icon: <AddCircle />,
        name: 'Добавить производителя',
        action: () => this.toggleDialog('add_category'),
      },
    ];
    this.props.fetchCategoriesTree();
    this.props.fetchCategoriesList();
    this.props.setTopBarTitle('Категории');
    this.props.setRightSideBarActions(actionsForRightSideBar);
  }
  componentWillUnmount() {
    this.props.setRightSideBarActions([]);
  }
  setSelectedCategory = (selectedCategory: CategoryType): void =>
    this.setState({ selectedCategory });
  toggleDialog = (type: Dialogs = null) => {
    if (!type) {
      this.setState({ selectedCategory: null });
    }
    return this.setState(prev => ({
      type,
      categoriesDialogOpened: !prev.categoriesDialogOpened,
      name: '',
    }));
  };
  toggleCategoryDialog = () =>
    this.setState(({ selectedCategory, categoryDialogOpened }) => ({
      categoryDialogOpened: !categoryDialogOpened,
      selectedCategory: categoryDialogOpened ? null : selectedCategory,
    }));
  handleChangeFeild = (name: string, value: string) => this.setState({ [name]: value });
  handleUpdateCategory = (category: typeCategory) => {
    if (!this.state.selectedCategory) return null;
    this.props.updateCategory(category);
    return this.setState({ selectedCategory: null, categoryDialogOpened: false });
  };
  render() {
    return (
      <React.Fragment>
        <CategoriesDialog
          opened={this.state.categoriesDialogOpened}
          toggleDialog={this.toggleDialog}
          addCategory={this.props.addCategory}
          name={this.state.name}
          descriptions={this.state.descriptions}
          additional_descriptions={this.state.additional_descriptions}
          selectedCategory={this.state.selectedCategory}
          handleChangeFeild={this.handleChangeFeild}
          type={this.state.type}
          setSelectedCategory={this.setSelectedCategory}
          categoriesList={this.props.list}
        />
        <Categories
          tree={this.props.tree}
          changeCategory={this.props.changeCategory}
          updateCategory={this.props.updateCategory}
          addCategory={this.props.addCategory}
          toggleDialog={this.toggleDialog}
          setSelectedCategory={this.setSelectedCategory}
          toggleCategoryDialog={this.toggleCategoryDialog}
        />
        <Dialog  fullWidth maxWidth="md" open={this.state.categoryDialogOpened} onClose={this.toggleCategoryDialog}>
          <DialogContent>
            <Category
              category={this.state.selectedCategory}
              onChange={this.handleUpdateCategory}
            />
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

CategoriesContainer.propTypes = {
  fetchCategoriesTree: PropTypes.func.isRequired,
  setTopBarTitle: PropTypes.func.isRequired,
  changeCategory: PropTypes.func.isRequired,
  tree: PropTypes.array.isRequired,
  addCategory: PropTypes.func.isRequired,
};

const mapStateToProps = store => {
  const state = store.get('categories');
  return {
    isFetching: state.getIn(['isFetching']),
    list: state
      .getIn(['list'])
      .valueSeq()
      .toArray(),
    tree: state
      .getIn(['tree'])
      .valueSeq()
      .toArray(),
  };
};

export default connect(
  mapStateToProps,
  {
    fetchCategoriesTree,
    setTopBarTitle,
    changeCategory,
    addCategory,
    fetchCategoriesList,
    setRightSideBarActions,
    updateCategory,
  },
)(CategoriesContainer);
