// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import TextField from '@material-ui/core/TextField';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Cancel from '@material-ui/icons/Cancel';
import CheckCircle from '@material-ui/icons/CheckCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import classnames from 'classnames';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import { type Manufacturer, type Brand } from 'types/entities';
import { type typeCategory } from 'types/entities/typeCategory';
import { type Entity } from '../MarkupContainer';

const styles = theme => ({
  root: {
    // padding: theme.spacing.unit * 2,
  },
  icon: {
    width: '30px',
    height: '30px',
  },
  textFeild: {
    maxWidth: '165px',
  },
  remove: {
    color: red[500],
  },
  add: {
    color: green[500],
  },
  toggle: {
    color: blue[500],
  },
});

type Props = {
  classes: Object,
  entityType: Entity,
  entity: Manufacturer | Brand | typeCategory,
  opened: boolean,
  value: string | void,
  toggleMarkup: (entityType: Entity, entityId: number) => void,
  handleChangeMarkupValue: (entityType: Entity, entityId: number, value: string) => void,
  putMarkup: () => void,
  deleteMarkup: () => void,
};

const MarkupActions = ({
  classes,
  entityType,
  entity,
  opened,
  value,
  handleChangeMarkupValue,
  toggleMarkup,
  putMarkup,
  deleteMarkup,
}: Props) => {
  const setCurrentMarkupValue = (
    entityTypeInner: Entity,
    entityId: number,
    valueInner: number | string,
  ) => {
    handleChangeMarkupValue(entityTypeInner, entityId, valueInner.toString());
    toggleMarkup(entityTypeInner, entityId);
  };
  const onPressEnter = (e, entityTypeInner, entityId) => {
    if (e.key === 'Enter') {
      putMarkup();
      toggleMarkup(entityTypeInner, entityId);
    }
  };

  return (
    <React.Fragment>
      {!entity.value && !opened && (
        <IconButton
          title="Добавить наценку"
          className={classnames(classes.icon, classes.toggle)}
          onClick={() => setCurrentMarkupValue(entityType, entity[entityType].id, '')}
        >
          <AddCircleOutline />
        </IconButton>
      )}
      {!opened && entity && entity.value && (
        <Button
          size="small"
          color="secondary"
          onClick={() => {
            if (entity.value) {
              setCurrentMarkupValue(entityType, entity[entityType].id, entity.value);
            }
          }}
        >
          <b>{entity.value}</b>
        </Button>
      )}
      {opened && (
        <TextField
          className={classes.textFeild}
          autoFocus
          type="number"
          value={value}
          placeholder={entity.value}
          onKeyPress={e => onPressEnter(e, entityType, entity.id)}
          onChange={e => handleChangeMarkupValue(entityType, entity[entityType].id, e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  title="Cохранить наценку"
                  disabled={(entity.markup && entity.value.toString() === value) || !value}
                  className={classnames(classes.icon, classes.add)}
                  onClick={() => {
                    putMarkup().then(() => toggleMarkup(entityType, entity[entityType].id));
                  }}
                >
                  <CheckCircle />
                </IconButton>
                {entity.value && (
                  <IconButton
                    title="Удалить наценку"
                    className={classnames(classes.icon, classes.remove)}
                    onClick={() => {
                      deleteMarkup().then(() => toggleMarkup(entityType, entity[entityType].id));
                    }}
                  >
                    <Cancel />
                  </IconButton>
                )}
                <IconButton
                  title="Отмена"
                  className={classes.icon}
                  onClick={() => toggleMarkup(entityType, entity[entityType].id)}
                >
                  <HighlightOff />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    </React.Fragment>
  );
};

MarkupActions.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleMarkup: PropTypes.func.isRequired,
  handleChangeMarkupValue: PropTypes.func.isRequired,
  deleteMarkup: PropTypes.func.isRequired,
  putMarkup: PropTypes.func.isRequired,
  value: PropTypes.any,
};

export default withStyles(styles)(MarkupActions);
