import { get } from 'lib/request';
import { API_HOST } from 'config';
import queryString from 'query-string';

export const getCategoriesMarkups = () =>
  get(`${API_HOST}/categories_markups`).then(res => res.data);

export const getManufacturersMarkups= () =>
  get(`${API_HOST}/manufacturers_markups`).then(res => res.data);

export const getBrandsMarkups= () =>
  get(`${API_HOST}/brands_markups`).then(res => res.data);
