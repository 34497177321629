// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import OrderLine from 'components/shop/order-line';
import Quantity from 'components/shop/quantity';
import Button from '@material-ui/core/Button';
import { type Theme } from 'types/components/Theme';
import { type Order } from 'types/entities/Order';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});
type Props = {|
  classes: Object,
  open: boolean,
  order: Order | null,
  toggleAssemblyOrderDialog: () => void,
  assemblyReport: (data: any) => Promise<any>,
  onSave: () => void,
|};

const AssemblyOrderDialog = ({
  classes,
  open,
  toggleAssemblyOrderDialog,
  order,
  assemblyReport,
  onSave,
}: Props) => {
  const [quantity, setQuantity] = useState({});
  const handleSetQuantity = (lineId: number, changedQuantity: number) => {
    setQuantity({ ...quantity, [lineId]: changedQuantity });
  };
  const handleAssemblyOrder = () => {
    if (!order) return null;
    const data = {
      orderId: order.id,
      items: [],
    };
    order.lines.forEach(line => {
      if (typeof quantity[line.id] !== 'undefined') {
        return data.items.push({
          barcode: line.product.barcode.value,
          moved: quantity[line.id],
        });
      }
      data.items.push({
        barcode: line.product.barcode.value,
        moved: line.quantity,
      });
    });
    assemblyReport(data).then(() => {
      toggleAssemblyOrderDialog();
      onSave();
    });
  };
  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={toggleAssemblyOrderDialog}>
      <DialogTitle id="alert-dialog-title">Фактическое количество товара</DialogTitle>
      <DialogContent>
        {order &&
          order.lines.map((line, index) => (
            <OrderLine
              key={line.id}
              line={line}
              index={index}
              priceBlock={
                <Quantity
                  limit={line.product.quantity}
                  zeroIsAllowed
                  quantity={
                    typeof quantity[line.id] !== 'undefined' ? quantity[line.id] : line.quantity
                  }
                  onChange={changedQuantity => handleSetQuantity(line.id, changedQuantity)}
                />
              }
            />
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleAssemblyOrderDialog}>Закрыть</Button>
        <Button color="secondary" onClick={handleAssemblyOrder}>
          Собрать
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(AssemblyOrderDialog);
