// @flow
import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  type Action,
} from 'types/actions';


export const userInitialState = {
  isFetching: false,
  data: null,
};

const user = (
  state: any = userInitialState,
  action: Action,
): any => {
  switch (action.type) {
    case FETCH_USER_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.user,
      };
    }
    default: {
      return state;
    }
  }
};

export default user;
