// @flow
import React, { type Node } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  children: Node
|};

const CategoryLayoutComponent = ({ classes, children }: {| ...Props, ...ProvidedProps |}) => (
  <div className={classes.root}>{children}</div>
);

export const CategoryLayout: ReactComponent<Props> = withStyles(styles)(CategoryLayoutComponent);
