// @flow
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core';
import { changeProductCharacteristic } from 'services/attributeApi';
import { useDebounce } from 'hooks/useDebounce';
import { Check } from '@material-ui/icons';
import { getProductsType } from 'services/productTypeApi';

const styles = (theme: Theme) => ({
  root: {},
  elementName: {
    '& > div': {
      display: 'inline-block',
      marginRight: theme.spacing.unit,
    },
  },
  listItemIcon: {
    width: '28px',
    height: '28px',
  },
  numeric: {
    display: 'flex',
    flexDirection: 'column',
  },
  numericTextField: {
    display: 'flex',
    alignItems: 'center',
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const AttributesComponent = ({
  classes,
  characteristics,
  typeId,
  onChange,
  onSave,
  disabled = false,
  onlySelected = false,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const [changedAttibute, setChangedAttribute] = useState(null);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);

  const handleSaveBool = (attribute, value) =>
    onChange(attribute.id, { value }).then(() => onSave());

  const handleSaveNumeric = (attribute, value) =>
    onChange(attribute.id, { value }).then(() => onSave());

  const handleSaveString = value => {
    setLoading(true);
    onChange((value && value.attribute.id) || (changedAttibute && changedAttibute.attribute.id), {
      value: (value && value.value) || (changedAttibute && changedAttibute.value),
    }).then(() => {
      onSave();
      setLoading(false);
      setChangedAttribute(null);
    });
  };
  const debouncedAttibute = useDebounce(changedAttibute, 500);

  useEffect(() => {
    if (debouncedAttibute && ![2, 8].includes(debouncedAttibute.id)) {
      handleSaveString();
    }
  }, [debouncedAttibute]);

  useEffect(() => {
    if (typeId) {
      getProductsType(typeId).then(setType);
    }
  }, [typeId]);

  const getCurrentValue = attribute =>
    characteristics && characteristics.find(item => item.attribute.id === attribute.id);

  return (
    <List className={classes.root} disableGutters dense>
      {type &&
        type.attributes &&
        type.attributes.map(attribute => {
          const currentValue = getCurrentValue(attribute);
          return (
            <ListItem
              key={attribute.id}
              disableGutters
              divider
              button={['bool', 'numeric'].includes(attribute.type)}
            >
              <ListItemText
                primary={
                  <div>
                    <div className={classes.elementName}>
                      <div>
                        <Typography variant="caption" title="id">
                          {attribute.id}
                        </Typography>
                      </div>
                      <div>{attribute.name}</div>
                      <div>
                        <Chip label={attribute.type} />
                      </div>
                    </div>
                    {attribute.type === 'string' && (
                      <List disableGutters dense>
                        {attribute.list_possible_values &&
                          attribute.list_possible_values
                            .filter(value =>
                              onlySelected
                                ? currentValue &&
                                  currentValue.value &&
                                  currentValue.value.id == value.id
                                : true,
                            )
                            .map(value => (
                              <ListItem
                                disableGutters
                                key={value.id}
                                button
                                dense
                                disabled={disabled}
                                onClick={() =>
                                  handleSaveString({
                                    attribute,
                                    value,
                                  })
                                }
                              >
                                <ListItemIcon className={classes.listItemIcon}>
                                  <Radio
                                    size="small"
                                    disableRipple
                                    checked={
                                      currentValue &&
                                      currentValue.value &&
                                      currentValue.value.id == value.id
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <div className={classes.elementName}>
                                      <div>
                                        <Typography variant="caption" title="id">
                                          {value.id}
                                        </Typography>
                                      </div>
                                      <div>{value.value}</div>
                                    </div>
                                  }
                                />
                                {currentValue && currentValue.values && (
                                  <ListItemSecondaryAction>
                                    (
                                    {
                                      currentValue.values.filter(item => item.id === value.id)
                                        .length
                                    }
                                    )
                                  </ListItemSecondaryAction>
                                )}
                              </ListItem>
                            ))}
                      </List>
                    )}
                  </div>
                }
              />

              {attribute.type === 'numeric' && (
                <ListItemSecondaryAction>
                  <div className={classes.numeric}>
                    <div className={classes.numericTextField}>
                      <TextField
                        disabled={disabled}
                        variant="outlined"
                        value={
                          changedAttibute !== null
                            ? changedAttibute.value
                            : currentValue && currentValue.value
                        }
                        onChange={e => setChangedAttribute({ attribute, value: e.target.value })}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {loading && <CircularProgress color="secondary" />}
                            </InputAdornment>
                          ),
                        }}
                      />
                      {currentValue && currentValue.adapt_value}
                    </div>
                    {currentValue && currentValue.values && (
                      <div>
                        {Object.values(
                          currentValue.values.reduce(
                            (prev, curr) =>
                              prev[curr]
                                ? {
                                    ...prev,
                                    [curr]: {
                                      key: curr,
                                      value: prev[curr].value + 1,
                                    },
                                  }
                                : { ...prev, [curr]: { key: curr, value: 1 } },
                            {},
                          ),
                        ).map(item => `${item.key} (${item.value})`)}
                      </div>
                    )}
                  </div>
                </ListItemSecondaryAction>
              )}
              {attribute.type === 'bool' && (
                <ListItemSecondaryAction>
                  <div>
                    <Button
                      disabled={disabled}
                      size="small"
                      onClick={() => handleSaveBool(attribute, true)}
                      color={currentValue && currentValue.value === true ? 'secondary' : 'default'}
                      variant={currentValue && currentValue.value === true ? 'raised' : 'default'}
                    >
                      Да{' '}
                      {currentValue &&
                        currentValue.values &&
                        `(${currentValue ? currentValue.values.filter(item => item).length : 0})`}
                    </Button>
                    <Button
                      disabled={disabled}
                      size="small"
                      onClick={() => handleSaveBool(attribute, false)}
                      color={currentValue && currentValue.value === false ? 'secondary' : 'default'}
                      variant={currentValue && currentValue.value === false ? 'raised' : 'default'}
                    >
                      Нет{' '}
                      {currentValue &&
                        currentValue.values &&
                        `(${currentValue ? currentValue.values.filter(item => !item).length : 0})`}
                    </Button>
                  </div>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
    </List>
  );
};
export const Attributes: ReactComponent<Props> = withStyles(styles)(AttributesComponent);
