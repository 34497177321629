// @flow
import { type typeUser } from './typeUser'

export type typeReview = {|
  id: number,
  comment: string | null,
  rating: number,
  user: typeUser,
  enabled: boolean,
  created_at: string,
  updated_at: string,
|};
