// @flow
// @flow-runtime enable

const processing: 'processing' = 'processing';
const confirmed: 'confirmed' = 'confirmed';
const assembled: 'assembled' = 'assembled';
const completed: 'completed' = 'completed';
const canceled: 'canceled' = 'canceled';
const all: 'all' = 'all';

export type OrderState =
  | typeof processing
  | typeof all
  | typeof confirmed
  | typeof assembled
  | typeof canceled
  | typeof completed;


export const orderStates: {
  processing: typeof processing,
  confirmed: typeof confirmed,
  assembled: typeof assembled,
  completed: typeof completed,
  canceled: typeof canceled,
  all: typeof all,
} = {
  all,
  processing,
  confirmed,
  assembled,
  completed,
  canceled,
};
export const orderStatesList: Array<OrderState> = [
  all,
  processing,
  confirmed,
  assembled,
  completed,
  canceled,
];

export const orderStatesNames: {
  processing: string,
  confirmed: string,
  assembled: string,
  completed: string,
  canceled: string,
  all: string,
} = {
  all: 'Все',
  processing: 'В обработке',
  confirmed: 'Подтвержден',
  assembled: 'Собран',
  completed: 'Выполнен',
  canceled: 'Отменен',
};

