// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { type Theme } from 'types/components/Theme';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Phone from '@material-ui/icons/Phone';
import OneFieldFormDialog from 'components/one-field-form-dialog';
import TextField from '@material-ui/core/TextField';

const styles = (theme: Theme) => ({
  title: {
    marginBottom: theme.spacing.unit * 2,
  },
  edited: {
    color: theme.palette.secondary[500],
    '& p, span': {
      color: theme.palette.secondary[500],
    },
  },
  filled: {
    color: theme.palette.primary[700],
    '& p, span': {
      color: theme.palette.primary[700],
    },
  },
  comment: {
    marginTop: theme.spacing.unit * 2,
  },
});

type Props = {|
  classes: Object,
  editable: boolean,
  handleSetName: (name: string) => void,
  handleSetPhone: (phone: string) => void,
  name: string | null,
  phone: string | null,
  comment: string | null,
  selectedName: string | null,
  selectedPhone: string | null,
|};

type State = {|
  openNameDialog: boolean,
  openPhoneDialog: boolean,
|};

class PickerInfo extends React.Component<Props, State> {
  state = {
    openNameDialog: false,
    openPhoneDialog: false,
  };

  getColor = entity => {
    if (!this.props.editable) return null;
    if (entity) return this.props.classes.edited;
    return this.props.classes.filled;
  };

  toggleNameDialog = () => {
    if (this.props.editable) {
      return this.setState(state => ({ openNameDialog: !state.openNameDialog }));
    }
    return null;
  };

  togglePhoneDialog = () => {
    if (this.props.editable) this.setState(state => ({ openPhoneDialog: !state.openPhoneDialog }));
    return null;
  };

  handleSetPhone = (value: string) => {
    this.props.handleSetPhone(value);
    this.togglePhoneDialog();
  };

  handleSetName = (value: string) => {
    this.props.handleSetName(value);
    this.toggleNameDialog();
  };

  render() {
    const { classes, editable, name, phone, selectedName, selectedPhone, comment } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="title" className={classes.title}>
          Получатель заказ
        </Typography>
        <List disablePadding>
          <ListItem
            disableGutters
            divider
            title="Имя"
            button={editable}
            onClick={this.toggleNameDialog}
          >
            <ListItemIcon className={this.getColor(selectedName)}>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText className={this.getColor(selectedName)} primary={selectedName || name} />
          </ListItem>
          <ListItem
            disableGutters
            divider
            title="Телефон"
            button={editable}
            onClick={this.togglePhoneDialog}
          >
            <ListItemIcon className={this.getColor(selectedPhone)}>
              <Phone />
            </ListItemIcon>
            <ListItemText
              className={this.getColor(selectedPhone)}
              primary={selectedPhone || phone}
            />
          </ListItem>
        </List>
        <TextField
          className={classes.comment}
          fullWidth
          label="Комментарий клиента"
          value={comment || ''}
          multiline
          disabled
        />
        <OneFieldFormDialog
          open={this.state.openNameDialog}
          onClose={this.toggleNameDialog}
          onSave={this.handleSetName}
          title="Имя"
        />
        <OneFieldFormDialog
          open={this.state.openPhoneDialog}
          onClose={this.togglePhoneDialog}
          onSave={this.handleSetPhone}
          title="Телефон"
        />
      </div>
    );
  }
}

PickerInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PickerInfo);
