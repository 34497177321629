import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
} from 'types/actions';
import Immutable from 'immutable';

const productsInitialState = Immutable.Map({
  isFetching: false,
  items: Immutable.Map(),
});

const products = (state = productsInitialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST: {
      return state.set('isFetching', action.value);
    }
    case FETCH_PRODUCTS_SUCCESS: {
      return state.merge({
        isFetching: false,
        items: Immutable.Map(action.products),
      });
    }
    default: {
      return state;
    }
  }
};

export default products;
