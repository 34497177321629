// @flow
// @flow-runtime enable
import { type OrderState } from './OrderState';
import { type OrderUser } from './OrderUser';
import { type OrderLine } from './OrderLine';
import { type OrderPayment } from './OrderPayment';
import { type RealizationMethodPickUp } from './RealizationMethodPickUp';
import { type RealizationMethodCourierDelivery } from './RealizationMethodCourierDelivery';

export type Order = {|
  id: number,
  state: OrderState,
  user: OrderUser,
  lines?: Array<OrderLine>,
  payments?: Array<OrderPayment>,
  realization_method: any,
  created_at: string,
  updated_at: string,
  cost: number,
  order_cost?: number,
  upp_invoice: number | string | null,
  comment?: string | null,
  private_comment: string | null,
|};
