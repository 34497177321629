// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  progress: {
    height: '5px',
  },
});

type Colors = "primary" | "secondary"

type Props ={
  classes: {
    progress: string
  },
  isFetching: boolean | void,
  color: Colors | void,
};

const Progress = ({ classes, isFetching, color }: Props) => (
  <div className={classes.progress}>
    {isFetching &&
      <LinearProgress color={color} />
    }
  </div>
);

Progress.defaultProps = {
  color: "primary",
  isFetching: false,
};

Progress.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  color: PropTypes.string,
};
const ProgressWithStyles: ()=> any = withStyles(styles)(Progress);

export default ProgressWithStyles;
