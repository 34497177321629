// @flow
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import {
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  ListItemText,
  MenuItem,
  Input,
} from '@material-ui/core';
import { getCouriers } from 'services/courierApi';
import { stringToColor } from 'lib/stringToColor';

const styles = (theme: Theme) => ({
  root: {
    // marginTop: theme.spacing.unit * 2,
    minWidth: '150px',
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CourierSelectComponent = ({
  classes,
  selectedCouriers,
  selectedCourier,
  onChange,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const [couriers, setCouriers] = useState(null);

  useEffect(() => {
    getCouriers().then(data => setCouriers(data));
  }, []);

  return (
    <FormControl className={classes.root}>
      <InputLabel htmlFor="places">Курьер</InputLabel>
      <Select
        fullWidth
        multiple={Boolean(selectedCouriers)}
        value={selectedCouriers || selectedCourier}
        onChange={e => onChange(e.target.value)}
        input={<Input id="couriers" />}
        renderValue={selected =>
          selectedCouriers
            ? selected.map((courier) => courier && courier.full_name).join(', ')
            : selected.full_name
        }
        MenuProps={MenuProps}
      >
            <MenuItem value={null}>
              {selectedCouriers && <Checkbox checked={selectedCouriers.includes(null)} />}
              <ListItemText
                primary="Не назначен"
              />
            </MenuItem>
        {couriers &&
          couriers.map(courier => courier && (
            <MenuItem key={courier.id} value={courier}>
              {selectedCouriers && <Checkbox checked={selectedCouriers.includes(courier)} />}
              <ListItemText
                primary={
                  <b style={{ color: stringToColor(courier.full_name) }}>{courier.full_name}</b>
                }
              />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
export const CourierSelect: ReactComponent<Props> = withStyles(styles)(CourierSelectComponent);
