// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { type typeCategory } from 'types/entities/typeCategory';
import { type Dialogs } from '../CategoriesContainer';
import Choiser from '../../choiser';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});
type Props = {
  opened: boolean,
  type: Dialogs,
  toggleDialog: (type?: Dialogs) => void,
  handleChangeFeild: Function,
  addCategory: (name: string, categoryId: number) => void,
  selectedCategory: typeCategory | null,
  name: string,
  categoriesList: Array<typeCategory> | Array<any>,
  setSelectedCategory: (selectedCategory: any) => void,
};
const CategoriesDialog = ({
  opened,
  type,
  toggleDialog,
  handleChangeFeild,
  selectedCategory,
  addCategory,
  categoriesList,
  name,
  descriptions,
  additional_descriptions,
  setSelectedCategory,
}: Props) => {
  const addCategoryAndToggleDialog = () => {
    if (name) {
      addCategory(name, selectedCategory && selectedCategory.id);
    }
    toggleDialog();
  };
  const content = () => {
    if (type === 'add_category') {
      return (
        <React.Fragment>
          <DialogTitle id="form-dialog-title">Создание новой категории</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Название категории"
              type="text"
              value={name}
              name="name"
              fullWidth
              onChange={e => handleChangeFeild(e.target.name, e.target.value)}
            />
            <Choiser
              margin="dense"
              fullWidth
              suggestions={categoriesList}
              selected={selectedCategory}
              placeholder="Родительская категория"
              returnSuggestion={(suggestion: typeCategory) => setSelectedCategory(suggestion)}
            />
            <TextField
              margin="dense"
              label="Описание"
              type="text"
              value={descriptions}
              multiline
              name="descriptions"
              fullWidth
              onChange={e => handleChangeFeild(e.target.name, e.target.value)}
            />
            <TextField
              margin="dense"
              label="Дополнительное описание"
              type="text"
              value={additional_descriptions}
              multiline
              name="additional_descriptions"
              fullWidth
              onChange={e => handleChangeFeild(e.target.name, e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => toggleDialog()}>Отмена</Button>
            <Button onClick={addCategoryAndToggleDialog} color="primary" disabled={!name}>
              Создать
            </Button>
          </DialogActions>
        </React.Fragment>
      );
    }
    return <div />;
  };
  return (
    <Dialog open={opened} onClose={() => toggleDialog()} aria-labelledby="form-dialog-title">
      {content()}
    </Dialog>
  );
};

CategoriesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.any,
  toggleDialog: PropTypes.func.isRequired,
  addCategory: PropTypes.func.isRequired,
  categoriesList: PropTypes.array.isRequired,
  handleChangeFeild: PropTypes.func.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.any,
};

export default withStyles(styles)(CategoriesDialog);
