import {
  FETCH_SUPPLIERS_REQUEST,
  FETCH_SUPPLIERS_SUCCESS,
  type Actions,
} from 'types/actions';
import Immutable from 'immutable';

const suppliersInitialState = Immutable.Map({
  isFetching: false,
  items: Immutable.Map(),
});

const suppliers = (state = suppliersInitialState, action: Actions) => {
  switch (action.type) {
    case FETCH_SUPPLIERS_REQUEST: {
      return state.set('isFetching', action.value);
    }
    case FETCH_SUPPLIERS_SUCCESS: {
      return state.merge({
        isFetching: false,
        items: Immutable.Map(action.suppliers),
      });
    }
    default: {
      return state;
    }
  }
};

export default suppliers;
