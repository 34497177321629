// @flow
import React, { type Node } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { type Theme } from 'types/components/Theme';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  item: {
    marginRight: theme.spacing.unit,
  },
});
type Props = {|
  classes: Object,
  isEnabled: 'all' | 'enabled' | 'disabled',
  changeIsEnabled: () => void,
  timeFilter: Node,
  email: Node,
|};

const Controls = ({
  classes,
  isEnabled,
  changeIsEnabled,
  timeFilter,
  email,
}: Props) => (
  <Paper className={classes.root}>
    {timeFilter}
    <div className={classes.item}>
      <Select value={isEnabled} onChange={changeIsEnabled}>
        <MenuItem value="all">
          <em>все</em>
        </MenuItem>
        <MenuItem value="enabled">Активные</MenuItem>
        <MenuItem value="disabled">Отключенные</MenuItem>
      </Select>
    </div>
    <div className={classes.item}>{email}</div>
  </Paper>
);

export default withStyles(styles)(Controls);
