// @flow
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import { type Product } from 'types/entities/Product';
import { type typeProductType } from 'types/entities/typeProductType';
import { connect } from 'react-redux';
import { type typeProductSeries } from 'types/entities/typeProductSeries';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Save from '@material-ui/icons/Save';
import Choiser from 'components/choiser';
import { getSeries as getSeriesAction } from 'components/series/actions';
import { getProductsTypes, addProductsType } from 'services/productTypeApi';
import { getProductSeries, addProductSeries } from 'services/productSeriesApi';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
  block: {
    minHeight: '58px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  title: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    fontSize: theme.typography.pxToRem(22),
  },
  addName: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

type ProvidedProps = {|
  classes: any,
  getSeries: typeof getSeriesAction,
  series: Array<typeProductSeries> | null,
|};

type Props = {|
  product: Product | null,
  changeProduct: (name: string, value: any) => any,
  changeCompositeName: (
    productId: number,
    data: {
      composite_name_type?: string,
      composite_name_series?: string,
      composite_name_extended?: string,
      composite_name_properties?: string,
    },
  ) => any,
|};

const CompositeNameComponent = ({
  classes,
  changeCompositeName,
  product,
  changeProduct,
  getSeries,
  series,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const [state, changeState] = useState({
    types: null,
    composite_name_extended: null,
    composite_name_properties: null,
  });

  const setState = (data: Object) => changeState({ ...state, ...data });

  const getData = async () => {
    const types = await getProductsTypes();
    if (product && product.brand) {
      await getSeries({ brand: product.brand.id });
    }
    setState({ types });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChangeProductType = (type: typeProductType | null) => {
    changeProduct('type', type);
  };

  const handleChangeProductSeries = (series: any | null) => {
    changeProduct('series', series);
  };

  const handleAddProductType = name => {
    addProductsType({ name }).then(type => {
      handleChangeProductType(type);
      getData();
    });
  };

  const handleAddProductSeries = name => {
    if (!product || !product.brand) return null;
    addProductSeries({ name, brand: product.brand }).then(type => {
      handleChangeProductSeries(type);
      getData();
    });
  };

  const handleChangeCompositeName = () => {
    if (!product) return null;
    const data = {};
    if (state.composite_name_extended) {
      data.composite_name_extended = state.composite_name_extended.trim();
    }
    if (state.composite_name_properties) {
      data.composite_name_properties = state.composite_name_properties.trim();
    }
    changeCompositeName(product.id, data);
  };
  if (!product) return null;
  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Составное название</Typography>
      <Grid container spacing={16} className={classes.block}>
        <Grid item xs={12} sm={6}>
          <Choiser
            disabled
            onAddNew={handleAddProductType}
            fullWidth
            suggestions={state.types || []}
            inputLabel="Тип"
            selected={product && product.type}
            returnSuggestion={type => handleChangeProductType(type)}
            onHandleDeleteChip={() => handleChangeProductType(null)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {product && product.brand ? (
            <Choiser
              disabled
              onAddNew={handleAddProductSeries}
              fullWidth
              suggestions={series || []}
              inputLabel="Серия"
              returnAllOnEmptyString
              selected={product && product.series}
              returnSuggestion={series => handleChangeProductSeries(series)}
              onHandleDeleteChip={() => handleChangeProductSeries(null)}
            />
          ) : (
            'Отсутствует бренд'
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            fullWidth
            margin="none"
            label="Расширенное описание"
            value={
              state.composite_name_extended !== null
                ? state.composite_name_extended
                : product.composite_name_extended || ''
            }
            onChange={e => setState({ composite_name_extended: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={3} sm={6} className={classes.block}>
          <TextField
            disabled
            fullWidth
            margin="none"
            label="Характеристики"
            value={
              state.composite_name_properties !== null
                ? state.composite_name_properties
                : product.composite_name_properties || ''
            }
            onChange={e => setState({ composite_name_properties: e.target.value })}
          />
        </Grid>
        {/* <Grid item xs={12} className={classes.addName}>
          <Button
            variant="fab"
            color="secondary"
            className={classes.button}
            title="Сохранить название"
            onClick={handleChangeCompositeName}
          >
            <Save />
          </Button>
        </Grid> */}
      </Grid>
    </div>
  );
};

const mapStateToProps = store => ({
  series: store.getIn(['series', 'list']),
});

export const CompositeName: ReactComponent<Props> = connect(mapStateToProps, {
  getSeries: getSeriesAction,
})(withStyles(styles)(CompositeNameComponent));
