// @flow
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import { withRouter } from 'react-router-dom';
import { getProductsType } from 'services/productTypeApi';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Attributes } from 'components/attributes';
import WithConfirm from 'components/with-confirm';
import { Add, Close } from '@material-ui/icons';
import { addAttributeToProductType, deleteAttributeFromProductType } from 'services/attributeApi';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
  name: {
    '& > div': {
      display: 'inline-block',
      marginRight: theme.spacing.unit,
    },
  },
  listItem: {
    maxWidth: '400px',
  },
  elementName: {
    '& > div': {
      display: 'inline-block',
      marginRight: theme.spacing.unit,
    },
  },
});

const ProductsTypeComponent = ({ classes, ...props }) => {
  const [type, setType] = useState(null);
  const [open, setOpen] = useState(false);
  const refetch = () => getProductsType(props.match.params.productTypeId).then(setType);

  useEffect(() => {
    refetch();
  }, [props.match.params.productTypeId]);

  const handleDeleteAttribute = (typeId, attributeId) =>
    deleteAttributeFromProductType(typeId, attributeId).then(() => refetch());

  const handleAddAttribute = attribute =>
    addAttributeToProductType(type.id, { id: attribute.id }).then(() => {
      setOpen(false);
      refetch();
    });

  if (!type) return null;

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Attributes edit={false} onClick={handleAddAttribute} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Отмена</Button>
        </DialogActions>
      </Dialog>
      <div className={classes.name}>
        <div>
          <Typography variant="caption">{type.id}</Typography>
        </div>
        <div>{type.name}</div>
      </div>
      <List className={classes.listItem} dense>
        {type.attributes.map(attribute => (
          <ListItem key={attribute.id} divider>
            <ListItemText
              primary={
                <div className={classes.elementName}>
                  <div>
                    <Typography variant="caption">{attribute.id}</Typography>
                  </div>
                  <div>{attribute.name}</div>
                  <div>
                    <Chip label={attribute.type} />
                  </div>
                </div>
              }
            />
            <ListItemSecondaryAction>
              <WithConfirm
                onConfirm={() => handleDeleteAttribute(type.id, attribute.id)}
                confirmTitle="Удалить"
              >
                <IconButton size="small" title="Удалить атрибут">
                  <Close />
                </IconButton>
              </WithConfirm>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        <ListItem button onClick={() => setOpen(true)}>
          <ListItemText primary="Добавить характеристику" color="primary" />
          <ListItemSecondaryAction>
            <Add />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </div>
  );
};
export const ProductsType: ReactComponent<Props> = withRouter(
  withStyles(styles)(ProductsTypeComponent),
);
