import { API_HOST } from 'config';
import { get, post } from 'lib/request';
import { normalize, schema } from 'normalizr';
import {
  FETCH_UNDEFINED_MANUFACTURERS_REQUEST,
  FETCH_UNDEFINED_MANUFACTURERS_SUCCESS,
  PROCESSING_UNDEFINED_MANUFACTURER,
} from 'types/actions';
import { fetchManufacturersIfNeeded } from 'components/manufacturers/actions';
import { addNotification } from '../notifications/actions';

export const undefinedManufacturerSchema = new schema.Entity('undefinedManufacturer');

export const requestUndefinedManufacturers = () => ({
  type: FETCH_UNDEFINED_MANUFACTURERS_REQUEST,
});

export const receiveUndefinedManufacturers = payload => ({
  type: FETCH_UNDEFINED_MANUFACTURERS_SUCCESS,
  payload,
  receivedAt: Date.now(),
});

export const processingUndefinedManufacturer = idManufacturer => ({
  type: PROCESSING_UNDEFINED_MANUFACTURER,
  idManufacturer,
});

export const fetchData = () => dispatch =>
  get(`${API_HOST}/undefinedManufacturers`)
    .then(
      (res) => {
        const payload = normalize(res.data, {
          undefinedManufacturers: [undefinedManufacturerSchema],
        });
        dispatch(receiveUndefinedManufacturers(payload));
      },
      () => { dispatch(addNotification('Неудачная попытка загрузки неизвестных производителей')); },
    );

export const fetchUndefinedManufacturers = () => (dispatch) => {
  dispatch(requestUndefinedManufacturers());
  dispatch(fetchData());
};

export const addToManufacturer = (idManufacturer, alias) => (dispatch) => {
  dispatch(processingUndefinedManufacturer(alias.id));
  return post(`${API_HOST}/manufacturers/${idManufacturer}/aliases`, alias)
    .then(
      () => dispatch(fetchData()),
      () => { dispatch(addNotification('Неудачная попытка добавления синонима производителю')); },
    );
};

export const addManufacturer = alias => (dispatch) => {
  dispatch(processingUndefinedManufacturer(alias.id));
  return post(`${API_HOST}/manufacturers`, { name: alias.value })
    .then(
      () => {
        dispatch(fetchData());
        dispatch(fetchManufacturersIfNeeded());
      },
      () => { dispatch(addNotification('Неудачная попытка добавления производителя')); },
    );
};
