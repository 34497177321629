// @flow
// @flow-runtime enable
import { type AddressUnit } from './AddressUnit';
import { type City } from './City';

export type Address = {|
  id?: number,
  postcode: number | string,
  region: AddressUnit,
  line: string,
  area?: AddressUnit | null,
  city: City | null,
  settlement?: AddressUnit | null,
  street: AddressUnit,
  locality?: AddressUnit,
  house: string,
  flat?: string | null,
  coordinates: {
    longitude: number | string,
    latitude: number | string,
  };
|};
