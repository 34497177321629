// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Settings from '@material-ui/icons/Settings';
import LocalOffer from '@material-ui/icons/LocalOffer';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import {
  type Brand,
  type Manufacturer,
} from 'types/entities';
import {
  type Product as ProductType,
} from 'types/entities/Product';
import {
  type typeCategory,
} from 'types/entities/typeCategory';
import Choiser from '../../choiser';
import LoadingWrapper from '../../loading_wrapper/';

const styles = theme => ({
  root: {
    marginBottom: `-${theme.spacing.unit}px`,
  },
  progress: {
    height: '5px',
  },
  barcodeBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  caption: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '130px',
    color: theme.palette.grey[600],
  },
  listBlock: {
    display: 'flex',
  },
});

type ManufacturerWithSuggestion = {|
  ...$Exact<Manufacturer>,
  suggestMatch?: string,
|}

type СategoryWithSuggestion = {|
  ...$Exact<typeCategory>,
  suggestMatch?: string,
|}

type BrandWithSuggestion = {|
  ...$Exact<Brand>,
  suggestMatch?: string,
|}


type Props = {
  classes: Object,
  manufacturersIsFetching: boolean,
  brandsIsFetching: boolean,
  categoriesIsFetching: boolean,
  product: ProductType | null,
  fetchCategoriesList: () => void,
  fetchManufacturersIfNeeded: () => void,
  getBrands: () => void,
  categories: Array<СategoryWithSuggestion>,
  brands: Array<BrandWithSuggestion>,
  manufacturers: Array<ManufacturerWithSuggestion>,
  changeProduct: (name: string, value: any) => void,
};

const productFieldsNames = {
  selectedManufacturer: 'manufacturer',
  selectedBrand: 'brand',
  selectedCategory: 'category',
};

const ManufacturerBrandCategory = ({
  classes,
  product,
  manufacturers,
  brands,
  categories,
  fetchManufacturersIfNeeded,
  getBrands,
  fetchCategoriesList,
  brandsIsFetching,
  manufacturersIsFetching,
  categoriesIsFetching,
  changeProduct,
}: Props) => {
  const handleChangeFieldAndProduct = (name, value) => {
    changeProduct(productFieldsNames[name], value);
  };
  const fetchManufacturers = () => {
    if (!manufacturers.length) {
      fetchManufacturersIfNeeded();
    }
  };
  const fetchCategories = () => {
    if (!categories.length) {
      fetchCategoriesList();
    }
  };
  const fetchBrands = () => {
    if (!brands.length) {
      getBrands();
    }
  };
  if (!product) return null;
  return (
    <List className={classes.root}>
      <ListItem disableGutters divider dense>
        <ListItemIcon>
          <Settings />
        </ListItemIcon>
        <ListItemText
          primary={
            <div className={classes.listBlock}>
              <span className={classes.caption}>производитель</span>
              <div
                role="button"
                tabIndex={-1}
                // onKeyPress={fetchManufacturers}
                // onClick={fetchManufacturers}
              >
                <LoadingWrapper
                  loading={manufacturersIsFetching}
                >
                  <Choiser
                    disabled
                    selected={product.manufacturer}
                    suggestions={manufacturers}
                    onHandleDeleteChip={fetchManufacturers}
                    returnSuggestion={(suggestion: Manufacturer | null) =>
                      handleChangeFieldAndProduct('selectedManufacturer', suggestion)
                    }
                  />
                </LoadingWrapper>
              </div>
            </div>
            }
        />
      </ListItem>
      <ListItem disableGutters divider dense>
        <ListItemIcon>
          <LocalOffer />
        </ListItemIcon>
        <ListItemText
          primary={
            <div className={classes.listBlock}>
              <span className={classes.caption}>бренд</span>
              <div
                // onClick={fetchBrands}
                // onKeyPress={fetchBrands}
                role="button"
                tabIndex={0}
              >
                <LoadingWrapper
                  loading={brandsIsFetching}
                >
                  <Choiser
                    disabled
                    selected={product.brand}
                    suggestions={brands}
                    onHandleDeleteChip={() => getBrands()}
                    returnSuggestion={(suggestion: Brand | null) =>
                      handleChangeFieldAndProduct('selectedBrand', suggestion)
                    }
                  />
                </LoadingWrapper>
              </div>
            </div>
          }
        />
      </ListItem>
      <ListItem disableGutters dense>
        <ListItemIcon>
          <FormatListNumbered />
        </ListItemIcon>
        <ListItemText
          primary={
            <div className={classes.listBlock}>
              <span className={classes.caption}>категория</span>
              <div
                role="button"
                tabIndex={0}
                onClick={fetchCategories}
                onKeyPress={fetchCategories}
              >
                <LoadingWrapper loading={categoriesIsFetching}>
                  <Choiser
                    selected={product.category}
                    suggestions={categories}
                    returnSuggestion={(suggestion: typeCategory | null) =>
                      handleChangeFieldAndProduct('selectedCategory', suggestion)
                    }
                    onHandleDeleteChip={() => fetchCategoriesList()}
                  />
                </LoadingWrapper>
              </div>
            </div>
          }
        />
      </ListItem>
    </List>
  );
};

export default withStyles(styles)(ManufacturerBrandCategory);
