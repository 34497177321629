import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';

function getStyle(backgroundColor, padding) {
  return {
    minHeight: '8px',
    width: '100%',
    backgroundColor,
    padding,
  };
}

const boxTarget = {
  drop(props) {
    props.setDroppedOn({
      index: props.index,
      parent: props.parent,
    });
    // const hasDroppedOnChild = monitor.didDrop();
    // if (hasDroppedOnChild && !props.greedy) {
    //   return;
    // }
    // component.setState({
    //   hasDropped: true,
    //   hasDroppedOnChild,
    // });
  },
  hover(props) {
    props.setDraggingOverElement(props.item);
  },
  canDrop(props) {
    if (
      props.item &&
      (props.dragging.id === props.item.id ||
        props.parent === props.dragging.id)
    ) {
      return false;
    }
    return true;
  },
};

class Dustbin extends Component {
  static propTypes = {
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    isOverCurrent: PropTypes.bool.isRequired,
    greedy: PropTypes.bool,
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasDropped: false,
      hasDroppedOnChild: false,
    };
  }

  render() {
    const {
      greedy,
      isOver,
      isOverCurrent,
      connectDropTarget,
      children,
      afterElement,
      item,
      dragging,
      parent,
    } = this.props;
    const { hasDropped, hasDroppedOnChild } = this.state;

    const text = greedy ? '' : 'not greedy';
    let backgroundColor = 'white';
    let padding = '0px';
    const addToCategoryBg = () => {
      if (!dragging) return null;
      return (dragging && dragging.id === item.id) || parent === dragging.id
        ? 'white'
        : green[200];
    }
    const addAfterCategoryBg = () =>
      item.id === dragging.id ? 'white' : blue[200];
    if (isOverCurrent || (isOver && greedy)) {
      backgroundColor = afterElement ? addAfterCategoryBg() : addToCategoryBg();
      padding = afterElement && parent !== dragging.id && dragging.id !== item.id ? '22px' : null;
    }

    return connectDropTarget(
      <div style={getStyle(backgroundColor, padding)}>
        {hasDropped && <span>dropped {hasDroppedOnChild && ' on child'}</span>}
        <div>{children}</div>
      </div>
    );
  }
}

export default DropTarget('box', boxTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(Dustbin);
