// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { type Promotion } from 'types/entities/Promotion';
import { type RightSideBarAction } from 'types/components/RightSideBarAction';
import Save from '@material-ui/icons/Save';
import Progress from 'components/progress';
import TextField from '@material-ui/core/TextField';
import { setRightSideBarActions } from 'components/right_side_bar/actions';
import OneFieldFormDialog from 'components/one-field-form-dialog';
import PromotionLayout from './view/PromotionLayout';
import PromotionDetails from './view/PromotionDetails';
import Participants from './view/Participants';
import Editor from './view/Editor';
import {
  uploadPromotionBanner,
  putPromotion,
  uploadPromotionVobler,
  deletePromotionVobler,
  deletePromotionBanner,
} from './actions';
import { Button } from '@material-ui/core';

type Props = {|
  setRightSideBarActions: (actions: Array<RightSideBarAction>) => void,
  putPromotion: (promotionId: number, promotion: Promotion) => Promise<any>,
  uploadPromotionBanner: (promotionId: number, file: any) => void,
  promotion: Promotion | null,
  isFetching: boolean,
|};

type State = {|
  changedDescription: string | null,
  promotionIsEnabled: boolean | null,
  title: string | null,
  email_body: string | null,
  caption: string | null,
  background_color: string | null,
  is_promoted_by_email: null,
|};

const emptyState = {
  changedDescription: null,
  promotionIsEnabled: null,
  title: null,
  email_body: null,
  is_promoted_by_email: null,
  caption: null,
  background_color: null,
};

export class PromotionContainer extends React.Component<Props, State> {
  state = {
    changedDescription: null,
    promotionIsEnabled: null,
    title: null,
    caption: null,
    background_color: null,
    email_body: null,
    is_promoted_by_email: null,
    is_promoted_by_web_push: null,
  };

  componentDidMount() {
    // this.props.action();
    this.props.setRightSideBarActions(this.actionsForRightSideBar(this.state));
  }

  componentWillUnmount() {
    this.props.setRightSideBarActions([]);
  }

  setStateWithUpdateSideBarActions = (nextState: Object) => {
    this.setState(nextState);
    this.props.setRightSideBarActions(this.actionsForRightSideBar({ ...this.state, ...nextState }));
  };

  actionsForRightSideBar = (state: Object): Array<RightSideBarAction> => [
    {
      key: 'savePromotion',
      icon: (
        <Save
          style={
            state.changedDescription !== null ||
            state.promotionIsEnabled !== null ||
            state.is_promoted_by_email !== null ||
            state.is_promoted_by_web_push !== null ||
            state.background_color !== null ||
            state.caption !== null ||
            state.email_body !== null ||
            state.title !== null
              ? { color: '#ff9100' }
              : null
          }
        />
      ),
      name: 'Сохранить',
      action: () => this.handleChangePromotion(),
    },
  ];

  handleChangePromotion = () => {
    const {
      is_promoted_by_email,
      email_body,
      title,
      caption,
      background_color,
      is_promoted_by_web_push,
    } = this.state;
    if (!this.props.promotion) return null;
    let changedPromotion: Promotion = { ...this.props.promotion, participants: null };
    if (this.state.changedDescription !== null) {
      changedPromotion = { ...changedPromotion, description: this.state.changedDescription };
    }
    if (this.state.promotionIsEnabled !== null) {
      changedPromotion = { ...changedPromotion, is_enabled: this.state.promotionIsEnabled };
    }
    if (title !== null) {
      changedPromotion = { ...changedPromotion, title };
    }
    if (caption !== null) {
      changedPromotion = { ...changedPromotion, caption };
    }
    if (email_body !== null) {
      changedPromotion = { ...changedPromotion, email_body };
    }
    if (background_color !== null) {
      changedPromotion = { ...changedPromotion, background_color };
    }
    if (is_promoted_by_email !== null) {
      changedPromotion = { ...changedPromotion, is_promoted_by_email };
    }
    if (is_promoted_by_web_push !== null) {
      changedPromotion = { ...changedPromotion, is_promoted_by_web_push };
    }
    return this.props
      .putPromotion(this.props.promotion.id, changedPromotion)
      .then(() => this.setStateWithUpdateSideBarActions(emptyState));
  };

  changeState = (name: string, value: any) =>
    this.setStateWithUpdateSideBarActions({ [name]: value });

  render() {
    return (
      <React.Fragment>
        <PromotionLayout
          progress={<Progress isFetching={this.props.isFetching} color="secondary" />}
          details={
            <PromotionDetails
              title={
                <TextField
                  fullWidth
                  placeholder="Заголовок"
                  value={
                    this.state.title === null
                      ? (this.props.promotion && this.props.promotion.title) || ''
                      : this.state.title
                  }
                  onChange={({ target }) => this.changeState('title', target.value)}
                />
              }
              caption={
                <TextField
                  fullWidth
                  placeholder="описание для push-уведомлений"
                  value={
                    this.state.caption === null
                      ? (this.props.promotion && this.props.promotion.caption) || ''
                      : this.state.caption
                  }
                  onChange={({ target }) => this.changeState('caption', target.value)}
                />
              }
              background_color={
                <TextField
                  placeholder="Цвет фона"
                  fullWidth
                  value={
                    this.state.background_color === null
                      ? (this.props.promotion && this.props.promotion.background_color) || ''
                      : this.state.background_color
                  }
                  onChange={({ target }) => this.changeState('background_color', target.value)}
                />
              }
              promotion={this.props.promotion}
              changeState={this.changeState}
              promotionIsEnabled={this.state.promotionIsEnabled}
              is_promoted_by_email={this.state.is_promoted_by_email}
              is_promoted_by_web_push={this.state.is_promoted_by_web_push}
              uploadPromotionBanner={this.props.uploadPromotionBanner}
              uploadPromotionVobler={this.props.uploadPromotionVobler}
              deletePromotionVobler={this.props.deletePromotionVobler}
              deletePromotionBanner={this.props.deletePromotionBanner}
            />
          }
          editor={
            <div>
              <Editor
                description={this.props.promotion && this.props.promotion.description}
                changedValue={this.state.changedDescription}
                onChange={value => this.changeState('changedDescription', value)}
                label="Описание для интернет-магазина"
                actions={[
                  <Button
                    onClick={() =>
                      this.changeState(
                        'changedDescription',
                        `${this.state.changedDescription || ''}{{ BILL_SUBMIT_FORM }}`,
                      )
                    }
                  >
                    {' '}
                    Вставить форму отправки чеков
                  </Button>,
                ]}
              />
            </div>
          }
          emailEditor={
            <Editor
              description={this.props.promotion && this.props.promotion.email_body}
              changedValue={this.state.email_body}
              onChange={value => this.changeState('email_body', value)}
              label="Описание для рассылки"
            />
          }
          participants={
            <Participants
              promotion={this.props.promotion}
              onChange={promotion => this.props.putPromotion(this.props.promotion.id, promotion)}
            />
          }
        />
      </React.Fragment>
    );
  }
}

PromotionContainer.propTypes = {
  putPromotion: PropTypes.func.isRequired,
  setRightSideBarActions: PropTypes.func.isRequired,
  uploadPromotionBanner: PropTypes.func.isRequired,
};

const mapStateToProps = store => {
  const state = store.getIn(['shop', 'promotion']);
  return {
    isFetching: state.isFetching,
    promotion: state.data,
  };
};

export default connect(mapStateToProps, {
  uploadPromotionBanner,
  uploadPromotionVobler,
  deletePromotionVobler,
  deletePromotionBanner,
  putPromotion,
  setRightSideBarActions,
})(PromotionContainer);
