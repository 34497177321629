// @flow
import {
  FETCH_PROMOTIONS_REQUEST,
  FETCH_PROMOTIONS_SUCCESS,
} from 'types/actions/Promotion';
import { type Action } from 'types/actions/Action';
import { type PromotionsState } from 'types/store/Promotions';

export const promotionsInitialState: PromotionsState = {
  isFetching: false,
  list: null,
  totalCount: 0,
};

const warehouses = (
  state: PromotionsState = promotionsInitialState,
  action: Action,
): any => {
  switch (action.type) {
    case FETCH_PROMOTIONS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_PROMOTIONS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: action.promotions,
        totalCount: action.totalCount,
      };
    }
    default: {
      return state;
    }
  }
};

export default warehouses;
