// @flow
import { type Address } from './Address';
import { type TimePeriod } from './TimePeriod';
import { type OrderUser } from './OrderUser';
import { type Order } from './Order';

export type Delivery = {|
  id: number,
  recipient_name: string | null,
  recipient_phone: string | null,
  shipping_cost: number,
  orders_cost: number,
  created_at: string,
  updated_at: string,
  type: 'courier',
  address: Address,
  delivery_period: TimePeriod,
  user: OrderUser,
  orders: Array<any> | null,
  total_cost: number,
  delivery_zone: { id: number, name: string } | null,
  shipment_place_pointer: string | null,
|};
