// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  ListItem,
  Checkbox,
  List,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { DatePicker } from 'components/date-picker';
import { createPromotion } from 'services/promotionApi';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
  row: {
    marginTop: theme.spacing.unit * 2,
  },
  datePicker: {
    marginTop: theme.spacing.unit * 2,
    '& > *:first-child': {
      marginRight: theme.spacing.unit * 2,
    },
  },
});

const regions = [
  {
    fias_id: '9c05e812-8679-4710-b8cb-5e8bd43cdf48',
    type: 'область',
    short_type: 'обл',
    name: 'Магаданская',
    scope: 'region',
  },
  {
    fias_id: 'd02f30fc-83bf-4c0f-ac2b-5729a866a207',
    type: 'край',
    short_type: 'край',
    name: 'Камчатский',
    scope: 'region',
  },
  {
    fias_id: '43909681-d6e1-432d-b61f-ddac393cb5da',
    type: 'край',
    short_type: 'край',
    name: 'Приморский',
    scope: 'region',
  },
];

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const AddPromotionDialogComponent = ({
  open,
  classes,
  onClose,
  onSave,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const [state, chageState] = useState({
    title: null,
    start: null,
    end: null,
    type: 'news_promotion',
    regions: [],
  });

  const setState = data => chageState({ ...state, ...data });

  const toggleRegion = region =>
    state.regions.find(({ fias_id }) => fias_id === region.fias_id)
      ? setState({ regions: state.regions.filter(({ fias_id }) => fias_id !== region.fias_id) })
      : setState({ regions: [...state.regions, region] });

  const handleOnSave = () =>
    createPromotion({
      ...state,
      start: state.start.format('YYYY-MM-DDTHH:mm:ssZ'),
      end: state.end.format('YYYY-MM-DDTHH:mm:ssZ'),
    }).then(promotion => onSave(promotion));

  return (
    <Dialog className={classes.root} open={open} onClose={onClose}>
      <DialogContent>
        <div>
          <TextField
            label="Название"
            value={state.title}
            onChange={e => setState({ title: e.target.value })}
            fullWidth
          />
        </div>
        <div className={classes.datePicker}>
          <DatePicker
            formatFrom="YYYY.MM.DD HH:mm:ss"
            formatTo="YYYY.MM.DD HH:mm:ss"
            labelFrom="дата начала"
            labelTo="дата окончания"
            from={state.start}
            to={state.end}
            setFrom={start => setState({ start })}
            setTo={end => setState({ end })}
          />
        </div>
        <List dense className={classes.row}>
          {regions.map(region => (
            <ListItem key={region.fias_id} button onClick={() => toggleRegion(region)}>
              <ListItemText primary={`${region.name} ${region.type}`} />
              <ListItemSecondaryAction>
                <Checkbox
                  checked={Boolean(state.regions.find(({ fias_id }) => fias_id === region.fias_id))}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Отмена</Button>
        <Button
          variant="raised"
          color="secondary"
          disabled={!state.title || !state.start || !state.end || !state.regions.length}
          onClick={handleOnSave}
        >
          Создать
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AddPromotionDialog: ReactComponent<Props> = withStyles(styles)(
  AddPromotionDialogComponent,
);
