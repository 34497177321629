// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { type Supplier } from 'types/entities';
import { connect } from 'react-redux';
import { setTopBarTitle } from 'components/top_bar/actions';
import Suppliers from './view/Suppliers';
import { fetchSuppliers } from './actions';

type Props = {
  fetchSuppliers: () => void,
  setTopBarTitle: (titile: string) => void,
  suppliers: Array<Supplier> | Array<any>,
  isFetching: boolean,
}

export class SuppliersContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchSuppliers();
    this.props.setTopBarTitle('Поставщики');
  }
  render() {
    return (
      <Suppliers
        isFetching={this.props.isFetching}
        suppliers={this.props.suppliers}
      />
    );
  }
}

SuppliersContainer.propTypes = {
  fetchSuppliers: PropTypes.func.isRequired,
};

const mapStateToProps = store => ({
  isFetching: store.getIn(['suppliers', 'isFetching']),
  suppliers: store.getIn(['suppliers', 'items']).valueSeq().toArray(),
});

export default connect(
  mapStateToProps,
  {
    fetchSuppliers,
    setTopBarTitle
  },
)(SuppliersContainer);
