// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorder from '@material-ui/icons/StarBorder';
import Star from '@material-ui/icons/Star';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { type Theme, type ReactComponent } from 'types/components';
import moment from 'moment';
import { type typeReview } from 'types/entities/typeReview';
import yellow from '@material-ui/core/colors/yellow';
import cn from 'classnames';
import ProductPreview from 'components/product-preview';

const styles = (theme: Theme) => ({
  review: {
    paddingBottom: theme.spacing.unit * 2,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginTop: theme.spacing.unit,
    padding: theme.spacing.unit / 2,
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      opacity: 1,
    },
  },
  date: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[500],
  },
  comment: {
    marginTop: theme.spacing.unit / 2,
  },
  product: {
    marginTop: theme.spacing.unit,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  star: {
    color: theme.palette.grey[500],
    '& > svg': {
      width: '20px',
      height: '20px',
    },
  },
  selected: {
    color: yellow[700],
  },
  disabled: {
    opacity: 0.4,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  review: typeReview,
  onChange: (data: Object) => any,
|};

const ReviewComponent = ({ classes, review, onChange }: {| ...Props, ...ProvidedProps |}) => (
  <div className={cn(classes.review, review.enabled || review.published ? null : classes.disabled)} key={review.id}>
    <div className={classes.header}>
      <div>
        <div>
          {Array(5)
            .fill()
            .map((item, index) => (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classes.star}
              >
                {index + 1 > review.rating ? <StarBorder /> : <Star className={classes.selected} />}
              </span>
            ))}
        </div>
        <div className={classes.date}>
          {moment(review.updated_at || review.comment_date).format('D MMMM YYYY')}
        </div>
      </div>
      <Checkbox
        checked={review.enabled || review.published}
        onChange={() => onChange(!(review.enabled || review.published))}
      />
      {/* <IconButton>
        <Close />
      </IconButton> */}
      {review.user && (
        <div>
          {review.user.full_name ? `${review.user.full_name}, ` : ''}
          {review.user.phone}
        </div>
      )}
      {review.name && <div>{review.name}</div>}
    </div>
    <div className={classes.comment}>
      <div dangerouslySetInnerHTML={{ __html: review.comment }} />
    </div>
    {review.product && (
      <div className={classes.product}>
        <ProductPreview product={review.product} />
      </div>
    )}
  </div>
);

export const Review: ReactComponent<Props> = withStyles(styles)(ReviewComponent);
