// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';

const styles = (theme: Theme) => ({
  promotions: {
    marginTop: theme.spacing.unit * 2,
  },
});
type Props = {|
  classes: Object,
  controls: any,
  promotions: any,
|};

const Layout = ({ classes, controls, promotions }: Props) => (
  <div className={classes.root}>
    {controls}
    <div className={classes.promotions}>
      {promotions}
    </div>
  </div>
);

export default withStyles(styles)(Layout);
