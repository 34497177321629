// @flow
import { type ThunkAction, type Dispatch } from 'types/actions';
import {
  createRealizationMethod as createRealizationMethodByApi,
  changeRealizationMethod as changeRealizationMethodByApi,
} from 'services/realizationMethodApi';
import { addNotification } from 'components/notifications/actions';

export const createRealizationMethod = (realizationMethod: $FlowFixMe): ThunkAction => (
  dispatch: Dispatch,
) =>
  createRealizationMethodByApi(realizationMethod).then(
    data => data,
    errors => dispatch(addNotification(errors)),
  );

export const changeRealizationMethod = (realizationMethod): ThunkAction => (dispatch: Dispatch) =>
  changeRealizationMethodByApi(realizationMethod).then(
    data => data,
    errors => errors.forEach(error => dispatch(addNotification(error.title))),
  );
