// @flow
import {
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
} from 'types/actions/Order';
import { type Action } from 'types/actions/Action';
import { type OrderState } from 'types/store/Order';
import { type AppStore } from 'types/store/AppStore';

export const orderInitialState: OrderState = {
  isFetching: false,
  data: null,
};

const reducer = (
  state: OrderState = orderInitialState,
  action: Action,
): any => {
  switch (action.type) {
    case FETCH_ORDER_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_ORDER_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.order,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
