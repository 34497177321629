// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import { type Theme } from 'types/components/Theme';
import Popover from '@material-ui/core/Popover';

const styles = (theme: Theme) => ({
  root: {
    // marginTop: theme.spacing.unit * 2,
  },
});
type Props = {|
  classes: Object,
  anchorEl: Object | null,
  handleClose: () => void,
  handleCancel: () => void,
  handleConfirm: () => void,
  confirmTitle?: string,
  cancelTitle?: string,
|};

const WithConfirm = ({
  classes,
  anchorEl,
  handleClose,
  handleCancel,
  handleConfirm,
  confirmTitle,
  cancelTitle,
}: Props) => (
  <Popover
    className={classes.root}
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <Button
      color="primary"
      onClick={handleCancel}
    >
      {cancelTitle ? cancelTitle : 'Отмена'}
    </Button>
    <Button
      color="secondary"
      onClick={handleConfirm}
    >
      {confirmTitle || 'Подтвердить'}
    </Button>
  </Popover>
);

WithConfirm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  anchorEl: PropTypes.any,
  confirmTitle: PropTypes.any,
};

export default withStyles(styles)(WithConfirm);
