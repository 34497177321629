// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Progress from 'components/progress';
import AddressDialog from 'components/shop/address-dialog';
import OneFieldFormDialog from 'components/one-field-form-dialog';
import { type Warehouse as WarehouseType } from 'types/entities/Warehouse';
import { type Address } from 'types/entities/Address';
import Button from '@material-ui/core/Button';
import { setTopBarTitle } from 'components/top_bar/actions';
import WarehouseLayout from './view/WarehouseLayout';
import Warehouse from './view/Warehouse';
import { putWarehouse, fetchWarehouse } from './actions';
import { Schedule } from './view/Schedule';
import {
  putWarehouseShchedule,
  addImages,
  getImages,
  removeImage,
  setDefaultImage,
} from 'services/warehouseApi';
import { ImagesList } from 'components/images-list';

type Props = {|
  isFetching: boolean,
  warehouse: WarehouseType | null,
  putWarehouse: (warehouseId: number, warehouse: WarehouseType) => Promise<any>,
  setTopBarTitle: (title: string) => void,
|};

type State = {|
  addressDialogOpen: boolean,
  nameDialogOpen: boolean,
  address: Address | null,
  isPickPoint: boolean | null,
  name: string | null,
|};

export class WarehouseContainer extends React.Component<Props, State> {
  state = {
    addressDialogOpen: false,
    nameDialogOpen: false,
    address: null,
    name: null,
    isPickPoint: null,
  };

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.warehouse && this.props.warehouse) ||
      (this.props.warehouse &&
        prevProps.warehouse &&
        prevProps.warehouse.id !== this.props.warehouse.id)
    ) {
      this.props.setTopBarTitle(this.props.warehouse.name);
    }
  }

  toggleDialog = (key: string) => () => this.setState(state => ({ [key]: !state[key] }));

  toggleIsPickupPoint = (value: boolean) => () => this.setState({ isPickPoint: value });

  selectAddress = (address: Address) => this.setState({ address, addressDialogOpen: false });

  selectName = (name: string) => this.setState({ name, nameDialogOpen: false });

  addImages = files =>
    addImages({ warehouseId: this.props.warehouse.id, files }).then(() =>
      this.props.fetchWarehouse(this.props.warehouse.id),
    );

  removeImage = image =>
    removeImage({ warehouseId: this.props.warehouse.id, imageId: image.id }).then(() =>
      this.props.fetchWarehouse(this.props.warehouse.id),
    );

  setDefaultImage = image =>{
    console.log(this.props.warehouse);
    setDefaultImage({ warehouseId: this.props.warehouse.id, image }).then(() =>
      this.props.fetchWarehouse(this.props.warehouse.id),
    );
}
  handlePutWarehouse = () => {
    const { warehouse } = this.props;
    if (!warehouse) return null;
    let changedWarehouse: WarehouseType = { ...warehouse };
    if (this.state.address) {
      changedWarehouse = { ...changedWarehouse, address: this.state.address };
    }
    if (this.state.name !== null) {
      changedWarehouse = { ...changedWarehouse, name: this.state.name };
    }
    if (this.state.isPickPoint !== null) {
      changedWarehouse = {
        ...changedWarehouse,
        is_pick_point: this.state.isPickPoint,
      };
    }
    return this.props
      .putWarehouse(warehouse.id, changedWarehouse)
      .then(() => this.setState({ name: null, address: null, isPickPoint: null }));
  };

  handleChangeSchedule(schedule) {
    return putWarehouseShchedule({ warehouseId: this.props.warehouse.id, schedule }).then(() =>
      this.props.fetchWarehouse(this.props.warehouse.id),
    );
  }

  render() {
    return (
      <React.Fragment>
        <WarehouseLayout
          progress={<Progress color="secondary" isFetching={this.props.isFetching} />}
          warehouse={
            <Warehouse
              warehouse={this.props.warehouse}
              selectedAddress={this.state.address}
              isPickPoint={this.state.isPickPoint}
              selectedName={this.state.name}
              toggleAddressDialog={this.toggleDialog('addressDialogOpen')}
              toggleNameDialog={this.toggleDialog('nameDialogOpen')}
              toggleIsPickupPoint={this.toggleIsPickupPoint}
              schedule={
                <Schedule
                  onSave={schedule => this.handleChangeSchedule(schedule)}
                  schedule={this.props.warehouse && this.props.warehouse.warehouse_schedule}
                />
              }
              images={
                <ImagesList
                  onSave={this.addImages}
                  onDelete={this.removeImage}
                  onSetDefault={this.setDefaultImage}
                  images={this.props.warehouse && this.props.warehouse.warehouse_images}
                  defaultImage={this.props.warehouse && this.props.warehouse.default_image}
                />
              }
            />
          }
          actions={
            <Button
              disabled={!this.state.name && !this.state.address && this.state.isPickPoint === null}
              onClick={this.handlePutWarehouse}
              color="secondary"
            >
              Сохранить
            </Button>
          }
        />
        <AddressDialog
          open={this.state.addressDialogOpen}
          onClose={this.toggleDialog('addressDialogOpen')}
          onSelect={this.selectAddress}
        />
        <OneFieldFormDialog
          title="название"
          open={this.state.nameDialogOpen}
          onClose={this.toggleDialog('nameDialogOpen')}
          onSave={this.selectName}
        />
      </React.Fragment>
    );
  }
}

WarehouseContainer.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  warehouse: PropTypes.any,
};

const mapStateToProps = store => {
  const state = store.getIn(['shop', 'warehouse']);
  return {
    isFetching: state.isFetching,
    warehouse: state.data,
  };
};

export default connect(mapStateToProps, { putWarehouse, setTopBarTitle, fetchWarehouse })(
  WarehouseContainer,
);
