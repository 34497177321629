import { get, post, del, patch, put } from 'lib/request';
import { API_HOST, PUBLIC_API_HOST } from 'config';

export const getAttributes = async () => {
  try {
    const response = await get(`${API_HOST}/attributes`).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const createAttribute = async attribute => {
  try {
    const response = await post(`${API_HOST}/attributes`, attribute).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const createAttributeItem = async (id, value) => {
  try {
    const response = await post(`${API_HOST}/attributes/${id}/list_possible_values`, value).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const changeAttribute = async (id, value) => {
  try {
    const response = await patch(`${API_HOST}/attributes/${id}`, value).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const chageAttributeElement = async (id, value) => {
  try {
    const response = await patch(`${API_HOST}/attributes/list_possible_values/${id}`, value).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const deleteAttribute = async id => {
  try {
    const response = await del(`${API_HOST}/attributes/${id}`).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const deleteAttributeElement = async id => {
  try {
    const response = await del(`${API_HOST}/attributes/list_possible_values/${id}`).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const addAttributeToProductType = async (id, value) => {
  try {
    const response = await post(`${API_HOST}/products-types/${id}/attributes`, value).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const deleteAttributeFromProductType = async (typeId, attributeId) => {
  try {
    const response = await del(
      `${API_HOST}/products-types/${typeId}/attributes/${attributeId}`,
    ).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const changeProductCharacteristic = async (productId, attributeId, value) => {
  try {
    const response = await put(
      `${API_HOST}/products/${productId}/characteristics/${attributeId}`,
      value,
    ).then(res => res.data);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const changeProductsCharacteristicsBatch = async (attributeId, value) => {
  try {
    const response = await post(
      `${API_HOST}/attributes/${attributeId}/products`,
      value,
    ).then(res => res.data);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};
