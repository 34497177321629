// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tpg from '@material-ui/core/Typography';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ArrowRightAlt from '@material-ui/icons/Forward';
import HelpOutline from '@material-ui/icons/HelpOutline';
import Star from '@material-ui/icons/Star';
import Ruble from 'components/ruble';
import Progress from 'components/progress';
import cn from 'classnames';
import blue from '@material-ui/core/colors/blue';
import { type ProductPricingReport } from 'types/entities';
import { type DialogType } from '../ProductPricingReportContainer';
import { IconButton } from '@material-ui/core';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing.unit * 2,
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70px',
    marginBottom: theme.spacing.unit * 2,
  },
  titleText: {
    transform: 'rotate(-45deg)',
  },
  productPrice: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  a: {
    '& > span': {
      color: theme.palette.secondary[500],
    },
    cursor: 'pointer',
  },
  value: {
    fontWeight: 900,
    color: theme.palette.primary[800],
    '& > span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  productPriceItem: {
    flex: 1,
    margin: theme.spacing.unit,
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[200],
  },
  price: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing.unit * 2,
    padding: theme.spacing.unit,
    backgroundColor: theme.palette.grey[200],
    fontWeight: 900,
    color: theme.palette.primary[800],
    '& span': {
      marginBottom: theme.spacing.unit,
    },
  },
  active: {
    backgroundColor: theme.palette.secondary[100],
  },
  startData: {
    display: 'flex',
  },
  minRetailPrice: {
    backgroundColor: blue[100],
  },
  result: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  priceResult: {
    flex: 1,
    margin: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  totalPrice: {
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '8px',
  },
  supplierTitle: {
    fontSize: '18px',
    paddingLeft: theme.spacing.unit * 2,
  },
  selected: {
    marginLeft: '8px',
    backgroundColor: theme.palette.secondary[100],
    borderRadius: '4px',
    padding: '2px 4px',
    fontSize: '13px',
  },
  promotion: {
    padding: '3px',
    width: '30px',
    width: '30px',
    height: '30px',
  },
});

const markupTitle = {
  mark_ups: {
    default: 'По-умолчанию',
    category: 'Категория',
    manufacturer: 'Производитель',
    brand: 'Бренд',
    product: 'Продукт',
  },
  fixed_prices: {
    product: 'FIX цена',
  },
  min_retail_price: 'Минимальная цена в рознице',
  wholesale_price: 'Базовая цена',
};

type Props = {
  classes: Object,
  isFetching: boolean,
  report: ProductPricingReport | null,
  toggleDialog: (dialogType: DialogType | null) => any,
};
const PricingReport = ({ classes, report, toggleDialog, isFetching }: Props) => {
  if (isFetching) return <Progress />;
  if (!report) return null;
  const getActiveClassName = path => {
    // if (path.every(key => report.mark_up_report.active.filter(name => name === key).length))
    //   return classes.active;
    return null;
  };
  const rub = <Ruble sizePx={12} fill="#37474f" verticalAlign="baseline" />;
  return (
    <div className={classes.root}>
      {report &&
        report.markup_reports &&
        report.markup_reports.map(markupReport => (
          <div key={markupReport.supplier}>
            <div className={classes.supplierTitle}>
              {markupReport.supplier.name}
              {markupReport.supplier.alias === report.active && (
                <span className={classes.selected}>этот поставщик выбран для расчета цены</span>
              )}
            </div>
            <div className={classes.productPrice}>
              <div className={cn(classes.productPriceItem)}>
                <div className={classes.title}>
                  <Tpg className={classes.titleText} variant="caption">
                    Начальная цена поставщика
                  </Tpg>
                </div>
                <div className={classes.value}>
                  <span>{markupReport.source_prices}</span>
                </div>
              </div>
              {Object.keys(markupReport.markup_reports)
                .reverse()
                .map(key => (
                  <div
                    key={key}
                    className={cn(
                      classes.productPriceItem,
                      key === markupReport.active && classes.active,
                    )}
                  >
                    <div className={classes.title}>
                      <Tpg className={classes.titleText} variant="caption">
                        {markupTitle.mark_ups[key]}
                      </Tpg>
                    </div>

                    <div className={classes.value}>
                      <span>
                        <span
                          tabIndex={0}
                          role="button"
                          onKeyPress={
                            key === 'product' &&
                            toggleDialog({
                              dialogType: 'product_markup',
                              supplier: markupReport.supplier,
                            })
                          }
                          onClick={
                            key === 'product' &&
                            toggleDialog({
                              dialogType: 'product_markup',
                              supplier: markupReport.supplier,
                            })
                          }
                          className={key === 'product' ? classes.a : null}
                        >
                          {markupReport.markup_reports[key] === null ? (
                            <Tpg variant="caption">не задана</Tpg>
                          ) : (
                            <span>{markupReport.markup_reports[key]} %</span>
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                ))}
              <div className={cn(classes.productPriceItem)}>
                <div className={classes.title}>
                  <Tpg className={classes.titleText} variant="caption">
                    Итоговая цена поставщика
                  </Tpg>
                </div>
                <div className={classes.value}>
                  <span>{markupReport.output_price}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      <div className={classes.productPrice}>
        <div className={cn(classes.productPriceItem)}>
          <Tpg variant="caption">FIX цена</Tpg>
          <span
            tabIndex={0}
            role="button"
            onKeyPress={toggleDialog({
              dialogType: 'fixed_price_product',
            })}
            onClick={toggleDialog({
              dialogType: 'fixed_price_product',
            })}
            className={classes.a}
          >
            {report.fixed_price === null ? (
              <Tpg variant="caption">не задана</Tpg>
            ) : (
              <span>
                {report.fixed_price}
                {' руб.'}
              </span>
            )}
          </span>
        </div>
        <div className={cn(classes.productPriceItem)}>
          <Tpg variant="caption">Регулярная цена</Tpg>
          <div className={classes.value}>
            <span>
              {report.base_price}
              {' руб.'}
            </span>
          </div>
        </div>
        <div className={cn(classes.productPriceItem)}>
          <Tpg variant="caption">Цена с учетом акции</Tpg>
          <div className={classes.value}>
            <span>
              {report.offer_price}
              {' руб.'}{' '}
              {report.promotion && (
                <IconButton
                  className={classes.promotion}
                  title="Страница акции"
                  href={`/shop/promotion/${report.promotion.id}`}
                  target="_blank"
                >
                  <Star />
                </IconButton>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
  // return (
  //   <div className={classes.root}>
  //     <div>
  //       <Tpg variant="subheading" align="center">
  //         Расчет наценки
  //       </Tpg>
  //     </div>
  //     <div className={classes.productPrice}>
  //       {Object.keys(markupTitle.mark_ups).map(key => (
  //         <div
  //           key={key}
  //           className={cn(classes.productPriceItem, getActiveClassName(['mark_ups', key]))}
  //         >
  //           <div className={classes.title}>
  //             <Tpg className={classes.titleText} variant="caption">
  //               {markupTitle.mark_ups[key]}
  //             </Tpg>
  //           </div>
  //           {key === 'product' ? (
  //             <div className={classes.value}>
  //               {report.mark_up_report.mark_ups[key] === null ? (
  //                 <Tpg variant="caption">
  //                   <span
  //                     tabIndex={0}
  //                     role="button"
  //                     onKeyPress={toggleDialog('product_markup')}
  //                     onClick={toggleDialog('product_markup')}
  //                     className={classes.a}
  //                   >
  //                     не задана
  //                   </span>
  //                 </Tpg>
  //               ) : (
  //                 <span>
  //                   <span
  //                     tabIndex={0}
  //                     role="button"
  //                     onKeyPress={toggleDialog('product_markup')}
  //                     onClick={toggleDialog('product_markup')}
  //                     className={classes.a}
  //                   >
  //                     {Number(report.mark_up_report.mark_ups[key])}
  //                   </span>{' '}
  //                   %
  //                 </span>
  //               )}
  //             </div>
  //           ) : (
  //             <div className={classes.value}>
  //               {/* {report.mark_up_report.mark_ups[key] === null ? (
  //                 <Tpg variant="caption">не задана</Tpg>
  //               ) : (
  //                 `${Number(report.mark_up_report.mark_ups[key])} %`
  //               )} */}
  //             </div>
  //           )}
  //         </div>
  //       ))}
  //       <div
  //         className={cn(classes.productPriceItem, getActiveClassName(['fixed_prices', 'product']))}
  //       >
  //         <div className={classes.title}>
  //           <Tpg className={classes.titleText} variant="caption">
  //             {markupTitle.fixed_prices.product}
  //           </Tpg>
  //         </div>
  //         <div className={classes.value}>
  //           <div>
  //             {report.mark_up_report.fixed_prices.product === null ? (
  //               <Tpg variant="caption">
  //                 <span
  //                   tabIndex={0}
  //                   role="button"
  //                   onKeyPress={toggleDialog('fixed_price_product')}
  //                   onClick={toggleDialog('fixed_price_product')}
  //                   className={classes.a}
  //                 >
  //                   не задана
  //                 </span>
  //               </Tpg>
  //             ) : (
  //               <span>
  //                 <span
  //                   tabIndex={0}
  //                   role="button"
  //                   onKeyPress={toggleDialog('fixed_price_product')}
  //                   onClick={toggleDialog('fixed_price_product')}
  //                   className={classes.a}
  //                 >
  //                   {report.mark_up_report.fixed_prices.product}
  //                 </span>{' '}
  //                 {rub}
  //               </span>
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div>
  //       <Tpg variant="subheading" align="center">
  //         Результат
  //       </Tpg>
  //     </div>
  //     <div className={classes.result}>
  //       <div className={cn(classes.price, classes.minRetailPrice)}>
  //         <Tpg variant="caption">{markupTitle.min_retail_price}</Tpg>
  //         <div className={classes.value}>
  //           {report.min_retail_price === null ? (
  //             <Tpg variant="caption">отсутствует</Tpg>
  //           ) : (
  //             <span>
  //               {report.min_retail_price} {rub}
  //             </span>
  //           )}
  //         </div>
  //       </div>
  //       <div>
  //         {report.mark_up_report.total_price !== null &&
  //           report.min_retail_price !== null &&
  //           (report.mark_up_report.total_price > report.min_retail_price ? (
  //             <ChevronLeft title="меньше" />
  //           ) : (
  //             <ChevronRight title="больше" />
  //           ))}
  //         {!report.mark_up_report.total_price && !report.min_retail_price && (
  //           <HelpOutline title="меньше" />
  //         )}
  //       </div>
  //       <div className={cn(classes.price, classes.active)}>
  //         <Tpg variant="caption">
  //           Цена после наценки (
  //           {markupTitle[report.mark_up_report.active[0]][report.mark_up_report.active[1]]})
  //         </Tpg>
  //         <div className={classes.value}>
  //           {report.mark_up_report.total_price === null ? (
  //             <Tpg variant="caption" align="center">
  //               не может быть расчитана,
  //               <br /> отсутствует базовая цена
  //             </Tpg>
  //           ) : (
  //             <span>
  //               {report.mark_up_report.total_price} {rub}
  //             </span>
  //           )}
  //         </div>
  //       </div>
  //       <div>
  //         <ArrowRightAlt />
  //       </div>
  //       <div className={cn(classes.price, classes.totalPrice)}>
  //         <Tpg variant="caption">Итоговая цена</Tpg>
  //         <div className={classes.value}>
  //           {report.total_price === null ? (
  //             <Tpg variant="caption" align="center">
  //               не может быть расчитана,
  //               <br /> отсутствует базовая цена
  //             </Tpg>
  //           ) : (
  //             <span>
  //               {report.total_price} {rub}
  //             </span>
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

PricingReport.propTypes = {
  classes: PropTypes.object.isRequired,
  report: PropTypes.object,
};

export default withStyles(styles)(PricingReport);
