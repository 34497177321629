// @flow
import { type ThunkAction, type Dispatch } from 'types/actions';
import { type Promotion } from 'types/entities/Promotion';
import {
  FETCH_PROMOTIONS_REQUEST,
  FETCH_PROMOTIONS_SUCCESS,
  type RequestPromotions,
  type ReceivePromotions,
} from 'types/actions/Promotion';
import { fetchPromotions as fetchPromotionsFromApi } from 'services/promotionApi';

export const requestPromotions = (): RequestPromotions => ({
  type: FETCH_PROMOTIONS_REQUEST,
});

export const receivePromotions = (
  res: { data: Array<Promotion>, total_count: number } | null,
): ReceivePromotions => ({
  type: FETCH_PROMOTIONS_SUCCESS,
  promotions: res && res.data,
  totalCount: (res && res.total_count) || 0,
});

export const fetchPromotions = (query = {}): ThunkAction => (dispatch: Dispatch) => {
  dispatch(requestPromotions());
  return fetchPromotionsFromApi(query).then(res => dispatch(receivePromotions(res)));
};
