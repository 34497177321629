import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
    display: 'flex',
    alignItems: 'flex-end',
  },
  textField: {
    marginRight: theme.spacing.unit * 3,
  },
  formControl: {
    minWidth: '120px',
  },
});

const UndefinedBrands = ({
  classes,
  handleChangeFeild,
  filterString,
  sources,
  selectedSource,
}) => (
  <Paper className={classes.root}>
    <TextField
      label="Название"
      value={filterString}
      name="filterString"
      className={classes.textField}
      onChange={e => handleChangeFeild(e.target.name, e.target.value)}
      margin="none"
    />
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="supplier">Источник</InputLabel>
      <Select
        name="selectedSource"
        value={selectedSource}
        onChange={e => handleChangeFeild(e.target.name, e.target.value)}
        inputProps={{ id: 'supplier' }}
      >
        <MenuItem value="">
          <em>Не выбран</em>
        </MenuItem>
        {sources.map(source => (
          <MenuItem key={source.alias} value={source.alias}>{source.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  </Paper>
);

UndefinedBrands.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChangeFeild: PropTypes.func.isRequired,
  filterString: PropTypes.string.isRequired,
  sources: PropTypes.array.isRequired,
  selectedSource: PropTypes.string.isRequired,
};

export default withStyles(styles)(UndefinedBrands);
