import {
  FETCH_UNDEFINED_MANUFACTURERS_REQUEST,
  FETCH_UNDEFINED_MANUFACTURERS_SUCCESS,
  PROCESSING_UNDEFINED_MANUFACTURER,
} from 'types/actions';
import Immutable from 'immutable';

const undefinedManufacturersInitialState = Immutable.Map({
  isFetching: false,
  items: Immutable.Map(),
});

const undefinedManufacturers = (state = undefinedManufacturersInitialState, action) => {
  switch (action.type) {
    case FETCH_UNDEFINED_MANUFACTURERS_REQUEST: {
      return state.set('isFetching', true);
    }
    case FETCH_UNDEFINED_MANUFACTURERS_SUCCESS: {
      const nextState = state.set('isFetching', false);
      const result = {};
      if (action.payload.result.undefinedManufacturers) {
        action.payload.result.undefinedManufacturers.forEach((id) => {
          result[id] = {
            ...action.payload.entities.undefinedManufacturer[id],
            processing: false,
          };
        });
      }
      return nextState.set('items', Immutable.fromJS(result));
    }

    case PROCESSING_UNDEFINED_MANUFACTURER: {
      return state.setIn(
        ['items', action.idManufacturer.toString(), 'processing'],
        true,
      );
    }

    default: {
      return state;
    }
  }
};

export default undefinedManufacturers;
