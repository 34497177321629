// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { type Theme, type ReactComponent } from 'types/components';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  product: any,
  changeProduct: (name: string, value: any) => any
|};

const IsPublishedControlComponent = ({ classes, product, changeProduct }: {| ...Props, ...ProvidedProps |}) => (
  <FormControlLabel
  control={
    <Switch checked={product && product.published} onChange={() => changeProduct('published', Boolean(product && !product.published))}/>
  }
  label={product && product.published ? 'Опубликован' : 'Неопубликован'}
/>
);

export const IsPublishedControl: ReactComponent<Props> = withStyles(styles)(
  IsPublishedControlComponent,
);
