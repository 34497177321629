// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { type typeStockItem } from 'types/entities/typeStockItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { withRouter } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { getProductItems } from 'services/productApi';
import queryString from 'query-string';

const styles = theme => ({
  root: {},
  progress: {
    height: '5px',
  },
  button: {
    width: '40px',
    height: '40px',
  },
  openedAliases: {
    backgroundColor: theme.palette.grey[200],
  },
  aliasesCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chip: {
    margin: '3px',
  },
  tableRow: {
    // cursor: 'pointer',
  },
  isSelling: {
    paddingRight: theme.spacing.unit * 2,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: '#98FB98',
  },
  isPickPoint: {
    paddingRight: theme.spacing.unit * 2,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: '#AFEEEE',
  },
  isPricingParticipant: {
    paddingRight: theme.spacing.unit * 2,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: '#DDA0DD',
  },
});

const tableColumns = [
  {
    key: 'id',
    name: 'id',
    sortable: true,
    numeric: true,
  },
  {
    key: 'purchase_price',
    name: 'Базовая',
    sortable: true,
    numeric: true,
  },
  {
    key: 'retail_price',
    name: 'Розничная',
    sortable: true,
    numeric: true,
  },
  {
    key: 'stock',
    name: 'Количество',
    sortable: true,
    numeric: true,
  },
  {
    key: 'warehouse',
    name: 'Расположение',
    sortable: true,
    numeric: false,
  },
  {
    key: 'update_time',
    name: 'Обновлен',
    sortable: true,
    numeric: true,
  },
];

/* eslint-disable no-confusing-arrow */

type Props = {
  classes: Object,
  product: typeProduct,
};

type State = {
  order: 'asc' | 'desc',
  orderBy: 'id' | 'price' | 'stock' | 'warehouse' | 'update_time' | 'import_process_id',
  page: number,
  rowsPerPage: number,
  is_pick_point: boolean,
  is_pricing_participant: boolean,
  is_selling: boolean,
};

class ProductStockItemsTable extends React.Component<Props, State> {
  state = {
    order: 'asc',
    orderBy: 'stock',
    page: 0,
    rowsPerPage: 14,
    is_pick_point: false,
    is_pricing_participant: false,
    is_selling: false,
    stockItems: null,
  };

  componentDidMount() {
    getProductItems({ productId: this.props.product.id }).then(stockItems =>
      this.setState({ stockItems }),
    );
  }

  sort = data => {
    if (!data) return [];
    if (['warehouse'].includes(this.state.orderBy)) {
      if (this.state.order === 'desc') {
        return data.sort((a: $FlowFixMe, b: $FlowFixMe) =>
          b[this.state.orderBy].name < a[this.state.orderBy].name ? -1 : 1,
        );
      }
      return data.sort((a: $FlowFixMe, b: $FlowFixMe) =>
        a[this.state.orderBy].name < b[this.state.orderBy].name ? -1 : 1,
      );
    }
    if (this.state.order === 'desc') {
      return data.sort((a: $FlowFixMe, b: $FlowFixMe) =>
        b[this.state.orderBy] < a[this.state.orderBy] ? -1 : 1,
      );
    }
    return data.sort((a: $FlowFixMe, b: $FlowFixMe) =>
      a[this.state.orderBy] < b[this.state.orderBy] ? -1 : 1,
    );
  };
  handleRequestSort = property => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };
  getPropertyClass = (stockItem: typeStockItem) => {
    return null;
  };
  handleChangePage = (event, page) => this.setState({ page });

  filterData = (list: Array<any>) => {
    if (!list) return null;

    let filtredList = [];

    const query = queryString.parse(this.props.location.search);

    if (query.fias_id) {
      filtredList = list.filter(item => item.warehouse.address.region.fias_id === query.fias_id);
    }

    if (!query.fias_id) {
      filtredList = list.filter(
        item => item.warehouse.address.region.fias_id === '43909681-d6e1-432d-b61f-ddac393cb5da',
      );
    }

    const { is_pick_point, is_selling, is_pricing_participant } = this.state;

    if (is_pick_point) {
      filtredList = filtredList.filter(item => item.warehouse.is_pick_point);
    }
    if (is_selling) {
      filtredList = filtredList.filter(item => item.warehouse.is_selling);
    }
    if (is_pricing_participant) {
      filtredList = filtredList.filter(item => item.warehouse.is_pricing_participant);
    }

    return filtredList;
  };

  render() {
    const { classes } = this.props;
    const { order, orderBy, page, rowsPerPage, stockItems } = this.state;
    const filteredData = this.filterData(stockItems);
    const sortedData = this.sort(filteredData);
    return (
      <div>
        <FormControlLabel
          className={classes.isPricingParticipant}
          control={
            <Checkbox
              checked={this.state.is_pricing_participant}
              onChange={() =>
                this.setState({
                  is_pricing_participant: !this.state.is_pricing_participant,
                })
              }
            />
          }
          label="Участвует в ценообоазовании"
        />
        <FormControlLabel
          className={classes.isPickPoint}
          control={
            <Checkbox
              checked={this.state.is_pick_point}
              onChange={() => this.setState({ is_pick_point: !this.state.is_pick_point })}
            />
          }
          label="Пункт выдачи"
        />
        <FormControlLabel
          className={classes.isSelling}
          control={
            <Checkbox
              checked={this.state.is_selling}
              onChange={() => this.setState({ is_selling: !this.state.is_selling })}
            />
          }
          label="Товар продается"
        />
        <Table>
          <TableHead>
            <TableRow>
              {tableColumns.map(column => {
                if (column.sortable) {
                  return (
                    <TableCell
                      numeric={column.numeric}
                      padding="dense"
                      key={column.key}
                      sortDirection={orderBy === column.key ? order : false}
                    >
                      <Tooltip title="Сортировать" placement="bottom-start" enterDelay={300}>
                        <TableSortLabel
                          active={orderBy === column.key}
                          direction={order}
                          onClick={() => this.handleRequestSort(column.key)}
                        >
                          {column.name}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  );
                }
                if (column.key === 'warehouse') {
                  return (
                    <TableCell
                      numeric={column.numeric}
                      padding="dense"
                      key={column.key}
                      sortDirection={orderBy === column.key ? order : false}
                    >
                      <Tooltip title="Сортировать" placement="bottom-start" enterDelay={300}>
                        <TableSortLabel
                          active={orderBy === column.key}
                          direction={order}
                          onClick={() => this.handleRequestSort(column.key)}
                        >
                          {column.name}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  );
                }
                return <TableCell numeric={column.numeric}>{column.name}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              // .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
              .map(item => (
                <TableRow hover className={classes.tableRow} key={item.id}>
                  <TableCell numeric padding="dense">
                    {item.id}
                  </TableCell>
                  <TableCell
                    numeric
                    padding="dense"
                    className={
                      item.warehouse.is_pricing_participant ? classes.isPricingParticipant : null
                    }
                  >
                    {item.purchase_price} {item.purchase_price ? 'руб.' : ''}
                  </TableCell>
                  <TableCell
                    numeric
                    padding="dense"
                    className={
                      item.warehouse.is_pricing_participant ? classes.isPricingParticipant : null
                    }
                  >
                    {item.retail_price} {item.retail_price ? 'руб.' : ''}
                  </TableCell>
                  <TableCell
                    numeric
                    padding="dense"
                    className={item.warehouse.is_selling ? classes.isSelling : null}
                  >
                    {item.stock}
                  </TableCell>
                  <TableCell
                    padding="dense"
                    className={item.warehouse.is_pick_point ? classes.isPickPoint : null}
                  >
                    {item.warehouse.name}
                  </TableCell>
                  <TableCell numeric padding="dense">
                    {new Date(item.update_time * 1000).toLocaleString('ru')}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {/* {sortedData.length > rowsPerPage &&
        <TablePagination
          component="div"
          count={sortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Строк на странице"
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          rowsPerPageOptions={[14, 25, 50, sortedData.length]}
        />
        } */}
      </div>
    );
  }
}
ProductStockItemsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  stockItems: PropTypes.array.isRequired,
};

export default withRouter(withStyles(styles)(ProductStockItemsTable));
