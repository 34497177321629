// @flow
import React from 'react';
import { connect } from 'react-redux';
import idx from 'idx';
import { type Order } from 'types/entities/Order';
import { type Product } from 'types/entities/Product';
import {
  createRealizationMethod,
  changeRealizationMethod,
} from 'components/shop/realization-method/actions';
import ProductsDialog from 'components/products-dialog';
import { orderStates, type OrderState } from 'types/entities/OrderState';
import { fetchDeliveryPeriods } from 'components/shop/delivery-periods/actions';
import { fetchPickupTime } from 'components/shop/pickup-time/actions';
import { toggleAssemblyOrderDialog } from 'components/shop/assembly-order-dialog/actions';
import AssemblyOrderDialog from 'components/shop/assembly-order-dialog';
import { GiftLabel } from 'components/shop/gift-label';
import OrderLayout from './view/OrderLayout';
import CustomerInfo from './view/CustomerInfo';
import OrderControls from './view/OrderControls';
import Delivery from './view/Delivery';
import { UncompletedDeliveries } from './view/UncompletedDeliveries';
import Lines from './view/Lines';
import { Plans } from './view/Plans';
import Comment from './view/Comment';
import Total from './view/Total';
import Payments from './view/Payments';
import AddElementButton from './view/AddElementButton';
import {
  fetchOrder,
  changeOrderState,
  changeOrder,
  changeOrderLineQuantity,
  addProduct,
  removeOrderLine,
} from './actions';
import { RecalculateByDate } from './view/RecalculateByDate';
import { Coupon } from './view/Coupon';
import { ApologizeCouponSend } from './view/ApologizeCouponSend';

type Props = {|
  fetchOrder: (orderId: number) => void,
  removeOrderLine: (orderId: number, lineId: number) => void,
  addProduct: (product: Product, orderId: number) => void,
  changeOrderState: (orderId: number, state: OrderState) => Promise<any>,
  createRealizationMethod: (realizationMethod: $FlowFixMe) => Promise<any>,
  changeOrderLineQuantity: (orderId: number, lineId: number, quantity: number) => void,
  changeOrder: (orderId: number, data: any) => Promise<any>,
  toggleAssemblyOrderDialog: () => void,
  getOrders?: Function,
  fetchDeliveryPeriods: ({ orderId: number }) => void,
  fetchPickupTime: ({ orderId: number }) => void,
  isFetching: boolean,
  elevation?: boolean,
  order: Order | null,
|};

type State = {|
  productDialogOpen: boolean,
  edited: boolean,
|};

export class OrderContainer extends React.Component<Props, State> {
  state = {
    productDialogOpen: false,
    edited: false,
  };

  fetchOrder = () => {
    if (this.props.order) this.props.fetchOrder(this.props.order.id);
  };

  changeRealizationMethod = realizationMethod => {
    if (
      this.props.order.realization_method &&
      this.props.order.realization_method.address &&
      this.props.order.realization_method.address.id === realizationMethod.address.id
    ) {
      return this.props.changeRealizationMethod(realizationMethod).then(() => this.fetchOrder());
    }
    return this.props.createRealizationMethod(realizationMethod).then(() => this.fetchOrder());
  };

  changeOrderState = (state: OrderState) => () => {
    if (this.props.order) {
      this.props.changeOrderState(this.props.order.id, state).then(() => this.props.getOrders());
    }
  };

  changeOrder = (data: any) => {
    if (this.props.order) {
      this.props.changeOrder(this.props.order.id, data).then(() => this.props.getOrders());
    }
  };

  changeQuantity = (lineId: number, quantity: number) => {
    if (this.props.order) this.props.changeOrderLineQuantity(this.props.order.id, lineId, quantity);
  };

  handleAddProduct = (product: Product) => () => {
    if (this.props.order) {
      const orderId = this.props.order.id;
      const address =
        this.props.order.realization_method && this.props.order.realization_method.address;
      this.props.addProduct(product, orderId);
      this.props.fetchDeliveryPeriods({ orderId, address });
      this.props.fetchPickupTime({ orderId });
    }
    this.toggleProductsDialog();
  };

  handleRemoveOrderLine = (lineId: number) => () => {
    if (this.props.order) this.props.removeOrderLine(this.props.order.id, lineId);
  };

  toggleProductsDialog = () =>
    this.setState(state => ({ productDialogOpen: !state.productDialogOpen }));

  changeEditedStatus = (edited: boolean) => this.setState({ edited });

  render() {
    const editable = ![orderStates.completed, orderStates.canceled].includes(
      idx(this.props.order, _ => _.state),
    );

    return (
      <OrderLayout
        isFetching={this.props.isFetching}
        elevation={Boolean(this.props.elevation)}
        orderControls={
          <OrderControls
            order={this.props.order}
            handleToggleAssemblyOrderDialog={this.props.toggleAssemblyOrderDialog}
            editable={editable && !this.state.edited}
            edited={this.state.edited}
            handleChangeOrderState={this.changeOrderState}
          />
        }
        customerInfo={
          <CustomerInfo
            orderId={idx(this.props.order, _ => _.id)}
            user={idx(this.props.order, _ => _.user)}
          />
        }
        delivery={
          <Delivery
            order={this.props.order}
            changeRealizationMethod={this.changeRealizationMethod}
            changeOrder={this.changeOrder}
            editable={editable}
            edited={this.state.edited}
            changeEditedStatus={this.changeEditedStatus}
            getOrder={this.fetchOrder}
          />
        }
        lines={
          this.props.order && this.props.order.plans.length ? (
            <Plans
              plans={idx(this.props.order, _ => _.plans)}
              renderLines={lines => (
                <Lines
                  editable={editable}
                  changeQuantity={this.changeQuantity}
                  handleRemoveOrderLine={this.handleRemoveOrderLine}
                  lines={lines}
                />
              )}
            />
          ) : (
            <Lines
              editable={editable}
              changeQuantity={this.changeQuantity}
              handleRemoveOrderLine={this.handleRemoveOrderLine}
              lines={idx(this.props.order, _ => _.lines)}
            />
          )
        }
        giftLabel={<GiftLabel order={this.props.order} />}
        total={
          <Total
            totalCost={
              idx(this.props.order, _ => _.realization_method.total_cost) ||
              idx(this.props.order, _ => _.cost)
            }
          />
        }
        addElementButton={
          <AddElementButton onClick={this.toggleProductsDialog} disabled={!editable} />
        }
        productsDialog={
          <ProductsDialog
            open={this.state.productDialogOpen}
            onSelect={this.handleAddProduct}
            onClose={this.toggleProductsDialog}
          />
        }
        uncompletedDeliveries={
          <UncompletedDeliveries order={this.props.order} onSave={this.fetchOrder} />
        }
        payments={
          <Payments
            isPaid={idx(this.props.order, _ => _.realization_method.is_paid)}
            payments={idx(this.props.order, _ => _.realization_method.payments)}
          />
        }
        coupon={<Coupon coupon={this.props.order && this.props.order.coupon} />}
        comments={
          this.props.order && this.props.order.user_comments
            ? this.props.order.user_comments.map(comment => <Comment comment={comment} />)
            : null
        }
        assemblyOrderDialog={<AssemblyOrderDialog onSave={this.fetchOrder} />}
        reaclculateByDate={
          this.props.order &&
          this.props.order.state === 'processing' && (
            <RecalculateByDate
              onChanage={this.fetchOrder}
              orderId={this.props.order && this.props.order.id}
            />
          )
        }
        apologizeCouponSend={
          this.props.order &&
          [orderStates.canceled, orderStates.completed].includes(this.props.order.state) && (
            <ApologizeCouponSend onChanage={this.fetchOrder} orderId={this.props.order && this.props.order.id} />
          )
        }
      />
    );
  }
}

const mapStateToProps = store => {
  const state = store.getIn(['shop', 'order']);
  return {
    isFetching: state.isFetching,
    order: state.data,
  };
};

export default connect(mapStateToProps, {
  fetchOrder,
  createRealizationMethod,
  changeRealizationMethod,
  changeOrderState,
  changeOrderLineQuantity,
  addProduct,
  removeOrderLine,
  fetchDeliveryPeriods,
  fetchPickupTime,
  toggleAssemblyOrderDialog,
  changeOrder,
})(OrderContainer);
