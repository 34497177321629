import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LeftSideBar from './view/LeftSideBar';
import { toggleLeftSideBar } from './actions';

export class LeftSideBarContainer extends React.Component {
  componentDidMount() {
  }
  render() {
    if (!this.props.user) return null;
    return (
      <LeftSideBar
        location={this.props.location}
        opened={this.props.opened}
        toggleLeftSideBar={this.props.toggleLeftSideBar}
      />
    );
  }
}

LeftSideBarContainer.propTypes = {
  opened: PropTypes.bool.isRequired,
  toggleLeftSideBar: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = store => {
  const state = store.get('leftSideBar');
  const user = store.get('user');
  return {
    opened: state.get('opened'),
    user: user.data,
  };
};

export default withRouter(connect(
  mapStateToProps,
  { toggleLeftSideBar },
)(LeftSideBarContainer));
