// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';
import Ruble from 'components/ruble';
import { formatNumber } from 'lib/formatting';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    fontSize: theme.typography.pxToRem(22),
  },
  price: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(26),
    lineHeight: 1,
  },
  border: {
    flexGrow: 1,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
});
type Props = {|
  classes: Object,
  cost: number | null,
  title: string,
|};

const PriceBlock = ({ classes, cost, title }: Props) => (
  <div className={classes.root} id="PriceBlockRoot">
    <div>
      {title}
    </div>
    <div className={classes.border} />
    <div className={classes.price}>
      {cost ? formatNumber(cost) : 0}
      {' '}
      <Ruble sizePx={20} verticalAlign="baseline" />
    </div>
  </div>
);

PriceBlock.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PriceBlock);
