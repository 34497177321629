// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';
import { type OrderLinePlanItem } from 'types/entities/OrderLinePlanItem';
import moment from 'moment';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.grey[800],
  },
});
type Props = {|
  classes: Object,
  plan: OrderLinePlanItem | null,
|};

const Plan = ({ classes, plan }: Props) => {
  if (!plan) return null;
  return (
    <div className={classes.root}>
      <div>
        {plan.warehouse_sender.name}
        {' '}
        {moment(plan.estimated_completion_time.substr(0, 19)).format('HH:mm, DD.MM.YYYY')}
      </div>
    </div>
  );
};

export default withStyles(styles)(Plan);
