// @flow
import React from 'react';
import { type Product } from 'types/entities/Product';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Create from '@material-ui/icons/Create';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';

type Props = {
  classes: Object,
  product: Product,
  changeProduct: (name: string, value: string) => void,
};

type State ={
  editOpened: boolean,
  newSlug: string,
};

const styles = theme => ({
  root: {
    margin: `0 -${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[200],
    zIndex: -1,
  },
  block: {
    display: 'inline-block',
    padding: '5px 5px 5px 12px',
  },
  slug: {
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  a: {
    color: theme.palette.secondary[700],
  },
  iconButton: {
    width: '30px',
    height: '30px',
    marginLeft: theme.spacing.unit,
  },
  icon: {
    fontSize: '18px',
  },
});

class Slug extends React.Component<Props, State> {
  state = {
    editOpened: false,
    newSlug: '',
  };
  saveSlug = () => {
    this.props.changeProduct('slug', this.state.newSlug);
    this.setState({ editOpened: false });
  }
  render() {
    const { classes, product } = this.props;
    const { editOpened, newSlug } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.block}>
          {editOpened &&
          <TextField
            placeholder="slug"
            className={classes.textField}
            value={this.state.newSlug || product.slug}
            onChange={e => this.setState({ newSlug: e.target.value })}
            margin="none"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={!newSlug}
                    color="secondary"
                    title="Сохранить"
                    onClick={this.saveSlug}
                  >
                    <Done className={classes.icon} />
                  </IconButton>
                  <IconButton
                    title="Отмена"
                    onClick={() => this.setState({ editOpened: false, newSlug: '' })}
                  >
                    <Close className={classes.icon} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          }
          {!editOpened &&
          <Typography variant="subheading" className={classes.slug}>
            <a href={`/products/${product.id}`} className={classes.a}>
              {product.slug}
            </a>
            <IconButton
              title="Изменить slug"
              onClick={() => this.setState({ editOpened: true })}
              className={classes.iconButton}
            >
              <Create className={classes.icon} />
            </IconButton>
          </Typography>
          }
        </div>
      </div>
    );
  }
}

Slug.propTypes = {
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};

export default withStyles(styles)(Slug);
