// @flow
export const ROOT_API_HOST: string = 'https://api.bonjour-dv.ru';
export const API_HOST: string = 'https://api.bonjour-dv.ru/v1';
export const PUBLIC_API_HOST = 'https://api.bonjour-dv.ru/public_v1';
export const SUGGESTIONS_HOST = 'https://suggestions.dadata.ru';
export const SHOP_HOST = 'https://bonjour-dv.ru';
export const HELPER_HOST = 'https://helper.bonjour-dv.ru';
export const GIFT_RULES = [
  {
    name:
      'Купи 2 любых продукта из категории Дезодоранты и/или гели для душа или дезодоранты брендов AXE, ReXONA, DOVE, CAMAY',
    brands: [42, 43, 46, 48],
    categories: [65, 66, 67],
    products: [],
    barcodes: [],
    start: '2019-11-20 00:00:00',
    end: '2019-12-01 00:00:00',
    quantity: 2,
  },
  {
    name: 'Купи одно средство Ласка - второе в подарок.',
    brands: [],
    categories: [],
    products: [36096, 76908],
    barcodes: [9000100488914, 9000101028447],
    start: '2020-01-10 00:00:00',
    end: '2020-03-01 00:00:00',
    quantity: 1,
  },
  {
    name: 'Код для регистрации в онлайн-кинотеатре',
    brands: [21,59,62],
    categories: [],
    products: [],
    barcodes: [],
    start: '2020-05-01 00:00:00',
    end: '2020-06-01 00:00:00',
    quantity: 1,
  },
  {
    name: 'Два по цене одного',
    brands: [],
    categories: [],
    products: [87534],
    barcodes: [],
    start: '2020-07-09 00:00:00',
    end: '2020-07-31 00:00:00',
    quantity: 1,
  },
  {
    name: '',
    brands: [1163, 42],
    categories: [],
    products: [],
    barcodes: [],
    start: '2020-08-01 00:00:00',
    end: '2020-09-01 00:00:00',
    quantity: 1,
  },
  {
    name: '',
    brands: [46],
    categories: [],
    products: [],
    barcodes: [],
    start: '2020-08-01 00:00:00',
    end: '2020-09-24 00:00:00',
    quantity: 2,
  },
  {
    name: 'При покупке двух продуктов, участвующих в акции, свеча в подарок',
    brands: [],
    categories: [],
    products: [128403,128408,128405,128409,128404,128407,128402,128401,128406],
    barcodes: [3700971368532,3700971368549,3700971368556,3666670801117,3666670801070,3700971368594,3700971368600,3666670801285,3700971368587],
    start: '2023-02-09 00:00:00',
    end: '2023-02-29 00:00:00',
    quantity: 2,
  }
];

