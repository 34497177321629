import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FilterList from '@material-ui/icons/FilterList';
import UndefinedManufacturers from './view/UndefinedManufacturers';
import fuzzyFilter from '../fuzzy_filter';
import {
  fetchUndefinedManufacturers,
  addToManufacturer,
  addManufacturer,
} from './actions';
import { fetchManufacturersIfNeeded } from '../manufacturers/actions';
import { setTopBarTitle } from '../top_bar/actions';
import { setRightSideBarActions } from '../right_side_bar/actions';
import UndefinedManufacturersFilter from './view/UndefinedManufacturersFilter';

const fuzzyOptionsByName = {
  extract: el => el.value,
  returnAllOnEmptyString: true,
  returnOriginal: true,
};

const fuzzyOptionsBySupplier = {
  ...fuzzyOptionsByName,
  extract: el => el.supplier.alias,
};

export class UndefinedManufacturersContainer extends React.Component {
  state = {
    filterOpened: false,
    filterString: '',
    sources: [],
    selectedSource: '',
  }
  componentDidMount() {
    const actionsForRightSideBar = [
      {
        key: 'filterManufacturersOpened',
        icon: <FilterList />,
        name: 'Фильтрация',
        action: () => this.toggleFilterUndefinedManufacturers(),
      },
    ];
    this.props.fetchUndefinedManufacturers();
    this.props.fetchManufacturersIfNeeded();
    this.props.setTopBarTitle('Новые производители');
    this.props.setRightSideBarActions(actionsForRightSideBar);
  }
  componentWillUnmount() {
    this.props.setRightSideBarActions([]);
  }
  setSources = undefinedManufacturers => {
    const sources = {};
    undefinedManufacturers.forEach(undMf => {
      sources[undMf.supplier.alias] = undMf.supplier.name;
    });
    this.setState({
      sources: Object.keys(sources).map(supplierAlias => (
        { alias: supplierAlias, name: sources[supplierAlias] }
      )),
    });
  }
  toggleFilterUndefinedManufacturers = () => {
    this.setState(prev => ({ filterOpened: !prev.filterOpened }));
    this.setSources(this.props.undefinedManufacturers);
  }
  handleChangeFeild = (name, value) => this.setState({ [name]: value });
  filterData = data => {
    let filtredData = [...data];
    filtredData = fuzzyFilter(this.state.filterString, filtredData, fuzzyOptionsByName);
    filtredData = fuzzyFilter(this.state.selectedSource, filtredData, fuzzyOptionsBySupplier);
    return filtredData;
  }
  render() {
    return (
      <React.Fragment>
        {this.state.filterOpened &&
          <UndefinedManufacturersFilter
            handleChangeFeild={this.handleChangeFeild}
            filterString={this.state.filterString}
            sources={this.state.sources}
            selectedSource={this.state.selectedSource}
          />
        }
        <UndefinedManufacturers
          addToManufacturer={this.props.addToManufacturer}
          addManufacturer={this.props.addManufacturer}
          isFetching={this.props.isFetching}
          undefinedManufacturers={this.filterData(this.props.undefinedManufacturers)}
          manufacturers={this.props.manufacturers}
        />
      </React.Fragment>
    );
  }
}

UndefinedManufacturersContainer.propTypes = {
  fetchUndefinedManufacturers: PropTypes.func.isRequired,
  setTopBarTitle: PropTypes.func.isRequired,
  fetchManufacturersIfNeeded: PropTypes.func.isRequired,
  manufacturers: PropTypes.array.isRequired,
  undefinedManufacturers: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  addToManufacturer: PropTypes.func.isRequired,
  setRightSideBarActions: PropTypes.func.isRequired,
  addManufacturer: PropTypes.func.isRequired,
};

const mapStateToProps = store => ({
  isFetching: store.getIn(['undefinedManufacturers', 'isFetching']),
  undefinedManufacturers: store.getIn(['undefinedManufacturers', 'items']).valueSeq().toArray().map(item => item.toJS()),
  manufacturers: store.getIn(['manufacturers', 'items']).valueSeq().toArray(),
});

export default connect(
  mapStateToProps,
  {
    fetchUndefinedManufacturers,
    fetchManufacturersIfNeeded,
    addToManufacturer,
    addManufacturer,
    setTopBarTitle,
    setRightSideBarActions,
  },
)(UndefinedManufacturersContainer);
