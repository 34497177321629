// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import IconButton from '@material-ui/core/IconButton';
import Store from '@material-ui/icons/Store';
import { withRouter } from 'react-router-dom';
import { SHOP_HOST } from 'config';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Link } from 'react-router-dom';
import { type Promotion } from 'types/entities/Promotion';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import { Checkbox } from '@material-ui/core';
import Progress from 'components/progress';
import { type Theme } from 'types/components/Theme';

const styles = (theme: Theme) => ({
  listItem: {
    // padding: theme.spacing.unit,
    width: '100%',
    cursor: 'pointer',
  },
  table: {
    tableLayout: 'fixed',
  },
});
type Props = {
  classes: Object,
  history: any,
  promotions: Array<Promotion> | null,
  isFetching: boolean,
  onSelect?: (promotion: Promotion) => void,
};

const PromotionsList = ({ classes, promotions, onSelect, isFetching, history }: Props) => {
  const handleClick = (promotion: Promotion) => () => {
    if (!onSelect) {
      window.open(`/shop/promotion/${promotion.id}`, '_blank');
    }
    if (onSelect) return onSelect(promotion);
    return null;
  };
  return (
    <React.Fragment>
      <Progress color="secondary" isFetching={isFetching} />
      <Paper>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Дата начала</TableCell>
              <TableCell>Дата окончания</TableCell>
              <TableCell>Название</TableCell>
              <TableCell numeric>Код</TableCell>
              <TableCell numeric>Расслыка</TableCell>
              <TableCell numeric>Активна</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {promotions &&
              promotions.map(promotion => (
                <TableRow hover key={promotion.id} onClick={handleClick(promotion)}>
                  <TableCell className={classes.listItem}>
                    {moment(promotion.start).format('DD.MM.YYYY HH:mm')}
                  </TableCell>
                  <TableCell className={classes.listItem}>
                    {moment(promotion.end).format('DD.MM.YYYY HH:mm')}
                  </TableCell>
                  <TableCell className={classes.listItem}>{promotion.title}</TableCell>
                  <TableCell className={classes.listItem} numeric>
                    {promotion.id_klipper}
                  </TableCell>
                  <TableCell className={classes.listItem} numeric>
                    {promotion.is_promoted_by_email ? 'Email' : null}
                  </TableCell>
                  <TableCell className={classes.listItem} numeric>
                    <Checkbox disabled checked={promotion.is_enabled} />
                  </TableCell>
                  <TableCell className={classes.listItem} numeric>
                    <IconButton
                      onClick={e => e.stopPropagation()}
                      target="_blank"
                      href={`${SHOP_HOST}/promotions/${promotion.id}`}
                    >
                      <Store />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  );
};

PromotionsList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(PromotionsList));
