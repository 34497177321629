// @flow
import { fetchImagesLogs as fetchImagesLogsFromApi } from 'services/imagesLogsApi';
import {
  FECTH_IMAGES_LOGS_REQUEST,
  FECTH_IMAGES_LOGS_SUCCESS,
  type RequestImagesLogs,
  type ReceivImagesLogs,
} from 'types/actions';
import { type ImageLog } from 'types/entities';

const requestImagesLogs = (): RequestImagesLogs => ({
  type: FECTH_IMAGES_LOGS_REQUEST,
});

const receivImagesLogse = (logs: Array<ImageLog>): ReceivImagesLogs => ({
  type: FECTH_IMAGES_LOGS_SUCCESS,
  logs,
});

export const fetchImagesLogs = (
  after: string | void,
  before: string | void,
) => (dispatch: $FlowFixMe) => {
  dispatch(requestImagesLogs());
  fetchImagesLogsFromApi({ 'filter[start]': after, 'filter[end]': before }).then(logs => dispatch(receivImagesLogse(logs)));
};

export const a = {};
