import { get, publicRoutes } from 'lib/request';
import { PUBLIC_API_HOST } from 'config';

export const getData = async endpoint => {
  try {
    const response = await get(endpoint);
    return response;
  } catch (error) {
    if (publicRoutes.every(item => !item.includes(location.pathname))) location.href = '/login';
    return error.response;
  }
};

export const getCurrentUser = () => getData(`${PUBLIC_API_HOST}/me`);

export const logout = () => get(`${PUBLIC_API_HOST}/logout`);
