// @flow
import { type ThunkAction, type Dispatch } from 'types/actions';
import { type Warehouse } from 'types/entities/Warehouse';
import {
  FETCH_WAREHOUSES_REQUEST,
  FETCH_WAREHOUSES_SUCCESS,
  type RequestWarehouses,
  type ReceiveWarehouses,
} from 'types/actions/Warehouse';
import { fetchWarehouses as fetchWarehousesFromApi } from 'services/warehouseApi';

export const requestWarehouses = (): RequestWarehouses => ({
  type: FETCH_WAREHOUSES_REQUEST,
});

export const receiveWarehouses = (warehouses: Array<Warehouse> | null): ReceiveWarehouses => ({
  type: FETCH_WAREHOUSES_SUCCESS,
  warehouses,
});

export const fetchWarehouses = (): ThunkAction => (dispatch: Dispatch) => {
  dispatch(requestWarehouses());
  return fetchWarehousesFromApi().then(warehouses => dispatch(receiveWarehouses(warehouses)));
};
