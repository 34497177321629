import {
  FETCH_MANUFACTURERS_REQUEST,
  FETCH_MANUFACTURERS_SUCCESS,
} from 'types/actions';
import Immutable from 'immutable';

const ManufacturersInitialState = Immutable.Map({
  isFetching: false,
  items: Immutable.Map(),
});

const manufacturers = (state = ManufacturersInitialState, action) => {
  switch (action.type) {
    case FETCH_MANUFACTURERS_REQUEST: {
      return state.set('isFetching', true);
    }
    case FETCH_MANUFACTURERS_SUCCESS: {
      return state.merge({
        isFetching: false,
        items: Immutable.Map(action.payload),
        receivedAt: action.receivedAt,
      });
    }
    default: {
      return state;
    }
  }
};

export default manufacturers;
