// @flow
import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-images';
import Paper from '@material-ui/core/Paper';
import { type Image, type Product } from 'types/entities';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Add from '@material-ui/icons/Add';
import CloudUpload from '@material-ui/icons/CloudUpload';
import green from '@material-ui/core/colors/green';
import ZoomIn from '@material-ui/icons/ZoomIn';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Star from '@material-ui/icons/Star';
import ColorLens from '@material-ui/icons/ColorLens';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 3,
    zIndex: 0,
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
  },
  imagePaper: {
    position: 'relative',
    display: 'inline-block',
    padding: '5px 5px 1px 5px',
    marginleft: '5px',
    marginRight: '5px',
    '&:hover  #zoomIn': {
      color: theme.palette.secondary[700],
    },
  },
  imagePaperButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80px',
    height: '80px',
  },
  mainImage: {
    objectFit: 'cover',
    width: '130px',
    height: '130px',
  },
  image: {
    objectFit: 'cover',
    width: '76px',
    height: '76px',
  },
  images: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& div:first-child': {
      marginRight: theme.spacing.unit,
    },
  },
  upload: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80px',
    height: '80px',
    backgroundColor: green[500],
    color: '#fff',
  },
  imageBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '5px',
    '&:hover #removeButton, &:hover  #zoomIn': {
      opacity: 1,
    },
    '&:hover #preActions': {
      opacity: 1,
    },
  },
  removeButton: {
    marginTop: '5px',
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(11),
    opacity: 0,
    '&:hover': {
      color: theme.palette.error[500],
    },
  },
  addButton: {
    marginBottom: '24px',
  },
  preActions: {
    paddingLeft: '4px',
    paddingRight: '4px',
    width: '100%',
    position: 'relative',
    display: 'flex',
    opacity: 0,
    justifyContent: 'space-between',
    color: theme.palette.grey[500],
    '& svg:hover': {
      color: theme.palette.secondary[500],
      cursor: 'pointer',
    },
  },
  zoomIn: {
    top: '28%',
    left: '28%',
    position: 'absolute',
    width: '50%',
    height: '50%',
    opacity: 0,
    color: theme.palette.grey[500],
  },
  colorIcon: {
    position: 'absolute',
    right: '-4px',
    top: '-4px',
    color: theme.palette.secondary[500],
  },
});

type Props = {
  classes: { [key: string]: Object },
  images: Array<Image> | Array<any>,
  product: Product,
  addImages: (productId: number, files: Array<File>) => void,
  removeImage: (productId: number, imageId: number) => void,
  changeProduct: (name: string, value: any) => any,
};

type State = {
  lightboxIsOpen: boolean,
  files: Array<File> | null,
  currentImage: number,
};

class ProductImages extends React.Component<Props, State> {
  state = {
    lightboxIsOpen: false,
    files: null,
    currentImage: 0,
  };
  setAttachedFile = (filesList: FileList) => {
    const files = [];
    Object.keys(filesList).forEach(key => files.push(filesList[Number(key)]));
    this.setState({ files });
  };
  addSrcProperty = (images: Array<Image> | Array<any>) =>
    images.map((image: Image) => ({ ...image, src: image.public_path }));
  toggleLightbox = () => this.setState(prev => ({ lightboxIsOpen: !prev.lightboxIsOpen }));
  showImage = currentImage => {
    this.setState({ currentImage });
    this.toggleLightbox();
  };
  changeCurrenImage = index => {
    let nextImage = this.state.currentImage + index;
    nextImage = this.props.images.length < nextImage ? this.props.images.length : nextImage;
    nextImage = nextImage < 0 ? 0 : nextImage;
    this.setState({ currentImage: nextImage });
  };
  removeImage = (productId: number, imageId: number) => this.props.removeImage(productId, imageId);

  renderAddImagesButton = () => {
    const { classes, product, addImages, disabled = false } = this.props;
    const { files } = this.state;
    return (
      <div className={classes.addButton}>
        {!files && !disabled && (
          <ButtonBase htmlFor="attach" component="label">
            <Paper className={classes.imagePaperButton} elevation={2} title="Добавить фотографии">
              <Add />
            </Paper>
            <input
              type="file"
              id="attach"
              multiple
              style={{ display: 'none' }}
              onChange={event => this.setAttachedFile(event.target.files)}
            />
          </ButtonBase>
        )}
        {Boolean(files) && (
          <ButtonBase
            onClick={() => {
              if (this.state.files) {
                addImages(product.id, this.state.files);
                this.setState({ files: null });
              }
            }}
          >
            <Paper className={classes.upload} elevation={2} title="Загрузить">
              <CloudUpload />
            </Paper>
          </ButtonBase>
        )}
      </div>
    );
  };
  renderImages = () => {
    const { images, product, classes, disabled = false } = this.props;
    const defaultImage =
      product &&
      product.default_image &&
      images &&
      images.find(item => item.uri === product.default_image.uri);
    return (
      <div className={classes.images}>
        {product.default_image && (
          <div className={classes.imageBlock}>
            <ButtonBase
              onClick={() =>
                this.showImage(images.findIndex(item => item.uri === product.default_image.uri))
              }
            >
              <Paper className={classes.imagePaper} elevation={2}>
                <img
                  className={classes.mainImage}
                  alt={product.name && product.name.value}
                  src={product.default_image.uri}
                />
                <ZoomIn id="zoomIn" className={classes.zoomIn} />
              </Paper>
            </ButtonBase>
            {/* <ButtonBase
              id="removeButton"
              className={classes.removeButton}
              onClick={() => defaultImage && this.removeImage(product.id, defaultImage.id)}
            >
              удалить
            </ButtonBase> */}
          </div>
        )}
        {images &&
          images
            .filter(item => (product.default_image ? item.uri !== product.default_image.uri : true))
            .map((image, index) => (
              <div
                className={classes.imageBlock}
                key={image.id}
                style={{ opacity: image.published ? 1 : 0.3 }}
              >
                {!disabled && (
                  <div className={classes.preActions} id="preActions">
                    <div title="Установить как главное изображение">
                      <Star onClick={() => this.props.changeProduct('default_image', image)} />
                    </div>
                    {(!product.color_image || product.color_image.uri !== image.uri) && (
                      <div title="Установить как изображение цвета">
                        <ColorLens onClick={() => this.props.changeProduct('color_image', image)} />
                      </div>
                    )}
                    <div title={image.published ? 'Скрыть' : 'Показать'}>
                      {image.published ? (
                        <VisibilityOff
                          onClick={() =>
                            this.props.changePublished({ ...image, published: !image.published })
                          }
                        />
                      ) : (
                        <Visibility
                          onClick={() =>
                            this.props.changePublished({ ...image, published: !image.published })
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                <ButtonBase onClick={() => this.showImage(index)}>
                  <Paper onClick={() => {}} className={classes.imagePaper} elevation={2}>
                    {!disabled && product.color_image && product.color_image.uri === image.uri && (
                      <ColorLens className={classes.colorIcon} />
                    )}
                    <img
                      className={classes.image}
                      alt={product.name && product.name.value}
                      src={image.uri}
                    />
                    <ZoomIn id="zoomIn" className={classes.zoomIn} />
                  </Paper>
                </ButtonBase>
                {/* <ButtonBase
                  id="removeButton"
                  className={classes.removeButton}
                  onClick={() => this.removeImage(product.id, image.id)}
                >
                  удалить
                </ButtonBase> */}
              </div>
            ))}
        {this.renderAddImagesButton()}
      </div>
    );
  };
  renderLightbox = () => {
    const { images } = this.props;
    if (!images.length) return null;
    const { lightboxIsOpen, currentImage } = this.state;
    const imagesWithSrc = this.addSrcProperty(images);
    return (
      <Lightbox
        images={imagesWithSrc}
        isOpen={lightboxIsOpen}
        currentImage={currentImage}
        onClickPrev={() => this.changeCurrenImage(-1)}
        onClickNext={() => this.changeCurrenImage(1)}
        onClose={this.toggleLightbox}
      />
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.renderImages()}
        {this.renderLightbox()}
      </div>
    );
  }
}

ProductImages.propTypes = {
  classes: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
  addImages: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProductImages);
