// @flow
import { authenticate } from 'services/authApi';
import { createUser, getCurrentUser } from 'services/userApi';

declare var process: { browser: number }

export const signIn = async (
  username: string,
  password: string,
  redirectTarget: string | void,
) => {
  const res = await authenticate(username, password);
  if (res.resilt !== 'success') {
    return res;
  }
  // if (redirectTarget) redirect(redirectTarget);
  return null;
};
