// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Phone from '@material-ui/icons/Phone';
import Mail from '@material-ui/icons/Mail';
import { type Theme } from 'types/components/Theme';
import { type OrderUser } from 'types/entities/OrderUser';
import { Comment } from '@material-ui/icons';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit,
  },
  comment: {
    backgroundColor: theme.palette.primary[50],
    padding: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});
type Props = {|
  classes: Object,
  user: OrderUser | null,
  orderId: number | null,
|};

const userFields = [
  { key: 'full_name', name: 'ФИО', icon: <AccountCircle /> },
  { key: 'phone', name: 'Телефон', icon: <Phone /> },
  { key: 'email', name: 'Email', icon: <Mail /> },
  { key: 'private_comment', name: 'Комментайри о пользователе', icon: <Comment /> },
];

const CustomerInfo = ({ classes, user, orderId }: Props) => (
  <div className={classes.root}>
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="title">
          Пользователь {user && <a href={`https://crm.bonjour-dv.ru/users/${user.id}`} target="_blank">{user.id}</a>}
        </Typography>
        <List disablePadding>
          {user &&
            userFields.map((item, index) => (
              <ListItem
                key={item.key}
                disableGutters
                divider={userFields.length > index + 1}
                title={item.name}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={user[item.key]} />
              </ListItem>
            ))}
        </List>
      </Grid>
    </Grid>
  </div>
);

CustomerInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.any,
};

export default withStyles(styles)(CustomerInfo);
