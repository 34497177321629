// @flow
import React, { type Node } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any
|};

type Props = {|
  seriesList: Node
|};

const SeriesLayoutComponent = ({ classes, seriesList }: {| ...Props, ...ProvidedProps |}) => (
  <div className={classes.root}>
    {seriesList}
  </div>
);

export const SeriesLayout: ReactComponent<Props> = withStyles(styles)(SeriesLayoutComponent);
