// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { type Warehouse } from 'types/entities/Warehouse';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { type Theme, type ReactComponent } from 'types/components';

const styles = (theme: Theme) => ({
  root: {
    // marginTop: theme.spacing.unit * 2,
  },
  local: {
    color: theme.palette.secondary[700],
    fontWeight: 900,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  warehouse: Warehouse | null,
  addresses: Array<string> | null,
  onChange: (name: string, value: any) => any,
|};

const AddressesFilterComponent = ({
  classes,
  address,
  addresses,
  onChange,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const addressesMap = {};
  if (addresses) {
    addresses.forEach(item => (addressesMap[item] = item));
  }
  const addressesArray = Object.keys(addressesMap)
    .map(key => addressesMap[key])
    .sort((a, b) => (a > b ? 1 : -1));
  return (
    <FormControl className={classes.root}>
      <Select
        value={address || 'Все адреса'}
        onChange={e =>
          onChange(
            'selectedAddress',
            (addresses && addresses.find(item => item == e.target.value)) || null,
          )
        }
        inputProps={{
          name: 'address',
          id: 'address',
        }}
      >
        <MenuItem value="Все адреса">
          <em>Все адреса</em>
        </MenuItem>
        {addressesArray &&
          addressesArray.map(item => (
            <MenuItem key={item} value={item} className={item === 'Днепровская' && classes.local}>
              {item}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
export const AddressesFilter: ReactComponent<Props> = withStyles(styles)(AddressesFilterComponent);
