// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { type Theme, type ReactComponent } from 'types/components';
import { type TimePeriod } from 'types/entities/TimePeriod';
import moment from 'moment';
import { Checkbox, ListItemText } from '@material-ui/core';
import { patchReview } from 'services/reviewApi';

const styles = (theme: Theme) => ({
  root: {
    minWidth: '150px',
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  selectedDeliveryPeriods: Array<TimePeriod> | null,
  deliveryPeriods: Array<TimePeriod> | null,
  onChange: (period: TimePeriod | null) => any,
|};

const DeliveryPeriodsComponent = ({
  classes,
  selectedDeliveryPeriods,
  deliveryPeriods,
  onChange,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => (
  <FormControl variant="filled" className={classes.root}>
    <InputLabel htmlFor="period">Период</InputLabel>
    <Select
      multiple
      fullWidth
      value={selectedDeliveryPeriods}
      renderValue={value =>
        value
          .map(
            item =>
              `c ${moment(item.start).format('HH:mm')} до ${moment(item.end).format('HH:mm')}`,
          )
          .join(', ')
      }
      inputProps={{
        id: 'period',
      }}
    >
      {deliveryPeriods &&
        deliveryPeriods
          .sort((a, b) => (a.start > b.start ? 1 : -1))
          .map(period => (
            <div value={period} key={period.start}>
              <MenuItem
                value={period}  
                onClick={() =>
                  onChange(
                    selectedDeliveryPeriods.find(({ start }) => start === period.start)
                      ? selectedDeliveryPeriods.filter(({ start }) => start !== period.start)
                      : [...selectedDeliveryPeriods, period],
                  )
                }
              >
                <Checkbox
                  checked={Boolean(
                    selectedDeliveryPeriods &&
                      selectedDeliveryPeriods.find(({ start }) => start === period.start),
                  )}
                />
                <ListItemText
                  primary={
                    <span>
                      c {moment(period.start).format('HH:mm')} до{' '}
                      {moment(period.end).format('HH:mm')}
                    </span>
                  }
                />
              </MenuItem>
            </div>
          ))}
    </Select>
  </FormControl>
);
export const DeliveryPeriods: ReactComponent<Props> = withStyles(styles)(DeliveryPeriodsComponent);
