// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {| onChange: Function, checked: boolean |};

const EmailComponent = ({
  classes,
  onChange,
  checked,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => (
  <FormControlLabel
    control={<Checkbox checked={checked} onChange={onChange} />}
    label="Участвует в Email рассылке"
  />
);

export const Email: ReactComponent<Props> = withStyles(styles)(EmailComponent);
