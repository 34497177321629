// @flow
import {
  FETCH_WAREHOUSE_REQUEST,
  FETCH_WAREHOUSE_SUCCESS,
} from 'types/actions/Warehouse';
import { type Action } from 'types/actions/Action';
import { type WarehouseState } from 'types/store/Warehouse';

export const warehouseInitialState: WarehouseState = {
  isFetching: false,
  data: null,
};

const warehouse = (
  state: WarehouseState = warehouseInitialState,
  action: Action,
): any => {
  switch (action.type) {
    case FETCH_WAREHOUSE_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_WAREHOUSE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.warehouse,
      };
    }
    default: {
      return state;
    }
  }
};

export default warehouse;
