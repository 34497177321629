// @flow
// @flow-runtime enable
import { type Warehouse } from './Warehouse';
import { type OrderUser } from './OrderUser';
import { PICK_UP } from './RealizationMethodType';

export type RealizationMethodPickUp = {|
  id: number,
  recipient_name: string | null,
  recipient_phone: string | null,
  shipping_cost: number,
  created_at: string,
  updated_at: string,
  type: typeof PICK_UP,
  estimated_assembly_time: string,
  pick_up_point: Warehouse,
  user: OrderUser,
  orders: Array<any>,
|};
