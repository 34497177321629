// @flow
import React, { useState } from 'react';
import { type Order } from 'types/entities/Order';
import IconButton from '@material-ui/core/IconButton';
import ProductPreview from 'components/product-preview';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CardGiftcard from '@material-ui/icons/CardGiftcard';
import { GIFT_RULES, SHOP_HOST } from 'config';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import moment from 'moment';

const styles = (theme: Theme) => ({
  fullMatch: {
    color: green[500],
  },
});

type Props = {|
  order: Order | null,
  classes: any,
  onlyFullMatch?: boolean,
|};

export const getMatched = (order, { onlyFullMatch }) => {
  const matchedRules = GIFT_RULES.map(rule => {
    if (!order) return null;

    if (moment(order.created_at).isAfter(moment(rule.end))) return null;

    if (moment(order.created_at).isBefore(moment(rule.start))) return null;

    let productsQuantity = 0;

    let matchedLines = null;

    rule.brands.forEach(brandId => {
      order &&
        order.lines &&
        order.lines.forEach(line => {
          if (
            line.product.brand &&
            line.product.brand.id === brandId &&
            (rule.categories && rule.categories.length
              ? line.product.category && rule.categories.includes(line.product.category.id)
              : true)
          ) {
            productsQuantity = productsQuantity + line.quantity;
            matchedLines = matchedLines ? [...matchedLines, line] : [line];
          }
        });
    });

    rule.products.forEach(productId => {
      order &&
        order.lines &&
        order.lines.forEach(line => {
          if (line.product.id === productId) {
            productsQuantity = productsQuantity + line.quantity;
            matchedLines = matchedLines ? [...matchedLines, line] : [line];
          }
        });
    });

    if (productsQuantity)
      return {
        rule,
        lines: matchedLines,
        match: Boolean(productsQuantity >= rule.quantity),
      };
    return null;
  });

  return matchedRules.filter(item => item).filter(item => (onlyFullMatch ? item.match : item));
};

const GiftLabelComponent = ({ order, classes, onlyFullMatch = false }: Props) => {
  const [open, toggle] = useState(false);

  const matchedRules = getMatched(order, { onlyFullMatch });

  return (
    <React.Fragment>
      {matchedRules.length > 0 && (
        <IconButton
          onClick={() => toggle(true)}
          className={matchedRules.some(item => item.match) ? classes.fullMatch : null}
        >
          <CardGiftcard />
        </IconButton>
      )}
      <Dialog open={open} onClose={() => toggle(!open)}>
        <DialogContent>
          {matchedRules
            .filter(item => item)
            .map((item, index) => (
              <div key={item.rule.name}>
                <div>
                  {index + 1}. {item.rule.name}
                </div>
                <br />
                <div>
                  Требуемое количество: {item.rule.quantity}
                  {' | '}
                  <a
                    target="_blank"
                    href={`${SHOP_HOST}/products?filter[categories]=${item.rule.categories.join(
                      ',',
                    )}&filter[brands]=${item.rule.brands.join(
                      ',',
                    )}&filter[query]=${item.rule.barcodes.join(' ')}`}
                  >
                    Товары
                  </a>
                </div>
                <List>
                  {item.lines.map(line => (
                    <ListItem key={line.product.id}>
                      <ProductPreview product={line.product} />
                      <ListItemSecondaryAction>{line.quantity} шт.</ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
                <Divider />
              </div>
            ))}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export const GiftLabel = withStyles(styles)(GiftLabelComponent);
