// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import TextField from '@material-ui/core/TextField';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  rawPrivateComment: string | null,
  changedValue: string | null,
  onChange: Function,
|};

const PrivateCommentComponent = ({
  classes,
  rawPrivateComment,
  onChange,
  changedValue,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  let parsedComment = null;

  try {
    parsedComment = rawPrivateComment && JSON.parse(rawPrivateComment);
  } catch (error) {
    console.log(error);
  }

  let privateComment = '';

  if (parsedComment) {
    privateComment = parsedComment.private_comment;
  }

  return (
    <TextField
      className={classes.comment}
      fullWidth
      onChange={e => onChange(e.target.value)}
      label="Приватный комментарий"
      value={changedValue === null ? privateComment || '' : changedValue}
      multiline
      margin="normal"
    />
  );
};
export const PrivateComment: ReactComponent<Props> = withStyles(styles)(PrivateCommentComponent);
