// @flow
import { get, put, post, del } from 'lib/request';
import { API_HOST } from 'config';
import { type Dispatch } from 'react-redux';
import { normalize, schema } from 'normalizr';
import {
  FETCH_MANUFACTURERS_REQUEST,
  FETCH_MANUFACTURERS_SUCCESS,
  type ManufacturersInclude,
} from 'types/actions';
import { addNotification } from '../notifications/actions';

export const manufacturerEntity = new schema.Entity('manufacturers');

export function requestManufacturers() {
  return { type: FETCH_MANUFACTURERS_REQUEST };
}

export function receiveManufacturers(payload: Object) {
  return {
    type: FETCH_MANUFACTURERS_SUCCESS,
    payload,
    receivedAt: Date.now(),
  };
}

export const fetchManufacturersIfNeeded: Function = (include: ManufacturersInclude | void) => (
  dispatch: Dispatch,
  getState: Function,
) => {
  if (getState().getIn(['manufacturers', 'isFetching'])) {
    return;
  }
  dispatch(requestManufacturers());
  const includeString = include
    ? include.reduce((prev, curr) => prev.concat(`${curr},`), '?include=')
    : '';
  get(`${API_HOST}/manufacturers${includeString}`).then(
    res => {
      const payload = normalize(res.data, {
        manufacturers: [manufacturerEntity],
      });
      dispatch(receiveManufacturers(payload.entities.manufacturers));
    },
    () => dispatch(addNotification('Неудачная попытка загрузки производителей.')),
  );
};

export const deleteAlias: Function = (aliasId: number) => (dispatch: Dispatch) =>
  del(`${API_HOST}/aliases/${aliasId}`).then(
    () => dispatch(fetchManufacturersIfNeeded(['alias'])),
    () => dispatch(addNotification('Неудачная попытка удаления синонима производителя')),
  );

export const addManufacturer: Function = (name: string) => (dispatch: Dispatch) =>
  post(`${API_HOST}/manufacturers`, { name }).then(
    () => dispatch(fetchManufacturersIfNeeded(['alias'])),
    () => dispatch(addNotification('Неудачная попытка создания нового производителя')),
  );

export const editManufacturer: Function = (idManufacturer: number, name: string, slug: string) => (
  dispatch: Dispatch,
) =>
  put(`${API_HOST}/manufacturers/${idManufacturer}`, { name, slug }).then(
    () => dispatch(fetchManufacturersIfNeeded(['alias'])),
    () => dispatch(addNotification('Неудачная попытка сохранения данных производителя')),
  );
