// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';
import cn from 'classnames';

const styles = (theme: Theme) => ({
  margin: {
    marginTop: theme.spacing.unit,
  },
});
type Props = {|
  classes: Object,
  list: any,
  progress: any,
  controls: any,
  variantSelect: any,
|};

const DeliveriesLayout = ({ classes, list, progress, controls, variantSelect }: Props) => (
  <div className={classes.root}>
    {controls}
    <div className={cn(controls && classes.margin, classes.variants)}>{variantSelect}</div>
    <div className={classes.margin}>
      {progress}
      {list}
    </div>
  </div>
);

export default withStyles(styles)(DeliveriesLayout);
