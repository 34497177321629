import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ChevronRight from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';
import { withWidth } from '@material-ui/core';

export const RightSideBardWidth = 210;

const styles = theme => ({
  drawerPaper: {
    position: 'relative',
    width: RightSideBardWidth,
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  toolbar: {
    padding: '8px',
  },
  avatar: {
    width: '24px',
    height: '24px',
  },
});

const RightSideBar = ({ classes, opened, actions, toggleRightSideBar, width }) => (
  <Drawer
    anchor="right"
    variant={['md', 'lg', 'xl'].includes(width) ? 'permanent' : null}
    classes={{
      paper: classNames(classes.drawerPaper, !opened && classes.drawerPaperClose),
    }}
    open={opened}
  >
    <div className={classes.toolbar}>
      <IconButton onClick={() => toggleRightSideBar()}>
        <ChevronRight />
      </IconButton>
    </div>
    <Divider />
    <List>
      {actions.map(action => (
        <ListItem
          button
          key={action.key}
          dense
          title={action.name}
          onClick={() => {
            action.action();
            toggleRightSideBar(false);
          }}
        >
          <ListItemIcon>{action.icon}</ListItemIcon>
          <ListItemText primary={action.name} />
        </ListItem>
      ))}
    </List>
  </Drawer>
);

RightSideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleRightSideBar: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
  opened: PropTypes.bool.isRequired,
};

export default withWidth()(withStyles(styles)(RightSideBar));
