// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { type Theme, type ReactComponent } from 'types/components';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import { TextField, ButtonBase, InputAdornment, IconButton, Typography } from '@material-ui/core';
import { putWarehousesDeliverySchedule } from 'services/warehouseApi';
import Save from '@material-ui/icons/Save';

const styles = (theme: Theme) => ({
  root: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    display: 'grid',
    gridTemplateColumns: '40% 20% 32%',
    columnGap: '8px',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  name: {
    paddingLeft: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  duration: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  weekdays: {
    display: 'grid',
    gridTemplateColumns: '20% 20% 20% 20% 20% ',
    columnGap: '8px',
    '& > button': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '18px',
      color: '#fff',
    },
  },
  on: {
    backgroundColor: green[500],
  },
  off: {
    backgroundColor: red[500],
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const DeliveryScheduleComponent = ({
  classes,
  schedule,
  onSave,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const [state, setState] = useState(null);

  const renderWeekday = (weekday, isActive) => (
    <ButtonBase
      onClick={() => handleOnSave({ [weekday]: !schedule[weekday] })}
      className={isActive ? classes.on : classes.off}
    >
      {
        {
          monday: 'пн',
          tuesday: 'вт',
          wednesday: 'ср',
          thursday: 'чт',
          friday: 'пт',
          sunday: 'вс',
        }[weekday]
      }
    </ButtonBase>
  );

  const handleOnSave = data =>
    putWarehousesDeliverySchedule({ ...schedule, ...data }).then(() =>
      onSave().then(() => setState(null)),
    );

  return (
    <div className={classes.root}>
      <div className={classes.name}>
        {schedule.warehouse.name}
        <br />
        <Typography variant="caption">{schedule.supplier.name}</Typography>
      </div>
      <div className={classes.duration}>
        <TextField
          label="Срок доставки, дней"
          onChange={e => setState(e.target.value)}
          value={state !== null ? state : schedule.moving_duration_in_days}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {state !== null && state !== schedule.moving_duration_in_days && (
                  <IconButton onClick={() => handleOnSave({ moving_duration_in_days: state })}>
                    <Save />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={classes.weekdays}>
        {renderWeekday('monday', schedule.monday)}
        {renderWeekday('tuesday', schedule.tuesday)}
        {renderWeekday('wednesday', schedule.wednesday)}
        {renderWeekday('thursday', schedule.thursday)}
        {renderWeekday('friday', schedule.friday)}
      </div>
    </div>
  );
};
export const DeliverySchedule: ReactComponent<Props> =
  withStyles(styles)(DeliveryScheduleComponent);
