// @flow
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { type Promotion } from 'types/entities/Promotion';
import { setRightSideBarActions } from 'components/right_side_bar/actions';
import Paper from '@material-ui/core/Paper';
import AddCircle from '@material-ui/icons/AddCircle';
import moment from 'moment';
import PromotionsList from './view/PromotionsList';
import Controls from './view/Controls';
import { fetchPromotions } from './actions';
import Layout from './view/Layout';
import localStorage from 'lib/local-storage';
import { TimeFilter } from './view/TimeFilter';
import { type typeTimeFilter } from './typeTimeFilter';
import { Email } from './view/Email';
import { AddPromotionDialog } from './view/AddPromotionDialog';
import { useRoutes, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { useRouter } from 'hooks/useRouter';
import { useGetPromotions } from 'hooks/api';
import { useEffect } from 'react';

const Promotions = ({ onSelect, setRightSideBarActions }) => {
  const router = useRouter();

  const [state, setState] = useState({
    isEnabled: 'enabled',
    addPromotionDialogOpen: false,
  });

  const request = useGetPromotions({ variables: router.query });

  useEffect(() => {
    setRightSideBarActions([
      {
        key: 'addPromotion',
        icon: <AddCircle />,
        name: 'Добавить акцию',
        action: () =>
          setState(prev => ({
            ...prev,
            addPromotionDialogOpen: !prev.addPromotionDialogOpen,
          })),
      },
    ]);
  }, [setRightSideBarActions]);

  return (
    <React.Fragment>
      <AddPromotionDialog
        open={state.addPromotionDialogOpen}
        onClose={() =>
          setState(prev => ({ ...prev, addPromotionDialogOpen: !prev.addPromotionDialogOpen }))
        }
        onSave={promotion => {
          setState(prev => ({ ...prev, addPromotionDialogOpen: !prev.addPromotionDialogOpen }));
          if (promotion) {
            router.push(`/shop/promotion/${promotion.id}`);
          }
        }}
      />
      <Layout
        controls={
          <Controls
            changeIsEnabled={e => setState(prev => ({ ...prev, isEnabled: e.target.value }))}
            isEnabled={state.isEnabled}
            timeFilter={
              <TimeFilter
                time={{
                  past: router.query.past === '1',
                  present: router.query.present === '1',
                  future: router.query.future === '1',
                }}
                onChange={key =>
                  router.push(
                    null,
                    { [key]: router.query[key] === '1' ? '0' : '1' },
                    { merge: true },
                  )
                }
              />
            }
            email={
              <Email
                checked={router.query.email === '1'}
                onChange={() =>
                  router.push(
                    null,
                    { email: router.query.email === '1' ? '0' : '1' },
                    { merge: true },
                  )
                }
              />
            }
          />
        }
        promotions={
          <PromotionsList
            isFetching={request.loading}
            promotions={
              request &&
              request.data.data
                .sort((a, b) => (a.start > b.start ? -1 : 1))
                .sort((a, b) => (a.start > b.start ? -1 : 1))
                .filter(promotion =>
                  state.isEnabled === 'all'
                    ? promotion
                    : (state.isEnabled === 'enabled' && promotion.is_enabled) ||
                      (state.isEnabled === 'disabled' && !promotion.is_enabled),
                )
            }
            onSelect={onSelect}
          />
        }
      />
    </React.Fragment>
  );
};

export default connect(null, { fetchPromotions, setRightSideBarActions })(Promotions);
