import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
    display: 'flex',
    alignItems: 'flex-end',
  },
  textField: {
    marginRight: theme.spacing.unit * 3,
  },
});

const ManufacturersFilter = ({
  classes,
  handleChangeFeild,
  filterString,
}) => (
  <Paper className={classes.root}>
    <TextField
      label="Фильтрация"
      value={filterString}
      name="filterString"
      className={classes.textField}
      onChange={e => handleChangeFeild(e.target.name, e.target.value)}
      margin="none"
    />
  </Paper>
);

ManufacturersFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChangeFeild: PropTypes.func.isRequired,
  filterString: PropTypes.string.isRequired,
};

export default withStyles(styles)(ManufacturersFilter);
