// @flow
import {
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
} from 'types/actions/Order';
import Immutable from 'immutable';
import { type Action } from 'types/actions/Action';

export const ordersInitialState = Immutable.Map({
  isFetching: false,
  list: null,
  totalCount: 0,
});

const orders = (state: any = ordersInitialState, action: Action) => {
  switch (action.type) {
    case FETCH_ORDERS_REQUEST: {
      return state.set('isFetching', true);
    }
    case FETCH_ORDERS_SUCCESS: {
      return state.merge({
        isFetching: false,
        list: action.orders,
        totalCount: action.totalCount,
      });
    }
    default: {
      return state;
    }
  }
};

export default orders;
