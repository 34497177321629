// @flow
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import CheckCircle from '@material-ui/icons/CheckCircle';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { type Theme, type ReactComponent } from 'types/components';
import { getAllowedForSaleProduct } from 'services/productApi';

const styles = (theme: Theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(14),
  },
  missed: {
    color: red[500],
  },
  inStock: {
    color: green[500],
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: theme.spacing.unit,
    },
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {||};

const AllowedForSaleProductComponent = ({
  classes,
  productId,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const [state, setState] = useState(null);
  useEffect(() => {
    if (productId) {
      getAllowedForSaleProduct({ productId }).then(res => setState(Boolean(res)));
    }
  }, []);
  return (
    <div className={classes.root}>
      {state !== null && (
        <div>
          {state ? (
            <div className={classes.label}>
              <CheckCircle className={classes.inStock} />
              Присутствует в матрице
            </div>
          ) : (
            <div className={classes.label}>
              <HighlightOff className={classes.missed} />
              Отсутствует в матрице
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export const AllowedForSaleProduct: ReactComponent<Props> = withStyles(styles)(
  AllowedForSaleProductComponent,
);
