// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { type Theme } from 'types/components/Theme';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing.unit,
  },
  previewBlock: {
    marginTop: theme.spacing.unit * 2,
  },
  preview: {
    marginTop: theme.spacing.unit * 2,
  },
  actions: {
    marginTop: theme.spacing.unit * 2,
  },
});
type Props = {|
  classes: Object,
  description: string | null,
  changedValue: string | null,
  label?: string,
  onChange: (value: any) => void,
|};

class Editor extends React.Component<Props> {
  handleChangeDescription = (e: Object) =>
    this.props.onChange(e.target.value);

  render() {
    return (
      <div className={this.props.classes.root}>
        <TextField
          fullWidth
          label={this.props.label || "Описание"}
          multiline
          value={
            this.props.changedValue === null
              ? this.props.description || ''
              : this.props.changedValue
          }
          onChange={this.handleChangeDescription}
          margin="none"
        />
        {this.props.actions ? <div className={this.props.classes.actions}>{this.props.actions}</div> : null}
        <div className={this.props.classes.previewBlock}>
          <Typography variant="subheading">Результат</Typography>
          <div
            className={this.props.classes.preview}
            dangerouslySetInnerHTML={{
              __html:
                this.props.changedValue === null
                  ? this.props.description
                  : this.props.changedValue,
            }}
          />
        </div>
      </div>
    );
  }
}

Editor.propTypes = {
  classes: PropTypes.object.isRequired,
  changedDescription: PropTypes.any,
  description: PropTypes.any,
};

export default withStyles(styles)(Editor);
