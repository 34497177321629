// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { type Theme, type ReactComponent } from 'types/components';
import { type Brand } from 'types/entities/Brand';
import { type typeProductSeries } from 'types/entities/typeProductSeries';
import { type typeProductType } from 'types/entities/typeProductType';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  ListItemSecondaryAction,
  Paper,
  Typography,
} from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import { Attributes } from 'components/attributes';
import { addAttributeToProductType, deleteAttributeFromProductType } from 'services/attributeApi';
import WithConfirm from 'components/with-confirm';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    '& > div': {
      display: 'inline-block',
      marginRight: theme.spacing.unit,
    },
  },
  item: {
    marginBottom: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    '&:hover': {
      boxShadow: theme.shadows[6],
    },
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  types: Array<typeProductType> | null,
|};

const ProductsTypesListComponent = ({
  classes,
  types,
  onChange,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => {
  const [selectedProductType, setSelectedProductType] = useState(null);
  return (
    <div>
      {types &&
        types
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map(item => (
            <Paper key={item.id} className={classes.item} onClick={() => window.open(`/products-type/${item.id}`, '_blank')}>
              <div className={classes.header}>
                <div className={classes.name}>
                  <div>
                    <Typography variant="caption">{item.id}</Typography>
                  </div>
                  <div>{item.name}</div>
                </div>
                <div>
                  {/* <IconButton
                    title="Редактировать"
                    onClick={() => setOpenEditDialog(true)}
                    size="small"
                  >
                    <Edit />
                  </IconButton>
                  <WithConfirm onConfirm={() => handleDelete(item.id)} confirmTitle="Удалить">
                    <IconButton size="small" title="Удалить">
                      <Close />
                    </IconButton>
                  </WithConfirm> */}
                </div>
              </div>
            </Paper>
          ))}
    </div>
  );
};
export const ProductsTypesList: ReactComponent<Props> = withStyles(styles)(
  ProductsTypesListComponent,
);
