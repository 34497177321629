import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createBrowserHistory from 'history/createBrowserHistory';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { routerMiddleware } from 'react-router-redux';
import { createLogger } from 'redux-logger';
import Immutable, { Iterable } from 'immutable';
import rootReducer from '../reducers';

export const history = createBrowserHistory();
const middleware = [thunkMiddleware, routerMiddleware(history)];

const stateTransformer = state => {
  if (Iterable.isIterable(state)) return state.toJS();
  return state;
};

if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger({ stateTransformer, collapsed: true }));
}

export default createStore(
  rootReducer,
  Immutable.Map({}),
  composeWithDevTools(applyMiddleware(...middleware)),
);
