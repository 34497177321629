// @flow
import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  a: {
    marginTop: theme.spacing.unit * 2,
    ...theme.mixins.link,
  },
  registerLink: {
    marginLeft: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3,
    borderLeft: `1px solid ${theme.palette.grey[500]}`,
  },
});

type Props ={
  classes: Object,
  result: { message: string, result: string } | null,
  toggleLoginDialog: () => void,
  handleChange: (name: 'username' | 'password', value: string) => void,
  username: { value: string, error: boolean },
  password: { value: string, error: boolean },
  signIn: (username: string, password: string) => void,
}

const LoginDialog = ({
  classes,
  toggleLoginDialog,
  handleChange,
  username,
  password,
  signIn,
  result,
}: Props) => (
  <div>
    <div>
      <TextField
        autoFocus
        id="dialogUsername"
        fullWidth
        placeholder="Телефон или email"
        margin="normal"
        value={username.value}
        onChange={(e: SyntheticEvent<HTMLInputElement>) => handleChange('username', e.currentTarget.value)}
      />
      <TextField
        id="dialogPassword"
        fullWidth
        placeholder="Пароль"
        margin="normal"
        type="password"
        value={password.value}
        onChange={(e: SyntheticEvent<HTMLInputElement>) => handleChange('password', e.currentTarget.value)}
      />
      <div>
        {result && result.result === 'error' && result.message}
      </div>
    </div>
    <div>
      <Button onClick={signIn(username.value, password.value)} color="primary">
        <b>Войти</b>
      </Button>
    </div>
  </div>
);

LoginDialog.propTypes = {
  toggleLoginDialog: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  username: PropTypes.object.isRequired,
  password: PropTypes.object.isRequired,
  result: PropTypes.any,
};

export default withStyles(styles)(LoginDialog);
