// @flow
import { type Warehouse } from './Warehouse';

export type typeStockItem = {|
  id: number,
  warehouse: Warehouse,
  vendor_id: string,
  price: number,
  stock: number,
  create_time: number,
  update_time: number,
  import_process_id: string,
|}