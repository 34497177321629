// @flow
import { API_HOST } from 'config';
import { get, post } from 'lib/request';
import queryString from 'query-string';
import { type User } from 'types/entities';
import idx from 'idx';

export const fetchDeliveries = async (params?: { day: string, user?: number }) => {
  try {
    const response = await get(`${API_HOST}/deliveries?${queryString.stringify(params)}`).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return Promise.reject(null);
  }
};

export const addOrderToDelivery = async (params?: { order: any, deliveryId?: number }) => {
  try {
    const response = await post(
      `${API_HOST}/realization_methods/${params.deliveryId}/orders`,
      params.order,
    ).then(res => res.data);
    return response;
  } catch (error) {
    if (idx(error, _ => _.response.data.errors)) {
      return Promise.reject(idx(error, _ => _.response.data.errors));
    }
    return Promise.reject(null);
  }
};

export const completeDelivery = async ({ deliveryId }: { deliveryId: number }) => {
  try {
    const response = await post(`${API_HOST}/deliveries/${deliveryId}/complete`).then(res => res.data);
    return response;
  } catch (error) {
    if (idx(error, _ => _.response.data.errors)) {
      return Promise.reject(idx(error, _ => _.response.data.errors));
    }
    return null;
  }
};

export const setDeliveryToCourier = async ({
  deliveryId,
  user,
}: {
  deliveryId: number,
  user: User,
}) => {
  try {
    const response = await post(`${API_HOST}/deliveries/${deliveryId}/courier`, user).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return Promise.reject(null);
  }
};

export const confirmReceiveAssemblyPlan = async ({ orderId, warehouse }) => {
  try {
    const response = await post(`${API_HOST}/orders/${orderId}/shipping`, warehouse).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return Promise.reject(null);
  }
};
