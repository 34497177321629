// @flow
import { type PickupPoint } from 'types/entities/PickupPoint';

export const FETCH_PICKUP_TIME_REQUEST: 'FETCH_PICKUP_TIME_REQUEST' = 'FETCH_PICKUP_TIME_REQUEST';
export const FETCH_PICKUP_TIME_SUCCESS: 'FETCH_PICKUP_TIME_SUCCESS' = 'FETCH_PICKUP_TIME_SUCCESS';
export const FETCH_PICKUP_POINTS_REQUEST: 'FETCH_PICKUP_POINTS_REQUEST' = 'FETCH_PICKUP_POINTS_REQUEST';
export const FETCH_PICKUP_POINTS_SUCCESS: 'FETCH_PICKUP_POINTS_SUCCESS' = 'FETCH_PICKUP_POINTS_SUCCESS';

export type RequestPickupTime = {|
  type: typeof FETCH_PICKUP_TIME_REQUEST,
|};

export type ReceivePickupTime = {|
  type: typeof FETCH_PICKUP_TIME_SUCCESS,
  pickupTime: string | null,
|};

export type RequestPickupPoints = {|
  type: typeof FETCH_PICKUP_POINTS_REQUEST,
|};

export type ReceivePickupPoints = {|
  type: typeof FETCH_PICKUP_POINTS_SUCCESS,
  pickupPoints: Array<PickupPoint> | null,
|};
