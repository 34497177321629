// @flow
import React, { useState } from 'react';
import { type ReactComponent } from 'types/components';
import { type typeCategory } from 'types/entities/typeCategory';
import { CategoryLayout } from './view/CategoryLayout';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

type Props = {|
  category: typeCategory | null,
  onChange: Function,
|};

const CategoryComponent = ({ category, onChange }: {| ...Props |}) => {
  const [state, setState] = useState({
    name: null,
    title: null,
    categoryTitle: null,
    metaDescription: null,
    keywords: null,
    description: null,
    additional_description: null,
  });
  if (!category) return null;
  const handleOnChange = () =>
    onChange({
      ...category,
      name: state.name || category.name,
      additional_description:
        state.additional_description !== null
          ? state.additional_description
          : category.additional_description,
      description: state.description !== null ? state.description : category.description,
      title: state.categoryTitle || category.title,
      meta_tag_data: {
        title:
          state.title !== null
            ? state.title
            : category.meta_tag_data && category.meta_tag_data.title,
        description:
          state.metaDescription !== null
            ? state.metaDescription
            : category.meta_tag_data && category.meta_tag_data.description,
        keywords:
          state.keywords !== null
            ? state.keywords
            : category.meta_tag_data && category.meta_tag_data.keywords,
      },
    });
  return (
    <CategoryLayout>
      <TextField
        fullWidth
        value={category.name}
        value={state.name !== null ? state.name : category.name}
        label="Название"
        onChange={e => setState({ ...state, name: e.target.value })}
      />
      <TextField
        fullWidth
        value={
          state.title !== null
            ? state.title
            : (category.meta_tag_data && category.meta_tag_data.title) || ''
        }
        onChange={e => setState({ ...state, title: e.target.value })}
        label="<title>"
      />
      <TextField
        fullWidth
        value={
          state.metaDescription !== null
            ? state.metaDescription
            : (category.meta_tag_data && category.meta_tag_data.description) || ''
        }
        label="<description>"
        onChange={e => setState({ ...state, metaDescription: e.target.value })}
      />
      <TextField
        fullWidth
        value={
          state.keywords !== null
            ? state.keywords
            : (category.meta_tag_data && category.meta_tag_data.keywords) || ''
        }
        onChange={e => setState({ ...state, keywords: e.target.value })}
        label="<keywords>"
      />
      <TextField
        fullWidth
        value={
          state.categoryTitle !== null
            ? state.categoryTitle
            : (category.title && category.title) || ''
        }
        onChange={e => setState({ ...state, categoryTitle: e.target.value })}
        label="h1"
      />
      <TextField
        margin="dense"
        label="Описание"
        type="text"
        value={state.description !== null ? state.description : category.description || ''}
        multiline
        name="description"
        fullWidth
        onChange={e => setState({ ...state, description: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Дополнительное описание"
        type="text"
        value={
          state.additional_description !== null
            ? state.additional_description
            : category.additional_description || ''
        }
        multiline
        name="additional_description"
        fullWidth
        onChange={e => setState({ ...state, additional_description: e.target.value })}
      />
      <DialogActions>
        <Button color="secondary" onClick={handleOnChange}>
          Сохранить
        </Button>
      </DialogActions>
    </CategoryLayout>
  );
};
export const Category: ReactComponent<Props> = CategoryComponent;
