// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { type AppStore } from 'types/store/AppStore';
import OneFieldFormDialog from './view/OneFieldFormDialog';

type Props = {|
  onClose: () => void,
  open: boolean,
  title: string | null,
  onSave: (value: string) => void,
|};

type State = {|
  value: string
|};

export class OneFieldFormDialogContainer extends React.Component<Props, State> {
  state = {
    value: '',
  }

  handleSave = () => this.props.onSave(this.state.value);

  handleChange = (e: Object) => this.setState({ value: e.target.value })

  render() {
    return (
      <OneFieldFormDialog
        title={this.props.title}
        value={this.state.value}
        open={this.props.open}
        handleSave={this.handleSave}
        handleClose={this.props.onClose}
        handleChange={this.handleChange}
      />
    );
  }
}

OneFieldFormDialogContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default OneFieldFormDialogContainer;
