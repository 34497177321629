// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import { connect } from 'react-redux';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ContentCopy from '@material-ui/icons/ContentCopy';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Message from '@material-ui/icons/Message';
import ButtonBase from '@material-ui/core/ButtonBase';
import { type Theme } from 'types/components/Theme';
import { type Delivery as DeliveryType } from 'types/entities/Delivery';
import { ListItemText, ListItemIcon, Collapse, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import { GiftLabel } from 'components/shop/gift-label';
import Paper from '@material-ui/core/Paper';
import HighlightOff from '@material-ui/icons/HighlightOff';
import WithConfirm from 'components/with-confirm';
import cn from 'classnames';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Store from '@material-ui/icons/Store';
import LocalShipping from '@material-ui/icons/LocalShipping';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LocalMall from '@material-ui/icons/LocalMall';
import { groupByWarehouses } from '../actions';
import { stringToColor } from 'lib/stringToColor';

const styles = (theme: Theme) => ({
  root: {
    marginBottom: theme.spacing.unit * 2,
    height: '100%',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  link: {
    color: theme.palette.secondary[500],
  },
  selected: {
    backgroundColor: blue[50],
  },
  action: {
    // top: '38px',
    display: 'flex',
    alignItems: 'center',
    '& button': {
      minWidth: 'unset',
    },
  },
  checkbox: {
    width: '30px',
    height: '30px',
    marginRigth: theme.spacing.unit,
  },
  payedIcon: {
    color: green[500],
    marginRight: theme.spacing.unit,
  },
  warehouse: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: '20px',
      height: '20px',
      marginLeft: theme.spacing.unit,
    },
  },
  completed: {
    color: green[500],
  },
  fail: {
    color: red[500],
  },
  local: {
    color: theme.palette.secondary[800],
    fontWeight: 900,
  },
});
type Props = {|
  classes: Object,
  delivery: DeliveryType,
  selected: boolean,
  onSelect: () => void,
  copyToClipboardButton: any,
  completeDelivery: ({ deliveryId: number }) => void,
|};

// $FlowFixMe

const Delivery = ({
  classes,
  delivery,
  completeDelivery,
  onSelect,
  selected,
  copyToClipboardButton,
  user,
}: Props) => {
  const [open, toggle] = useState(null);
  const warehouses = groupByWarehouses(delivery);
  const handleCompleteDelivery = () => completeDelivery({ deliveryId: delivery.id });

  const privateComments = delivery.orders.map(
    order => JSON.parse(order.private_comment).private_comment,
  );

  const gifts =
    delivery.orders && delivery.orders.map(order => <GiftLabel order={order} onlyFullMatch />);

  return (
    <Paper className={cn(classes.root, selected ? classes.selected : null)}>
      <Grid container>
        <Grid item xs={12} md={5}>
          <ListItem dense className={cn(classes.listItem, selected ? classes.selected : null)}>
            <ListItemText
              primary={
                <span>
                  {delivery.orders &&
                    delivery.orders.map(order => (
                      <span key={order.id}>
                        <ButtonBase href={`/shop/order/${order.id}`} className={classes.link}>
                          #{order.id}
                        </ButtonBase>
                        {', '}
                      </span>
                    ))}{' '}
                  <span
                    className={cn(
                      delivery &&
                        delivery.address &&
                        delivery.address.street.name === 'Днепровская' &&
                        delivery.address.house.toLowerCase() === '21б' &&
                        classes.local,
                    )}
                  >
                    {delivery.address.line}
                  </span>
                  <br />
                  <b>
                    {delivery.total_cost}
                    {' ₽ '}
                    {delivery.is_paid ? (
                      <span>
                        <MonetizationOn className={classes.payedIcon} />
                        Заказ оплачен
                      </span>
                    ) : null}
                  </b>
                  <br />
                </span>
              }
              secondary={
                <span>
                  {moment(delivery.delivery_period.start).format('DD.MM.YYYY')},
                  <b>
                    {' c '}
                    {moment(delivery.delivery_period.start).format('HH:mm')}
                    {' до '}
                    {moment(delivery.delivery_period.end).format('HH:mm')}
                  </b>
                </span>
              }
            />
          </ListItem>
          <ListItem dense>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText
              primary={
                <div>
                  <ButtonBase
                    href={`tel:8${delivery.recipient_phone || delivery.user.phone}`}
                    className={classes.link}
                  >
                    {delivery.recipient_phone || delivery.user.phone}
                  </ButtonBase>
                  {', '}
                  {delivery.recipient_name || delivery.user.full_name}
                </div>
              }
            />
          </ListItem>
        </Grid>
        <Grid item xs={12} md={7}>
          {
            <ListItem dense divider>
              <ListItemIcon>
                <Message />
              </ListItemIcon>
              <ListItemText
                primary={
                  <div>
                    <div title="Комментарий клиента">
                      {delivery.orders.map(order => (
                        <div key={order.id}>{order.comment}</div>
                      ))}
                    </div>
                    <div style={{ color: 'teal' }} title="Комментарий менеджера">
                      {privateComments.map((privateComment, index) => (
                        <div key={index}>{privateComment}</div>
                      ))}
                    </div>
                  </div>
                }
              />
              <ListItemSecondaryAction className={classes.action}>
                <WithConfirm confirmTitle="Выдать" onConfirm={handleCompleteDelivery}>
                  <IconButton color="secondary">
                    <CheckCircle />
                  </IconButton>
                </WithConfirm>
                <Checkbox checked={selected} onClick={onSelect} className={classes.checkbox} />
              </ListItemSecondaryAction>
            </ListItem>
          }
          {/* <ListItem>{gifts}</ListItem> */}
          <React.Fragment>
            {delivery.courier && (
              <ListItem dense divider>
                <ListItemIcon style={{ color: stringToColor(delivery.courier.full_name) }}>
                  <LocalShipping />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <b style={{ color: stringToColor(delivery.courier.full_name) }}>
                      {delivery.courier.full_name}
                    </b>
                  }
                />
              </ListItem>
            )}
            <ListItem dense divider>
              <ListItemIcon>
                <Store />
              </ListItemIcon>
              <ListItemText
                primary={
                  <div>
                    {}
                    {Object.keys(warehouses).map(id => (
                      <div
                        key={id}
                        className={cn(
                          classes.warehouse,
                          warehouses[id].completed && delivery.courier && classes.completed,
                          !warehouses[id].completed && delivery.courier && classes.fail,
                        )}
                      >
                        {warehouses[id].warehouse.name}
                        <b style={{ marginLeft: '8px' }}>
                          {warehouses[id].shipment_place_pointers.join(', ')}
                        </b>{' '}
                        <b style={{ marginLeft: '8px' }}>{warehouses[id].invoices.join(', ')}</b>
                        {delivery.courier && (
                          <React.Fragment>
                            {warehouses[id].completed ? <CheckCircle /> : <HighlightOff />}
                          </React.Fragment>
                        )}
                      </div>
                    ))}
                  </div>
                }
              />
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                <LocalMall />
              </ListItemIcon>
              <ListItemText
                primary={
                  <div>
                    {(delivery.delivery_zone || delivery.shipment_place_pointer) && (
                      <React.Fragment>
                        <React.Fragment>
                          {delivery.delivery_zone && (
                            <React.Fragment>
                              Зона: <b />
                              {delivery.delivery_zone.name}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      </React.Fragment>
                    )}
                  </div>
                }
              />
            </ListItem>
          </React.Fragment>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = store => {
  const user = store.get('user');
  return {
    user: user.data,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Delivery));
