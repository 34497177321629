import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 2,
  },
});

const ManufacturersDialog = ({
  classes,
  opened,
  type,
  toggleDialog,
  handleChangeFeild,
  name,
  slug,
  addManufacturer,
  editManufacturer,
}) => {
  const content = () => {
    if (type === 'add_manufacturer') {
      return (
        <React.Fragment>
          <DialogTitle id="form-dialog-title">Создание нового производителя</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Название производителя"
              type="text"
              value={name}
              name="name"
              fullWidth
              onChange={e => handleChangeFeild(e.target.name, e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialog}>
              Отмена
            </Button>
            <Button onClick={addManufacturer} color="primary">
              Создать
            </Button>
          </DialogActions>
        </React.Fragment>
      );
    }
    if (type === 'edit_manufacturer') {
      return (
        <React.Fragment>
          <DialogTitle id="form-dialog-title">Редакторование производителя</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Название производителя"
              type="text"
              value={name}
              name="name"
              fullWidth
              onChange={e => handleChangeFeild(e.target.name, e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              label="slug"
              type="text"
              value={slug || ''}
              name="slug"
              fullWidth
              onChange={e => handleChangeFeild(e.target.name, e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialog}>
              Отмена
            </Button>
            <Button onClick={editManufacturer} color="primary">
              Сохранить
            </Button>
          </DialogActions>
        </React.Fragment>
      );
    }
    return <div />;
  };
  return (
    <Dialog
      open={opened}
      onClose={toggleDialog}
      aria-labelledby="form-dialog-title"
    >
      {content()}
    </Dialog>
  );
};

ManufacturersDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  editManufacturer: PropTypes.func.isRequired,
  handleChangeFeild: PropTypes.func.isRequired,
  addManufacturer: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default withStyles(styles)(ManufacturersDialog);
