// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { type Order } from 'types/entities/Order';
import { type Warehouse } from 'types/entities/Warehouse';
import { connect } from 'react-redux';
import FilterList from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';
import { setTopBarTitle } from 'components/top_bar/actions';
import OrderDialog from 'components/shop/order-dialog';
import { fetchOrder } from 'components/shop/order/actions';
import { type RightSideBarAction } from 'types/components';
import Timeline from '@material-ui/icons/Timeline';
import Search from '@material-ui/icons/Search';
import { withRouter } from 'react-router-dom';
import localStorage from 'lib/local-storage';
import { type OrderState, orderStates, orderStatesList } from 'types/entities/OrderState';
import { COURIER, PICK_UP } from 'types/entities/RealizationMethodType';
import { setRightSideBarActions } from 'components/right_side_bar/actions';
import fuzzyFilter from 'components/fuzzy_filter';
import moment from 'moment';
import { WarehouseFilter } from 'components/shop/warehouses-filter';
import { AddressesFilter } from 'components/shop/addresses-filter';
import { DatePicker } from 'components/date-picker';
import queryString from 'query-string';
import OrdersLayout from './view/OrdersLayout';
import OrdersTable from './view/OrdersTable';
import { fetchOrders } from './actions';
import { Controls } from './view/Controls';
import { Charts } from './view/Charts';
import { OrdersByWarehouses } from './view/OrdersByWarehouses';
import { Liked } from './view/Liked';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const fuzzyOptions = {
  extract: el =>
    `${el.user.phone} ${(el.realization_method && el.realization_method.recipient_phone) || ''}`,
  returnAllOnEmptyString: false,
  returnOriginal: true,
};

type Props = {
  fetchOrders: (query: any) => void,
  fetchOrder: (orderId: number) => Promise<any>,
  setTopBarTitle: (titile: string) => void,
  orders: Array<Order> | null,
  isFetching: boolean,
  history: any,
  location: any,
  setRightSideBarActions: (acriosn: Array<RightSideBarAction>) => void,
  totalCount: number,
};

type State = {|
  open: boolean,
  chartsOpen: boolean,
  stateSetFromQuery: boolean,
  phone: string,
  selectedStates: Array<OrderState>,
  selectedRealizationMethod: 'all' | typeof COURIER | typeof PICK_UP,
  start_date: any,
  end_date: any,
  readiness_time: any,
  onLinePayed: any,
  selectedWarehouse: Warehouse | null,
|};

const mapQueryToState = {
  'filter[start_date]': 'start_date',
  'filter[end_date]': 'end_date',
  'filter[phone]': 'phone',
  'filter[realization_method]': 'selectedRealizationMethod',
  'filter[state]': 'selectedStates',
  'filter[readiness_time]': 'readiness_time',
  'filter[onLinePayed]': 'onLinePayed',
};

export class OrdersContainer extends React.Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.stateSetFromQuery) return state;
    const nextState = { ...state, stateSetFromQuery: true };
    const queryObj = queryString.parse(props.location.search);
    Object.keys(queryObj).forEach(key => {
      if (key === 'filter[state]') {
        nextState.selectedStates = queryObj[key].split(',');
        return null;
      }
      if (['filter[start_date]', 'filter[end_date]', 'filter[readiness_time]'].includes(key)) {
        nextState[mapQueryToState[key]] = moment(queryObj[key]);
        return null;
      }
      nextState[mapQueryToState[key]] = queryObj[key];
    });
    return nextState;
  }
  state = {
    stateSetFromQuery: false,
    open: false,
    chartsOpen: false,
    phone: '',
    selectedStates: [orderStates.processing, orderStates.confirmed, orderStates.assembled],
    selectedRealizationMethod: 'all',
    start_date: moment().subtract(1, 'days'),
    end_date: moment(),
    readiness_time: null,
    selectedWarehouse: null,
    selectedAddress: null,
    liked: null,
  };

  componentDidMount() {
    this.getOrders();
    this.props.setTopBarTitle('Заказы');
    const ordersListSettings = localStorage.get('ordersListSettings');
    if (ordersListSettings) {
      this.setState(ordersListSettings);
    }
    const actionsForRightSideBar: Array<RightSideBarAction> = [
      {
        key: 'toggleCarts',
        icon: <Timeline />,
        name: 'Отчет',
        action: () => this.toggleCharts(),
      },
    ];
    this.props.setRightSideBarActions(actionsForRightSideBar);
  }

  getOrders = () => {
    const query = {};
    if (this.state.liked !== null) {
      query['filter[liked]'] = JSON.stringify(this.state.liked);
    }
    if (this.state.start_date) {
      query['filter[start_date]'] = this.state.start_date.format('YYYY-MM-DD');
    }
    if (this.state.readiness_time) {
      query['filter[readiness_time]'] = this.state.readiness_time.format('YYYY-MM-DD');
    }
    if (this.state.end_date) {
      query['filter[end_date]'] = this.state.end_date.format('YYYY-MM-DD');
    }
    if (this.state.selectedStates.length && !this.state.selectedStates.includes(orderStates.all)) {
      query['filter[state]'] = this.state.selectedStates.join(',');
    }
    if (this.state.selectedStates.includes(orderStates.all)) {
      delete query['filter[realization_method]'];
    }
    if (this.state.phone) {
      query['filter[phone]'] = this.state.phone;
    }
    if (this.state.onLinePayed) {
      query['filter[onLinePayed]'] = this.state.onLinePayed;
    }
    if (this.state.selectedRealizationMethod !== 'all') {
      query['filter[realization_method]'] = this.state.selectedRealizationMethod;
    }
    this.props.history.replace(`/shop/orders?${queryString.stringify(query)}`);
    this.props.fetchOrders(query);
  };

  toggleCharts = () => this.setState(({ chartsOpen }) => ({ chartsOpen: !chartsOpen }));

  updateLocalStorage = (data: Object) => {
    const prevSettings = localStorage.get('ordersListSettings');
    const nextSettings = prevSettings ? { ...prevSettings, ...data } : data;
    localStorage.set('ordersListSettings', nextSettings);
  };

  handleToggleOrderDialog = () => this.setState(state => ({ open: !state.open }));

  changePhone = (phone: string) => this.setState({ phone });

  handleOpenOrder = (orderId: number) => () =>
    this.props.fetchOrder(orderId).then(() => this.handleToggleOrderDialog());

  changeSelectedStates = (state: OrderState) => {
    const nextStates = [];
    let selectedStates = this.state.selectedStates;
    if (state === orderStates.all) {
      return this.setState(({ selectedStates }) => ({
        selectedStates: selectedStates.includes(orderStates.all) ? [] : [orderStates.all],
      }));
    }
    if (selectedStates.includes(orderStates.all)) {
      selectedStates = selectedStates.filter(state => state !== orderStates.all);
    }
    selectedStates.forEach(prevState => (prevState === state ? null : nextStates.push(prevState)));
    if (!selectedStates.includes(state)) nextStates.push(state);
    this.setState({ selectedStates: nextStates });
    this.updateLocalStorage({ selectedStates: nextStates });
  };

  changeRealizationMethod = (
    selectedRealizationMethod: 'all' | typeof COURIER | typeof PICK_UP,
  ) => {
    this.setState({ selectedRealizationMethod });
    this.updateLocalStorage({ selectedRealizationMethod });
  };

  handleChange = (name: string, value: any) => this.setState({ [name]: value });

  filterOrders = () => {
    let result = this.props.orders || [];

    if (this.state.selectedWarehouse) {
      result = result.filter(order =>
        Boolean(
          order.realization_method &&
            order.realization_method.pick_up_point &&
            order.realization_method.pick_up_point.vendor_id ===
              this.state.selectedWarehouse.vendor_id,
        ),
      );
    }

    if (this.state.selectedAddress) {
      result = result.filter(order =>
        Boolean(
          order.realization_method &&
            order.realization_method.address &&
            order.realization_method.address.street.name === this.state.selectedAddress,
        ),
      );
    }

    return result;
  };

  render() {
    const filtredOrders = this.filterOrders();
    const warehouses =
      this.props.orders &&
      this.props.orders
        .filter(order => order.realization_method && order.realization_method.pick_up_point)
        .map(order => order.realization_method.pick_up_point);
    const addresses =
      this.props.orders &&
      this.props.orders
        .filter(order => order.realization_method && order.realization_method.address)
        .map(order => order.realization_method.address.street.name);
    return (
      <React.Fragment>
        <OrdersLayout
          controls={
            <Controls
              addressFilter={
                <AddressesFilter
                  onChange={this.handleChange}
                  address={this.state.selectedAddress}
                  addresses={addresses}
                />
              }
              warehouseFilter={
                <WarehouseFilter
                  onChange={this.handleChange}
                  warehouse={this.state.selectedWarehouse}
                  warehouses={warehouses}
                />
              }
              getOrdersButton={
                <Button color="secondary" onClick={this.getOrders}>
                  <Search />
                </Button>
              }
              onLinePayed={
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.onLinePayed}
                      onChange={() => this.setState({ onLinePayed: !this.state.onLinePayed })}
                    />
                  }
                  label="Онлайн оплата"
                />
              }
              selectedStates={this.state.selectedStates}
              selectedRealizationMethod={this.state.selectedRealizationMethod}
              changeSelectedStates={this.changeSelectedStates}
              changeRealizationMethod={this.changeRealizationMethod}
              changePhone={this.changePhone}
              orders={filtredOrders}
              phone={this.state.phone}
              liked={
                <Liked
                  liked={this.state.liked}
                  onChange={value => this.handleChange('liked', value)}
                />
              }
              datePicker={
                <DatePicker
                  from={this.state.start_date}
                  to={this.state.end_date}
                  setFrom={value => this.handleChange('start_date', value)}
                  setTo={value => this.handleChange('end_date', value)}
                  labelFrom="Дата создания от"
                  labelTo="Дата создания до"
                />
              }
              readinessTimePicker={
                <DatePicker
                  from={this.state.readiness_time}
                  setFrom={value => this.handleChange('readiness_time', value)}
                  labelFrom="Дата готовности"
                />
              }
            />
          }
          charts={
            this.state.chartsOpen && (
              <Charts
                orders={filtredOrders}
                warehouses={warehouses}
                ordersByWarehouses={
                  <OrdersByWarehouses orders={filtredOrders} warehouses={warehouses} />
                }
              />
            )
          }
          isFetching={this.props.isFetching}
          ordersTable={<OrdersTable list={filtredOrders} handleOpenOrder={this.handleOpenOrder} />}
        />
        <OrderDialog
          handleClose={this.handleToggleOrderDialog}
          open={this.state.open}
          getOrders={this.getOrders}
        />
      </React.Fragment>
    );
  }
}

OrdersContainer.propTypes = {
  setTopBarTitle: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  orders: PropTypes.any,
};

const mapStateToProps = store => {
  const state = store.getIn(['shop', 'orders']);
  return {
    isFetching: state.get('isFetching'),
    orders: state.get('list'),
    totalCount: state.get('totalCount'),
  };
};

export default connect(mapStateToProps, {
  fetchOrders,
  setTopBarTitle,
  fetchOrder,
  setRightSideBarActions,
})(withRouter(OrdersContainer));
