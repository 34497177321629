// @flow
import {
  TOGGLE_ASSEMBLY_ORDER_DIALOG,
} from 'types/actions/AssemblyOrder';
import { type Action } from 'types/actions/Action';
import { type AssemblyOrderDialogState } from 'types/store/AssemblyOrderDialog';

export const assemblyOrderDialogInitialState: AssemblyOrderDialogState = {
  open: false,
};

const assemblyOrderDialog = (
  state: AssemblyOrderDialogState = assemblyOrderDialogInitialState,
  action: Action,
): any => {
  switch (action.type) {
    case TOGGLE_ASSEMBLY_ORDER_DIALOG: {
      return {
        ...state,
        open: !state.open,
      };
    }
    default: {
      return state;
    }
  }
};

export default assemblyOrderDialog;
