// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { type Theme } from 'types/components/Theme';
import PriceBlock from 'components/price-block';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
type Props = {|
  classes: Object,
  totalCost: number | null,
|};

const Total = ({
  classes,
  totalCost,
}: Props) => (
  <div className={classes.root}>
    <PriceBlock title="Сумма заказа" cost={totalCost} />
  </div>
);

Total.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Total);
