import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Choiser from 'components/choiser';
import LoadingWrapper from 'components/loading_wrapper';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
  },
  row: {
    marginTop: theme.spacing.unit,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  withImage: {
    minWidth: '200px',
  },
  textField: {
    marginRight: theme.spacing.unit * 2,
    display: 'flex',
    alignItems: 'flex-end',
    minWidth: '200px',
  },
  between: {
    '& > div': {
      marginRight: theme.spacing.unit,
      maxWidth: '140px',
    },
  },
  saveButton: {
    marginLeft: theme.spacing.unit,
  },
});

const ProductsControls = ({
  classes,
  manufacturers,
  fetchManufacturersIfNeeded,
  manufacturersIsFetching,
  handleChange,
  selectedManufacturer,
  selectedBrand,
  selectedCategory,
  name,
  barcode,
  brands,
  getBrands,
  brandsIsFetching,
  fetchProducts,
  fetchCategoriesList,
  categories,
  categoriesIsFetching,
  withImage,
  showAssignCategory,
  selectedCategoryForAssign,
  assignCategory,
  filterInStock,
  dateBetween,
  saveTableButton,
  description,
  type,
  typeSelect,
  series,
  ingredients,
  howToUse,
  published,
}) => {
  const fetchManufacturers = () => {
    if (!manufacturers.length) {
      fetchManufacturersIfNeeded();
    }
  };
  const fetchBrands = () => {
    if (!brands.length) {
      getBrands();
    }
  };
  const fetchCategories = () => {
    if (!categories.length) {
      fetchCategoriesList();
    }
  };
  return (
    <div>
      <Paper className={classes.paper}>
        <div className={classes.row}>
          <TextField
            label="Штрих-код"
            className={classes.textField}
            value={barcode}
            name="barcode"
            onChange={e => handleChange(e.target.name, e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') fetchProducts();
            }}
            margin="none"
          />
          <TextField
            label="Название"
            className={classes.textField}
            value={name}
            name="name"
            onChange={e => handleChange(e.target.name, e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') fetchProducts();
            }}
            margin="none"
          />
          <span onClick={fetchManufacturers} className={classes.textField}>
            <LoadingWrapper loading={manufacturersIsFetching}>
              <Choiser
                fullWidth={false}
                inputLabel="Производитель"
                suggestions={manufacturers}
                selected={selectedManufacturer}
                returnSuggestion={suggestion => handleChange('selectedManufacturer', suggestion)}
              />
            </LoadingWrapper>
          </span>
          <span onClick={fetchBrands} className={classes.textField}>
            <LoadingWrapper loading={brandsIsFetching}>
              <Choiser
                fullWidth={false}
                inputLabel="Бренд"
                suggestions={brands}
                selected={selectedBrand}
                returnSuggestion={suggestion => handleChange('selectedBrand', suggestion)}
              />
            </LoadingWrapper>
          </span>
          <span onClick={fetchCategories} className={classes.textField}>
            <LoadingWrapper loading={categoriesIsFetching}>
              <Choiser
                fullWidth={false}
                inputLabel="Категория"
                suggestions={[{ id: 0, name: 'Без категории', is_publish: true }, ...categories]}
                selected={selectedCategory}
                returnSuggestion={suggestion => handleChange('selectedCategory', suggestion)}
              />
            </LoadingWrapper>
          </span>
          <span className={classes.textField}>{typeSelect}</span>
        </div>
        <div className={classes.row}>
          <span className={classes.textField}>
            <FormControl className={classes.withImage}>
              <InputLabel htmlFor="age-simple">Наличие картинки</InputLabel>
              <Select
                value={withImage}
                onChange={e => handleChange('withImage', e.target.value)}
                inputProps={{
                  name: 'age',
                  id: 'age-simple',
                }}
              >
                <MenuItem value="all">
                  <em>Все</em>
                </MenuItem>
                <MenuItem value="1">С картинкой</MenuItem>
                <MenuItem value="0">Без картинки</MenuItem>
              </Select>
            </FormControl>
          </span>
          <div className={classes.between}>{dateBetween}</div>
          {type}
          {series}
          {description}
          {ingredients}
          {howToUse}
          <Button variant="raised" color="secondary" onClick={fetchProducts}>
            Найти
          </Button>
          <div className={classes.saveButton}>{saveTableButton}</div>
        </div>
        <div className={classes.row}>
          {filterInStock}
          {published}
        </div>
      </Paper>
    </div>
  );
};
ProductsControls.propTypes = {
  classes: PropTypes.object.isRequired,
  manufacturers: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  fetchManufacturersIfNeeded: PropTypes.func.isRequired,
  manufacturersIsFetching: PropTypes.bool.isRequired,
  brandsIsFetching: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  getBrands: PropTypes.func.isRequired,
  fetchProducts: PropTypes.func.isRequired,
  fetchCategoriesList: PropTypes.func.isRequired,
  selectedManufacturer: PropTypes.any,
  selectedBrand: PropTypes.any,
  selectedCategory: PropTypes.any,
  barcode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  withImage: PropTypes.string.isRequired,
  categoriesIsFetching: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ProductsControls);
