// @flow
import {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_TREE_SUCCESS,
  FETCH_CATEGORIES_LIST_SUCCESS,
  type RequestCategories,
  type ReceiveCategoriesList,
  type ReceiveCategoriesTree,
  type Action,

} from 'types/actions';
import Immutable from 'immutable';

type CategoriesState = Object;

const categoriesInitialState: CategoriesState = Immutable.Map({
  isFetching: false,
  tree: Immutable.Map(),
  list: Immutable.Map(),
});

const categories: Function = (
  state: CategoriesState = categoriesInitialState,
  action: Action,
): CategoriesState => {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST: {
      return state.set('isFetching', action.value);
    }
    case FETCH_CATEGORIES_TREE_SUCCESS: {
      return state.merge({
        isFetching: false,
        tree: Immutable.List(action.tree),
      });
    }
    case FETCH_CATEGORIES_LIST_SUCCESS: {
      return state.merge({
        isFetching: false,
        list: Immutable.Map(action.list),
      });
    }
    default: {
      return state;
    }
  }
};

export default categories;
