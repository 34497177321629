// @flow

import { type Warehouse } from 'types/entities/Warehouse';

export type Markup = {|
  id: number,
  value: string,
|};

export type Category = {
  id: number,
  name: string,
  markup?: Markup | null,
  meta_tag_data?: any,
  is_publish?: boolean,
  __children?: Array<Category> | null,
};

export type CategoriesTree = Array<Category>

export type Alias = {|
  id: number,
  value: string,
|}

export type Manufacturer = {|
  id: number,
  name: string,
  slug?: string | null,
  aliases?: Array<Alias> | Array<any>,
  markup?: Markup | null,
  brands?: Array<Brand> | Array<any>, // eslint-disable-line no-use-before-define
|}

export type Brand = {|
  id: number,
  name: string,
  adapted_name: string | null,
  slug?: string | null,
  aliases?: Array<Alias> | Array<any>,
  manufacturer?: Manufacturer | null,
  markup?: Markup | null,
|}

export type Supplier = {|
  alias: string,
  name: string,
|}

export type Name = {|
  id: number,
  value: string,
  supplier?: Supplier,
  created_time?: number,
|}

export type Barcode = {|
  id: number,
  value: string,
|}

export type Slug = string | null;

export type StockItem = {|
  id: number,
  warehouse: Warehouse,
  vendor_id: string,
  price: number,
  stock: number,
  created_time: number,
  update_time: number,
  import_process_id: string,
|}

export type Image = {|
  id: number,
  width: number,
  height: number,
  public_path: string,
  uri: string,
|};

export type ProductPreview = {
  id: number,
  name: Name,
  barcode: Barcode,
  slug: Slug,
  brand: Brand | null,
  preview: $FlowFixMe,
  manufacturer: Manufacturer | null,
  category: Category | null,
  offer_price: number | null,
  base_price: number | null,
  quantity: number | null,
};

export type ProductPricingReport = {|
    active: Array<any>,
    wholesale_price: number | null,
    min_retail_price: number | null,
    mark_up_report: {
      fixed_prices: {
        product: number | null
      },
      mark_ups: {
        product: number | string | null,
        brand: number | string | null,
        manufacturer: number | string | null,
        category: number | string | null,
        default: number | string | null
      },
      active: Array<string>,
      source_price: number | null,
      total_price: number | null
    },
    total_price: number | null
|};

export type Role = string;

export type User = {|
  roles?: Array<Role> | Array<any> | null,
  phone: string,
  id: number,
  first_name: string | null,
  father_name: string | null,
  full_name: string | null,
  last_name: string | null,
  email: string | null,
  is_subscribed_email: boolean,
|}

export type ImageLog = {|
  image_product: Image,
  create_time: string,
  user: User,
|};
