// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { type Theme, type ReactComponent } from 'types/components';
import { type typeTimeFilter } from '../typeTimeFilter';

const styles = (theme: Theme) => ({
  root: {
    '& button': {
      marginRight: theme.spacing.unit * 2,
    },
  },
});

type ProvidedProps = {|
  classes: any,
|};

type Props = {|
  onChange: Function,
  time: typeTimeFilter,
|};

const controls = [
  { key: 'past', name: 'Прошедшие' },
  { key: 'present', name: 'Текущие' },
  { key: 'future', name: 'Будущие' },
];

const TimeFilterComponent = ({
  classes,
  onChange,
  time,
}: {|
  ...Props,
  ...ProvidedProps,
|}) => (
  <div className={classes.root}>
    {controls.map(control => (
      <FormControlLabel
        key={control.key}
        control={
          <Checkbox
            checked={time[control.key]}
            onChange={() => onChange(control.key)}
          />
        }
        label={control.name}
      />
    ))}
  </div>
);

export const TimeFilter: ReactComponent<Props> = withStyles(styles)(
  TimeFilterComponent,
);
