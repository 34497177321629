// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { type ReactComponent } from 'types/components/ReactComponent';
import { type OrderState, orderStatesList } from 'types/entities/OrderState';
import { type Warehouse } from 'types/entities/Warehouse';
import { type Order } from 'types/entities/Order';
import Paper from '@material-ui/core/Paper';
import Close from '@material-ui/icons/Close';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import cn from 'classnames';
import { formatNumber } from 'lib/formatting';
import { COURIER, PICK_UP } from 'types/entities/RealizationMethodType';
import { OrderStateLabel } from 'components/shop/order-state-label';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  CartesianAxis,
} from 'recharts';
import moment from 'moment';

const countColor = '#8884d8';
const totalCostColor = '#82ca9d';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  count: {
    fontWeight: 900,
    color: countColor,
  },
  totalCost: {
    fontWeight: 900,
    color: totalCostColor,
  },
  warehouses: {
    marginTop: theme.spacing.unit * 2,
  },
});

const realizationMethods = [
  { type: 'all', name: 'Все' },
  { type: COURIER, name: 'Доставка' },
  { type: PICK_UP, name: 'Самовывоз' },
];

type PassedProps = {|
  classes: Object,
|};

type Props = {|
  orders: Array<Order> | null,
  warehouses: Array<Warehouse> | null,
  ordersByWarehouses: any,
|};

const ChartsComponent = ({
  classes,
  orders,
  ordersByWarehouses,
}: {|
  ...PassedProps,
  ...Props,
|}) => {
  const datesMap = {};
  const ordersWithDate = [];
  if (orders) {
    orders.forEach(order => {
      const date = moment(order.created_at).format('YYYY-MM-DD');
      if (datesMap[date]) {
        datesMap[date] = {
          ...datesMap[date],
          count: datesMap[date].count + 1,
          cost: datesMap[date].cost + order.cost,
        };
        return null;
      }
      datesMap[date] = { name: date, count: 1, cost: order.cost };
      ordersWithDate.push({
        ...order,
        date: moment(order.created_at).format('YYYY-MM-DD'),
      });
    });
  }
  const data = Object.keys(datesMap)
    .sort((a, b) => (a > b ? 1 : -1))
    .map(date => datesMap[date]);
  return (
    <Paper className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <LineChart
            width={1000}
            height={300}
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            <Line
              label={({ x, y, stroke, value }) => (
                <text
                  x={x}
                  y={y}
                  dy={-4}
                  fill={stroke}
                  fontSize={10}
                  textAnchor="middle"
                >
                  {formatNumber(value)}
                </text>
              )}
              type="monotone"
              dataKey="cost"
              stroke={totalCostColor}
              activeDot={{ r: 8 }}
              name="Сумма"
            />
            <Line
              type="monotone"
              dataKey="count"
              stroke={countColor}
              name="Заказы"
            />
          </LineChart>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.total}>
            <div className={classes.totalCost}>
              Сумма:{' '}
              {(orders &&
                formatNumber(
                  orders.reduce((prev, curr) => prev + curr.cost, 0),
                )) ||
                0}{' '}
              ₽
            </div>
            <div className={classes.count}>
              Заказы: {(orders && orders.length) || 0}
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          {ordersByWarehouses}
        </Grid>
      </Grid>
    </Paper>
  );
};
export const Charts: ReactComponent<Props> = withStyles(styles)(
  ChartsComponent,
);
